import * as React from 'react'
import {useState} from 'react'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'
import {height, width} from '../../../styles/mixins/sizing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import LazyImage from '../../atoms/LazyImage'

export interface MediaProps {
  maxWidth?: string | number
  width?: string | number
  height?: string | number
}

export type Props = {
  onLoaded?: () => void
  hasLightbox?: boolean
  mainThumbSrc?: string
  sources: string[]
  desktop?: MediaProps
} & MediaProps

const View = styled.div`
  position: relative;
  ${width};
  ${height};
`

const Count = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: ${space.xs}px;
  bottom: ${space.xs}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.gray300};
  color: #fff;
  font-weight: 700;
`

const Thumbnails = styled.div`
  width: 100%;
  height: 100%;
`
const Thumbnail = styled(LazyImage)``

export default function LightboxImages(props: Props) {
  const {
    onLoaded,
    sources,
    width,
    hasLightbox = true,
    height,
    maxWidth,
    mainThumbSrc,
  } = props
  const [showLightbox, setShowLightbox] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const mainSrc = sources[imageIndex]
  const handleCloseRequest = () => {
    setShowLightbox(false)
  }
  const handleClickThumbnail = (index: number) => {
    if (hasLightbox) {
      setImageIndex(index)
      setShowLightbox(true)
    }
  }
  // const thumbnails = sources.map((source, index) => {
  //   return (
  //     <Thumbnail onClick={() => handleClickThumbnail(index)} src={source.src} />
  //   )
  // })
  const prevSrc =
    sources.length > 0 &&
    sources[(imageIndex + sources.length - 1) % sources.length]
  const nextSrc =
    sources.length > 0 && sources[(imageIndex + 1) % sources.length]

  const defaultLightBoxProps = {
    mainSrc: mainSrc,
    reactModalStyle: {
      overlay: {zIndex: zIndex.dialog},
    },
    onCloseRequest: handleCloseRequest,
    onMoveNextRequest: () => setImageIndex((imageIndex + 1) % sources.length),
    onMovePrevRequest: () =>
      setImageIndex((imageIndex + sources.length - 1) % sources.length),
  }
  const lightboxProps =
    sources.length > 1
      ? {
          ...defaultLightBoxProps,
          prevSrc,
          nextSrc,
        }
      : defaultLightBoxProps
  return (
    <View height={height} width={width}>
      <Thumbnails height={height} width={width}>
        <Thumbnail
          hasIndicator={true}
          height={height}
          maxWidth={maxWidth}
          objectFit="cover"
          onClick={() => handleClickThumbnail(0)}
          onLoaded={onLoaded}
          src={mainThumbSrc || mainSrc}
          width={width}
        />
      </Thumbnails>
      {showLightbox && <Lightbox {...lightboxProps} />}
      {sources.length > 1 && <Count>{sources.length}</Count>}
    </View>
  )
}
