import * as React from 'react'
import ArrayHistoryItem from '../../components/molecules/ArrayHistoryItem'
import CheckboxHistoryItem from '../../components/molecules/CheckboxHistoryItem'
import FillTextHistoryItem from '../../components/molecules/FillTextHistoryItem'
import ImageHistoryItem from '../../components/molecules/ImageHistoryItem'
import MultiHistoryItem from '../../components/molecules/MultiHistoryItem'
import TextHistoryItem from '../../components/molecules/TextHistoryItem'
import {TreeLeafType} from '../../domain/note/snapshot/Tree'

export interface Props {
  userId: string
  userIconUrl: string
  name: string
  label: string
  date: Date
  previous: any
  data: any
  fullname: string
  type: string
  attr: KeySignature
}

const historyItemMap: Record<TreeLeafType, React.ComponentType<any>> = {
  address: TextHistoryItem,
  array: ArrayHistoryItem,
  multiText: MultiHistoryItem, // TODO: multiText用のコンポーネント作る
  checkbox: CheckboxHistoryItem,
  radio: CheckboxHistoryItem,
  text: TextHistoryItem,
  date: TextHistoryItem,
  image: ImageHistoryItem,
  freeText: TextHistoryItem,
  fillText: FillTextHistoryItem,
  tel: TextHistoryItem,
  number: TextHistoryItem,
}

export default function NoteHistoryItem(props: Props) {
  const {type} = props
  const View = historyItemMap[type] || TextHistoryItem
  return React.createElement(View, props)
}
