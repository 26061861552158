import {endOfDay, format, isValid, parseISO} from 'date-fns'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {getFormValues, isDirty, isInvalid, reduxForm} from 'redux-form'
import View, {Props} from '../../components/molecules/EditableText'
import {NoteTimelineItemType} from '../../domain/note/timeline/NoteTimeline'
import {
  stringToTimestamp,
  dateToDateStr,
  dateToTimeStr,
} from '../../domain/note/track/NoteTrack'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import FormService from '../../infra/services/FormService'
import {HandleSubmitProps} from '../../lib/form/types'
import {getCurrentNoteId, getCurrentTrackDate} from '../../selectors/note'
import {Forms} from '../../services/forms'
import UploadResourceUseCase from '../../useCases/resource/UploadResourceUseCase'
import PostNoteTrackUseCase from '../../useCases/track/PostNoteTrackUseCase'
import UpdateNoteTrackUseCase from '../../useCases/track/UpdateNoteTrackUseCase'

const inputToTimestamp = (date: string, time: string): Date | null =>
  stringToTimestamp(`${date} ${time || '00:00'}`)

export interface OwnProps {
  type: NoteTimelineItemType
  trackId?: string
  content: string
  dismiss: () => void
  editing: boolean
  name: string
  onComplete: () => void
  placeholder: string
  shrinkable?: boolean
  timestamp?: Date
  image?: string
}

export type FormData = any

export default compose<Props & HandleSubmitProps, OwnProps>(
  connect(
    (state: IReduxState, ownProps: OwnProps): Props => {
      const noteId = getCurrentNoteId(state)
      const {trackId = ''} = ownProps
      const form = Forms.track(trackId)
      const currentTrackDate = getCurrentTrackDate(state)
      const {timestamp} = ownProps
      const initialTimestamp = timestamp || currentTrackDate
      const {image = ''} =
        (getFormValues(form)(state) as any) /*fixme: type*/ || {}
      return {
        ...ownProps,
        form,
        text: ownProps.content,
        timestampEditable: true,
        initialValues: {
          [ownProps.name]: ownProps.content,
          timestampDate: dateToDateStr(initialTimestamp),
          timestampTime: dateToTimeStr(
            timestamp ? initialTimestamp : new Date(),
          ),
          image: ownProps.image,
        },
        image,
        onClickCancelButton: () => {
          FormService.create().reset(form)
          ownProps.dismiss && ownProps.dismiss()
        },
        submitForm: v => {
          const {timestampDate, timestampTime, ...data} = v
          const timestamp = inputToTimestamp(timestampDate, timestampTime)

          UseCaseCommander.create().command(PostNoteTrackUseCase)({
            noteId,
            trackId: ownProps.trackId,
            type: ownProps.type,
            onComplete: () => ownProps.onComplete(),
            data,
            timestamp,
          })
        },
        submitDisabled:
          !isDirty(form)(state) ||
          isInvalid(form)(state) ||
          useCaseSelectors.isProcessing(UploadResourceUseCase)(state),
        isProcessing: useCaseSelectors.isProcessing(PostNoteTrackUseCase)(
          state,
        ),
      }
    },
  ),
  reduxForm<FormData>({
    enableReinitialize: false,
  }),
)(View)
