import {formatDistance, parseISO} from 'date-fns'
import {ja} from 'date-fns/locale'
import {createSelector} from 'reselect'
import {UserNotificationListItemProps} from '../../../components/organisms/UserNotificationList'
import UserTimeline from '../../../domain/account/UserTimeline'
import Note from '../../../domain/note/Note'
import {getUserTimeline} from '../../../infra/redux/api/selectors'
import {getNotes} from '../note'

export const getUserNotifications = (limit: number) =>
  createSelector(
    getUserTimeline,
    getNotes,
    (
      userTimelines: UserTimeline[],
      notes: Note[],
    ): UserNotificationListItemProps[] => {
      const list = userTimelines.map(timeline => {
        const {timestamp, id, message, noteId} = timeline
        const date = parseISO(timestamp)
        const [note] = notes.filter(n => n.id === noteId)
        const {title: noteTitle = '', personName: notePersonName = ''} =
          note || {}
        return {
          id,
          message,
          noteId,
          noteTitle,
          notePersonName,
          date:
            timestamp &&
            `${formatDistance(date, new Date(), {
              locale: ja,
            })}前`,
        }
      })
      return limit ? list.filter((v, i) => i < limit) : list
    },
  )
