import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View, {Props as ViewProps} from '../../components/organisms/NoteTrackItem'
import {NoteTrackDataType} from '../../domain/note/track/NoteTrack'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {
  NoteTrackDataTypeLabelMap,
  NoteTrackDataTypeQuantityUnitMap
} from '../../selectors/note/track'
import {SpacingBreakPointsProps} from '../../styles/mixins/spacing'
import DeleteNoteTrackUseCase, {Params as DeleteNoteTrackUseCaseParams} from '../../useCases/track/DeleteNoteTrackUseCase'

export type Props = SpacingBreakPointsProps &
  ViewProps & {
    trackId: string
    type: NoteTrackDataType
    quantity?: number
  }

export default function NoteTrackItem(props: Props) {
  const {type, trackId, quantity, ...rest} = props
  const noteId = useSelector(getCurrentNoteId)
  const label = NoteTrackDataTypeLabelMap[type] || ''
  const handleClickRemoveButton = useCallback(() => {
    UseCaseCommander.create().command<DeleteNoteTrackUseCaseParams>(
      DeleteNoteTrackUseCase,
    )({noteId, trackId})
  }, [trackId])
  return React.createElement(View, {
    ...rest,
    type,
    trackId,
    content: quantity
      ? `${label} ${quantity}${NoteTrackDataTypeQuantityUnitMap[type]}`
      : label,
    onClickRemoveButton: handleClickRemoveButton,
  })
}
