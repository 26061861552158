export enum MemberStatusType {
  member = 'member',
  notMember = 'not_member',
  requesting = 'requesting',
  notLogin = 'not_login',
}

export default interface NoteInvitation {
  token: string
  noteTitle: string
  noteImageUrl: string
  inviterName: string
  memberStatus: MemberStatusType
}

export const NullNoteInvitation: NoteInvitation = {
  noteTitle: '',
  noteImageUrl: '', // ISSUE: 未設定の場合、カバーっぽい画像を使う
  inviterName: '招待者', // (nickname || fullname) が入るはず。本来、空文字になりえないが、万が一無い場合はこのデフォルト値を使う。
  memberStatus: MemberStatusType.notLogin,
  token: '',
}
