import {createSelector} from 'reselect'
import Note from '../../../domain/note/Note'
import {NoteSchema, NoteTimelineItemSchema} from '../../../infra/redux/api/schemata'
import * as apiSelectors from '../../../infra/redux/api/selectors'
import {getFollowingList, getOwnedList} from '../index'

export const getNotes = createSelector(
  apiSelectors.getAllData(NoteSchema),
  (notes: Note[]) => {
    return notes
  },
)

export const getTimelineItems = apiSelectors.getAllData(NoteTimelineItemSchema)

export const hasNote = createSelector(
  getOwnedList,
  getFollowingList,
  (ownedList: Note[], followingList: Note[]): boolean => {
    return (
      ownedList &&
      !!ownedList.length &&
      (followingList && !!followingList.length)
    )
  },
)
