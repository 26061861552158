import * as React from 'react'
import {useCallback} from 'react'
import styled from 'styled-components'
import NoteId from '../../../domain/note/NoteId'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Divider from '../../atoms/Divider'
import ListItem from '../../layouts/ListItem'
import Empty from '../../molecules/Empty'

const View = styled.div``

const ListItemContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export interface UserNotificationListItemProps {
  id: string
  noteId: NoteId
  noteTitle: string
  notePersonName: string
  message: string
  date: string
}

export interface Props {
  list: UserNotificationListItemProps[]
  onClickItem: (item: UserNotificationListItemProps) => void
}

export default function UserNotificationList(props: Props) {
  const {list, onClickItem} = props
  const items = list.map(item => {
    const {date, message, id} = item
    const handleClickItem = useCallback(() => {
      onClickItem(item)
    }, [item.id])
    return (
      <React.Fragment key={id}>
        <ListItem onClick={handleClickItem}>
          <ListItemContent>
            <div>
              <Body as="span" color={colors.link} type="body2">
                {item.noteTitle}
              </Body>
              <Body as="span" color={colors.body} type="body2">
                に{message}しました。
              </Body>
            </div>
            <Body color={colors.gray300} type="overline">
              {date}
            </Body>
          </ListItemContent>
        </ListItem>
        <Divider />
      </React.Fragment>
    )
  })
  return (
    <View>
      {items.length > 0 ? (
        items
      ) : (
        <Empty body={<span>お知らせが表示されます！</span>} />
      )}
    </View>
  )
}
