import INoteMemberRepository from '../../../domain/note/INoteMemberRepository'
import Note from '../../../domain/note/Note'
import {
  DefaultAcceptRelationTypeValue,
  UpdatableRelationType,
  UpdatableTrackRelationType
} from '../../../domain/note/NoteRelatedUser'
import AppAPI from '../../api/AppAPI'
import AppAPIClient from '../../api/AppAPIClient'
import {actions} from '../../redux/api/entities'
import {NoteSchema} from '../../redux/api/schemata'
import {getNoteById} from '../../redux/api/selectors'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteMemberRepository implements INoteMemberRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteMemberRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteMemberRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  private mergedNoteWithState<AnyNote extends Note>({
    id,
    newProps,
  }: {
    id: string
    newProps: AnyNote
  }): AnyNote {
    const note = getNoteById(id)(this.redux.getState())
    const newNote = {...note, ...newProps}
    return newNote
  }

  public async getMembers(id: string): Promise<Note> {
    const client = await this.api.getClient()
    const res = await client.getNoteMembers({id})
    const note = this.mergedNoteWithState({
      id: res.id,
      newProps: res as Note,
    })
    this.redux.dispatch(actions.store(note, NoteSchema))
    return note
  }

  private async changeNoteMember(
    id: string,
    doChange: (client: AppAPIClient) => Promise<void>,
  ): Promise<Note> {
    const client = await this.api.getClient()
    await doChange(client)
    const res = await client.getNoteMembers({id})
    const note = this.mergedNoteWithState({
      id: res.id,
      newProps: res as Note,
    })
    this.redux.dispatch(actions.store(note, NoteSchema))
    return note
  }

  public async acceptNoteMember({
    id: noteId,
    userId,
  }: {
    id: string
    userId: string
  }): Promise<Note> {
    return this.changeNoteMember(noteId, async client => {
      await client.acceptNoteMember({
        noteId,
        userId,
        relationType: DefaultAcceptRelationTypeValue,
      })
    })
  }

  public async updateNoteMember({
    id: noteId,
    userId,
    relationType,
    trackRelationType,
  }: {
    id: string
    userId: string
    relationType: UpdatableRelationType
    trackRelationType: UpdatableTrackRelationType
  }): Promise<Note> {
    return this.changeNoteMember(noteId, async client => {
      await client.updateNoteMember({
        noteId,
        userId,
        relationType,
        trackType: trackRelationType,
      })
    })
  }

  public async deleteNoteMember({
    id: noteId,
    userId,
  }: {
    id: string
    userId: string
  }): Promise<Note> {
    return this.changeNoteMember(noteId, async client => {
      await client.deleteNoteMember({noteId, userId})
    })
  }
}
