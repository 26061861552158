import {css} from 'styled-components'
import {Breakpoint, breakpoints, defaultBreakpoints} from './breakpoints'

type Break = (cssProperties: any) => string

// const media: Record<keyof typeof breakpoints, Break> = Object.keys(
const media: any = Object.keys(
  breakpoints,
).reduce(
  (accumulator, label) => {
    return {
      ...accumulator,
      [label]: (args: TemplateStringsArray) => {
        return css`
          ${defaultBreakpoints.up(label as Breakpoint)} {
            ${args};
          }
        `
      },
    }
  },
  {} as any,
)

export default media
