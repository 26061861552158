import * as React from 'react'
import styled from 'styled-components'
import logo from '../../../assets/images/logo-h.png'
import space from '../../../styles/variables/space'

const View = styled.div`
  padding: 0 ${space.xs}px;
  img {
    width: 200px;
    height: auto;
  }
`

export default function Logo() {
  return (
    <View>
      <img src={logo} />
    </View>
  )
}
