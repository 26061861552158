import * as React from 'react'
import {
  BaseFieldProps,
  Field,
  GenericField,
  GenericFieldHTMLAttributes,
} from 'redux-form'
import {
  FieldProps,
  IFieldCustomProps,
  WrappedCustomFieldProps,
} from '../lib/form/types'
import {FieldValidator, FieldValidators} from '../lib/validators/types'
import {getValidates} from '../services/forms/getValidators'

const ReduxCustomField = Field as new () => GenericField<IFieldCustomProps>
/**
 * PresentationalなFieldをredux-formに対応させる
 *
 * @param [opts] - Options
 * @param [opts.withMeta] - props.meta を Field Componentに流すか否か
 * @return {<P extends {}>(WrappedField: React.ComponentType<P>) => (props: (IFieldProps<P> & P)) => any}
 */
export const withReduxField = (
  opts: {withMeta?: boolean} = {},
): any /*FIXME: types*/ => <P extends {}>(
  WrappedField: React.ComponentType<BaseFieldProps<P>>,
) => {
  /**
   * Field components
   *
   * @param props - Field MarginProps
   * @return {any}
   */
  const RenderWrappedField: React.ComponentType<WrappedCustomFieldProps> = (
    props: WrappedCustomFieldProps,
  ) => {
    const {input, meta, ...p} = props
    const fieldProps = opts.withMeta ? {...input, ...p, meta} : {...input, ...p}
    return <WrappedField {...fieldProps} />
  }

  // FIXME: displatyNameに対応させる
  return (props: FieldProps) => {
    const {validators} = props
    const validate =
      validators &&
      validators.map((v: FieldValidator) => {
        return v.validate
      })
    return (
      <ReduxCustomField
        {...props}
        component={RenderWrappedField}
        validate={validate}
      />
    )
  }
}

/**
 * PresentationalなFieldをReduxField用ラップコンポーネントにする
 * @param WrappedField
 * @constructor
 */
export const WrappedReduxField = (
  WrappedField: React.ComponentType<GenericFieldHTMLAttributes>,
) => {
  return (props: WrappedCustomFieldProps) => {
    const {input, meta, ...p} = props
    const fieldProps = {...input, ...p, meta}
    return <WrappedField {...fieldProps} />
  }
}

/**
 * カスタムPropsでラップされたFieldコンポーネント
 * @param name
 * @param component
 * @param rest
 * @returns {any}
 * @constructor
 */
export const ReduxField = ({
  name,
  component,
  validators,
  disabled,
  ...rest
}: {
  name?: string
  // component: React.ComponentType<WrappedCustomFieldProps>
  disabled?: boolean
  component: React.ComponentType<any>
  validators?: FieldValidators | string[]
}) => {
  // } & FieldProps) => {
  const validates = getValidates(validators)
  return (
    <ReduxCustomField
      component={component}
      name={name || ''}
      validate={validates}
      {...rest}
    />
  )
}
