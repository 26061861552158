import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteList'
import * as noteSelectors from '../../selectors/note'
import {SpacingProps} from '../../styles/mixins/spacing'

export default function FollowingList(props: SpacingProps) {
  const query = useSelector(noteSelectors.getSearchNoteQuery)
  const items = useSelector(noteSelectors.getFollowingNoteCardList(query))
  return items.length > 0
    ? React.createElement(View, {
        addable: false,
        title: 'フォロー中のノート一覧',
        items,
        ...props,
      })
    : null
}
