import UsersMe from '../../../domain/account/UsersMe'
import UserTimeline from '../../../domain/account/UserTimeline'
import {UserMeResponse, UserTimelineResponse} from '../types'

export function toUsersMe(response: UserMeResponse): UsersMe {
  return {
    ...response,
  } as UsersMe
}

export function toUserTimeline(response: UserTimelineResponse): UserTimeline[] {
  const {items} = response
  return items
}
