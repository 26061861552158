import {connect} from 'react-redux'
import {match, withRouter} from 'react-router'
import {compose, lifecycle} from 'recompose'
import {IReduxState} from '../../infra/redux'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import VerifyEmailVerificationTokenUseCase from '../../useCases/verification/VerifyEmailVerificationTokenUseCase'

interface IOwnProps {
  match: match<{emailVerificationToken: string}>
}

export default compose(
  withRouter,
  connect((state: IReduxState, ownProps: IOwnProps) => {
    const {emailVerificationToken} = ownProps.match.params
    return {
      emailVerificationToken,
    }
  }),
  lifecycle<{emailVerificationToken: string}, {}>({
    /**
     * componentWillMount
     */
    componentWillMount() {
      const {emailVerificationToken} = this.props
      UseCaseCommander.create().command(VerifyEmailVerificationTokenUseCase)({
        emailVerificationToken,
      })
    },
  }),
)(() => null)
