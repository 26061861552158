import {parseISO, startOfDay} from 'date-fns'
import {bindActionCreators} from 'redux'
import {getCurrentNoteId, getCurrentNoteSlideId} from '../../selectors/note'
import {INoteManager} from '../../services/app/INoteManager'
import {actions as entitiesActions} from '../redux/api/entities'
import {NoteTimelineItemSchema, NoteTrackItemSchema, TreeSchema} from '../redux/api/schemata'
import {getEntity} from '../redux/api/selectors'
import * as reduxApp from '../redux/note'
import ReduxProvider from '../redux/ReduxProvider'

type NoteActionCreator = typeof reduxApp.actions

interface Props {
  actions: NoteActionCreator
  redux: ReduxProvider
}

export default class NoteManager implements INoteManager {
  private redux: ReduxProvider
  private actions: NoteActionCreator

  public static create() {
    const redux = ReduxProvider.create()
    const actions = bindActionCreators(reduxApp.actions, action =>
      redux.dispatch(action),
    )
    return new NoteManager({redux, actions})
  }

  public constructor(props: Props) {
    this.redux = props.redux
    this.actions = props.actions
  }

  public setCurrent(noteId: string): string {
    if (this.getCurrent() !== noteId) {
      this.actions.updateCurrentNoteId(noteId)
      this.actions.updateCurrentNoteSlideId(0)
      this.redux.dispatch(entitiesActions.clear(NoteTimelineItemSchema))
      this.redux.dispatch(entitiesActions.clear(NoteTrackItemSchema))
    }
    return noteId
  }

  public getCurrent(): string {
    const state = this.redux.getState()
    return getCurrentNoteId(state)
  }

  public clear() {
    this.actions.updateCurrentNoteId('')
  }

  public getCurrentNoteSlide(): number {
    const state = this.redux.getState()
    return getCurrentNoteSlideId(state)
  }

  public setCurrentNoteSlide(slideIndex: number): void {
    this.actions.updateCurrentNoteSlideId(slideIndex)
  }

  public setFilterQuery(query: string): void {
    if (query) {
      const fields = getEntity(TreeSchema)(this.redux.getState())
      // FIXME: selectorsへ
      const ret = Object.keys(fields)
        .filter(key => {
          const field = fields[key]
          const {attr: {label = ''} = {}, parentsTitles = []} = field || {}
          const someMatch = parentsTitles.some((title: string) => {
            return title.includes(query)
          })
          return (label && label.includes(query)) || someMatch
        })
        .reduce((acc: any[], key: string) => {
          const {id} = fields[key]
          acc = [...acc, id]
          return acc
        }, [])
      this.actions.updateFilteredNoteFields(ret)
    } else {
      this.actions.updateFilteredNoteFields([])
    }
    this.actions.updateFilterNoteQuery(query)
  }

  public setCurrentTrackDate(date: Date | string): void {
    if (typeof date === 'string') {
      const parsed = date ? parseISO(date) : startOfDay(new Date())
      this.actions.updateCurrentTrackDate(parsed.toISOString())
    } else {
      const parsed = date ? date : startOfDay(new Date())
      this.actions.updateCurrentTrackDate(parsed.toISOString())
    }
  }

  public setSearchNoteQuery(query: string): void {
    this.actions.updateSearchNoteQuery(query)
  }
}
