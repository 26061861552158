import {routerMiddleware} from 'connected-react-router'
import {History} from 'history'
import {applyMiddleware, createStore} from 'redux'
import reducers, {IReduxState} from './'
import {getComposeEnhancers} from './helpers'
import {Action} from './types'
import {createUseCaseMiddleware} from './useCase/middleware'
import {loggerMiddleware} from './log/middleware'

/**
 * Configure redux
 */
export default function configure({history}: {history: History}) {
  const useCaseMiddleware = createUseCaseMiddleware()
  const middlewares = [
    routerMiddleware(history),
    useCaseMiddleware,
    loggerMiddleware,
  ]
  const composeEnhancers = getComposeEnhancers()
  const enhancer = composeEnhancers(applyMiddleware(...middlewares))
  const store = createStore<IReduxState, Action, any, any>(
    reducers(history),
    enhancer,
  )

  if (module.hot) {
    module.hot.accept('./', () => {
      const nextReducers = require('./').default
      store.replaceReducer(nextReducers)
    })
  }
  return {
    store,
  }
}
