import * as React from 'react'
import styled from 'styled-components'
import NoteMembers from '../../../containers/NoteMembers'
import NoteTimeline from '../../../containers/NoteTimeline'
import NoteTimelineInput from '../../../containers/NoteTimelineInput'
import {NoteRelatedUser} from '../../../domain/note/NoteRelatedUser'
import {defaultBreakpoints} from '../../../styles/mixins/breakpoints'
import {containerStyle} from '../../../styles/mixins/container'
import {flexPosition} from '../../../styles/mixins/grid'
import {spacing} from '../../../styles/mixins/spacing'
import useMediaQuery from '../../../styles/useMediaQuery'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import LazyImage from '../../atoms/LazyImage'
import Paper, {paperMarginTop} from '../../atoms/Paper'
import PlaceholderImage from '../../atoms/PlaceholderImage'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import LabeledIcon from '../../molecules/LabeledIcon'
import PaperTitle from '../../molecules/PaperTitle'
import NoteInvitationCodeButton from '..//NoteInvitationCodeButton'
import NoteEmergencyCodeButton from '../NoteEmergencyCodeButton'

const ActionGutter = space.sm + 4

const View = styled.div``

const Inner = styled.div`
  padding-bottom: ${space.xxl}px;

  position: relative;

  ${defaultBreakpoints.up('tablet')} {
    ${containerStyle('tablet')};
  }
`

const Cover = styled.div`
  position: relative;
`
const CoverImage = styled.div`
  box-shadow: aliceblue;
`
const CoverHeader = styled.div`
  padding: ${space.sm}px;
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.26),
    transparent 56px,
    transparent
  );
`

const CoverHeaderRight = styled(Body)`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const Controls = styled.div`
  margin-left: 100px;
  padding-top: ${space.xs}px;
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-end;
`

const Action = styled(Col)<{isPhone: boolean}>`
  margin-top: ${ActionGutter}px;
  // margin-top: ${p => (p.isPhone ? space.sm : space.lg)}px;
`

const PersonImage = styled.div<{isPhone: boolean}>`
  display: inline-block;
  position: absolute;
  left: ${p => (p.isPhone ? 12 : 62)}px;
  top: -60px;
  text-align: center;
`

const Members = styled.div`
  ${spacing};
`
const TimelineInputOuter = styled.div`
  ${spacing};
`

export interface Props {
  coverImageSrc: string
  title: string
  personName: string
  personImageUrl: string
  editable: boolean
  members: NoteRelatedUser[] /* FIXME: domain依存しない */
  memberEditable: boolean
  numMembersIconBadge?: number
  invitable: boolean
  emergencyCodeShowable: boolean
  onClickEditButton: () => void
  onClickTrackButton: () => void
  onClickNoteButton: () => void
  onClickContactsButton: () => void
  onClickMembersButton: () => void
}

export default function NoteCover(props: Props) {
  const isPhone = !useMediaQuery('tablet')
  return (
    <View>
      <Cover>
        <CoverImage>
          <CoverHeader>
            <Body>{props.title}</Body>
            <Body ml="auto" pl="md" py="xxs">
              {props.editable && (
                <CoverHeaderRight
                  onClick={props.onClickEditButton}
                  type="body2"
                >
                  表紙の編集
                  <Icon ml="xxs" size={18} type="pencil" />
                </CoverHeaderRight>
              )}
            </Body>
          </CoverHeader>
          {props.coverImageSrc ? (
            <LazyImage
              hasIndicator={true}
              height={isPhone ? '200px' : '300px'}
              objectFit="cover"
              src={props.coverImageSrc}
              width="100%"
            />
          ) : (
            <PlaceholderImage
              bgColor={colors.gray200}
              height={isPhone ? '200px' : '300px'}
              width="100%"
            />
          )}
        </CoverImage>
      </Cover>

      <Inner>
        <PersonImage isPhone={isPhone}>
          <Avatar
            borderColor="#fff"
            borderStyle="solid"
            borderWidth={3}
            size={100}
            src={props.personImageUrl}
          />
          <Body mt="xxs">{props.personName}</Body>
        </PersonImage>

        <Paper>
          <Controls>
            <Row
              align={flexPosition.center}
              gutter={space.lg - 4}
              justify={flexPosition.flexEnd}
              mt={-ActionGutter}
            >
              <Action
                col="auto"
                isPhone={isPhone}
                onClick={props.onClickTrackButton}
              >
                <LabeledIcon type="calendar-check">記録</LabeledIcon>
              </Action>
              <Action
                col="auto"
                isPhone={isPhone}
                onClick={props.onClickNoteButton}
              >
                <LabeledIcon type="book">じぶんのこと</LabeledIcon>
              </Action>
              <Action
                col="auto"
                isPhone={isPhone}
                mt={space.sm}
                onClick={props.onClickContactsButton}
              >
                <LabeledIcon type="telephone">連絡先</LabeledIcon>
              </Action>
              {props.emergencyCodeShowable && (
                <Action col="auto" isPhone={isPhone}>
                  <NoteEmergencyCodeButton />
                </Action>
              )}
            </Row>
          </Controls>
        </Paper>

        <Paper mt={paperMarginTop}>
          <PaperTitle
            iconType="users"
            left={
              <Row flexWrap="nowrap" gutter={space.md + 5}>
                {props.invitable && (
                  <Col>
                    <NoteInvitationCodeButton />
                  </Col>
                )}
                {props.memberEditable && (
                  <Col onClick={props.onClickMembersButton}>
                    <LabeledIcon
                      badgeNum={props.numMembersIconBadge}
                      type="group-work"
                    >
                      管理
                    </LabeledIcon>
                  </Col>
                )}
              </Row>
            }
            title={`${props.title}のメンバー`}
          />
          <Members>
            <NoteMembers />
          </Members>
        </Paper>

        <Paper mt={paperMarginTop}>
          <PaperTitle iconType="alarm" title={`${props.title}へコメントする`} />
          <TimelineInputOuter mt="xs">
            <NoteTimelineInput />
          </TimelineInputOuter>
        </Paper>

        <NoteTimeline />
      </Inner>
    </View>
  )
}
