import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View, {UserNotificationListItemProps} from '../../components/organisms/UserNotificationList'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getUserNotifications} from '../../selectors/api/usersMe'
import PushNavigationUseCase from '../../useCases/app/PushNavigationUseCase'

export interface Props {
  limit?: number
  onClickItem?: (item: UserNotificationListItemProps) => void
}

export default function UserNotificationList(props: Props) {
  const {limit = 0, onClickItem} = props
  const list = useSelector(getUserNotifications(limit))

  const handleClickItem = useCallback(
    (item: UserNotificationListItemProps) => {
      UseCaseCommander.create().command(PushNavigationUseCase)({
        routeKey: 'noteCover',
        noteId: item.noteId,
      })
      onClickItem && onClickItem(item)
    },
    [list],
  )
  return React.createElement(View, {
    list,
    onClickItem: handleClickItem,
  })
}
