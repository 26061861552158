import * as React from 'react'
import {useCallback} from 'react'
import View from '../../components/molecules/HeaderLogo'
import {unregister as unregisterServiceWorker} from '../../registerServiceWorker'
import {setLocationHref} from '../../services/app/WindowService'

export type Props = {
  title?: string
}

export default function HeaderLogo(props: Props) {
  const onClick = useCallback(() => {
    // FIXME: ロゴのクリックでページ遷移しつつindexへ移動（ios safariのservice worker更新のための一時対応)
    unregisterServiceWorker()
    setLocationHref('/')
  }, [])
  return React.createElement(View, {
    ...props,
    onClick,
  })
}
