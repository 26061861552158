import {
  getFormMeta,
  getFormSyncErrors,
  getFormValues,
  isDirty,
  isInvalid,
} from 'redux-form'
import {createSelector} from 'reselect'

export interface FormMeta {
  [key: string]: {active?: boolean; touched?: boolean; visited: boolean}
}

export const getFormErrorMessage = (form: string) =>
  createSelector(
    getFormSyncErrors(form),
    (errors: KeySignature) => {
      const [firstKey] = Object.keys(errors)
      return errors[firstKey]
    },
  )

export const getAnyActive = (form: string) =>
  createSelector(
    getFormValues(form),
    getFormMeta(form),
    (values: any, meta: FormMeta) => {
      return (
        values &&
        Object.keys(values)
          .map(key => {
            const {active = false} = meta[key] || {}
            return active
          })
          .reduce((acc, v) => acc || v, false)
      )
    },
  )

export const getValues = (form: string) =>
  createSelector(
    getFormValues(form) as any /*FIXME*/,
    (values: any) => {
      return values
    },
  )

export const isSubmitDisabled = (form: string) =>
  createSelector(
    isInvalid(form),
    (formIsInvalid: boolean) => {
      return formIsInvalid
    },
  )

export const getFormSelector = (form: string) => {
  return {
    isDirty: createSelector(
      isDirty(form),
      (value: boolean) => {
        return value
      },
    ),
  }
}
