import * as React from 'react'
import {default as BaseHelmet} from 'react-helmet'

const NO_ROBOTS_META_ITEM = {
  name: 'robots',
  content: 'noindex,nofollow,noarchive',
}

export interface Props {
  title: string
  siteTitle: string
  allowRobots: boolean
}

const Helmet = (props: Props) => {
  // export default function Helmet(props: MarginProps) {
  const pageTitle = props.title
    ? `${props.title} - ${props.siteTitle}`
    : props.siteTitle
  const meta = [...(props.allowRobots ? [] : [NO_ROBOTS_META_ITEM])]
  return <BaseHelmet meta={meta} title={pageTitle} />
}

export default Helmet
