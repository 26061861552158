import * as React from 'react'
import {useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {dateToTimeStr, NoteTrackDataType} from '../../../domain/note/track/NoteTrack'
import {useModalState} from '../../../selectors/app/dom'
import {NoteTrackDataTypeIconMap} from '../../../selectors/note/track'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Paper from '../../atoms/Paper'
import {replaceNewLine} from '../../helpers'
import LightboxImages from '../../molecules/LightboxImages'
import TrackHeader from '../../molecules/TrackHeader'
import PostEditNoteTrackModal from '../../organisms/PostEditNoteTrackModal'

export interface Props {
  trackId: string
  type: NoteTrackDataType
  avatarSrc: string
  timestamp?: Date
  content?: string
  editable?: boolean
  deletable?: boolean
  onClickRemoveButton?: () => void
  memo?: string
  image?: string
}

const View = styled.div`
  ${spacing};
`
const Outer = styled.div`
  ${spacing};
`

export default function NoteTrackItem(props: Props) {
  const {
    avatarSrc,
    deletable,
    editable,
    content,
    onClickRemoveButton,
    memo,
    trackId,
    type,
    timestamp,
    image,
    ...rest
  } = props
  const [editing, setEditing, pushed] = useModalState(false)
  const handleDismiss = useCallback(() => {
    setEditing(false)
  }, [editing, pushed])
  const handleClickEditButton = useCallback(() => {
    setEditing(true)
  }, [pushed])
  const time = useMemo(() => {
    return timestamp ? dateToTimeStr(timestamp) : ''
  }, [timestamp])
  const newlinedMemo = useMemo(() => {
    return replaceNewLine(memo)
  }, [memo])
  return (
    <View {...rest}>
      <Paper>
        <PostEditNoteTrackModal
          dismiss={handleDismiss}
          show={editing}
          timestamp={timestamp}
          trackId={trackId}
          type={type}
        />
        <TrackHeader
          avatarSrc={avatarSrc}
          content={content}
          deletable={deletable}
          editable={editable}
          iconType={NoteTrackDataTypeIconMap[type]}
          image={image}
          onClickEditButton={handleClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
          time={time}
        />
        {memo && (
          <Outer mt="xs">
            <Body color={colors.gray400} type="caption">
              {newlinedMemo}
            </Body>
          </Outer>
        )}
        {image && (
          <Outer mt="xs">
            <LightboxImages
              hasLightbox={true}
              height="auto"
              maxWidth="100%"
              sources={[image]}
              width="auto"
            />
          </Outer>
        )}
      </Paper>
    </View>
  )
}
