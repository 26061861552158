import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import Swiper, {SwiperInstance} from 'react-id-swiper'
import styled from 'styled-components'
import PostNoteTrackFeelingModal from '../../../containers/PostNoteTrackFeelingModal'
import PostNoteTrackWeatherModal from '../../../containers/PostNoteTrackWeatherModal'
import {NoteTrackDataTypes} from '../../../domain/note/track/NoteTrack'
import {NoteTrackDataTypeIconMap} from '../../../selectors/note/track'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import ActionButton from '../../molecules/ActionButton'
import PostEditNoteTrackModal from '../../organisms/PostEditNoteTrackModal'
import PostDiaryModal from '../PostDiaryModal'

const View = styled.div`
  position: relative;
`

const NextArrowOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 34px;
  height: 100%;
  z-index: 10;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 1) 100%
  );
`

const Actions = styled(Row)`
  margin-top: ${-space.sm}px;
  padding-top: 1px; // 見切れる
  padding-left: ${space.md + 4}px;
  padding-right: ${space.xl}px;
  width: auto !important;
`
const Action = styled(Col)<{disabled?: boolean}>`
  margin-top: ${space.sm}px;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  opacity: ${p => (p.disabled ? 0.3 : 1.0)};
`

export interface Props {}

export default function TrackActions(props: Props) {
  const [showArrow, setShowArrow] = useState(false)

  const handleGetSwiper = useCallback((swiperInstance: SwiperInstance) => {
    swiperInstance && setShowArrow(!swiperInstance.isEnd)
  }, [])

  const swiperProps: any /* react-id-swiperの型が対応してないpropsがある */ = useMemo(
    () => ({
      getSwiper: handleGetSwiper,
      slidesPerView: 'auto',
      direction: 'horizontal',
      freeMode: true,
      mousewheel: true,
      on: {
        reachEnd: () => {
          setShowArrow(false)
        },
        fromEdge: () => {
          setShowArrow(true)
        },
      },
    }),
    [],
  )
  const actions = useMemo(
    () => (
      <Actions flexWrap="nowrap" gutter={space.lg + 3}>
        <Action col="auto">
          <ActionButton
            buttonLabel="日記"
            iconType="pencil3"
            modal={PostDiaryModal}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="ご飯"
            iconType={NoteTrackDataTypeIconMap['meal']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.meal}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="しっこ"
            iconType={NoteTrackDataTypeIconMap['pee']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.pee}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="うんち"
            iconType={NoteTrackDataTypeIconMap['feces']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.feces}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="くすり"
            iconType={NoteTrackDataTypeIconMap['pills']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.pills}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="発作"
            iconType={NoteTrackDataTypeIconMap['seizure']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.seizure}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="体重"
            iconType={NoteTrackDataTypeIconMap['weight']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.weight}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="体温"
            iconType={NoteTrackDataTypeIconMap['temperature']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.temperature}
          />
        </Action>
        {/*
       <Action col="auto">
        <ActionButton
          buttonLabel="血圧"
          iconType={NoteTrackDataTypeIconMap['bloodPressure']}
          modal={PostEditNoteTrackModal}
          type={NoteTrackDataTypes.bloodPressure}
        />
      </Action>
      */}
        <Action col="auto">
          <ActionButton
            buttonLabel="酸素濃度"
            iconType={NoteTrackDataTypeIconMap['spo2']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.spo2}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="心拍数"
            iconType={NoteTrackDataTypeIconMap['heartRate']}
            modal={PostEditNoteTrackModal}
            type={NoteTrackDataTypes.heartRate}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="気分"
            iconType={NoteTrackDataTypeIconMap['feeling']}
            modal={PostNoteTrackFeelingModal}
          />
        </Action>
        <Action col="auto">
          <ActionButton
            buttonLabel="天気"
            iconType={NoteTrackDataTypeIconMap['weather']}
            modal={PostNoteTrackWeatherModal}
          />
        </Action>
      </Actions>
    ),
    [],
  )
  return (
    <View>
      <Swiper {...swiperProps}>{actions}</Swiper>
      {showArrow && (
        <NextArrowOuter>
          <Icon color={colors.gray300} size={22} type="fingers-scroll-right2" />
        </NextArrowOuter>
      )}
    </View>
  )
}
