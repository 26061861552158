import {addDays, format, startOfWeek} from 'date-fns'
import {ja} from 'date-fns/locale'
import * as React from 'react'
import {useMemo} from 'react'
import styled from 'styled-components'
import {NoteTrackDataType} from '../../../domain/note/track/NoteTrack'
import {NoteTrackDataTypeQuantityUnitMap} from '../../../selectors/note/track'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

const HEIGHT = '20px'
const View = styled.div`
  height: 100%;
`

const TableHeader = styled(Row)``
const TableFooter = styled(Row)`
  //border-bottom: solid 1px ${colors.border};
`
const Table = styled(Row)`
  border-top: solid 1px ${colors.border};
  height: 100%;
`

const Hour = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEIGHT};
  text-align: center;
  border-bottom: solid 1px ${colors.border};
`

const IconCol = styled(Col)`
  display: flex;
  align-items: center;
`

const VerticalCols = styled(Col)<{even?: boolean}>`
  border-right: solid 1px ${colors.gray70};
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${p => (p.even ? colors.gray100 : colors.white)};

  &:last-child {
    border-right: none;
  }
`

const HourHeader = styled(Col)`
  height: ${HEIGHT};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  border-bottom: solid 1px ${colors.border};

`
const HourTitle = styled(Body)``
const WeekHeader = styled.div<{sunday?: boolean; saturday?: boolean}>`
  text-align: center;
  color: ${p => {
    if (p.sunday) {
      return colors.red100
    }
    if (p.saturday) {
      return colors.blue100
    }
    return ''
  }};
`

export interface HourItem {
  type: string
  iconType: string
  color?: string
  count: number
  quantity: number
}

export interface DayItem {
  [hour: number]: {[type: string]: HourItem}
}

export interface WeekItem {
  [day: string]: DayItem
}

export interface Props {
  date?: Date
  week: WeekItem
}

const COLUMNS = 4 * 7 + 1
const COL = 4
const FIRST_COL = 2

const time = Array.from({length: 24}, (v, k) => k)

export default function Timetable(props: Props) {
  const {date = new Date(), week} = props
  const trackIconTypes: {
    color: string
    type: NoteTrackDataType
    iconType: string
  }[] = []
  const start = useMemo(() => startOfWeek(date), [date.toISOString()])
  const weekDays = useMemo(
    () =>
      Object.keys(week).map((day, index) => {
        return addDays(start, index)
      }),
    [date.toISOString()],
  )
  const hourHeaders = time.map((col, index) => {
    return (
      <HourHeader key={index}>
        <HourTitle type="overline">{index}</HourTitle>
      </HourHeader>
    )
  })
  const cols = Object.keys(week).map((dayKey, i) => {
    const day = week[dayKey]
    return (
      <VerticalCols col={COL} columns={COLUMNS} even={i % 2 === 0} key={i}>
        {time.map((hourKey, j) => {
          const hour = day[hourKey] || {}
          const icons =
            Object.keys(hour).length > 0 &&
            Object.keys(hour).map((hourKey, index) => {
              const {color = colors.black, iconType, type, count} = hour[
                hourKey
              ]
              !trackIconTypes.filter(item => item.type === type).length &&
                trackIconTypes.push({color, type: type as any, iconType})
              return (
                <Col key={index}>
                  <Row align="baseline" flexWrap="nowrap" gutter={0}>
                    <IconCol>
                      <Icon color={color} size={14} type={iconType} />
                    </IconCol>
                    {count > 1 && (
                      <Col>
                        <Body
                          color={colors.gray350}
                          fontSize={8}
                          lineHeight={1}
                        >
                          {count}
                        </Body>
                      </Col>
                    )}
                  </Row>
                </Col>
              )
            })
          const isOdd = j % 2 > 0
          return (
            <Hour key={j} odd={isOdd}>
              <Row
                align="baseline"
                flexWrap="nowrap"
                gutter={2}
                justify="center"
              >
                {icons}
              </Row>
            </Hour>
          )
        })}
      </VerticalCols>
    )
  })
  const headers = Object.keys(week).map((day, i) => {
    const sunday = i === 0
    const saturday = i === 6
    const titleDate = format(weekDays[i], 'M月d日', {locale: ja})
    const titleDw = format(weekDays[i], '(EE)', {locale: ja})
    return (
      <Col col={COL} columns={COLUMNS} gutter={0} key={i}>
        <WeekHeader saturday={saturday} sunday={sunday}>
          <Body fontSize={10}>
            <p>{titleDate}</p>
            <p>{titleDw}</p>
          </Body>
        </WeekHeader>
      </Col>
    )
  })
  const footers = Object.keys(week).map((dayKey, i) => {
    const day = week[dayKey]
    const sums = Object.keys(trackIconTypes).map((key, iconIndex) => {
      const {color, type, iconType} = trackIconTypes[key]
      const sum = Object.keys(day).reduce(
        ({count, quantity}, hourKey) => {
          const hour = day[hourKey]
          const sum = Object.keys(hour).reduce(
            ({quantity: accQuantity = 0, count: accCount = 0}, hourKey) => {
              const {quantity = 0, count = 0, iconType: hourIconType} = hour[
                hourKey
              ]
              return iconType === hourIconType
                ? {
                    quantity: Number(accQuantity) + Number(quantity),
                    count: Number(accCount) + Number(count),
                  }
                : {quantity: accQuantity, count: accCount}
            },
            {quantity: 0, count: 0},
          )
          return {
            count: count + sum.count,
            quantity: quantity + sum.quantity,
          }
        },
        {count: 0, quantity: 0},
      )
      const unit = NoteTrackDataTypeQuantityUnitMap[type]
      return (
        <Row
          align="center"
          flexWrap="nowrap"
          gutter={0}
          justify="center"
          key={iconIndex}
          mt="xxs"
          px={3}
        >
          <Col col={4}>
            <Icon color={color} size={14} type={iconType} />
          </Col>
          <Col col={8}>
            <Body fontSize={8} textAlign="right">
              {unit && (
                <p>
                  {sum.quantity}
                  {unit}
                </p>
              )}
              <p>{sum.count}回</p>
            </Body>
          </Col>
        </Row>
      )
    })
    return (
      <VerticalCols
        col={COL}
        columns={COLUMNS}
        gutter={0}
        key={i}
        odd={i % 2 > 0}
      >
        <WeekHeader>{sums}</WeekHeader>
      </VerticalCols>
    )
  })
  return (
    <View>
      <TableHeader flexWrap="nowrap" gutter={0}>
        <Col col={FIRST_COL} columns={COLUMNS}>
          <Body fontSize={8} textAlign="right">
            &nbsp;
          </Body>
        </Col>
        {headers}
      </TableHeader>
      <Table flexWrap="nowrap" gutter={0} mt="xs">
        <VerticalCols col={FIRST_COL} columns={COLUMNS}>
          {hourHeaders}
        </VerticalCols>
        {cols}
      </Table>
      <TableFooter flexWrap="nowrap" gutter={0}>
        <Col col={FIRST_COL} columns={COLUMNS}>
          <Body fontSize={8} textAlign="right">
            &nbsp;
          </Body>
        </Col>
        {footers}
      </TableFooter>
    </View>
  )
}
