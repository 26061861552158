import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Icon from '../Icon'

export interface Props {
  onClick: () => void
}

const Arrow = styled(Icon)`
  padding: ${space.xs}px ${space.xs}px ${space.xs}px 0;
  font-weight: 700;
  ${spacing};
`

export default function BackButton(props: Props) {
  const {onClick} = props
  return (
    <Arrow
      color={colors.brand}
      onClick={onClick}
      size="icon2"
      type="arrow-left"
    />
  )
}
