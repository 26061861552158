import {hasSomeProperty} from '../../../lib'

export const NodePolicyTypes = {
  default: 'default',
  emergency: 'emergency',
}
export type NodePolicyType = keyof typeof NodePolicyTypes

export const TreeContainerTypes = {
  root: 'root',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
}

export const TreeLeafTypes = {
  array: 'array',
  text: 'text',
  freeText: 'freeText',
  fillText: 'fillText',
  tel: 'tel',
  image: 'image',
  date: 'date',
  checkbox: 'checkbox',
  radio: 'radio',
}

export const TreeTypes = {
  ...TreeContainerTypes,
  ...TreeLeafTypes,
}

export type TreeType = keyof typeof TreeTypes
export type TreeContainerType = keyof typeof TreeContainerTypes
export type TreeLeafType = keyof typeof TreeLeafTypes

export interface ChangeInfo {
  itemId: string
  previousData: KeySignature
  updatedAt: string
  userId: string
}
export interface TreeContainer {
  id: string
  type: TreeContainerType
  attr?: KeySignature
  tree?: Tree[]
}

export interface TreeLeaf {
  id: string
  type: TreeLeafType
  attr: KeySignature
  data?: KeySignature
  changeInfo: ChangeInfo
  policyType?: NodePolicyType
}

export type Tree = TreeContainer | TreeLeaf

export type TreeRoot = Tree & {
  type: 'root'
}

export const hasFieldData = (
  type: TreeLeafType | string,
  name: string,
  data: KeySignature<string>,
  options: KeySignature<string> = {},
) => {
  switch (type) {
    case TreeLeafTypes.radio:
      return Object.keys(options).some(key => {
        return data && data[name]
      })
    case TreeLeafTypes.checkbox:
      return Object.keys(options).some(key => {
        return data && data[key]
      })
    default:
      return data && data[name]
  }
}

export const hasSomeFieldData = (
  data: KeySignature,
  items: {type: string; options: KeySignature},
) =>
  Object.keys(items).some((key, j) => {
    const item = items[key]
    const {type = 'text', options = {}} = item || {}
    return hasFieldData(type, key, data, options)
  })

export function getContentedTreeLeaf(leaf: TreeLeaf): TreeLeaf | undefined {
  const {data = {}} = leaf || {}
  const hasData = hasSomeProperty(data)
  return hasData ? leaf : undefined
}

export function getContentedTree(container: Tree): Tree | undefined {
  const {tree = []} = (container as TreeContainer) || {}
  const children = tree
    .map((child: Tree) => {
      return getContentedTree(child)
    })
    .filter(v => v)
  return children.length
    ? ({...container, tree: children} as TreeContainer)
    : getContentedTreeLeaf(container as TreeLeaf)
}
