import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

export interface Props {
  children: React.ReactNode
}

const View = styled.div`
  padding: ${space.sm + 4}px ${space.sm}px;
  cursor: ${p => p.onClick && 'pointer'};

  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  ${p =>
    p.onClick &&
    `
    transition: 0.3s ease;
  &:hover {
    background-color: ${colors.gray50};
  `}
`

export default function ListItem(props: Props) {
  const {children, ...rest} = props
  return <View {...rest}>{children}</View>
}
