/**
 * 別窓でHTMLElementを開く。
 */
export function openWindowWithHtml(html: string, target: string = '_blank') {
  const w = window.open('about:blank', target)
  if (w) {
    w.document.write(html)
  }
}

/**
 * 画像窓を開く
 *
 * src には base64形式を指定可能 "data:..."
 */
export function openWindowWithImage(src: string, target: string = '_blank') {
  const element = new Image()
  element.src = src
  openWindowWithHtml(element.outerHTML, target)
}

/**
 * テキストのみのウィンドウをひらく。
 * <pre>タグを使うので改行文字は改行表示される。
 */
export function openWindowWithPlainText(
  text: string,
  target: string = '_blank',
) {
  const element = document.createElement('pre')
  element.innerText = text
  openWindowWithHtml(element.outerHTML, target)
}

// トップのスクロールする
export function windowScrollTop() {
  window.scroll(0, 0)
}

export function reload() {
  window.location.reload()
}

export function setLocationHref(href: string) {
  window.location.href = href
}

export function getWindowHistory() {
  return window.history
}

