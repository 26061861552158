/**
 * MeRepository
 */
import IMeRepository from '../../../domain/account/IMeRepository'
import UsersMe from '../../../domain/account/UsersMe'
import UserTimeline from '../../../domain/account/UserTimeline'
import {getMe} from '../../../selectors/api'
import AppAPI from '../../api/AppAPI'
import {toNotes} from '../../api/translators/note'
import {toUsersMe, toUserTimeline} from '../../api/translators/usersMe'
import {actions} from '../../redux/api/entities'
import {MeSchema, NoteSchema, UserTimelineSchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class MeRepository implements IMeRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): MeRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new MeRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async get(): Promise<UsersMe> {
    const client = await this.api.getClient()
    const res = await client.usersMe()
    const usersMeEntity = toUsersMe(res)
    this.redux.dispatch(actions.store(usersMeEntity, MeSchema))
    const notesEntity = toNotes([...res.ownedNotes, ...res.followingNotes])
    this.redux.dispatch(actions.store(notesEntity, [NoteSchema]))
    return usersMeEntity
  }

  public async getByLocalFirst(): Promise<UsersMe> {
    const me = getMe(this.redux.getState())
    if (me && Object.keys(me).length === 0) {
      return this.get()
    }
    return me
  }

  public async getTimeline(): Promise<UserTimeline[]> {
    const client = await this.api.getClient()
    const res = await client.getUserTimeline()
    const entities = toUserTimeline(res)
    this.redux.dispatch(actions.store(entities, [UserTimelineSchema]))
    return entities
  }
}
