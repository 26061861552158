import {css} from 'styled-components'
import {iconContentMap} from '../icon'
import svg from './jibunnote.svg'
import ttf from './jibunnote.ttf'
import woff from './jibunnote.woff'

export const iconCss = css`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jibunnote' !important;
  speak: none;
  font-style: normal;
  font-weight: inherit;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 2px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const fontFaceCss = css`
  @font-face {
    font-family: 'jibunnote';
    src: url('${ttf}') format('truetype'),
      url('${woff}') format('woff'),
      url('${svg}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class^='icon-'],
  [class*=' icon-'] {
    ${iconCss}
  }
`

export const generateIconContentCss = () =>
  Object.keys(iconContentMap).reduce((acc, key) => {
    const content = iconContentMap[key]
    return `${acc}
    .icon-${key}:before {
      content: '${content}';
    }
  `
  }, '')

export const iconContentCss = generateIconContentCss()
