import {connect} from 'react-redux'
import {compose} from 'recompose'
import {
  getFormMeta,
  getFormSyncErrors,
  hasSubmitSucceeded,
  isDirty as isFormDirty,
  reduxForm,
  reset,
} from 'redux-form'
import FormedField, {Props} from '../../components/molecules/FormedField'
import {IReduxState} from '../../infra/redux'
import ReduxProvider from '../../infra/redux/ReduxProvider'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {NoteLeafComponentProps} from '../../components/helpers/snapshot'
import {getAnyActive, getFormErrorMessage} from '../../selectors/form'
import ResetFormUseCase from '../../useCases/app/ResetFormUseCase'
import UpdateNoteUseCase from '../../useCases/note/UpdateNoteUseCase'
import {getLeafComponentType} from '../NoteComponent'

const useCaseIdByName = (name: string): string => 'FormedNoteField_' + name

export type OwnProps = Props & {
  onSubmit?: () => void
  onCancel?: () => void
}

type StateProps = OwnProps & Props

export default compose<Props, NoteLeafComponentProps>(
  connect(
    (state: IReduxState, ownProps: OwnProps): StateProps => {
      const {form, type} = ownProps
      const component = getLeafComponentType(type)
      const errors = getFormSyncErrors(form)(state)
      const formErrorMessage = getFormErrorMessage(form)(state)
      const formMeta = getFormMeta(form)(state)
      const formDirty = isFormDirty(form)(state)
      const anyActive = getAnyActive(form)(state)
      return {
        ...ownProps,
        formErrorMessage,
        anyActive,
        formMeta,
        errors,
        formDirty,
        component,
        type,
        hasSubmitSucceeded,
        isProcessing: useCaseSelectors.isProcessing(
          UpdateNoteUseCase,
          useCaseIdByName(form),
        )(state),
      }
    },
    {},
    (props: Props & OwnProps) => {
      const {form, noteId, name, onCancel, onSubmit} = props
      return {
        ...props,
        submitForm: (values: any) => {
          UseCaseCommander.create().command(
            UpdateNoteUseCase,
            useCaseIdByName(form),
          )({
            form,
            id: noteId,
            data: {[name]: values},
            onSuccess: () => {
              onSubmit && onSubmit()
            },
          })
        },
        onClickCancel: () => {
          ReduxProvider.create().dispatch(reset(form))
          UseCaseCommander.create().command(ResetFormUseCase)({form})
          onCancel && onCancel()
        },
      }
    },
  ),
  reduxForm<{}, Props>({
    enableReinitialize: true,
    // MEMO: 下記２行は検索で値がリセットされないように必要
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  }),
)(FormedField)
