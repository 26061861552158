import * as React from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import LightboxImages from '../../molecules/LightboxImages'

const View = styled.div`
  ${spacing};
  position: relative;

  display: flex;
  flex-flow: row nowrap;
`

const ImageOuter = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`

const Input = styled.input`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const ImageSelectOuter = styled.div`
  position: relative;
`

const HiddenInput = styled.input`
  display: none;
`

export interface Props {
  hideUploadIcon?: boolean
  sources: string[]
  name: string
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
  isProcessing: boolean
  useReduxForm?: boolean
}

export default function UploadTrackImageField(props: Props) {
  const {
    sources,
    name,
    onDelete,
    onChangeFile,
    isProcessing,
    useReduxForm,
    hideUploadIcon,
    ...rest
  } = props
  const hasImage = !isProcessing && sources.filter(s => s).length > 0
  return (
    <View {...rest}>
      {useReduxForm ? (
        <ReduxField component={HiddenInput} name={name} type="hidden" />
      ) : (
        <HiddenInput name={name} type="hidden" />
      )}
      <Row align="center" width="100%">
        <Col col="fit">
          {hasImage ? (
            <ImageOuter>
              <LightboxImages
                hasLightbox={true}
                height={40}
                sources={sources}
                width={40}
              />
            </ImageOuter>
          ) : (
            <ImageSelectOuter>
              <Row align="center" gutter={space.sm}>
                {isProcessing ? (
                  <Col>
                    <Loading size={30} />
                  </Col>
                ) : (
                  <>
                    <Input onChange={onChangeFile} type="file" />
                    {!hideUploadIcon && (
                      <Col>
                        <Icon color={colors.gray300} size={34} type="picture" />
                      </Col>
                    )}
                    <Col>
                      <Body type="body2">画像を選択</Body>
                    </Col>
                  </>
                )}
              </Row>
            </ImageSelectOuter>
          )}
        </Col>
        {hasImage && (
          <Col ml="auto">
            <Icon onClick={() => onDelete()} type="trash2" />
          </Col>
        )}
      </Row>
    </View>
  )
}
