import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import Heading from '../../atoms/Heading'
import Loading from '../../atoms/Loading'

const View = styled.div`
  overflow: hidden;
  z-index: ${zIndex.indicator};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: visibility 0.5s linear;

  &::after {
    content: '';
    background-color: ${colors.overlay};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Center = styled.div`
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Message = styled(Heading)`
  margin-top: ${space.xxl}px;
`

export interface Props {
  message?: string
}

const Indicator = ({message}: Props) => (
  <View>
    <Center>
      <Loading />

      {message && (
        <Message color={colors.gray400} size="heading5" weight={500}>
          {message}
        </Message>
      )}
    </Center>
  </View>
)

export default Indicator
