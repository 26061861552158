import ISnapshotRepository from '../../../domain/note/ISnapshotRepository'
import NoteId from '../../../domain/note/NoteId'
import Snapshot from '../../../domain/note/snapshot/Snapshot'
import {getSnapshot, getTreeById} from '../../../selectors/api/snapshot'
import AppAPI from '../../api/AppAPI'
import {toSnapshot} from '../../api/translators/snapshot'
import {actions} from '../../redux/api/entities'
import {NoteSchema, TreeSchema} from '../../redux/api/schemata'
import {getNoteById} from '../../redux/api/selectors'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

// FIXME: noteとsnapshotを統合したい。命名をどうにかしたい
export default class SnapshotRepository implements ISnapshotRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): SnapshotRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new SnapshotRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async get(id: NoteId): Promise<Snapshot | undefined> {
    const client = await this.api.getClient()
    const res = await client.fetchNoteSnapshot({id: id})
    const note = getNoteById(id)(this.redux.getState())
    this.redux.dispatch(actions.store({...note, ...res}, NoteSchema))
    return toSnapshot(res)
  }

  public async remove(id: NoteId): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {snapshot = {}, ...note} =
      getNoteById(id)(this.redux.getState()) || {}
    Object.keys(note).length &&
      this.redux.dispatch(actions.store(note, NoteSchema))
  }

  public async update(id: NoteId, data: KeySignature): Promise<void> {
    const client = await this.api.getClient()
    const {updatedDataMap, ...rest} = await client.updateSnapshot({
      id: id,
      dataMap: data,
    })
    this.redux.dispatch(actions.store(updatedDataMap, [TreeSchema]))
    // noteを再取得して、responseとマージして、snapshotAtだけ更新
    const note = getNoteById(id)(this.redux.getState())
    this.redux.dispatch(
      actions.store({...note, snapshotAt: rest.updatedAt}, NoteSchema),
    )
  }

  public async updatePolicy(
    id: NoteId,
    policyMap: KeySignature,
  ): Promise<void> {
    const client = await this.api.getClient()
    await client.updatePolicy({
      id: id,
      policyMap,
    })

    const updatedPolicyMap = Object.keys(policyMap).map(key => {
      const policyType = policyMap[key]
      const item = getTreeById(key)(this.redux.getState())
      return {...item, policyType}
    })
    this.redux.dispatch(actions.store(updatedPolicyMap, [TreeSchema]))
    // noteを再取得して、responseとマージして、snapshotAtだけ更新
    const note = getNoteById(id)(this.redux.getState())
    this.redux.dispatch(
      actions.store({...note, snapshotAt: new Date()}, NoteSchema),
    )
  }
}
