import {Action as ReduxAction} from 'redux'
import {Notification} from '../../../services/app/Notification'

export enum ModalType {
  settings = 'settings',
  account = 'account',
}

export enum ActionType {
  initialized = 'app/initialized',
  busy = 'app/busy',
  modal = 'app/modal',
  notice = 'app/notice',
  dismissNotice = 'app/dismissNotice',
  nav = 'app/nav',
}

export interface UpdateInitializedAction extends ReduxAction<ActionType> {
  type: typeof ActionType.initialized
  payload: boolean
}

export interface UpdateBusyAction extends ReduxAction<ActionType> {
  type: typeof ActionType.busy
  payload: boolean
}

export interface UpdateNavAction extends ReduxAction<ActionType> {
  type: typeof ActionType.nav
  payload: boolean
}

export interface UpdateModalAction extends ReduxAction<ActionType> {
  type: typeof ActionType.modal
  payload: {
    type: string
    show: boolean
  }
}

export interface NoticeAction extends ReduxAction<ActionType> {
  type: typeof ActionType.notice
  payload: Notification
}

export interface DismissNoticeAction extends ReduxAction<ActionType> {
  type: typeof ActionType.dismissNotice
  payload: string
}

export type Action =
  | UpdateBusyAction
  | UpdateModalAction
  | UpdateNavAction
  | NoticeAction
  | DismissNoticeAction
