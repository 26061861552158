import {connect} from 'react-redux'
import {compose} from 'recompose'
import {FormProps, reduxForm} from 'redux-form'
import {SpaceProps} from '../../components/layouts/Space'
import View, {
  IFormData,
  IFormProps,
  Props,
} from '../../components/organisms/RequestResetPassowrdForm'
import {IReduxState} from '../../infra/redux'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {Forms} from '../../services/forms'
import RequestResetPassordUseCase from '../../useCases/auth/RequestResetPasswordUseCase'
import * as selectors from './selectors'

type StateProps = IFormProps &
  FormProps<IFormData, IFormProps> & {
    initialValues: Partial<IFormData>
  }

export default compose<Props, SpaceProps>(
  connect(
    (state: IReduxState): StateProps => {
      return {
        initialValues: {
          email: '',
        },
        submitForm: values => {
          UseCaseCommander.create().command(RequestResetPassordUseCase)(values)
        },
        submitDisabled: selectors.isSubmitDisabled(state),
      }
    },
  ),
  reduxForm<IFormData, IFormProps>({
    form: Forms.requestResetPassword,
  }),
)(View)
