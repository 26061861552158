import {getZeroPadding} from '../../lib'

/**
 * デフォルト画像など、サンプルのリソースのURLを置く場所です。
 *
 * ファイルの置き場所：
 * https://console.aws.amazon.com/s3/buckets/static.jnote.jp/images/samples/?region=us-east-1&tab=overview
 */

const IMAGES_BASE_URL =
  'https://s3.amazonaws.com/static.jnote.jp/images/samples'
const NUM_COVER_IMAGES = 9
const NUM_CHILD_FACE_IMAGES = 4

/**
 * 3 => [1,2,3]
 */
const getSerialNumberList = (maxNum: number): number[] =>
  Array.from(Array(maxNum + 1).keys()).slice(1)

/**
 * (3, "jpg") => ["001.jpg", "002.jpg", "003.jpg"]
 */
const getNumberedFileNames = (maxNum: number, extension: string): string[] =>
  getSerialNumberList(maxNum).map(n => `${getZeroPadding(n, 3)}.${extension}`)

/**
 * 3 => 0 | 1 | 2
 */
const getRandomIndex = (size: number) => Math.floor(Math.random() * size)

/**
 * ノートカバー
 */
export const sampleNoteCoverImageUrls = getNumberedFileNames(
  NUM_COVER_IMAGES,
  'jpg',
).map(filename => `${IMAGES_BASE_URL}/covers/${filename}`)

export const getSampleNoteCoverImageUrlByRandom = () =>
  sampleNoteCoverImageUrls[getRandomIndex(NUM_COVER_IMAGES)]

/**
 * こどもの顔のアイコンイラスト（中性的）
 */
export const sampleChildFaceImageUrls = getNumberedFileNames(
  NUM_CHILD_FACE_IMAGES,
  'png',
).map(filename => `${IMAGES_BASE_URL}/cute-faces/${filename}`)

export const getSampleChildFaceImageUrlByRandom = () =>
  sampleChildFaceImageUrls[getRandomIndex(NUM_CHILD_FACE_IMAGES)]
