import * as React from 'react'
import styled from 'styled-components'
import NoteHistory from '../../../containers/NoteHistory'
import NoteNav from '../../../containers/NoteNav'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'
import container from '../../../styles/mixins/container'
import space from '../../../styles/variables/space'
import DefaultPage from '../../templates/DefaultPage'

const View = styled.div`
  margin-top: ${space.lg}px;
  margin-bottom: ${space.lg}px;
  ${container};
  background-color: #fff;
  ${boxShadow};
  ${borderRadius};
`

export interface Props {
  title: string
}

export default function NoteHistoryPage(props: Props) {
  const {title} = props
  return (
    <DefaultPage back={true} nav={NoteNav} title={title}>
      <View>
        <NoteHistory />
      </View>
    </DefaultPage>
  )
}
