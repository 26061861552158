import UsersMe from '../../domain/account/UsersMe'
import UserTimeline from '../../domain/account/UserTimeline'
import NoteEmergency from '../../domain/note/NoteEmergency'
import NoteEmergencyCode from '../../domain/note/NoteEmergencyCode'
import NoteInvitation from '../../domain/note/NoteInvitation'
import NoteInvitationCode from '../../domain/note/NoteInvitationCode'
import {NoteMailNotificationType} from '../../domain/note/notifications/NoteMailNotificationType'
import {TreeLeaf, TreeRoot} from '../../domain/note/snapshot/Tree'
import {NoteTimelineItemType} from '../../domain/note/timeline/NoteTimeline'
import NoteTrackDailySum from '../../domain/note/track/NoteTrackDailySum'

// FIXME: ばしょ

export const ApiErrorType = {
  disabledUser: 'disabled_user', //対象ユーザーのステータスが認証中か、BAN中です。
  invalidToken: 'invalid_token', //不正なトークン・期限切れのトークンです。
  invalid: 'invalid', //アカウントが存在しない,パスワードが間違っている
  verifying: 'verifying', // パスワードは合っている が「認証待ち中」
  suspended: 'suspended', //パスワードは合っている が「BAN中」 ※ 個人情報保護の観点から、invalid 意外のレスポンスは、 emailとパスワードが一致している時 にのみ、返します
  notFound: 'not_found', // users 存在しない
  tooManyNotes: 'too_many_notes', // すでにノートが存在するため、これ以上作れない
  unauthorized: 'unauthorized',
}

export enum RelationType {
  editor = 'editor',
  owner = 'owner',
  watcher = 'watcher',
  requesting = 'requesting',
  none = 'none',
}

export enum TrackRelationType {
  editor = 'editor',
  watcher = 'watcher',
  none = 'none',
}

export enum ResourceType {
  note = 'note',
  user = 'user',
  track = 'track',
}

export interface ErrorResponse {
  error: keyof typeof ApiErrorType
  description?: string
}

export type ApiResponse =
  | {
      success: boolean
    }
  | ErrorResponse

export type SessionResponse = ApiResponse & {
  bearerToken: string
}

export interface UserResponse {
  id: string
  fullname: string
  nickname: string
  iconUrl: string
  email?: string
  relation?: {
    type: RelationType
    trackType: TrackRelationType
  }
}

interface NoteApiAttributesProps {
  title: string
  personName: string
  personImageUrl: string
  imageUrl: string
}

interface NoteRelationResponse {
  type: RelationType
  trackType: TrackRelationType
  mailTypes: NoteMailNotificationType[]
}

type NoteApiPropsBase = {
  id: string
  relationToMe: NoteRelationResponse
} & NoteApiAttributesProps

export interface NoteTimelineFetchRequest {
  id: string
  types?: string
  begin?: string
  end?: string
  limit?: number
  offset?: number
}
export interface NoteTimelinePostRequest {
  id: string
  type: NoteTimelineItemType
  data: any
}
export interface NoteTimelineUpdateRequest {
  id: string
  type: NoteTimelineItemType
  timelineId: string
  data: any
}
export interface NoteTimelineDeleteRequest {
  id: string
  timelineId: string
}

export interface NoteTimelineCommentsResponse {
  id: string
  userId: string
  createdAt: string
  data: {
    comment: string
  }
}
export interface NoteTimelineItemResponse {
  type: string
  userId: string
  timestamp: string
  comments: NoteTimelineCommentsResponse[]
  [key: string]: any // type specific responses
}
export interface NoteTimelineResponse {
  noteId: string
  timeline: {
    items: {[key: string]: NoteTimelineItemResponse}
    users?: {[key: string]: UserResponse}
    timelineAt: string
    pager: {
      offset: number
      limit: number
      totalCount: number
      nextOffset: number
    }
  }
}
export interface NoteTimelineCommentsPostRequest {
  id: string
  timelineId: string
  data: any
}
export interface NoteTimelineCommentsDeleteRequest {
  id: string
  timelineId: string
  commentId: string
}
export interface NoteTimelineDailySumsRequest {
  id: string
  types?: string
}
export interface NoteTimelineDailySumsResponse {
  dailySums: {
    list: NoteTrackDailySum[]
  }
}

export type NoteResponse = NoteApiPropsBase & {
  snapshotAt?: string
  users?: {[key: string]: UserResponse}
  members?: (UserResponse & {email: string})[]
  membersRelationMap?: {
    [userId: string]: NoteRelationResponse
  }
  history?: TreeItemResponse[]
}

export type UserMeResponse = SessionResponse &
  UsersMe & {
    ownedNotes: NoteResponse[]
    followingNotes: NoteResponse[]
  }
// sign_up
export interface SignUpRequest {
  fullname: string
  email: string
  password: string
  bridgeData?: KeySignature | string | number
}
// sign_up_verify
export interface SignUpVerifyRequest {
  token: string
}
export type SignUpVerifyResponse = SessionResponse & {
  token: string
  bridgeData?: KeySignature | string | number
}
// sign_in
export interface SignInRequest {
  email: string
  password: string
  isAutoLogout: boolean
}
export type SignInResponse = UserMeResponse
// sign_out
export type SignOutResponse = ApiResponse & {
  token: string
}
// request_reset_password
export interface RequestResetPasswordRequest {
  email: string
  bridgeData?: KeySignature | string | number
}
// reset_password
export interface ResetPasswordRequest {
  token: string
  newPassword: string
}
export type ResetPassowrdResponse = UserMeResponse & {
  bridgeData?: KeySignature | string | number
}

// me/update
export interface UsersMeUpdateRequest {
  fullname?: string
  nickname?: string
  iconUrl?: string
}
// users/:id
export interface UsersResponse {
  id: string
  fullname: string
  nickname: string
  iconUrl: string
}

// notes/create
export type NotesCreateRequest = NoteApiAttributesProps

export type NotesCreateResponse = NoteApiPropsBase & {
  numMembers: number
  numRequests: number
  needsHelpGuide: boolean
}

// notes/get
export interface NotesGetRequest {
  id: string
}
export type NotesGetResponse = NoteApiPropsBase

// notes/:id/update_attrs
export type NotesUpdateAttrsRequest = NoteApiPropsBase
export type NotesUpdateAttrsResponse = NoteApiPropsBase & {
  numMembers: number
  numRequests: number
  needsHelpGuide: boolean
}

// notes/:id/update
export interface NotesUpdateRequset {
  id: string
  dataMap: object
}
export type TreeItemResponse = TreeLeaf
export interface NotesUpdateResponse extends NoteResponse {
  updatedAt: string
  updatedDataMap: {[key: string]: TreeItemResponse}
}
// notes/:id/update_policy
export interface NotesUpdatePolicyRequset {
  id: string
  policyMap: object
}

// notes/:id/snapshot
export interface NotesSnapshotResponse extends NoteResponse {
  numMembers?: number
  numRequests?: number
  needsHelpGuide?: boolean
  snapshot: TreeRoot
}

// notes/:id/history
export interface NotesHistoryRequest {
  id: string
  offset?: number
  limit?: number
  fromDate?: string
  toDate?: string
  itemId?: string
  userId?: string
}
export interface NotesHistoryResponse extends NoteResponse {
  numMembers?: number
  numRequests?: number
  needsHelpGuide?: boolean
  historyAt: string
  history: TreeItemResponse[]
  pager: {
    // ページング
    offset?: number // 指定しない場合は 0
    limit?: number // 指定しない場合は 200
    // 検索クエリ
    fromDate?: string
    toDate?: string
    itemId?: string
    userId?: string
  }
}

// notes/:id/invite/url
export interface NotesInvitationCodeRequest {
  noteId: string
}
export type NotesInvitationCodeResponse = NoteInvitationCode

// notes/invite/:token
export interface NotesInvitationRequest {
  token: string
}
export type NotesInvitationResponse = Omit<NoteInvitation, 'token'>

// notes/invite/:token/request
export interface NotesInvitationJoinRequest {
  token: string
}
export type NotesInvitationJoinResponse = NotesInvitationResponse

// notes/:id/members
export interface NotesMembersGetRequest {
  id: string
}
export interface NotesMembersGetResponse extends NoteResponse {
  members: (UserResponse & {email: string})[]
  membersRelationMap: {
    [userId: string]: NoteRelationResponse
  }
  requestingUsers: (UserResponse & {email: string})[]
}

// notes/:id/members/:uid/accept
export interface NotesMembersAcceptRequest {
  noteId: string
  userId: string
  relationType: RelationType.watcher | RelationType.editor // API はどちら指定でもOK。ドメイン的には watcher がデフォルト。
}
export type NotesMembersAcceptResponse = UserResponse

// notes/:id/members/:uid/update
export interface NotesMembersUpdateRequest {
  noteId: string
  userId: string
  relationType: RelationType.watcher | RelationType.editor
  trackType: TrackRelationType.watcher | TrackRelationType.editor
}
export interface NotesMembersUpdateResponse {
  relationType: RelationType
}

// notes/:id/members/:uid/delete
export interface NotesMembersDeleteRequest {
  noteId: string
  userId: string
}
export interface NotesMembersDeleteResponse {
  success: boolean
}

export interface NotesRelationMeRequest {
  noteId: string
  mailTypes?: string
  data?: any
}
export interface NotesRelationMeMailTypeResponse {
  type: NoteMailNotificationType
  label: string
  help: string
}
// notes/relation/me
export interface NotesRelationMeResponse {
  notes: NoteResponse
  allMailTypes: NotesRelationMeMailTypeResponse[]
}

export interface ResourceRequest {
  file: any
  id: string
  type: ResourceType
  token: string
}
export interface ResourceResponse {
  url: string
}

// notes/:id/nodes/:nodeId/update
export interface NotesPolicyUpdateRequest {
  noteId: string
  policyMap: KeySignature
}
export interface NotesPolicyUpdateResponse extends ErrorResponse {
  success: boolean
}
export interface NotesEmergencyCodeRequest {
  noteId: string
}
export type NotesEmergencyCodeResponse = NoteEmergencyCode

export interface NotesEmergencyRequest {
  token: string
}
export type NotesEmergencyResponse = NoteEmergency

export interface UserTimelineResponse {
  userId: string
  items: UserTimeline[]
}
