import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import View, {Props} from '../../components/molecules/EditNoteMemberItem'
import ContactableUser from '../../domain/account/ContactableUser'
import {
  getDeletable,
  isEditMember,
  isFullMember,
  isTrackMember,
  isWatchOnlyMember,
  NoteRelatedUser
} from '../../domain/note/NoteRelatedUser'
import {RelationType, TrackRelationType} from '../../infra/api/types'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import ChangeNoteMemberRelationTypeUseCase, {
  NoteMemberChangeType,
  Params as ChangeNoteMemberRelationTypeUseCaseParams
} from '../../useCases/note/ChangeNoteMemberRelationTypeUseCase'
import UpdateNoteMemberRelationUseCase, {Params as UpdateNoteMemberRelationUseCaseParams} from '../../useCases/note/UpdateNoteMemberRelationUseCase'

export interface OwnProps {
  user: NoteRelatedUser<ContactableUser>
}

export default function EditNoteMemberItem({user}: OwnProps) {
  const [
    showAuthorityDropdownButton,
    setShowAuthorityDropdownButton,
  ] = useState(false)
  const paramsBase = {
    noteId: user.noteId,
    userId: user.id,
    fullname: user.fullname,
  }
  const me = useSelector(getMe) || {}
  const handleComplete = useCallback(() => {
    setShowAuthorityDropdownButton(false)
  }, [user])
  const memberAuthorityMenuItems = useMemo(
    () => [
      {
        active: isFullMember(user),
        label: 'フルメンバー',
        subLabel: 'ノートの編集と日々の記録が\nできるメンバーです。',
        onClick: () => {
          UseCaseCommander.create().command<
            UpdateNoteMemberRelationUseCaseParams
          >(UpdateNoteMemberRelationUseCase)({
            ...paramsBase,
            relationType: RelationType.editor,
            trackRelationType: TrackRelationType.editor,
            onComplete: handleComplete,
          })
        },
      },
      {
        active: isTrackMember(user),
        label: '日々の記録可',
        subLabel: '日々の記録ができるメンバーです。',
        onClick: () => {
          UseCaseCommander.create().command<
            UpdateNoteMemberRelationUseCaseParams
          >(UpdateNoteMemberRelationUseCase)({
            ...paramsBase,
            relationType: RelationType.watcher,
            trackRelationType: TrackRelationType.editor,
            onComplete: handleComplete,
          })
        },
      },
      {
        active: isEditMember(user),
        label: 'ノートの編集可',
        subLabel: 'ノートの編集ができるメンバーです。',
        onClick: () => {
          UseCaseCommander.create().command<
            UpdateNoteMemberRelationUseCaseParams
          >(UpdateNoteMemberRelationUseCase)({
            ...paramsBase,
            relationType: RelationType.editor,
            trackRelationType: TrackRelationType.watcher,
            onComplete: handleComplete,
          })
        },
      },
      {
        active: isWatchOnlyMember(user),
        label: '閲覧のみ',
        subLabel: '閲覧のみ可能なメンバーです。',
        onClick: () => {
          UseCaseCommander.create().command<
            UpdateNoteMemberRelationUseCaseParams
          >(UpdateNoteMemberRelationUseCase)({
            ...paramsBase,
            relationType: RelationType.watcher,
            trackRelationType: TrackRelationType.watcher,
            onComplete: handleComplete,
          })
        },
      },
    ],
    [user.id, user.relation.type, user.relation.trackType],
  )
  const props = {
    user,
    isMe: user.id == me.id,
    isDeletable: getDeletable(user),
    showAuthorityDropdownButton,
    setShowAuthorityDropdownButton,
    memberAuthorityMenuItems,
    handleAcceptClick: () => {
      UseCaseCommander.create().command<
        ChangeNoteMemberRelationTypeUseCaseParams
      >(ChangeNoteMemberRelationTypeUseCase)({
        ...paramsBase,
        changeType: NoteMemberChangeType.accept,
      })
    },
    handleDeleteClick: () => {
      UseCaseCommander.create().command<
        ChangeNoteMemberRelationTypeUseCaseParams
      >(ChangeNoteMemberRelationTypeUseCase)({
        ...paramsBase,
        changeType: NoteMemberChangeType.delete,
      })
    },
  }

  return React.createElement<Props>(View, props)
}
