import AppError from '../../../services/error/AppError'

export enum ActionType {
  Show = 'app/error/SHOW',
  Dismiss = 'app/error/DISMISS',
}

export interface IShowAction {
  type: typeof ActionType.Show
  payload: AppError
}

export interface IDismissAction {
  type: typeof ActionType.Dismiss
  payload: string
}

export type Action = IShowAction | IDismissAction
