import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'

const Code = styled.code`
  ${spacing}
  display: block;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 1.2;
  background-color: #eaf2c2;
  color: #406619;
  border-radius: 4px;
  word-break: break-all;
`

export default Code
