import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import Button from '../../../components/atoms/Button'
import EditAccountDialog from '../../organisms/EditAccountDialog'
import {height, width} from '../../../styles/mixins/sizing'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import LazyImage from '../../atoms/LazyImage'
import Col from '../../layouts/Col'
import Dialog from '../../layouts/Dialog'
import Row from '../../layouts/Row'
import TextButton from '../../atoms/TextButton'
import ShareLineButton from '../../molecules/ShareLineButton'
import HelpLink from '../../molecules/HelpLink'

const LINE_BUTTON_WIDTH = '110px'

const Inner = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  text-align: center;
  ${spacing}
`

const Note = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`
const Thumbnail = styled.div`
  ${width};
  ${height};
  ${spacing}
`

const ButtonList = styled.div`
  margin: 12px 0;
  align-items: center;
`

const ShareButton = styled.div`
  color: ${colors.gray400};
  cursor: pointer;
  margin: 16px auto;
`

const ShareLineButtonOuter = styled.div`
  margin: 0 auto;
  padding: 8px 0;
  width: ${LINE_BUTTON_WIDTH};
`

const BottomButtons = styled.div`
  ${spacing}
  display: flex;
  align-items: center;
`

const RefreshButton = styled.div`
  ${spacing}
  color: ${colors.gray400};
  cursor: pointer;
`

const DialogButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${spacing}
`

const QrCodeImage = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 8px;
`

const Description = styled(Body)``

const DescriptionDetail = styled(Body)`
  text-align: left;
`

const InviteMessagePre = styled.pre`
  white-space: pre-wrap;
`

export interface Props {
  isShow: boolean
  hasFullname: boolean
  noteTitle: string
  personName: string
  personImageUrl: string
  noteImageUrl: string
  inviteUrl: string
  inviteMessageText: string
  inviteMessageShortText: string
  inviteQrCodeData: string
  dismiss: () => void
  onClickRefreshQrCode: () => void
  onClickSendByMail: () => void
}

export default function NoteInvitationCodeDialog(props: Props) {
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [qrCodeDialog, setQrCodeDialog] = useState(false)
  const [inviteMsgDialog, setInviteMsgDialog] = useState(false)
  const {
    hasFullname,
    noteTitle,
    noteImageUrl,
    inviteUrl,
    inviteMessageText,
    inviteMessageShortText,
    inviteQrCodeData,
    isShow,
    onClickRefreshQrCode,
    onClickSendByMail,
  } = props
  const handleRefreshButton = () => {
    setConfirmDialog(false)
    onClickRefreshQrCode()
  }
  return (
    <React.Fragment>
      {hasFullname ? (
        <Dialog
          header="ノートに招待する"
          onClickClose={props.dismiss}
          transitionIn={isShow}
        >
          <Inner>
            <Note>
              <Thumbnail height={20} mr="xs" width={20}>
                <LazyImage objectFit="cover" src={noteImageUrl} />
              </Thumbnail>
              <Body>{noteTitle}への招待</Body>
            </Note>

            <Description mt="md">
              このノートを見てほしい人に、招待状を送りましょう。
            </Description>

            <ButtonList>
              <ShareButton>
                <ShareLineButtonOuter>
                  <ShareLineButton
                    height="auto"
                    text={inviteMessageShortText}
                    url={inviteUrl}
                    width={LINE_BUTTON_WIDTH}
                  />
                </ShareLineButtonOuter>
              </ShareButton>
              <ShareButton onClick={onClickSendByMail}>
                <TextButton>
                  <Body>メールで送る</Body>
                </TextButton>
              </ShareButton>
              <ShareButton onClick={() => setInviteMsgDialog(true)}>
                <TextButton>
                  <Body>招待メッセージを表示</Body>
                </TextButton>
              </ShareButton>
              <ShareButton onClick={() => setQrCodeDialog(true)}>
                <TextButton>
                  <Body>QRコードを表示</Body>
                </TextButton>
              </ShareButton>
            </ButtonList>

            <DescriptionDetail type="body2">
              　招待を受け取った方は、「{noteTitle}
              」への参加リクエストを送ることができます。
              あなたが参加を許可すると、その方はこのノートを見ることができるようになります。
              くわしい招待方法はこちらをお読み下さい→
              <HelpLink
                title="他の人がノートに参加するには"
                url="https://jnote.jp/faq/how-to-join/"
              />
            </DescriptionDetail>
          </Inner>
          <BottomButtons mt="md">
            <RefreshButton onClick={() => setConfirmDialog(true)}>
              <Body type="caption">招待用URLを変更する</Body>
            </RefreshButton>
          </BottomButtons>

          <Dialog
            header="ノート招待用URLを変更する"
            onClickClose={() => setConfirmDialog(false)}
            transitionIn={confirmDialog}
          >
            <Body type="body2">
              今まで使っていたURLおよびQRコードは使用できなくなります。
              <br />
              本当にノート招待用URLを変更してもよろしいですか？
            </Body>
            <DialogButtons mt="md">
              <Row>
                <Col>
                  <Button
                    backgroundColor={colors.gray400}
                    onClick={() => setConfirmDialog(false)}
                  >
                    キャンセル
                  </Button>
                </Col>
                <Col>
                  <Button onClick={handleRefreshButton}>変更する</Button>
                </Col>
              </Row>
            </DialogButtons>
          </Dialog>

          <Dialog
            header="招待状のQRコード"
            onClickClose={() => setQrCodeDialog(false)}
            transitionIn={qrCodeDialog}
          >
            <QrCodeImage>
              <LazyImage
                hasIndicator={true}
                height={150}
                src={inviteQrCodeData}
                width={150}
              />
            </QrCodeImage>

            <Body type="body2">
              招待したい方のカメラで、
              <br />
              QRコード読み取ってもらいましょう。
            </Body>

            <DialogButtons mt="md">
              <Row>
                <Col>
                  <Button onClick={() => setQrCodeDialog(false)}>OK</Button>
                </Col>
              </Row>
            </DialogButtons>
          </Dialog>

          {/* ISSUE: 印刷用に全画面表示　*/}
          <Dialog
            header="招待メッセージ"
            onClickClose={() => setInviteMsgDialog(false)}
            transitionIn={inviteMsgDialog}
          >
            <Body type="body2">
              <InviteMessagePre>{inviteMessageText}</InviteMessagePre>
            </Body>
            <QrCodeImage>
              <LazyImage
                hasIndicator={true}
                height={150}
                src={inviteQrCodeData}
                width={150}
              />
            </QrCodeImage>
          </Dialog>
        </Dialog>
      ) : (
        <EditAccountDialog
          dismiss={props.dismiss}
          isShow={isShow}
          message="ノートへの招待をするにはあなたの「お名前」が必要です。\n「お名前」を入力しましょう!"
        />
      )}
    </React.Fragment>
  )
}
