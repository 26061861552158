import {createSelector} from 'reselect'
import NoteEmergency from '../../domain/note/NoteEmergency'
import {TreeLeaf} from '../../domain/note/snapshot/Tree'
import {
  NoteEmergencyCodeSchema,
  NoteEmergencySchema,
} from '../../infra/redux/api/schemata'
import {getById} from '../../infra/redux/api/selectors'

export const getNoteEmergencyCodeById = getById(NoteEmergencyCodeSchema)

export const getNoteEmergencyById = getById(NoteEmergencySchema)

export const getNoteEmergencyNodes = (id: string) =>
  createSelector(
    getNoteEmergencyById(id),
    (emergency: NoteEmergency): TreeLeaf[] => {
      const {nodes = {}} = emergency || {}
      return Object.keys(nodes).map(key => {
        return nodes[key]
      })
    },
  )
