import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/UploadTrackImageField'
import {ResourceType} from '../../infra/api/types'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getResourceUploadToken} from '../../selectors/api'
import RemoveNoteResourceUseCase from '../../useCases/resource/RemoveNoteResourceUseCase'
import UploadResourceUseCase from '../../useCases/resource/UploadResourceUseCase'

export interface Props {
  useReduxForm?: boolean
  form?: string
  name: string
  sources: string[]
  hideUploadIcon?: boolean
}

export default function UploadTrackImageField(props: Props) {
  const {form, name, sources, useReduxForm, hideUploadIcon, ...rest} = props
  const resourceId = `${form}_${name}`
  const token = useSelector(getResourceUploadToken)

  const isProcessing = useSelector(
    useCaseSelectors.isProcessing(UploadResourceUseCase /* resourceId */),
  )

  const onDelete = useCallback(() => {
    UseCaseCommander.create().command(RemoveNoteResourceUseCase)({
      id: resourceId,
      form,
      field: name,
      type: ResourceType.user,
    })
  }, [])

  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    UseCaseCommander.create().command(UploadResourceUseCase /*, resourceId*/)({
      id: resourceId,
      type: ResourceType.track,
      token,
      formParams: {
        form,
        field: name,
      },
      file: e.target.files && e.target.files.item(0),
    })
  }, [])

  return React.createElement(View, {
    ...rest,
    useReduxForm,
    name,
    isProcessing,
    sources,
    onDelete,
    onChangeFile,
    hideUploadIcon,
  })
}
