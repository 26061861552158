import * as React from 'react'
import styled from 'styled-components'
import {fontSize} from '../../../styles/mixins/font'
import {spacing} from '../../../styles/mixins/spacing'
import {body2} from '../../../styles/variables'
import colors from '../../../styles/variables/colors'

const StyledButton = styled.button`
  ${spacing};
  padding: 0;
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: transparent;
  ${fontSize(body2.fontSize)};
  color: ${p => p.color};

  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};

  :disabled {
    opacity: 0.5;
  }
`

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

/**
 * ボタン
 */
export default function TextButton({
  color = colors.blue100,
  children,
  type = 'button',
  ...props
}: Props) {
  return (
    <StyledButton color={color} type={type} {...props}>
      {children}
    </StyledButton>
  )
}
