import * as React from 'react'
import styled from 'styled-components'
import TrackWeekTable from '../../../containers/TrackWeekTable'
import {NoteTrackDataType} from '../../../domain/note/track/NoteTrack'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Paper from '../../atoms/Paper'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

const View = styled.div``

const PaperButton = styled(Paper)<{active: boolean}>`
  background-color: ${p => (p.active ? colors.blue100 : '#fff')};
  color: ${p => (p.active ? '#fff' : '#000')};
  cursor: pointer;
`

export interface Props {
  tab: number
  setTab: (val: number) => void
  types: NoteTrackDataType[]
}

export default function NoteTrackTimetableTabs(props: Props) {
  const {tab, types, setTab} = props
  return (
    <View>
      <Row gutter={space.xxs} mt="xxs">
        <Col col={3} onClick={() => setTab(0)}>
          <PaperButton active={tab === 0}>
            <Body textAlign="center" type="body2">
              食事
            </Body>
          </PaperButton>
        </Col>
        <Col col={3} onClick={() => setTab(1)}>
          <PaperButton active={tab === 1}>
            <Body textAlign="center" type="body2">
              排泄
            </Body>
          </PaperButton>
        </Col>
        <Col col={3} onClick={() => setTab(2)}>
          <PaperButton active={tab === 2}>
            <Body textAlign="center" type="body2">
              くすり
            </Body>
          </PaperButton>
        </Col>
        <Col col={3} onClick={() => setTab(3)}>
          <PaperButton active={tab === 3}>
            <Body textAlign="center" type="body2">
              発作
            </Body>
          </PaperButton>
        </Col>
      </Row>
      <Paper mt="xxs" px={0}>
        <TrackWeekTable types={types} />
      </Paper>
    </View>
  )
}
