import {useSelector} from 'react-redux'
import View, {Props} from '../../components/pages/EditNoteCoverPage'
import {getNoteById} from '../../infra/redux/api/selectors'
import React, {useEffect} from 'react'
import {useRouter} from '../../lib/router'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import FetchNoteUseCase from '../../useCases/note/FetchNoteUseCase'

export default function EditNoteCoverPage() {
  const {
    match: {
      params: {noteId: urlNoteId},
    },
  } = useRouter<{noteId: string}>()

  const note = useSelector(getNoteById(urlNoteId))
  const props = {
    noteId: note.id,
    formInitialValues: {
      ...note,
    },
  }

  useEffect(() => {
    urlNoteId &&
      UseCaseCommander.create().command(FetchNoteUseCase)({id: urlNoteId})
  }, [urlNoteId]) // NOTE: depsを指定しないと無限API呼び出し

  return React.createElement<Props>(View, props)
}
