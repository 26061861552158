// FIXME: ばしょ
import {FieldValidators} from '../../lib/validators/types'
import * as formValidations from '../forms'

export function getValidate(validatorName: string) {
  const {validate = null} = formValidations[validatorName] || {}
  return validate
}

export function getValidates(validators?: FieldValidators | string[]) {
  return (
    validators &&
    (validators as []).map((v: any) => {
      return typeof v === 'string' ? getValidate(v) : v.validate
    })
  )
}
