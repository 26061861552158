import {Action as ReduxAction, Dispatch, MiddlewareAPI, Action} from 'redux'
import {IUseCaseActionCommandedType, ActionType} from '../useCase/types'
import {logUsecaseCommandCommanded} from '.'

export function loggerMiddleware<S>(api: MiddlewareAPI<any, S>) {
  return (next: Dispatch<Action>) => {
    return <A extends ReduxAction>(action: any): A => {
      if (action.type === ActionType.CommandCommanded) {
        const {
          command: {params, UseCase},
        } = (action as IUseCaseActionCommandedType).payload
        logUsecaseCommandCommanded({params, UseCase})
      }
      return next(action)
    }
  }
}
