import BaseAPIClient from './BaseAPIClient'
import {
  ApiResponse,
  NoteResponse,
  NotesCreateRequest,
  NotesCreateResponse,
  NotesEmergencyCodeRequest,
  NotesEmergencyCodeResponse,
  NotesEmergencyRequest,
  NotesEmergencyResponse,
  NotesGetRequest,
  NotesGetResponse,
  NotesHistoryRequest,
  NotesHistoryResponse,
  NotesInvitationCodeRequest,
  NotesInvitationCodeResponse,
  NotesInvitationJoinRequest,
  NotesInvitationJoinResponse,
  NotesInvitationRequest,
  NotesInvitationResponse,
  NotesMembersAcceptRequest,
  NotesMembersAcceptResponse,
  NotesMembersDeleteRequest,
  NotesMembersDeleteResponse,
  NotesMembersGetRequest,
  NotesMembersGetResponse,
  NotesMembersUpdateRequest,
  NotesPolicyUpdateRequest,
  NotesPolicyUpdateResponse,
  NotesRelationMeRequest,
  NotesRelationMeResponse,
  NotesSnapshotResponse,
  NotesUpdateAttrsRequest,
  NotesUpdateAttrsResponse,
  NotesUpdatePolicyRequset,
  NotesUpdateRequset,
  NotesUpdateResponse,
  NoteTimelineCommentsDeleteRequest,
  NoteTimelineCommentsPostRequest,
  NoteTimelineDailySumsRequest,
  NoteTimelineDailySumsResponse,
  NoteTimelineDeleteRequest,
  NoteTimelineFetchRequest,
  NoteTimelineItemResponse,
  NoteTimelinePostRequest,
  NoteTimelineResponse,
  NoteTimelineUpdateRequest,
  RequestResetPasswordRequest,
  ResetPasswordRequest,
  SessionResponse,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpVerifyRequest,
  SignUpVerifyResponse,
  UserMeResponse,
  UsersMeUpdateRequest,
  UserTimelineResponse
} from './types'

interface Res {
  body: any
  ok: boolean
  status: number
}

export default class AppAPIClient extends BaseAPIClient {
  private readonly token: string

  public constructor({endpoint, token}: {endpoint: string; token: string}) {
    super({endpoint})
    this.token = token
  }

  protected getDefaultOptions(): KeySignature {
    let headers: any = {
      // 'Accept-Language': 'ja',
      // 'jnote-App-ID': this.appName,
      // 'jnote-App-Version': this.appVersion,
      // 'jnote-UUID': this.uuid,
    }
    if (this.token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${this.token}`,
      }
    }
    return {headers}
  }

  public async signUp(params: SignUpRequest): Promise<ApiResponse> {
    return await this.post('/session/sign_up', params)
  }

  public async signUpVerify(
    params: SignUpVerifyRequest,
  ): Promise<SignUpVerifyResponse> {
    return await this.post(`/session/sign_up_verify`, params)
  }

  public async usersMe(): Promise<UserMeResponse> {
    return await this.get('/users/me/')
  }

  // FIXME: params
  public async updateMe(params: UsersMeUpdateRequest): Promise<UserMeResponse> {
    return await this.post('/users/me/update', params)
  }

  public async signIn(params: SignInRequest): Promise<SignInResponse> {
    return await this.post('/session/sign_in', params)
  }

  public async signOut(): Promise<ApiResponse> {
    return await this.post('/session/sign_out')
  }

  public async requestResetPassword(
    params: RequestResetPasswordRequest,
  ): Promise<ApiResponse> {
    return await this.post('/session/request_reset_password', params)
  }

  public async resetPassword(
    params: ResetPasswordRequest,
  ): Promise<SessionResponse> {
    return await this.post('/session/reset_password', params)
  }

  public async fetchNoteSnapshot({
    id,
  }: {
    id: string
  }): Promise<NotesSnapshotResponse> {
    return await this.get(`/notes/${id}/snapshot`, {})
  }

  public async fetchNoteHistory({
    id,
    ...payload
  }: NotesHistoryRequest): Promise<NotesHistoryResponse> {
    return await this.get(`/notes/${id}/history`, payload)
  }

  public async fetchNoteTimeline({
    id,
    ...payload
  }: NoteTimelineFetchRequest): Promise<NoteTimelineResponse> {
    return await this.get(`/notes/${id}/timeline`, payload)
  }

  public async postNoteTimeline({
    id,
    ...payload
  }: NoteTimelinePostRequest): Promise<NoteTimelineItemResponse> {
    return await this.post(`/notes/${id}/timeline`, payload)
  }

  public async updateNoteTimeline({
    id,
    timelineId,
    ...payload
  }: NoteTimelineUpdateRequest): Promise<NoteResponse> {
    return await this.post(
      `/notes/${id}/timeline/${timelineId}/update`,
      payload,
    )
  }

  public async deleteNoteTimeline({
    id,
    timelineId,
  }: NoteTimelineDeleteRequest): Promise<NoteResponse> {
    return await this.post(`/notes/${id}/timeline/${timelineId}/delete`)
  }

  public async postNoteTimelineComment({
    id,
    timelineId,
    ...payload
  }: NoteTimelineCommentsPostRequest): Promise<NoteTimelineItemResponse> {
    return await this.post(
      `/notes/${id}/timeline/${timelineId}/comments`,
      payload,
    )
  }

  public async deleteNoteTimelineComment({
    id,
    timelineId,
    commentId,
  }: NoteTimelineCommentsDeleteRequest): Promise<ApiResponse> {
    return await this.post(
      `/notes/${id}/timeline/${timelineId}/comments/${commentId}/delete`,
    )
  }

  public async fetchNoteTimelineDailySums({
    id,
    ...payload
  }: NoteTimelineDailySumsRequest): Promise<NoteTimelineDailySumsResponse> {
    return await this.get(`/notes/${id}/timeline/daily_sums`, payload)
  }

  public async updateSnapshot({
    id,
    dataMap,
  }: NotesUpdateRequset): Promise<NotesUpdateResponse> {
    return await this.post(`/notes/${id}/update`, {dataMap})
  }

  public async updatePolicy({
    id,
    policyMap,
  }: NotesUpdatePolicyRequset): Promise<NotesUpdateResponse> {
    return await this.post(`/notes/${id}/update_policy`, {policyMap})
  }

  public async createNote(
    data: NotesCreateRequest,
  ): Promise<NotesCreateResponse> {
    return await this.post(`/notes/create`, data)
  }

  public async getNote({id}: NotesGetRequest): Promise<NotesGetResponse> {
    return await this.get(`/notes/${id}`)
  }

  public async updateNoteAttrs({
    id,
    ...payload
  }: NotesUpdateAttrsRequest): Promise<NotesUpdateAttrsResponse> {
    return await this.post(`/notes/${id}/update_attrs`, payload)
  }

  public async getNoteInvitationCode({
    noteId,
  }: NotesInvitationCodeRequest): Promise<NotesInvitationCodeResponse> {
    return await this.get(`/notes/${noteId}/invite/url`)
  }

  public async refreshNoteInvitationCode({
    noteId,
  }: NotesInvitationCodeRequest): Promise<NotesInvitationCodeResponse> {
    return await this.post(`/notes/${noteId}/invite/url/refresh`)
  }

  public async getNoteInvitation({
    token,
  }: NotesInvitationRequest): Promise<NotesInvitationResponse> {
    return await this.get(`/notes/invite/${token}`)
  }

  public async requestNoteInvitationJoin({
    token,
  }: NotesInvitationJoinRequest): Promise<NotesInvitationJoinResponse> {
    return await this.post(`/notes/invite/${token}/request`)
  }

  public async getNoteMembers({
    id,
  }: NotesMembersGetRequest): Promise<NotesMembersGetResponse> {
    return await this.get(`/notes/${id}/members`)
  }

  public async acceptNoteMember({
    noteId,
    userId,
    ...payload
  }: NotesMembersAcceptRequest): Promise<NotesMembersAcceptResponse> {
    return await this.post(`/notes/${noteId}/members/${userId}/accept`, payload)
  }

  public async updateNoteMember({
    noteId,
    userId,
    ...payload
  }: NotesMembersUpdateRequest): Promise<NotesMembersAcceptResponse> {
    return await this.post(`/notes/${noteId}/members/${userId}/update`, payload)
  }

  public async deleteNoteMember({
    noteId,
    userId,
  }: NotesMembersDeleteRequest): Promise<NotesMembersDeleteResponse> {
    return await this.post(`/notes/${noteId}/members/${userId}/delete`)
  }

  public async updateNotePolicy({
    noteId,
    ...payload
  }: NotesPolicyUpdateRequest): Promise<NotesPolicyUpdateResponse> {
    return await this.post(`/notes/${noteId}/update_policy`, payload)
  }

  public async getNoteEmergencyCode({
    noteId,
  }: NotesEmergencyCodeRequest): Promise<NotesEmergencyCodeResponse> {
    return await this.get(`/notes/${noteId}/emergency/url`)
  }

  public async refreshNoteEmergencyCode({
    noteId,
  }: NotesEmergencyCodeRequest): Promise<NotesEmergencyCodeResponse> {
    return await this.post(`/notes/${noteId}/emergency/url/refresh`)
  }

  public async getNoteEmergency({
    token,
  }: NotesEmergencyRequest): Promise<NotesEmergencyResponse> {
    return await this.get(`/notes/emergency/${token}`)
  }

  public async getNoteRelationMe(): Promise<NotesRelationMeResponse> {
    return await this.get(`/notes/relation/me`)
  }

  public async updateNoteRelationMe({
    noteId,
    ...payload
  }: NotesRelationMeRequest): Promise<NoteResponse> {
    return await this.post(`/notes/${noteId}/relation/me/update`, payload)
  }

  public async getUserTimeline(): Promise<UserTimelineResponse> {
    return await this.get(`/users/me/timeline/`)
  }
}
