import {COLMUNS, GUTTER} from '../../variables/dom'

const colWidth = (width: string | number) => {
  if ('clear' === width) {
    return `
      flex: 1 1 auto;
      max-width: none;
    `
  } else if ('auto' === width) {
    return `
      flex: 0 1 auto;
  `
  } else if ('fit' === width) {
    return `
      width: 1%;
      flex: 1 1 auto;
  `
  } else if (1 === width) {
    return `
      flex: 1 1 0;
    `
  } else {
    return `
      flex: 0 1 ${width};
      max-width: ${width};
    `
  }
}

const col = ({
  col = COLMUNS,
  columns = COLMUNS,
  gutter = GUTTER,
}: {
  col?: number | string
  columns?: number
  gutter?: number
}) => {
  const width =
    typeof col === 'string'
      ? colWidth(col)
      : colWidth(`${((col as number) / columns) * 100}%`)
  return `
    ${width}
    padding-left: ${gutter / 2}px;
    padding-right: ${gutter / 2}px;
  `
}

export enum flexTarget {
  items = 'items',
  self = 'self',
  content = 'content',
}

export enum flexPosition {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  baseline = 'baseline',
}

const align = ({
  target = flexTarget.items,
  position = flexPosition.flexStart,
}: {
  target?: flexTarget
  position?: flexPosition
}) => {
  return `
    background-color: red;
    align-${target}: ${position};
  `
}

export {align, col, colWidth}
