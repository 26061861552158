import * as React from 'react'
import styled from 'styled-components'
import {col} from '../../../styles/mixins/grid'
import media from '../../../styles/mixins/media'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {COLMUNS, GUTTER} from '../../../styles/variables/dom'

type MediaProps = {
  auto?: boolean
  col?: number | string
  columns?: number
  gutter?: number
} & SpacingProps

type ViewProps = {
  tablet?: MediaProps
  desktop?: MediaProps
} & MediaProps

type Props = {
  children: React.ReactNode
} & ViewProps &
  SpacingProps

const ColView = styled.div<ViewProps>`
  ${(p: ViewProps) => col(p)} 
  ${media.tablet((p: ViewProps) => p.tablet && col(p.tablet))}
  ${media.desktop((p: ViewProps) => p.desktop && col(p.desktop))}
  
  ${spacing};
`

const Col = ({
  col = 'auto',
  columns = COLMUNS,
  children,
  desktop,
  tablet,
  gutter = GUTTER,
  ...rest
}: Props) => (
  <ColView
    col={col}
    columns={columns}
    desktop={desktop}
    gutter={gutter}
    tablet={tablet}
    {...rest}
  >
    {children}
  </ColView>
)

export default Col
export {ColView}
