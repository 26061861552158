import {endOfWeek, format, startOfWeek} from 'date-fns'
import * as React from 'react'
import {useCallback} from 'react'
import Calendar, {CalendarTileProperties, ViewCallbackProperties} from 'react-calendar'
import styled from 'styled-components'
import {dateToDateStr} from '../../../domain/note/track/NoteTrack'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Modal from '../../layouts/Modal'
import Row from '../../layouts/Row'

const View = styled.div`
  cursor: pointer;
`

const CalendarOuter = styled(Body)``
const BadgeGroup = styled.div`
  margin-top: ${space.xxs}px;
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Badge = styled.div`
  margin: 0 1px;
  width: 4px;
  height: 4px;
  background-color: ${colors.blue100};
  border-radius: 50%;
`

export interface Props {
  date: Date
  weekly?: boolean
  onChange?: (value: Date) => void
  onActiveDateChange?: (props: ViewCallbackProperties) => void
  badgeDates: string[]
  showCal: boolean
  setShowCal: (val: boolean) => void
}

export default function DatePicker(props: Props) {
  const {
    badgeDates,
    date,
    onChange,
    onActiveDateChange,
    weekly,
    showCal,
    setShowCal,
  } = props
  const handleChange = useCallback(
    value => {
      onChange && onChange(value)
      setShowCal(false)
    },
    [showCal, date],
  )
  const handleClick = useCallback(() => {
    setShowCal(true)
  }, [date])
  const handleDismiss = useCallback(() => {
    setShowCal(false)
  }, [date])
  const handleTileClassName = useCallback(
    ({date, view}: CalendarTileProperties) => {
      const dateStr = dateToDateStr(date)
      return (
        <BadgeGroup>
          {badgeDates && badgeDates.includes(dateStr) && <Badge />}
        </BadgeGroup>
      )
    },
    [badgeDates],
  )
  return (
    <>
      <View onClick={handleClick}>
        <Row align="center">
          <Col>
            <Icon size={20} type="calendar-31" />
          </Col>
          <Col>
            {weekly ? (
              <>
                <Body type="overline">
                  {format(startOfWeek(date), 'yyyy年MM月dd日')}〜
                </Body>
                <Body type="overline">
                  {format(endOfWeek(date), 'yyyy年MM月dd日')}
                </Body>
              </>
            ) : (
              <Body type="body1">{format(date, 'yyyy年MM月dd日')}</Body>
            )}
          </Col>
        </Row>
      </View>
      <Modal dismiss={handleDismiss} paper={false} show={showCal}>
        <CalendarOuter>
          <Calendar
            calendarType="US"
            onActiveDateChange={onActiveDateChange}
            onChange={handleChange}
            tileContent={handleTileClassName}
            value={date}
          />
        </CalendarOuter>
      </Modal>
    </>
  )
}
