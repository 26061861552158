import NoteId from '../../../domain/note/NoteId'
import INoteNotifications from '../../../domain/note/notifications/INoteNotifications'
import {NoteMailNotificationType} from '../../../domain/note/notifications/NoteMailNotificationType'
import NoteNotifications from '../../../infra/repositories/note/notifications/NoteNotifications'
import {
  NotificationPrinciple,
  NotificationType
} from '../../../services/app/Notification'
import NoticeUseCase from '../../app/NoticeUseCase'
import UseCase from '../../lib/UseCase'

interface Props {
  noteNotification: INoteNotifications
}

export interface Params {
  noteId: NoteId
  type: NoteMailNotificationType
}

export default class NoticeNoteNotificationsUseCase extends UseCase {
  private noteNotification: INoteNotifications

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteNotification = props.noteNotification
  }

  /**
   * create
   */
  public static create() {
    return new NoticeNoteNotificationsUseCase({
      noteNotification: NoteNotifications.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({noteId, type}: Params): Promise<void> {
    this.registerSuccess(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: 'お知らせメールの受信設定を登録しました',
      })
    })
    type && (await this.noteNotification.notice(noteId, type))
  }
}
