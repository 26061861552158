import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'

interface Props {
  applicationService: ApplicationService
}

interface Params {
  hide?: boolean
}

/**
 * SignUpUseCase
 */
export default class ToggleMenuUseCase extends UseCase {
  private _applicationService: ApplicationService

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._applicationService = props.applicationService
  }

  /**
   * create
   */
  public static create() {
    return new ToggleMenuUseCase({
      applicationService: ApplicationService.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({hide = false}: Params = {}): Promise<void> {
    if (hide) {
      this._applicationService.hideMenu()
    } else {
      this._applicationService.toggleMenu()
    }
  }
}
