import * as React from 'react'
import {useRef, useState} from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import LazyImage from '../../atoms/LazyImage'
import MenuIconButton from '../../atoms/MenuIconButton'
import Card from '../../molecules/Card'
import CardHeader from '../../molecules/CardHeader'
import Menu, {MenuItem} from '../../molecules/Menu'

const Thumbnail = styled.div`
  z-index: 10;
  width: 100%;
  //height: 200px;
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
`

const Content = styled.div`
  margin: ${space.sm}px ${space.sm}px;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
`
const ContentTail = styled(Content)`
  padding-bottom: ${space.sm}px;
`

const ContentBody = styled(Body)`
  text-align: left;
  color: ${colors.gray400};
`

const Actions = styled.div`
  margin-top: ${space.md};

  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-left: auto;
  & > * {
    &:first-child {
      margin-left: 0;
    }
    margin-left: ${space.md}px;
  }
`
const Action = styled(Body)<{onClick: () => void}>`
  display: flex;
  align-items: center;
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
  color: ${colors.primary};
`

const MenuAction = styled(MenuIconButton)`
  padding: ${space.xs}px 0 ${space.xs}px ${space.sm}px;
  cursor: pointer;
`

export interface CardActionProps {
  iconType?: string
  label: string
  onClick: () => void
}

export interface Props {
  headerIconUrl: string
  headerTitle: string
  thumbnailImageUrl: string
  body?: string
  actions?: CardActionProps[]
  menuItems: MenuItem[]
  onClickThumbnail?: () => void
}

export default function NoteCard(props: Props) {
  const {
    actions,
    body,
    headerTitle,
    headerIconUrl,
    menuItems,
    thumbnailImageUrl,
    onClickThumbnail,
  } = props
  const [showMenu, setShowMenu] = useState(false)
  const menuActionElement = useRef<HTMLDivElement>(null)
  const buttons =
    actions &&
    actions.map((action: CardActionProps, index) => {
      return (
        <Action key={index} onClick={action.onClick} type="body2">
          {action.iconType && (
            <Icon mr="xxs" size={16} type={action.iconType} />
          )}
          {action.label}
        </Action>
      )
    })
  return (
    <Card>
      <CardHeader
        /*action={
          <MenuAction
            onClick={() => setShowMenu(true)}
            ref={menuActionElement}
          />
        }*/
        avatar={<Avatar src={headerIconUrl} />}
        title={headerTitle}
      />
      <Thumbnail onClick={e => onClickThumbnail && onClickThumbnail()}>
        <LazyImage
          hasIndicator={true}
          height="100%"
          objectFit="cover"
          src={thumbnailImageUrl}
          width="100%"
        />
      </Thumbnail>
      <Content>
        {body && <ContentBody type="body2">{body}</ContentBody>}
      </Content>
      <ContentTail>{buttons && <Actions>{buttons}</Actions>}</ContentTail>

      {showMenu && (
        <Menu
          items={menuItems}
          onClose={() => setShowMenu(false)}
          trigger={menuActionElement.current as HTMLElement}
        />
      )}
    </Card>
  )
}
