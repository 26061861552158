import * as React from 'react'
import styled from 'styled-components'
import NoteNav from '../../../containers/NoteNav'
import {NoteMailNotificationType} from '../../../domain/note/notifications/NoteMailNotificationType'
import {
  NoteMailNotificationTypeProp,
  NoteMailNotificationTypeProps,
} from '../../../selectors/api/note/notifications'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Divider from '../../atoms/Divider'
import Heading from '../../atoms/Heading'
import Switch from '../../atoms/Switch'
import Container from '../../layouts/Container'
import List from '../../layouts/List'
import ListItem from '../../layouts/ListItem'
import HelpLink from '../../molecules/HelpLink'
import DefaultPage from '../../templates/DefaultPage'

const View = styled(Container)`
  margin-top: ${space.lg}px;
  margin-bottom: ${space.lg}px;
  padding-top: ${space.lg}px;
  padding-bottom: ${space.lg}px;
  background-color: #fff;
  ${boxShadow};
  ${borderRadius};
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`
const ItemContent = styled.div`
  padding-right: 2em;
`
const ItemSwitchOuter = styled.div`
  margin-left: auto;
`

export interface Props {
  disabled: boolean
  noteTitle: string
  title: string
  types: NoteMailNotificationTypeProps
  onChangeMailNotification: (
    type: NoteMailNotificationType,
    notice: boolean,
  ) => void
}

export default function SettingsNotificationsPage(props: Props) {
  const {disabled, title, noteTitle, types, onChangeMailNotification} = props
  const items =
    types &&
    types.map(({checked, help, type, label}: NoteMailNotificationTypeProp) => {
      return (
        <>
          <ListItem key={type}>
            <ItemRow>
              <ItemContent>
                <Heading type="heading5">{label}</Heading>
                <Body color={colors.gray400} type="body2">
                  {help}
                </Body>
              </ItemContent>
              <ItemSwitchOuter>
                <Switch
                  defaultChecked={checked}
                  disabled={disabled}
                  onChange={e =>
                    onChangeMailNotification(type, e.target.checked)
                  }
                />
              </ItemSwitchOuter>
            </ItemRow>
          </ListItem>
          <Divider />
        </>
      )
    })
  return (
    <DefaultPage back={true} nav={NoteNav} title={title}>
      <View>
        <Heading type="heading3">{noteTitle}のお知らせメールの受信設定</Heading>
        <Body color={colors.gray400} mt="md" type="body1">
          「{noteTitle}」 に関するお知らせをメールで受信するかどうかの設定です。
        </Body>
        <List mt="md">{items}</List>

        <Body color={colors.gray400} type="body2">
          お知らせメールの受信設定の詳細は、
          <HelpLink url="https://jnote.jp/faq/note-mail-notify/">
            ヘルプ
          </HelpLink>
          をご覧ください。
        </Body>
      </View>
    </DefaultPage>
  )
}
