import {UseCaseConstructor} from '../../../useCases/lib/types'
import {createCommander} from '../../../useCases/lib/UseCase'
import ReduxProvider from '../ReduxProvider'
import {actions} from './index'

export interface Props {
  redux: ReduxProvider
}

/**
 * UseCaseCommander
 */
export default class UseCaseCommander {
  /**
   * create
   */
  public static create() {
    if (UseCaseCommander._instance) {
      return UseCaseCommander._instance
    }
    return (UseCaseCommander._instance = new UseCaseCommander({
      redux: ReduxProvider.create(),
    }))
  }

  private static _instance: UseCaseCommander
  private _redux: ReduxProvider

  /**
   * constructor
   */
  public constructor(props: Props) {
    this._redux = props.redux
  }

  /**
   * run
   *
   * @param UseCaseClass
   * @param useCaseId?
   * @returns {(params: P) => void}
   */
  public command<P = {}>(UseCaseClass: UseCaseConstructor, useCaseId?: string) {
    return (params?: P): string => {
      const command = createCommander(UseCaseClass, useCaseId)(params)
      this._redux.dispatch(actions.command({command}))
      return command.id
    }
  }
}
