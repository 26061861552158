import INoteTimelineRepository from '../../domain/note/timeline/INoteTimelineRepository'
import NoteTimelineRepository from '../../infra/repositories/note/NoteTimelineRepository'
import FormService from '../../infra/services/FormService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTimelineRepository: INoteTimelineRepository
  formService: IFormService
}

export interface Params {
  noteId: string
  timelineId: string
  commentId: string
}

export default class DeleteNoteTimelineCommentUseCase extends UseCase {
  private _noteTimelineRepository: INoteTimelineRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTimelineRepository = props.noteTimelineRepository
    this._formService = props.formService
  }

  public static create() {
    return new DeleteNoteTimelineCommentUseCase({
      noteTimelineRepository: NoteTimelineRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({noteId, timelineId, commentId}: Params): Promise<void> {
    this.registerComplete(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '削除しました',
      })
    })
    await this._noteTimelineRepository.deleteComment({noteId, timelineId, commentId})
  }
}
