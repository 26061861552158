import * as React from 'react'
import HamburgerButton from '../../../containers/HamburgerButton'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import HeaderLayout from '../../layouts/HeaderLayout'
import Row from '../../layouts/Row'
import HeaderLogo from '../../../containers/HeaderLogo'
import LabeledIcon from '../../molecules/LabeledIcon'

const LoginButton = (props: any) => (
  <LabeledIcon {...props} type="enter">
    ログイン
  </LabeledIcon>
)

const HeaderRight = () => {
  return (
    <Row gutter={space.md}>
      <Col>
        <PushNavigationLink routeKey="login" view={LoginButton} />
      </Col>
      <Col>
        <HamburgerButton />
      </Col>
    </Row>
  )
}

export type Props = {
  title?: string
}

export default function UnauthorizedHeader(props: Props) {
  return <HeaderLayout left={<HeaderLogo {...props} />} right={<HeaderRight />} />
}
