import * as React from 'react'
import {SyntheticEvent} from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {emailFormat, required} from '../../../services/forms'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Icon from '../../atoms/Icon'
import Space, {SpaceProps} from '../../layouts/Space'
import LabeledInput from '../../molecules/LabeledInput'

const View = styled(Space)``
const Header = styled.header`
  text-align: center;
`
const Content = styled.div`
  margin-top: ${space.lg}px;
  text-align: center;
`
const ButtonOuter = styled.div`
  margin-top: ${space.lg}px;
  width: 100%;
  text-align: center;
`
const Controls = styled.div`
  margin-top: ${space.md}px;
`

export interface IFormProps {
  submitForm: (e: SyntheticEvent<any>) => void
  submitDisabled: boolean
}

export interface IFormData {
  email: string
}

export type Props = SpaceProps &
  IFormProps & {
    handleSubmit: (event: any) => any
  }

export default function RequestResetPasswordForm(props: Props) {
  return (
    <View {...props}>
      <Header>
        <Heading size={'heading1'}>
          新しいパスワードを作成するためのリンクをメールでお送りします
        </Heading>
      </Header>
      <Content>
        <form onSubmit={props.handleSubmit(props.submitForm)}>
          <Controls>
            <ReduxField
              component={LabeledInput}
              label="メールアドレス"
              name="email"
              placeholder="メールアドレスを入力する"
              validators={[required, emailFormat]}
            />
          </Controls>

          <ButtonOuter>
            <Button
              backgroundColor={colors.success}
              expanded={true}
              size="large"
              // disabled={props.submitDisabled}
            >
              新しいパスワードを取得
              <Space ml={space.xs}>
                <Icon type="repeat" />
              </Space>
            </Button>
          </ButtonOuter>
        </form>
      </Content>
    </View>
  )
}
