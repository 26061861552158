import * as React from 'react'
import {ReduxField} from '../../../hocs/withReduxField'
import {FieldValidators} from '../../../lib/validators/types'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import CheckboxRadio from '../../atoms/CheckboxRadio'
import Col from '../Col'
import {InterpolatedReduxComponent} from '../InterpolatedComponent'
import Row from '../Row'

const ReduxCheckboxRadio = (props: any /*ReduxFieldProps*/) => {
  const {disabled, label} = props
  const labelComponent = InterpolatedReduxComponent(label, {}, true, disabled)
  const hasControl = Array.isArray(labelComponent)

  return (
    <CheckboxRadio
      {...props}
      {...props.input}
      control={hasControl && labelComponent}
      label={!hasControl && label}
    />
  )
}

export interface NoteCheckboxRadioProps {
  label: string
  validators: FieldValidators | string[]
}

type CheckboxRadioType = 'checkbox' | 'radio'

export interface Props {
  type: CheckboxRadioType
  name?: string
  label?: string
  options: KeySignature<NoteCheckboxRadioProps>
  disabled?: boolean
}

export default function CheckboxRadioItems(props: Props) {
  const {options, disabled, label, name, type} = props
  const inputs =
    options &&
    Object.keys(options).map(key => {
      const p = options[key]
      const {validators = []} = p
      const getName = (type: CheckboxRadioType) => {
        return (
          {
            checkbox: key,
            radio: name,
          }[type] || ''
        )
      }
      return (
        <Col col="auto" key={key} mt="sm" {...p}>
          <ReduxField
            component={ReduxCheckboxRadio}
            disabled={disabled}
            name={getName(type)}
            {...p}
            type={type}
            validators={validators}
            value={key}
          />
        </Col>
      )
    })
  return (
    <Row mt={-space.sm}>
      {label && (
        <Col col={12} mt="sm">
          <Body>{label}</Body>
        </Col>
      )}
      {inputs}
    </Row>
  )
}
