import LoginError from '../../domain/auth/LoginError'
import APIError from '../../infra/api/APIError'
import {ApiErrorType} from '../../infra/api/types'
import {IErrorDialogAction} from './AppError'

export interface IErrorBehavior {
  message: string
  actions?: IErrorDialogAction[]
}

type ErrorBehaviorCreator = (
  error: any /*FIXME: type*/,
) => IErrorBehavior | undefined

const domainErrorMap: Map<any, ErrorBehaviorCreator> = new Map([
  // auth
  [
    LoginError,
    () => ({
      message:
        '入力されたメールアドレスもしくはパスワードが正しくありません。確認してやりなおしてください。',
    }),
  ],
])

// TODO: 文言
const apiErrorMap: Map<any, ErrorBehaviorCreator> = new Map([
  [
    APIError,
    (error: APIError) => {
      const message = {
        [ApiErrorType.disabledUser]:
          '対象ユーザーのステータスが認証中か、BAN中です。',
        [ApiErrorType.notFound]: '存在していません',
        [ApiErrorType.tooManyNotes]: 'これ以上ノートを作成できません',
        [ApiErrorType.unauthorized]: '認証されていません',
      }[error.error]
      return {message: `${message}(${error.message})`}
    },
  ],
])

export const DEFAULT_ERROR_BEHAVIOR = {
  message: 'システムでエラーが発生しました。',
}

export const errorHandlers = [
  (error: any): IErrorBehavior | undefined => {
    const val = apiErrorMap.get(error.constructor)
    return typeof val === 'function' ? val(error) : val
  },
  (error: any): IErrorBehavior | undefined => {
    const val = domainErrorMap.get(error.constructor)
    return typeof val === 'function' ? val(error) : val
  },
]
