import {EventEmitter} from 'events'
import IUseCaseExecutor from './IUseCaseExecutor'
import {
  UseCaseCommand,
  UseCaseEvent,
  UseCaseEventCommandAccepted,
  UseCaseEventCommandCanceled,
  UseCaseEventTypes,
  UseCaseEventUseCaseFailed,
  UseCaseEventUseCaseSucceeded,
} from './types'
import UseCase, {createUseCaseByCommand} from './UseCase'

/**
 * UseCaseExecutor
 *
 * FIXME: カンムさんのそのままなので・・・・テストも・・・・
 * ユースケース実行クラス
 * ref. https://www.npmjs.com/package/@almin/usecase-bus
 * Command
 *   - A bus send Command to a single Command Handler
 *   - Command may be rejected by system
 *   - Command may be failed during executing in Handler
 *   - Command may be various effect in system state
 *   - Command does not be over the boundary
 *   - Command should have imperative named.
 */
export default class UseCaseExecutor extends EventEmitter
  implements IUseCaseExecutor {
  /**
   * createEventCommandAccepted
   *
   * @param params - params
   */
  public static createEventCommandAccepted(params: {
    command: UseCaseCommand
  }): UseCaseEventCommandAccepted {
    return {
      command: params.command,
      type: UseCaseEventTypes.CommandAccepted,
    }
  }

  /**
   * createEventCommandCanceled
   *
   * @param params - params
   */
  public static createEventCommandCanceled(params: {
    command: UseCaseCommand
  }): UseCaseEventCommandCanceled {
    return {
      command: params.command,
      type: UseCaseEventTypes.CommandCanceled,
    }
  }

  /**
   * createEventUseCaseSucceeded
   *
   * @param params - params
   */
  public static createEventUseCaseSucceeded(params: {
    command: UseCaseCommand
    result: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }): UseCaseEventUseCaseSucceeded {
    return {
      command: params.command,
      result: params.result,
      type: UseCaseEventTypes.UsecaseSucceeded,
    }
  }

  /**
   * createEventUseCaseFailed
   *
   * @param params - Params
   */
  public static createEventUseCaseFailed(params: {
    command: UseCaseCommand
    error: Error
  }): UseCaseEventUseCaseFailed {
    return {
      command: params.command,
      error: params.error,
      type: UseCaseEventTypes.UsecaseFailed,
    }
  }

  /**
   * 変更の通知
   *
   * @param event - ユースケースイベント
   */
  public change(event: UseCaseEvent): void {
    this.emit(UseCaseEventTypes.Change, event)
  }

  /**
   * onChange
   * @param handler - ハンドラ
   */
  public onChange(handler: (event: UseCaseEvent) => void): any {
    this.on(UseCaseEventTypes.Change, handler)
    return this.removeListener.bind(this, UseCaseEventTypes.Change, handler)
  }

  /**
   * ユースケースを実行する
   *
   * @param command - ユースケースコマンド
   */
  public async execute(command: UseCaseCommand): Promise<UseCase> {
    const {params} = command
    const useCase = createUseCaseByCommand(command)
    useCase.setContext({executor: this})
    this.change(UseCaseExecutor.createEventCommandAccepted({command}))
    const ret = await useCase
      .execute(params)
      .then(result => {
        this.change(
          UseCaseExecutor.createEventUseCaseSucceeded({command, result}),
        )
        useCase.onSuccess()
        return result
      })
      .catch(error => {
        this.change(UseCaseExecutor.createEventUseCaseFailed({command, error}))
        useCase.onError(error)
        console.error(error)
      })
    useCase.onComplete()
    return ret
  }
}
