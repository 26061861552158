import * as React from 'react'
import styled from 'styled-components'
import {APP_VERSION} from '../../../config'
import transition from '../../../styles/mixins/transition'
import useMediaQuery from '../../../styles/useMediaQuery'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import Body from '../../atoms/Body'
import Divider from '../../atoms/Divider'
import Icon from '../../atoms/Icon'
import ListSubheader from '../../atoms/ListSubheader'
import List from '../../layouts/List'
import ListItem from '../../layouts/ListItem'
import TransitionNav from '../../layouts/TransitionNav'

const View = styled.div<{isPhone: boolean}>`
  padding-bottom: ${space.xxl}px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  z-index: 10;
  position: relative;
  width: ${p => (p.isPhone ? '85%' : '400px')};
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.white};
`

const Bg = styled.div<{isShow: boolean}>`
  z-index: ${zIndex.nav};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  ${transition()};
  opacity: ${p => (p.isShow ? 1 : 0)};
  pointer-events: ${p => (p.isShow ? 'auto' : 'none')};
`

const Version = styled(Body)`
  margin-top: auto;
  padding: 0 ${space.md}px;
  color: ${colors.gray300};
  text-align: right;
`

export interface NavItem {
  onClick?: () => void
  content?: React.ReactNode
  icon?: string
  subheader?: string
}

export interface Props {
  isShow: boolean
  list: NavItem[]
  handleClickClose: () => void
}

export default function Nav({isShow, list, handleClickClose}: Props) {
  const isPhone = !useMediaQuery('tablet')
  const items = list.map((item, i) => {
    const {onClick} = item
    return (
      <React.Fragment key={i}>
        {item.subheader ? (
          <ListSubheader>{item.subheader}</ListSubheader>
        ) : (
          <React.Fragment>
            <ListItem key={i} onClick={() => onClick && onClick()}>
              {item.icon && <Icon mr="xs" size="icon1" type={item.icon} />}
              <Body>{item.content}</Body>
              {item.onClick && (
                <Icon ml="auto" size="body1" type="chevron-right" />
              )}
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  })
  return (
    <div>
      <Bg isShow={isShow} onClick={handleClickClose} />
      <TransitionNav in={isShow} timeout={0}>
        <View isPhone={isPhone}>
          <List>{items}</List>
          <Version type="caption">
            Version: {APP_VERSION}
            <br />© 2017-2019 Shanord Inc.
          </Version>
        </View>
      </TransitionNav>
    </div>
  )
}
