import * as React from 'react'
import {useCallback, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {flex} from '../../../styles/mixins/flex'
import {minWidth, width} from '../../../styles/mixins/sizing'
import LineyInput from '../../atoms/LineyInput'

// ref. https://github.com/LeaVerou/stretchy/blob/gh-pages/stretchy.js
function resize(element: HTMLInputElement, type?: string) {
  if (type && type === 'date') {
    element.style.width = 'auto'
    element.style.minWidth = '10em'
    return
  }
  const empty = !element.value && element.placeholder
  empty && (element.value = element.placeholder)

  const computedStyle = getComputedStyle(element)
  element.style.width = '1000px'
  if (element.offsetWidth) {
    element.style.width = '0'

    let offset = 0
    if (computedStyle.boxSizing == 'border-box') {
      offset = element.offsetWidth
    } else if (computedStyle.boxSizing == 'padding-box') {
      offset = element.clientWidth
    } else if (computedStyle.boxSizing == 'content-box') {
      offset = parseFloat(computedStyle.minWidth as string)
    }

    let width = Math.max(offset, element.scrollWidth - element.clientWidth)

    element.style.width = width + 'px'

    for (let i = 0; i < 10; i++) {
      element.scrollLeft = 1e10

      if (element.scrollLeft == 0) {
        break
      }

      width += element.scrollLeft

      element.style.width = `${width + 9}px`
    }
  } else {
    element.style.width = `${element.value.length + 1}ch`
  }
  empty && (element.value = '')
}

export type Props = React.InputHTMLAttributes<HTMLInputElement>

const Input = styled(LineyInput)`
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  ${width};
  ${minWidth};
  ${flex};
`

/**
 * 伸縮Text入力
 */
export default function StretchyInput(props: Props) {
  const {onChange, type} = props
  const element = useRef<HTMLInputElement>(null)
  const handleChange = useCallback(e => {
    const {current} = element
    current && resize(current, type)
    onChange && onChange(e)
  }, [])
  useEffect(() => {
    const {current} = element
    current && resize(current, type)
  })
  return <Input width="auto" {...props} onChange={handleChange} ref={element} />
}
