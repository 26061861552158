import * as React from 'react'
import styled from 'styled-components'
import {
  borderColor,
  borderStyle,
  borderWidth,
} from '../../../styles/mixins/border'
import {height, width} from '../../../styles/mixins/sizing'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import LazyImage from '../../atoms/LazyImage'

const Contained = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blank};
  color: #fff;
  ${width};
  ${height};
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
`

const View = styled.div<{onClick?: any} & SpacingProps>`
  ${spacing};
  img,
  ${Contained} {
    ${borderWidth};
    ${borderColor};
    ${borderStyle};
    border-radius: 50%;
  }

  cursor: ${p => p.onClick && 'pointer'};
`

export interface Props {
  src?: string
  children?: React.ReactNode
  size?: number
}

export const AvatarSize = {
  small: 28,
  medium: 32,
  large: 40,
}

export default function Avatar(props: Props) {
  const {src, children, size = AvatarSize.medium, ...rest} = props
  return (
    <View {...rest}>
      {src ? (
        <LazyImage height={size} objectFit="cover" src={src} width={size} />
      ) : (
        <Contained height={size} width={size}>
          {children}
        </Contained>
      )}
    </View>
  )
}
