import * as React from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Container from '../../layouts/Container'
import LabeledIconList, {
  LabeledIconListItem,
} from '../../layouts/LabledIconList'

const View = styled(Container)`
  padding: 0;
`
const Content = styled(Body)``
const Label = styled(Body)``
const Value = styled(Body)`
  color: ${colors.gray400};
  ${spacing};
`

export interface AccountListItem extends LabeledIconListItem {
  value?: string
}

export interface Props {
  list: AccountListItem[]
}

export default function AccountList({list}: Props) {
  const items = list.map(item => {
    return {
      ...item,
      content: (
        <Content>
          <Label>{item.content}</Label>
          <Value size="body2">{item.value}</Value>
        </Content>
      ),
    }
  })
  return (
    <View>
      <LabeledIconList list={items} />
    </View>
  )
}
