import * as React from 'react'
import {useSelector} from 'react-redux'
import {getFormValues} from 'redux-form'
import View from '../../components/organisms/NoteTimelineInputForm'
import {NoteTimelineItemType} from '../../domain/note/timeline/NoteTimeline'
import {getThumbUrl} from '../../domain/resource/Resource'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import useForm from '../../lib/form/useForm'
import {getMe} from '../../selectors/api'
import {getCurrentNoteId} from '../../selectors/note'
import {Forms, required} from '../../services/forms'
import PostTimelineUseCase from '../../useCases/timeline/PostTimelineUseCase'

export default function NoteTimelineInput() {
  const noteId = useSelector(getCurrentNoteId)
  const usersMe = useSelector(getMe)
  const form = Forms.comment
  const {image = ''} = (useSelector(getFormValues(Forms.comment)) as any) || {}
  const {useField, handleSubmit, isInvalid} = useForm({
    form,
    enableReinitialize: true,
    initialValues: {
      comment: '',
      image: '',
    },
    onSubmit: (values: any /* FIXME: type */) => {
      UseCaseCommander.create().command(PostTimelineUseCase, form)({
        noteId,
        type: NoteTimelineItemType.noteComment,
        data: values,
      })
    },
  })

  const isProcessing = useSelector(
    useCaseSelectors.isProcessing(PostTimelineUseCase, form),
  )

  const {input: commentFieldProps} = useField('comment', [required])
  const {input: imageFieldProps} = useField('image')

  return React.createElement(View, {
    avatarSrc: getThumbUrl(usersMe.iconUrl),
    hasImageField: true,
    commentFieldProps,
    imageFieldProps,
    form,
    image,
    handleSubmit,
    submitDisabled: isInvalid || isProcessing,
    isProcessing,
  })
}
