import * as React from 'react'
import {useCallback, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {flex} from '../../../styles/mixins/flex'
import {
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  width,
} from '../../../styles/mixins/sizing'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {theme} from '../../../styles/variables'
import Textarea from '../../atoms/Textarea'

// ref. https://github.com/LeaVerou/stretchy/blob/gh-pages/stretchy.js
function resize(element: HTMLInputElement) {
  const computedStyle = getComputedStyle(element)
  let offset = 0

  element.style.height = '0'

  if (computedStyle.boxSizing === 'border-box') {
    // offset = element.offsetHeight
    offset = 0
  } else if (computedStyle.boxSizing == 'content-box') {
    offset =
      -element.clientHeight + parseFloat(computedStyle.minHeight as string)
  }

  element.style.height = `${element.scrollHeight + offset}px`
}

const StyledTextarea = styled(Textarea)`
  padding: 0;
  ${spacing};
  ${width};
  ${minHeight};
  ${maxHeight};
  ${minWidth};
  ${maxWidth};
  ${flex};
  overflow-y: hidden;
  border-radius: 0;
  resize: none;
`

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  SpacingProps & {
    maxHeight?: string | number
    minHeight?: string | number
  }

/**
 * 伸縮Textarea入力
 */
export default function StretchyTextarea(props: Props) {
  const {minHeight = theme.formField.lineHeight} = props
  const {onChange} = props
  const element = useRef<HTMLInputElement>(null)
  const handleChange = useCallback(
    e => {
      const {current} = element
      current && resize(current)
      onChange && onChange(e)
    },
    [props],
  )
  useEffect(() => {
    const {current} = element
    current && resize(current)
  })
  return (
    <StyledTextarea
      {...props}
      minHeight={minHeight}
      onChange={handleChange}
      ref={element}
    />
  )
}
