import IAuthenticationService from '../../domain/auth/IAuthenticationService'
import {Password} from '../../domain/auth/Password'
import {ResetPasswordVerificationToken} from '../../domain/auth/ResetPasswordVerificationToken'
import AuthenticationService from '../../infra/services/AuthenticationService'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'

interface Props {
  authenticationService: IAuthenticationService
  navigation: Navigation
}

interface Params {
  newPassword: Password
  token: ResetPasswordVerificationToken
}

/**
 * SignUpUseCase
 */
export default class ResetPasswordUseCase extends UseCase {
  private _authenticationService: IAuthenticationService
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._authenticationService = props.authenticationService
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new ResetPasswordUseCase({
      authenticationService: AuthenticationService.create(),
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: Params): Promise<void> {
    await this._authenticationService.resetPassword(
      params.token,
      params.newPassword,
    )
    // TODO: route
    this._navigation.push('login')
  }
}
