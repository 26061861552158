import * as React from 'react'
import styled from 'styled-components'
import {ReduxFieldProps} from '../../../lib/form/types'
import Input from '../../atoms/Input'
import LabeledField from '../../layouts/LabeledField'

const View = styled.div`
  width: 100%;
`

export type Props = {label?: string} & React.InputHTMLAttributes<
  HTMLInputElement
> &
  ReduxFieldProps

/**
 * Text入力
 */
export default function LabeledInput({label, meta, ...rest}: Props) {
  const {error = '', touched = false} = meta || {}
  const err = touched ? error : ''
  return (
    <View>
      <LabeledField error={err} label={label}>
        <Input {...rest} {...rest.input} />
      </LabeledField>
    </View>
  )
}
