import * as React from 'react'
import Icon from '../../atoms/Icon'
import colors from '../../../styles/variables/colors'
import styled from 'styled-components'

const Link = styled.a`
  color: ${colors.blue100};
  i {
    padding-left: 4px;
  }
`

export interface Props {
  telNumber: string
  label?: string
  stopClickPropagation?: boolean // 親要素にクリックイベントを伝搬させないフラグ
}

/**
 * 電話番号リンクです。
 */
export default function TelLink(props: Props) {
  const {telNumber, label, stopClickPropagation} = props
  return (
    <Link
      href={`tel:${telNumber}`}
      onClick={e => stopClickPropagation && e.stopPropagation()}
    >
      {label || telNumber}
      <Icon type="phone-wave" />
    </Link>
  )
}
