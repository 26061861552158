import * as React from 'react'

function htmlStringToNode(html: string) {
  return <div dangerouslySetInnerHTML={{__html: html}}></div>
}

export function replaceNewLine(
  text: string | React.ReactNode,
  options: {splitter?: string | RegExp; isTextToHtml?: boolean} = {},
) {
  const {splitter = '\n', isTextToHtml = false} = options
  const filterContent = (content: string | React.ReactNode) => {
    if (isTextToHtml && typeof content === 'string') {
      return htmlStringToNode(content as string)
    }
    return content
  }
  return typeof text === 'string'
    ? text
        .split(splitter)
        .map((content, i) => <p key={i}>{filterContent(content) || <br />}</p>)
    : filterContent(text)
}
