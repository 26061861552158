import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import EditNoteMemberItem from '../../../containers/EditNoteMemberItem'
import NoteInvitationCodeDialog from '../../../containers/NoteInvitationCodeDialog'
import ContactableUser from '../../../domain/account/ContactableUser'
import {NoteRelatedUser} from '../../../domain/note/NoteRelatedUser'
import Button from '../../atoms/Button'
import Divider from '../../atoms/Divider'
import ListSubheader from '../../atoms/ListSubheader'
import Col from '../../layouts/Col'
import List from '../../layouts/List'
import ListItem from '../../layouts/ListItem'
import Row from '../../layouts/Row'
import Space from '../../layouts/Space'

const View = styled(Space)``

export type UserProps = NoteRelatedUser<ContactableUser>

export interface Props {
  requestUsers: UserProps[]
  memberUsers: UserProps[]
  invitable: boolean
}

export default function EditNoteMembersForm({
  requestUsers,
  memberUsers,
  invitable,
  ...rest
}: Props) {
  const [showInvitationCodeDialog, setShowInvitationCode] = useState(false)
  const requestItems = requestUsers.map((user, index) => (
    <React.Fragment key={index}>
      <ListItem>
        <EditNoteMemberItem user={user} />
      </ListItem>
      <Divider />
    </React.Fragment>
  ))
  const memberItems = memberUsers.map((user, index) => (
    <React.Fragment key={index}>
      <ListItem>
        <EditNoteMemberItem user={user} />
      </ListItem>
      <Divider />
    </React.Fragment>
  ))
  return (
    <View>
      {invitable && (
        <NoteInvitationCodeDialog
          dismiss={() => setShowInvitationCode(false)}
          isShow={showInvitationCodeDialog}
        />
      )}

      <List>
        {requestItems.length > 0 && (
          <React.Fragment>
            <ListSubheader>リクエスト</ListSubheader>
            {requestItems}
          </React.Fragment>
        )}
        <ListSubheader>
          <Col>メンバー</Col>

          {invitable && (
            <Col ml="auto">
              <Button onClick={() => setShowInvitationCode(true)}>
                メンバーを招待
              </Button>
            </Col>
          )}
        </ListSubheader>
        {memberItems}
      </List>
    </View>
  )
}
