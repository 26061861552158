import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

const View = styled.div`
  ${spacing};
`

export interface Props {
  iconType: string
  title: string
  left?: React.ReactNode
}

export default function PaperTitle(props: Props) {
  const {left, title, iconType} = props
  return (
    <View>
      <Row flexWrap="nowrap" gutter={space.xs}>
        <Col>
          <Icon size={20} type={iconType} />
        </Col>
        <Col>
          <Body color={colors.gray400}>{title}</Body>
        </Col>
        {left && <Col ml="auto">{left}</Col>}
      </Row>

    </View>
  )
}
