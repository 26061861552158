import INoteEmergencyCodeRepository from '../../../domain/note/INoteEmergencyCodeRepository'
import NoteEmergencyCode from '../../../domain/note/NoteEmergencyCode'
import AppAPI from '../../api/AppAPI'
import * as noteTranslators from '../../api/translators/note'
import {actions} from '../../redux/api/entities'
import {NoteEmergencyCodeSchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteEmergencyCodeRepository
  implements INoteEmergencyCodeRepository {
  private api: AppAPI
  private redux: ReduxProvider

  public static create(): NoteEmergencyCodeRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteEmergencyCodeRepository({api, redux})
  }

  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async getCode(noteId: string): Promise<NoteEmergencyCode> {
    const client = await this.api.getClient()
    const res = await client.getNoteEmergencyCode({noteId})
    const entity = noteTranslators.toNoteEmergencyCode(res)
    this.redux.dispatch(actions.store(entity, NoteEmergencyCodeSchema))
    return entity
  }

  public async refreshCode(noteId: string): Promise<NoteEmergencyCode> {
    const client = await this.api.getClient()
    const res = await client.refreshNoteEmergencyCode({noteId})
    const entity = noteTranslators.toNoteEmergencyCode(res)
    this.redux.dispatch(actions.store(entity, NoteEmergencyCodeSchema))
    return entity
  }
}
