import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import Swiper, {SwiperInstance} from 'react-id-swiper'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import Tab from '../../atoms/Tab'

export interface Props {
  items: string[]
  tabIndex: number
  onChangeTab: (tabIndex: number) => void
}

const View = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.bg};
`

export default function Tabs(props: Props) {
  const [swiper, updateSwiper] = useState<SwiperInstance>(null)
  const {items, tabIndex = 0, onChangeTab} = props
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex)

  const handleGetSwiper = (swiper: SwiperInstance) => {
    updateSwiper(swiper)
  }

  const swiperProps: any /* react-id-swiperの型が対応してないpropsがある */ = {
    getSwiper: handleGetSwiper,
    slidesPerView: 'auto',
    direction: 'horizontal',
    // freeMode: true,
    mousewheel: true,
    centeredSlides: true,
    // slidesPerView: 3,
  }

  const handleChangeTab = (index: number) => {
    setActiveTabIndex(index)
    swiper && swiper.slideTo(index)
    onChangeTab(index)
  }

  // FIXME: Tabから直接ref参照できないの謎
  const tabs =
    items &&
    items.map((p, index) => {
      return (
        <Tab
          active={activeTabIndex === index}
          key={index}
          onClick={() => handleChangeTab(index)}
          type="body1"
        >
          {p}
        </Tab>
      )
    })

  useEffect(() => {
    setActiveTabIndex(tabIndex)
    swiper && swiper.slideTo(tabIndex)
  })

  return (
    <View>
      <Swiper {...swiperProps}>{tabs}</Swiper>
    </View>
  )
}
