import * as React from 'react'
import {useRef} from 'react'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {colWidth} from '../../../styles/mixins/grid'
import {cursor} from '../../../styles/mixins/misc'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import MenuIconButton from '../../atoms/MenuIconButton'
import Menu, {MenuItem} from '../../molecules/Menu'

const View = styled.div`
  ${borderRadius};

  padding-top: ${space.lg}px;
  width: 100%;

  &:first-child {
    padding-top: ${space.md}px;
  }
`

const Inner = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const HeaderIcons = styled.div`
  margin-top: ${-space.sm}px;
  margin-left: auto;
`

const Label = styled(Body)`
  margin-bottom: ${space.sm}px;
  display: flex;
  align-items: center;
  text-align: left;
  color: ${colors.gray350};
  ${cursor};
`

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const ContentLeft = styled.div`
  ${colWidth('fit')};
`

const PolicyIcon = styled(Icon)`
  ${spacing};
`

export interface Props {
  isOwner?: boolean
  disabled: boolean
  label?: string
  help?: string | string[]
  menuItems: MenuItem[]
  policyMenuItems: MenuItem[]
  children?: React.ReactNode
  onClickEdit: () => void
  showMenu: boolean
  setShowMenu: (val: boolean) => void
  showPolicyMenu: boolean
  setShowPolicyMenu: (val: boolean) => void
}

export default function NoteFieldLayout({
  label,
  children,
  disabled,
  help,
  menuItems,
  policyMenuItems,
  onClickEdit,
  showMenu,
  setShowMenu,
  showPolicyMenu,
  setShowPolicyMenu,
}: Props) {
  const menuActionElement = useRef<HTMLDivElement>(null)
  const policyMenuActionElement = useRef<HTMLDivElement>(null)
  return (
    <View>
      {showMenu && (
        <Menu
          items={menuItems}
          onClose={() => setShowMenu(false)}
          trigger={menuActionElement.current}
        />
      )}
      {showPolicyMenu && (
        <Menu
          items={policyMenuItems}
          onClose={() => setShowPolicyMenu(false)}
          trigger={policyMenuActionElement.current}
        />
      )}

      <Inner>
        <Header>
          {label && (
            <Label
              cursor={disabled ? 'inherit' : 'pointer'}
              fontWeight={700}
              onClick={onClickEdit}
              size="heading5"
            >
              <Icon
                fontWight={700}
                mr="xxs"
                size={16}
                type={disabled ? 'label' : 'pencil'}
              />
              <div>{label}</div>
            </Label>
          )}
          {help && <Icon ml="xs" pb="sm" size={18} type="bubble-question" />}

          <HeaderIcons>
            {policyMenuItems.length > 0 && (
              <PolicyIcon
                ml="auto"
                onClick={() => setShowPolicyMenu(!showPolicyMenu)}
                p="sm"
                ref={policyMenuActionElement}
                size={18}
                type="heart-pulse"
              />
            )}

            {menuItems.length > 0 && (
              <MenuIconButton
                ml="auto"
                mr={-space.sm}
                onClick={() => setShowMenu(!showMenu)}
                p="sm"
                ref={menuActionElement}
                size={15}
              />
            )}
          </HeaderIcons>
        </Header>

        <Content>
          <ContentLeft>{children}</ContentLeft>
        </Content>
      </Inner>
    </View>
  )
}
