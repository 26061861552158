import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/HambugerButton'
import {isShowNav} from '../../infra/redux/app/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import ToggleMenuUseCase from '../../useCases/app/ToggleMenuUseCase'

export default function HamburgerButton() {
  return React.createElement(View, {
    isShowMenu: useSelector(isShowNav),
    handleClickMenu: () => {
      UseCaseCommander.create().command(ToggleMenuUseCase)()
    },
  })
}
