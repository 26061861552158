import {NoteRelationProps} from './Note'

export default interface NoteInvitationCode {
  id: string
  title: string
  personName: string
  personImageUrl: string
  imageUrl: string
  relationToMe: NoteRelationProps
  inviteInfo: {
    url: string
    urlQrCodeData: string
  }
}

export function makeInvitationMessage({
  meFullname,
  noteInvitationCode,
}: {
  meFullname: string
  noteInvitationCode: NoteInvitationCode
}) {
  const {
    inviteInfo: {url = ''} = {},
    title = '',
    personName = '',
  } = noteInvitationCode
  return `こんにちは、${meFullname}です。

${personName}さんのための、
「${title}」ができました。
あなたにはぜひ、このノートに参加していただきたく思います。

以下のページから、参加をリクエストすることができます。
ぜひ、ご参加ください。

「${title}」への招待状
${url}

「じぶんノート」のアカウントをおもちでない場合は、
アカウントを作成してから参加をリクエストしてくださいますようお願いします。
`
}

export function makeInvitationShortMessage({
  noteInvitationCode,
}: {
  noteInvitationCode: NoteInvitationCode
}) {
  const {inviteInfo: {url = ''} = {}, title = ''} = noteInvitationCode
  return `あなたを「${title}」へ招待します。
${url}
こちらから、アカウント登録またはログインをして、参加リクエストをして下さい。`
}
