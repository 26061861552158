import INoteTrackRepository from '../../domain/note/track/INoteTrackRepository'
import {makeTrackWeekCondition} from '../../domain/note/track/NoteTrack'
import NoteTrackRepository from '../../infra/repositories/note/NoteTrackRepository'
import FormService from '../../infra/services/FormService'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTrackRepository: INoteTrackRepository
  formService: IFormService
}

interface Params {
  noteId: string
  begin: Date
  end: Date
}

export default class FetchTrackWeekUseCase extends UseCase {
  private _noteTrackRepository: INoteTrackRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTrackRepository = props.noteTrackRepository
    this._formService = props.formService
  }

  public static create() {
    return new FetchTrackWeekUseCase({
      noteTrackRepository: NoteTrackRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({noteId, begin, end}: Params): Promise<void> {
    const condition = makeTrackWeekCondition(begin, end)
    await this._noteTrackRepository.get({noteId, ...condition})
  }
}
