import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

const View = styled.div`
  padding: ${space.sm}px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  text-align: left;
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
`
const HeaderTitle = styled(Body)``
const HeaderSubTitle = styled(Body)`
  color: ${colors.gray300};
`

export interface CardActionProps {
  iconType?: string
  label: string
  onClick: () => void
}

export interface Props {
  action?: React.ReactNode
  avatar?: React.ReactNode
  title: string
  subTitle?: string
}

export default function CardHeader(props: Props) {
  const {action, avatar, title, subTitle, ...rest} = props
  return (
    <View {...rest}>
      <Row align="center" flexWrap="nowrap" gutter={space.xs} width="100%">
        <Col>{avatar}</Col>
        <Col>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderSubTitle type="body2">{subTitle}</HeaderSubTitle>
        </Col>
        {action && <Col ml="auto">{action}</Col>}
      </Row>
    </View>
  )
}
