import {ModalType} from '../../infra/redux/app/types'
import ApplicationService from '../../services/app/ApplicationService'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'

interface Props {
  applicationService: ApplicationService
}

interface Params {
  type: ModalType
}

/**
 * SignUpUseCase
 */
export default class HideModalUseCase extends UseCase {
  private _applicationService: ApplicationService

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._applicationService = props.applicationService
  }

  /**
   * create
   */
  public static create() {
    return new HideModalUseCase({
      applicationService: ApplicationService.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({type}: Params): Promise<void> {
    // this._applicationService.hideModal(type)
    Navigation.create().goBack()
  }
}
