import * as React from 'react'
import {useRef, useState} from 'react'
import styled from 'styled-components'
import {useDebounce} from '../../../lib'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Icon from '../../atoms/Icon'
import Body from '../../atoms/Body'
import LineyInput from '../../atoms/LineyInput'
import HelpLink from '../../molecules/HelpLink'

const View = styled.div`
  padding-bottom: ${space.xxl}px;

  z-index: 10;
  position: relative;
  width: 100%;
  height: 100%;
`
const HeadOuter = styled.div`
  margin-top: 50px;
`

const SearchOuter = styled.div`
  display: flex;
  align-items: center;
`

const GuideOuter = styled.div`
  margin-top: ${space.md}px;
`

const Input = styled(LineyInput)`
  padding-right: 30px;
  background-color: transparent;
`

const ResetButton = styled(Icon)`
  padding: ${space.sm}px;
  position: absolute;
  right: 32px;
  color: ${colors.gray400};
`

const SearchButton = styled(Icon)`
  padding-left: ${space.sm}px;
`

export interface Props {
  children: React.ReactNode
  onChangeSearchInput: (query: string) => void
  visibleGuide?: boolean
}

export default function GeneratedNoteForm(props: Props) {
  const {children, onChangeSearchInput, visibleGuide = false} = props
  const [query, setQuery] = useState('')
  const inputElement = useRef<HTMLInputElement>(null)
  useDebounce(
    () => {
      onChangeSearchInput(query)
    },
    100,
    [query],
  )
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }
  const handleClick = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const {current} = inputElement
    current && current.blur()
  }
  const handleClickClose = () => {
    const {current} = inputElement
    if (current) {
      current.value = ''
      setQuery('')
    }
  }
  return (
    <View>
      <HeadOuter>
        <SearchOuter>
          <Input
            clearable={true}
            onChange={handleChange}
            placeholder="項目名で検索する"
            ref={inputElement}
          />
          {query && (
            <ResetButton onClick={handleClickClose} size={12} type="cross" />
          )}
          <SearchButton onClick={handleClick} size={16} type="magnifier" />
        </SearchOuter>

        {visibleGuide && (
          <GuideOuter>
            <Body>
              わかる項目からノートを記入していきましょう。
              <br />
              <HelpLink
                title="ノートの記入について"
                url="https://jnote.jp/faq/how-to-note-input/"
              />
            </Body>
          </GuideOuter>
        )}
      </HeadOuter>
      {children}
    </View>
  )
}
