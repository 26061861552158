import {change, reset} from 'redux-form'
import ReduxProvider from '../../infra/redux/ReduxProvider'
import IFormService from '../../useCases/lib/IFormService'

/**
 * redux-form を利用した IFormService の実現
 */
export default class FormService implements IFormService {
  private redux: ReduxProvider

  public constructor(props: {redux: ReduxProvider}) {
    this.redux = props.redux
  }

  public static create() {
    return new FormService({
      redux: ReduxProvider.create(),
    })
  }

  public change(form: string, field: string, value: any): void {
    this.redux.dispatch(change(form, field, value, true))
  }

  public reset(form: string): void {
    this.redux.dispatch(reset(form))
  }
}
