import * as React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import View, {Props} from '../../components/molecules/ImageUploadField'
import {ResourceType} from '../../infra/api/types'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getResourceUploadToken} from '../../selectors/api'
import {getResourceLatestItem} from '../../selectors/api/resource'
import UploadResourceUseCase from '../../useCases/resource/UploadResourceUseCase'

interface OwnProps {
  url: string
  resourceType: ResourceType
  label: string
  width: number | string
  height: number | string
  form: string
  formField: string
}

// TODO: useSelectorを使う
export default compose<Props, OwnProps>(
  connect(
    (state: IReduxState, ownProps: OwnProps): Props => {
      const {
        url,
        width,
        height,
        label,
        form,
        formField,
        resourceType,
      } = ownProps
      const resourceId = `${form}_${formField}`
      const token = getResourceUploadToken(state)
      const {url: uploadedUrl = ''} =
        getResourceLatestItem(resourceId)(state) || {}
      const isProcessing = useCaseSelectors.isProcessing(
        UploadResourceUseCase,
        resourceId,
      )(state)
      const showingImageUrl = isProcessing ? '' : uploadedUrl || url
      return {
        name: formField,
        url: showingImageUrl,
        label,
        width,
        height,
        onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files) {
            return
          }
          UseCaseCommander.create().command(UploadResourceUseCase, resourceId)({
            id: resourceId,
            type: resourceType,
            token,
            formParams: {
              form,
              field: formField,
            },
            file: e.target.files && e.target.files.item(0),
          })
        },
        isProcessing,
      }
    },
  ),
)(View)
