import {useEffect, useState} from 'react'

export default function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    // const updateHeight = debounce(() => setHeight(window.innerHeight), 10)
    const updateHeight = () => setHeight(window.innerHeight)

    // updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [window.innerHeight])

  return height
}
