import * as React from 'react'
import styled from 'styled-components'
import {Props} from '../../../containers/NoteHistoryItem'
import {hasFieldData} from '../../../domain/note/snapshot/Tree'
import {getInterpolated} from '../../../lib'
import Code from '../../atoms/Code'
import NoteHistoryItemLayout from '../../../containers/NoteHistoryItemLayout'

const PrevCode = styled(Code)`
  color: #b30000;
  background: #fadad7;
`

export const getValue = (
  options: {[key: string]: {label: string}},
  data: KeySignature,
  name: string,
) => {
  return Object.keys(options).map(key => {
    const option = options[key]
    const {label} = option
    const list = getInterpolated(label, ({name, key, val}) => {
      return <span key={key}>{data[name]}</span>
    })
    const value = list.length > 0 ? list : label
    const checked = data[key] || data[name] === key
    return checked && <div key={key}>・{value}</div>
  })
}

export default function CheckboxHistoryItem(props: Props) {
  const {previous, data, ...rest} = props
  const {attr: {options = {}} = {}} = props
  const hasPrevious = hasFieldData(rest.type, rest.name, previous, options)
  const hasData = hasFieldData(rest.type, rest.name, data, options)

  const prevData = hasPrevious && (
    <PrevCode>{getValue(options, previous, props.name)}</PrevCode>
  )
  const currentData = hasData && <Code>{getValue(options, data, props.name)}</Code>
  return (
    <NoteHistoryItemLayout
      currentData={currentData}
      prevData={prevData}
      {...rest}
    />
  )
}
