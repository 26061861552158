import * as React from 'react'
import {generatePath, Route} from 'react-router'
import routes, {RouteCustomProps} from './routes'

export type RouteKey = keyof typeof routes

type Routes = {[Key in string]: RouteCustomProps}

/**
 * getRoutes
 */
export default function getRoutes(): Routes {
  return routes
}

export const renderRoutes = (routes: Routes) => {
  return Object.keys(routes).map((key, index) => {
    const route = routes[key]
    const routeClass = Route
    return React.createElement(routeClass, {
      component: route.component,
      exact: route.exact,
      key: index,
      path: route.path,
    })
  })
}

/**
 * getRouteUrl
 *
 * @param routeKey - string
 * @param params - Params
 */
export function getRouteUrl(
  routeKey: RouteKey,
  params?: KeySignature<string>,
): string {
  const {path} = routes[routeKey]
  return generatePath(path, params)
}
