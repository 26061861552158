import * as React from 'react'
import View, {Props as ViewProps} from '../../components/organisms/NoteCard'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from '../../useCases/app/PushNavigationUseCase'

export interface Props {
  noteId: string
  headerIconUrl: string
  headerTitle: string
  thumbnailImageUrl: string
}

export default function NoteCard(props: Props) {
  const {noteId} = props
  const menuItems = [
    {
      label: 'ノートを削除する',
      iconType: 'trash2',
      onClick: () => {
        /*TODO:*/
      },
    },
  ]
  return React.createElement(View, {
    ...props,
    menuItems,
    onClickThumbnail: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'noteCover',
        noteId,
      })
    },
    actions: [
      {
        iconType: 'telephone',
        label: '緊急連絡先',
        onClick: () => {
          UseCaseCommander.create().command<PushNavigationUseCaseParams>(
            PushNavigationUseCase,
          )({
            routeKey: 'noteContacts',
            noteId,
          })
        },
      },
    ],
  })
}
