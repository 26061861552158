import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteTrackList'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as navigationSelectors from '../../selectors/app/navigation'
import {getCurrentNoteId, getCurrentTrackDateWithRoute} from '../../selectors/note'
import {getTrackItemsForView} from '../../selectors/note/track'
import FetchTrackUseCase from '../../useCases/track/FetchTrackUseCase'

export default function NoteTrackList() {
  const noteId = useSelector(getCurrentNoteId)
  const routeDate = navigationSelectors.getDate()
  const currentDate = useSelector(getCurrentTrackDateWithRoute(routeDate))
  const list = useSelector(getTrackItemsForView(noteId, currentDate))

  const fetching = useSelector(useCaseSelectors.isProcessing(FetchTrackUseCase))

  useEffect(() => {
    currentDate &&
      UseCaseCommander.create().command(FetchTrackUseCase)({
        noteId,
        date: currentDate,
      })
  }, [noteId, routeDate])

  return React.createElement(View, {
    fetching,
    list,
  })
}
