import * as React from 'react'
import styled from 'styled-components'
import UpdateProfileForm from '../../../containers/UpdateProfileForm'
import Container from '../../layouts/Container'

const View = styled(Container)``

export default function UpdateProfilePage() {
  return (
    <View>
      <UpdateProfileForm />
    </View>
  )
}
