import * as React from 'react'
import styled from 'styled-components'
import withForwardRef from '../../../hocs/ref'
import {fontSize} from '../../../styles/mixins/font'
import placeholderColor from '../../../styles/mixins/placeholder'
import {body2} from '../../../styles/variables'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

const Textarea = styled.textarea`
  padding: ${space.sm}px 0;

  width: 100%;
  min-height: 0;

  border: none;
  //background-color: transparent;
  background-color: ${colors.input};
  border-bottom: solid 1px ${colors.gray200};

  ${fontSize(body2.fontSize)};
  ${placeholderColor(colors.gray300)};

  transition: border 0.3s ease;
  &:focus,
  &:hover:focus {
    border-bottom: solid 1px ${colors.brand};
  }
  &:hover {
    border-bottom: solid 1px ${colors.gray400};
  }
`

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement>

/**
 * Textarea入力
 */
export default withForwardRef(Textarea)
