import {Validator} from './types'
import {parseISO, isValid} from 'date-fns'

/**
 * required
 */
export const required: Validator = {
  name: 'required',
  rule: '',
  validate: (value: string) =>
    !(value === undefined || value === null || value === ''),
}

/**
 * format
 *
 * @param regexp - RegExp
 */
export const format = (regexp: RegExp): Validator => {
  return {
    name: 'format',
    rule: {regexp},
    validate: (val: string) => regexp.test(val && val.toString()),
  }
}

/* eslint-disable no-control-regex */
const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
/* eslint-enable no-control-regex */

export const emailFormat: Validator = {
  ...format(EMAIL_REGEX),
  name: 'emailFormat',
}

/**
 * maxLength
 * @param max
 */
export const maxLength = (max: number): Validator => ({
  name: 'maxLength',
  rule: {max},
  validate: (value: string) => max >= value.length,
})

/**
 * minLength
 * @param min
 */
export const minLength = (min: number): Validator => ({
  name: 'minLength',
  rule: {min},
  validate: (value: string) => !!(value && min <= value.length),
})

/**
 * 数字のみ
 */
export const notNumbersOnly = () => {
  const {validate} = format(/^[0-9]$/)
  return {
    name: 'notNumbersOnly',
    validate: (val: string) => !validate(val),
  }
}

/**
 * 下限日時
 *
 * @param date - Date
 */
export const earliest = (date: Date) => ({
  name: 'earliest',
  rule: {date},
  validate: (val: Date) => val >= date,
})

/**
 * 上限日時
 *
 * @param date - Date
 */
export const latest = (date: Date) => ({
  name: 'latest',
  rule: {date},
  validate: (val: Date) => val <= date,
})

/**
 * 指定数以上の種類の文字列に制限
 *
 * @param rule - Rules
 * @param rule.kinds - 許容する文字列パターンのリスト
 * @param rule.min - 最低現必要な種類の数
 */
export const multipleKindChars = (rule: {
  kinds: RegExp[]
  min: number
}): Validator => ({
  name: 'multipleKindChars',
  rule,
  validate: (value: string) =>
    rule.kinds.map(k => k.test(value)).filter(t => t).length >= rule.min,
})

/**
 * dateDayFormat
 */
export const dateDayFormat = format(/^\d{4}-\d{2}-\d{2}$/)

/**
 * dateDayISOFormat
 */
export const dateDayISOFormat = {
  name: 'dateDayISOFormat',
  validate: (val: string) => isValid(parseISO(`${val} 00:00`)),
}

/**
 * optionalDateTimeFormat
 */
export const optionalDateTimeFormat = format(/^(|\d{2}:\d{2})$/)

/**
 * optionalDateTimeISOFormat
 *
 * 空文字もOK
 */
export const optionalDateTimeISOFormat = {
  name: 'optionalDateTimeISOFormat',
  validate: (val: string) => isValid(parseISO(`2019-08-08 ${val}`)),
}
