/**
 * ドメインエラー
 */
export default class DomainError extends Error {
  protected _original: any

  /**
   * constructor
   *
   * @param error - any
   */
  public constructor(error: any) {
    super()
    this._original = error
  }

  /**
   * original
   *
   * @returns {any}
   */
  public get original(): any {
    return this._original
  }
}
