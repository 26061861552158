import * as React from 'react'
import styled from 'styled-components'
import UploadTrackImageField from '../../../containers/UploadTrackImageField'
import {colWidth} from '../../../styles/mixins/grid'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import TextButton from '../../atoms/TextButton'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import StretchyTextarea from '../../molecules/StretchyTextarea'

const TopControls = styled(Row)``

const BottomControls = styled.div`
  margin-top: ${space.xxs}px;
`

const InputOuter = styled.div`
  ${colWidth('fit')};
`

const SubmitButton = styled(TextButton)`
  ${spacing};
  color: ${colors.primary};
`

export interface FormProps {
  form?: string
  submitDisabled: boolean
  isProcessing: boolean
  handleSubmit: any
}

export type Props = FormProps & {
  avatarSrc?: string
  hasImageField?: boolean
  image?: string
  commentFieldProps?: any
  imageFieldProps?: any
}

export default function NoteTimelineInputForm(props: Props) {
  const {
    avatarSrc,
    form,
    image,
    hasImageField,
    handleSubmit,
    submitDisabled,
    isProcessing,
    commentFieldProps,
    imageFieldProps,
  } = props
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Row widht="100%">
        <Col>{avatarSrc && <Avatar mr="xs" src={avatarSrc} />}</Col>
        <Col col="fit">
          <TopControls>
            <InputOuter>
              <StretchyTextarea
                p="xs"
                placeholder="コメントを入力してください"
                {...commentFieldProps}
              />
            </InputOuter>
            <SubmitButton
              disabled={submitDisabled || isProcessing}
              ml="sm"
              py="xs"
              type="submit"
            >
              {isProcessing ? '送信中...' : '投稿する'}
            </SubmitButton>
          </TopControls>
          {hasImageField && (
            <BottomControls>
              <UploadTrackImageField
                form={form}
                sources={image ? [image] : []}
                {...imageFieldProps}
              />
            </BottomControls>
          )}
        </Col>
      </Row>
    </form>
  )
}
