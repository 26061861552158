import * as React from 'react'
import {useSelector} from 'react-redux'
import View, {NavItem} from '../../components/organisms/Nav'
import {getMeName} from '../../domain/account/UsersMe'
import {LogCategory} from '../../domain/log/loggerTypes'
import * as appSelectors from '../../infra/redux/app/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import * as sessionSelectors from '../../selectors/app/session'
import {logLinkClicked} from '../../services/log'
import Navigation from '../../services/navigation/Navigation'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'
import ToggleMenuUseCase from '../../useCases/app/ToggleMenuUseCase'
import SignOutUseCase from '../../useCases/auth/SignOutUseCase'

export interface Props {
  list?: NavItem[]
}

const onClickForExternalLink = (url: string) => () => {
  Navigation.create().openExternal(url)
  logLinkClicked({logCategory: LogCategory.nav, url})
}

const list = (
  isAuthorized: boolean,
  customList: NavItem[] = [],
  accountName: string,
) => {
  const home = {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({routeKey: 'index'})
    },
    icon: 'home',
    content: 'ホーム',
  }
  const common = [
    {
      onClick: onClickForExternalLink('https://jnote.jp/terms/'),
      icon: 'register',
      content: '利用規約',
    },
    {
      onClick: onClickForExternalLink('https://jnote.jp/privacypolicy/'),
      icon: 'key-hole',
      content: 'プライバシーポリシー',
    },
    {
      onClick: onClickForExternalLink('https://jnote.jp/faq/'),
      icon: 'question-circle',
      content: 'よくある質問',
    },
  ]
  const unAuthorizedMenuList = [
    {
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'login',
        })
      },
      icon: 'enter',
      content: 'ログインページ',
    },
    {
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'signUp',
        })
      },
      icon: 'user-plus',
      content: 'アカウントを作成する',
    },
    {
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'requestResetPassword',
        })
      },
      icon: 'key-hole',
      content: 'パスワードの変更',
    },
  ]
  const authorizedMenuList = [
    {subheader: accountName},
    {
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'userNotifications',
        })
      },
      icon: 'alarm',
      content: 'お知らせ',
    },
    {
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'account',
        })
      },
      icon: 'user',
      content: 'アカウント設定',
    },
    {
      onClick: () => {
        UseCaseCommander.create().command(SignOutUseCase)()
      },
      icon: 'exit',
      content: 'ログアウト',
    },
  ]
  const accountMenuList = isAuthorized
    ? authorizedMenuList
    : unAuthorizedMenuList
  return [
    ...customList,
    ...accountMenuList,
    ...((accountMenuList.length > 0 && [{subheader: 'その他'}]) || []),
    home,
    ...common,
  ]
}

export default function Nav(props: Props) {
  const {list: customList} = props
  const isShow = useSelector(appSelectors.isShowNav)
  const isAuthorized = useSelector(sessionSelectors.isAuthorized)
  const usersMe = useSelector(getMe)
  const accountName = getMeName(usersMe, 'アカウント')

  return React.createElement(View, {
    isShow,
    list: list(isAuthorized, customList, accountName),
    handleClickClose: () => {
      UseCaseCommander.create().command(ToggleMenuUseCase)({hide: true})
    },
  })
}
