import {AnyAction, compose, Reducer} from 'redux'
import {IReduxState} from './index'
import ReduxProvider from './ReduxProvider'

/**
 * getComposeEnhancers
 */
export function getComposeEnhancers() {
  const KEY = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
  if (typeof window[KEY] === 'function') {
    return window[KEY]({serialize: {options: true}})
  }
  return compose
}

/**
 * handleActions
 *
 * @param handlers - ハンドラオブジェクト
 * @param initialState - Stateの初期値
 */
export function handleActions<S, A extends AnyAction>(
  handlers: {[key: string]: Reducer<S, A>},
  initialState: S,
): Reducer<S, A> {
  return (state: S = initialState, action: A): S => {
    const handler = handlers[action.type]
    return handler ? handler(state, action) : state
  }
}

export function useReduxState(): IReduxState {
  return ReduxProvider.create().getState()
}
