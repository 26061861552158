import * as React from 'react'
import {useMemo, useRef} from 'react'
import {Transition} from 'react-transition-group'
import {ENTERED, TransitionStatus} from 'react-transition-group/Transition'
import styled from 'styled-components'
import {TransitionState} from '../../../styles'
import ease from '../../../styles/variables/ease'
import debounce from 'lodash.debounce'

const View = styled.div`
  overflow: hidden;
  display: block;
`

const Wrapper = styled.div<TransitionState & {height: number}>`
  display: flex;
  transition: 0.3s ${ease.easeOutCubic};
  // height: ${({state, height}) => (state === 'entered' ? `${height}px` : 0)};
  height: ${({state, height}) => {
    switch (state) {
      case 'entering':
        return `${height}px`
      case 'entered':
        return `auto`
      case 'exiting':
        return `${height}px`
      case 'exited':
        return 0
      default:
        return `${height}px`
    }
  }};
`

const WrapperInner = styled.div`
  width: 100%;
`

export interface Props {
  in: boolean
  children: React.ReactNode
}

const getHeight = (content: HTMLDivElement | null) => {
  if (!content) return 0
  const clone = content.cloneNode(true) as HTMLDivElement
  clone.style.cssText =
    'visibility: hidden; display: block; margin: -9999px 0; height: auto;'
  const {parentNode} = content
  if (!parentNode) {
    return 0
  }
  const height = parentNode.appendChild(clone).clientHeight
  parentNode.removeChild(clone)
  return height
  // const {clientHeight = 0} = content || {}
  // return clientHeight
}

const Collapse = (props: Props) => {
  const {children, in: inProp} = props
  const wrapperElement = useRef<HTMLDivElement>(null)
  const wrapperHeight = useMemo(() => {
    return getHeight(wrapperElement.current)
  }, [wrapperElement.current])
  return (
    <Transition
      in={inProp}
      // mountOnEnter={true}
      timeout={inProp ? 300 : 0}
      // unmountOnExit={true}
    >
      {(state: TransitionStatus, childProps: any) => {
        if (state === ENTERED) {
          debounce(() => window.dispatchEvent(new Event('resize')), 100)()
        }
        return (
          <View state={state} {...childProps}>
            <Wrapper height={wrapperHeight} ref={wrapperElement} state={state}>
              <WrapperInner>{children}</WrapperInner>
            </Wrapper>
          </View>
        )
      }}
    </Transition>
  )
}

export default Collapse
