import INoteTrackRepository from '../../domain/note/track/INoteTrackRepository'
import NoteTrackRepository from '../../infra/repositories/note/NoteTrackRepository'
import FormService from '../../infra/services/FormService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import {Forms} from '../../services/forms'
import NoticeUseCase from '../app/NoticeUseCase'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTrackRepository: INoteTrackRepository
  formService: IFormService
}

interface Params {
  noteId: string
  trackId: string
  data: {
    comment: string
  }
  onComplete?: () => void
}

export default class PostTrackCommentUseCase extends UseCase {
  private _noteTrackRepository: INoteTrackRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTrackRepository = props.noteTrackRepository
    this._formService = props.formService
  }

  public static create() {
    return new PostTrackCommentUseCase({
      noteTrackRepository: NoteTrackRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({
    noteId,
    trackId,
    data,
    onComplete,
  }: Params): Promise<void> {
    this.registerComplete(async () => {
      this._formService.reset(Forms.diaryComment(trackId))
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '投稿しました',
      })
      onComplete && onComplete()
    })
    await this._noteTrackRepository.postComment({noteId, trackId, data})
  }
}
