import * as React from 'react'
import styled from 'styled-components'
import {typography} from '../../../styles/mixins/typography'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

export interface Props {
  children: React.ReactNode
}

const View = styled.div`
  ${typography};
  padding: ${space.md}px ${space.xs}px ${space.xxs}px;

  display: flex;
  align-items: center;

  color: ${colors.gray400};
`

export default function ListSubheader(props: Props) {
  const {children, ...rest} = props
  return (
    <View {...rest} type="listSubheader">
      {children}
    </View>
  )
}
