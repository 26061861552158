import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteTimeline'
import {getTimelinePagination} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {getTimelineItems} from '../../selectors/note/timeline'
import FetchTimelineUseCase from '../../useCases/timeline/FetchTimelineUseCase'

const LIMIT = 20

export default function NoteTimeline() {
  const noteId = useSelector(getCurrentNoteId)
  const items = useSelector(getTimelineItems(noteId))

  useEffect(() => {
    UseCaseCommander.create().command(FetchTimelineUseCase)({
      noteId,
    })
  }, [noteId])

  const {nextOffset = 0} = useSelector(getTimelinePagination)
  const params = {
    noteId,
    offset: nextOffset,
    limit: LIMIT,
  }
  const hasNext = nextOffset !== null && nextOffset !== undefined
  const onViewed = useCallback(() => {
    nextOffset > 0 &&
      UseCaseCommander.create().command(FetchTimelineUseCase)(params)
  }, [noteId, nextOffset])
  return React.createElement(View, {
    items,
    hasNext,
    onViewed,
  })
}
