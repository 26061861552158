import ErrorManager from '../../infra/error/ErrorManager'
import {IErrorManager} from '../../services/error/IErrorManager'
import UseCase from '../lib/UseCase'

interface Props {
  errorManager: IErrorManager
}

interface Params {
  id: string
}

/**
 * SignUpUseCase
 */
export default class DismissErrorDialogUseCase extends UseCase {
  private _errorManager: IErrorManager

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._errorManager = props.errorManager
  }

  /**
   * create
   */
  public static create() {
    return new DismissErrorDialogUseCase({
      errorManager: ErrorManager.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({id}: Params): Promise<void> {
    if (id) {
      this._errorManager.dismiss(id)
    }
  }
}
