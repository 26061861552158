import * as React from 'react'
import styled from 'styled-components'
import withForwardRef from '../../../hocs/ref'
import {fontSize} from '../../../styles/mixins/font'
import placeholderColor from '../../../styles/mixins/placeholder'
import {body2, theme} from '../../../styles/variables'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

const LineyInput = styled.input`
  padding: ${space.sm}px 0;

  min-width: 3em;
  width: 100%;
  min-height: ${theme.formField.lineHeight}; // for ios safari

  border: none;
  //background-color: transparent;
  background-color: ${colors.input};

  border-bottom: solid 1px ${colors.gray200};
  border-radius: 0;

  ${fontSize(body2.fontSize)};
  ${placeholderColor(colors.gray300)};

  transition: border 0.3s ease;
  &:focus,
  &:hover:focus {
    border-bottom: solid 1px ${colors.brand};
  }
  &:hover {
    border-bottom: solid 1px ${colors.gray400};
  }
`

export type Props = React.InputHTMLAttributes<HTMLInputElement>

/**
 * Text入力
 */
export default withForwardRef(LineyInput)
