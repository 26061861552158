export const breakpoints = {
  phone: 0,
  tablet: 768,
  desktop: 992,
}

export type Breakpoint = keyof typeof breakpoints

export const defaultBreakpoints = {
  keys: Object.keys(breakpoints),
  up: (key: Breakpoint) => `@media (min-width:${breakpoints[key]}px)`,
}
