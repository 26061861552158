import {RouteKey, getRouteUrl} from '../../routes'

export default interface UseCaseRedirectParams {
  routeKey: RouteKey
  params?: KeySignature<string>
}

export const getQueryStringByRedirectParams = ({
  routeKey,
  params,
}: UseCaseRedirectParams) => getRouteUrl(routeKey, params)
