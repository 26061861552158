import * as React from 'react'
import styled from 'styled-components'
import Logo from '../../atoms/Logo'

const View = styled.div`
  display: flex;
  align-items: center;
`

const SiteId = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default function HeaderLogo(props: any) {
  return (
    <View {...props}>
      <SiteId>
        <Logo />
      </SiteId>
    </View>
  )
}
