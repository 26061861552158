import * as React from 'react'
import {useState} from 'react'
import Swiper, {ReactIdSwiperChildren, SwiperInstance} from 'react-id-swiper'
import styled from 'styled-components'
import NoteTabs from '../../../containers/NoteTabs'
import Container from '../Container'
import {HEADER_HEIGHT} from '../HeaderLayout'

export interface Props {
  pagination: string[]
  slideIndex: number
  children: ReactIdSwiperChildren
  onChangeSwiperSlide: (activeIndex: number) => void
}

const View = styled.div``

const TabsOuter = styled(Container)`
  z-index: 100;
  width: 100%;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
`

const Inner = styled.div`
  position: relative;
`

// FIXME: breakpoint option 対応
export default function NoteSwiper(props: Props) {
  const {children, pagination, onChangeSwiperSlide, slideIndex} = props
  const [swiper, updateSwiper] = useState<SwiperInstance>(null)

  const allowTouchMove = false

  const handleGetSwiper = (swiper: SwiperInstance) => {
    swiper && (swiper.allowTouchMove = allowTouchMove)
    swiper &&
      swiper.on('slideChange', () => {
        if (slideIndex !== swiper.activeIndex) {
          onChangeSwiperSlide(slideIndex)
        }
      })
    updateSwiper(swiper)
  }

  const swiperProps = {
    initialSlide: slideIndex,
    allowTouchMove,
    getSwiper: handleGetSwiper,
    autoHeight: true,
    spaceBetween: 30,
  }

  return (
    <View>
      <TabsOuter>
        <NoteTabs items={pagination} />
      </TabsOuter>
      <Inner>
        <Swiper {...swiperProps}>{children}</Swiper>
      </Inner>
    </View>
  )
}
