import * as React from 'react'
import NoteCover from '../../../containers/NoteCover'
import NoteNav from '../../../containers/NoteNav'
import NoteHeader from '../../organisms/NoteHeader'
import DefaultPage from '../../templates/DefaultPage'

export interface Props {
  title: string
}

export default function NoteCoverPage(props: Props) {
  const {title} = props
  return (
    <DefaultPage header={NoteHeader} nav={NoteNav} title={title}>
      <NoteCover />
    </DefaultPage>
  )
}
