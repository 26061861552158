import * as React from 'react'
import styled from 'styled-components'
import UploadTrackImageField from '../../../containers/UploadTrackImageField'
import {defaultBreakpoints} from '../../../styles/mixins/breakpoints'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Divider from '../../atoms/Divider'
import Icon from '../../atoms/Icon'
import ListSubheader from '../../atoms/ListSubheader'
import Paper from '../../atoms/Paper'
import Col from '../../layouts/Col'
import List from '../../layouts/List'
import ListItem from '../../layouts/ListItem'
import Row from '../../layouts/Row'
import StretchyInput from '../../molecules/StretchyInput'
import StretchyTextarea from '../../molecules/StretchyTextarea'

export interface Props {
  form: string
  title?: string
  memo?: string
  timeFieldProps: any
  quantityFieldProps: any
  memoFieldProps: any
  imageFieldProps: any
  handleSubmit: any
  submitDisabled: boolean
  isProcessing: boolean
  onCancel: () => void
  quantityUnit?: string
  quantify?: boolean
}

const View = styled(Paper)`
  width: 90vw;

  ${defaultBreakpoints.up('tablet')} {
    max-width: 400px;
  }
`

export default function PostEditNoteTrackModal(props: Props) {
  const {
    form,
    title,
    timeFieldProps,
    quantityFieldProps,
    memoFieldProps,
    imageFieldProps,
    submitDisabled,
    isProcessing,
    onCancel,
    quantityUnit,
    quantify,
  } = props
  return (
    <View py={0}>
      <form onSubmit={props.handleSubmit}>
        <List p={0}>
          {title && <ListSubheader>{title}</ListSubheader>}
          <ListItem>
            <Row align="center" width="100%">
              <Col col={3}>
                <Icon type="clock3" />
                <Body as="span" ml={3}>
                  時間
                </Body>
              </Col>
              <Col col={9}>
                <StretchyInput minWidth="6em" type="time" {...timeFieldProps} />
              </Col>
            </Row>
          </ListItem>
          {quantify && (
            <>
              <Divider />
              <ListItem>
                <Row align="center" width="100%">
                  <Col col={3}>
                    <Icon type="register" />
                    <Body as="span" ml={3}>
                      量
                    </Body>
                  </Col>
                  <Col col={9}>
                    <StretchyInput
                      maxHeight={150}
                      step="any"
                      type="number"
                      {...quantityFieldProps}
                    />
                    <Body as="span" type="overline">
                      {quantityUnit}
                    </Body>
                  </Col>
                </Row>
              </ListItem>
            </>
          )}
          <Divider />
          <ListItem>
            <Row align="center" width="100%">
              <Col col={3}>
                <Icon type="register" />
                <Body as="span" ml={3}>
                  メモ
                </Body>
              </Col>
              <Col col={9}>
                <StretchyTextarea maxHeight={150} {...memoFieldProps} />
              </Col>
            </Row>
          </ListItem>
          <Divider />
          <ListItem>
            <Row align="center" width="100%">
              <Col col={3}>
                <Icon type="picture" />
                <Body as="span" ml={3}>
                  写真
                </Body>
              </Col>
              <Col col={9}>
                <Body>
                  <UploadTrackImageField
                    {...imageFieldProps}
                    form={form}
                    hideUploadIcon={true}
                    sources={
                      imageFieldProps.value ? [imageFieldProps.value] : []
                    }
                  />
                </Body>
              </Col>
            </Row>
          </ListItem>
        </List>

        <Row justify="center" py="md">
          <Col>
            <Button
              backgroundColor={colors.gray400}
              onClick={() => onCancel()}
              type="reset"
            >
              キャンセル
            </Button>
          </Col>
          <Col>
            <Button disabled={submitDisabled || isProcessing} type="submit">
              {isProcessing ? '送信中...' : '投稿する'}
            </Button>
          </Col>
        </Row>
      </form>
    </View>
  )
}
