import * as React from 'react'
import {useCallback, useRef, useState} from 'react'
import styled from 'styled-components'
import {containsElement} from '../../../lib/dom'
import {borderRadius} from '../../../styles/mixins/border'
import useMediaQuery from '../../../styles/useMediaQuery'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import TextButton from '../../atoms/TextButton'
import {replaceNewLine} from '../../helpers'
import Col from '../Col'
import Row from '../Row'
import Space from '../Space'

const View = styled.div`
  width: 100%;
  height: 100%;

  ${borderRadius};
`

const ControlHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${space.md}px;
  display: flex;
  align-items: center;

  background-color: ${colors.primary};
`

const CloseButton = styled(TextButton)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  font-weight: 700;
  color: #fff;
`

const SubmitButton = styled(TextButton)`
  margin-left: auto;
  font-weight: 700;
  color: #fff;
`

const Inner = styled.div<{isPhone: boolean}>`
  ${p =>
    p.isPhone
      ? `
  padding: 70px ${space.sm}px ${space.xl}px;
  `
      : `
  padding: ${space.sm}px;
  `};
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
`

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Label = styled(Body)`
  margin-bottom: ${space.sm}px;
  font-weight: 700;
  text-align: left;
  color: ${colors.gray400};
`

const Error = styled(Body)`
  margin-left: auto;
  color: ${colors.error};
  text-align: left;
`

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Help = styled(Body)`
  margin-top: ${space.md}px;
`

const HelpContent = styled(Body)`
  margin-top: ${space.xxs}px;
  margin-bottom: ${space.xs}px;
  margin-left: ${space.xs}px;
`

export interface Props {
  active?: boolean
  dirty?: boolean
  disabled?: boolean
  error?: string
  label?: string
  help?: string | string[]
  children?: React.ReactNode
  onClickCancel: () => void
  isProcessing: boolean
  valid?: boolean
}

export default function FormedFieldLayout({
  active,
  dirty,
  disabled,
  error,
  label,
  children,
  help,
  valid,
  ...rest
}: Props) {
  const isPhone = !useMediaQuery('tablet')
  const [touch, setTouch] = useState(false)
  const submitElement = useRef<HTMLButtonElement>(null)
  const resetElement = useRef<HTMLButtonElement>(null)
  const menuActionElement = useRef<HTMLDivElement>(null)
  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      const {target} = e
      setTouch(
        !containsElement(target as any, [
          submitElement.current,
          resetElement.current,
          menuActionElement.current,
        ]),
      )
    },
    [touch],
  )
  return (
    <View>
      {isPhone && (
        <ControlHeader>
          <CloseButton
            disabled={rest.isProcessing}
            onClick={() => rest.onClickCancel()}
          >
            <Icon mr="xs" size={18} type="cross" />
            <span>閉じる</span>
          </CloseButton>
          <SubmitButton disabled={rest.isProcessing || !dirty} type="submit">
            {rest.isProcessing ? '変更中' : '変更する'}
          </SubmitButton>
        </ControlHeader>
      )}
      <Inner isPhone={isPhone} onClick={handleClick}>
        <Header>
          {label && <Label size="body2">{label}</Label>}
          {error && <Error pb="sm">{error}</Error>}
        </Header>

        <Content>{children}</Content>

        <div>
          {help && (
            <Help color={colors.gray800} type="body2">
              <Icon size={18} type="question-circle" />
              解説
              {((Array.isArray(help) && help) || [help]).map(
                (content, index) => (
                  <HelpContent key={index} type="body2">
                    {replaceNewLine(content, {isTextToHtml: true})}
                  </HelpContent>
                ),
              )}
            </Help>
          )}
          {!isPhone && (
            <Row justify="flex-end" mt="md">
              <Col col="auto">
                <Button
                  backgroundColor={colors.gray400}
                  disabled={rest.isProcessing}
                  onClick={() => {
                    setTouch(false)
                    rest.onClickCancel()
                  }}
                  ref={resetElement}
                  type="reset"
                >
                  キャンセル
                </Button>
              </Col>
              <Col col="auto">
                <Button
                  disabled={rest.isProcessing || !dirty}
                  ref={submitElement}
                  type="submit"
                >
                  {rest.isProcessing ? '変更中...' : '変更する'}
                  <Space ml={space.xs}>
                    {rest.isProcessing ? (
                      <Loading size={12} />
                    ) : (
                      <Icon size={12} type="pencil3" />
                    )}
                  </Space>
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Inner>
    </View>
  )
}
