import * as React from 'react'
import {useMemo} from 'react'
import HamburgerButton from '../../../containers/HamburgerButton'
import NoteCoverRefreshButton from '../../../containers/NoteCoverRefreshButton'
import NoteSelect from '../../../containers/NoteSelect'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import HeaderLayout from '../../layouts/HeaderLayout'
import Row from '../../layouts/Row'

export default function NoteHeader() {
  const right = useMemo(
    () => (
      <Row gutter={space.md - 3}>
        <Col>
          <NoteCoverRefreshButton />
        </Col>
        <Col>
          <HamburgerButton />
        </Col>
      </Row>
    ),
    [],
  )
  return <HeaderLayout left={<NoteSelect />} right={right} />
}
