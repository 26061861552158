import * as React from 'react'
import styled from 'styled-components'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Loading from '../../atoms/Loading'
import Timetable, {WeekItem} from '../../molecules/Timetable'

const View = styled.div``

const LoadingOuter = styled.div`
  padding-top: ${space.xxl}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export interface Props {
  fetching: boolean
  date: Date
  week: WeekItem
}

export default function NoteTrackTimetable(props: Props) {
  const {date, fetching, week} = props
  return (
    <View>
      {fetching ? (
        <LoadingOuter>
          <Loading />
          <Body mt="sm">読み込み中...</Body>
        </LoadingOuter>
      ) : (
        <Timetable date={date} week={week} />
      )}
    </View>
  )
}
