import * as React from 'react'
import {useCallback, useState} from 'react'
import styled from 'styled-components'
import useImage, {ImageStatus} from '../../../lib/image'
import {height, maxWidth, width} from '../../../styles/mixins/sizing'
import ViewportObserver, {View as ViewportObserverView} from '../../layouts/ViewportObserver'
import Loading from '../Loading'
import PlaceholderImage, {ObjectFitType} from '../PlaceholderImage'

export interface MediaProps {
  src: string
  maxWidth?: string | number
  width?: string | number
  height?: string | number
  objectFit?: ObjectFitType
  bgColor?: string
}

export type Props = {
  onLoaded?: () => void
  hasIndicator?: boolean
  desktop?: MediaProps
} & MediaProps

const View = styled.div<{width: string | number; height: string | number}>`
  position: relative;
  ${width}
  ${height}
  ${maxWidth};
  ${ViewportObserverView} {
    ${width};
    ${height};
    ${maxWidth};
  }
`

const Indicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function LazyImage(props: Props) {
  const {
    maxWidth = 'none',
    width = '100%',
    height = '100%',
    objectFit,
    src,
    hasIndicator = false,
    onLoaded,
    bgColor,
    ...rest
  } = props
  const [viewed, setViewed] = useState<boolean>(false)
  const lazySrc = viewed ? src : ''
  const onViewed = useCallback(() => {
    setViewed(true)
  }, [])
  const imageStatus = useImage(lazySrc)
  if (imageStatus === ImageStatus.loaded) {
    onLoaded && onLoaded()
  }
  return (
    <View height={height} width={width} {...rest}>
      <ViewportObserver onAbove={onViewed} onBelow={onViewed} rootMargin="0px">
        <PlaceholderImage
          bgColor={bgColor}
          height={height}
          maxWidth={maxWidth}
          objectFit={objectFit}
          src={lazySrc}
          width={width}
        />
        {hasIndicator && imageStatus === ImageStatus.loading && (
          <Indicator>
            <Loading size={30} />
          </Indicator>
        )}
      </ViewportObserver>
    </View>
  )
}
