import * as React from 'react'
import View from '../../components/molecules/HeaderNavigation'
import {RouteKey} from '../../routes'

export interface Props {
  back?: boolean | RouteKey
  title?: string
}

export default function HeaderNavigation(props: Props) {
  const {back, title} = props
  return React.createElement(View, {
    back,
    title,
  })
}
