import * as React from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {someOne} from '../../../lib'
import {FieldValidators} from '../../../lib/validators/types'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import CheckboxRadio, {CheckboxRadioOption} from '../../atoms/CheckboxRadio'
import Margin from '../../layouts/Margin'

const View = styled.div`
  display: inline-block;
`

const Label = styled(Body)`
  // margin-right: ${space.sm}px;
  width: 100%;
  font-weight: 600;
  text-align: left;
`

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export type Props = {
  label?: string
  type?: string
  options: CheckboxRadioOption[]
  validators?: FieldValidators
} & React.InputHTMLAttributes<HTMLInputElement>

const getSelectionsByArray = (props: any, options: CheckboxRadioOption[]) => {
  const {type, name, ...rest} = props
  return (
    options &&
    options.map((option, i) => {
      const label = someOne(option, 'label', 'value', 'name')
      const value = someOne(option, 'value', 'label', 'name')
      const checkboxName = someOne(option, 'name', 'label', 'value')
      const radioName = name || checkboxName
      return (
        <Margin key={i} left={space.sm} top={space.xs}>
          <ReduxField
            component={CheckboxRadio}
            label={label}
            name={type === 'checkbox' ? checkboxName : radioName}
            type={type}
            value={value}
            {...rest}
          />
        </Margin>
      )
    })
  )
}

const getSelectionsByObject = (
  props: any,
  options: {
    [key: string]: CheckboxRadioOption
  },
) => {
  return (
    options &&
    Object.keys(options).map((key, i) => {
      const label = key
      const value = options[key]
      return (
        <Margin key={i} left={space.sm} top={space.xs}>
          <ReduxField
            component={CheckboxRadio}
            label={label}
            name={key}
            value={value}
            {...props}
          />
        </Margin>
      )
    })
  )
}

export default function LabeledCheckboxRadio({label, options, ...rest}: Props) {
  const selections = Array.isArray(options)
    ? getSelectionsByArray(rest, options)
    : getSelectionsByObject(rest, options)
  return (
    <View>
      <Label mr="sm">{label}</Label>
      <Row>{selections}</Row>
    </View>
  )
}
