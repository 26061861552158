import * as React from 'react'
import styled from 'styled-components'
import {row} from '../../layouts/Row'

const View = styled.div`
  ${row};
`

export interface Props {
  children: React.ReactNode
  exclusive?: boolean
  onChange: (event: React.SyntheticEvent, value: string | string[]) => void
  value: string | string[]
}

const isValueSelected = (value?: string, candidate?: string | string[]) => {
  return candidate && value && [candidate].flat().includes(value)
}

export default function ToggleButtonGroup(props: Props) {
  const {children, exclusive, onChange, value, ...rest} = props
  const handleChange = (event: React.SyntheticEvent, childValue: string) => {
    if (!onChange) {
      return
    }
    const values = [value].flat()
    const included = values.includes(childValue)
    onChange(
      event,
      included ? values.filter(v => v !== childValue) : [...values, childValue]
    )
  }
  const handleExclusiveChange = (
    event: React.SyntheticEvent,
    childValue: string
  ) => {
    if (!onChange) {
      return
    }
    onChange(event, value === childValue ? '' : childValue)
  }
  return (
    <View {...rest}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return
        }

        const {selected: childSelected, value: childValue} = child.props
        const selected =
          childSelected === undefined
            ? isValueSelected(childValue, value)
            : childSelected
        return React.cloneElement(child, {
          selected,
          onChange: exclusive ? handleExclusiveChange : handleChange
        })
      })}
    </View>
  )
}
