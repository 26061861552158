import * as React from 'react'
import {AnchorHTMLAttributes} from 'react'
import {LogCategory} from '../../domain/log/loggerTypes'
import {logLinkClicked} from '../../services/log'

const DEFAULT_LOG_CATEGORY = LogCategory.externalLink

type Props = {
  logCategory?: LogCategory
} & AnchorHTMLAttributes<HTMLAnchorElement>

/**
 * クリック時にログをとる a エレメントです。
 */
export default function LoggableAnchor(props: Props) {
  const {
    logCategory = DEFAULT_LOG_CATEGORY,
    onClick: onClickOrigin,
    ...rest
  } = props

  return React.createElement('a', {
    ...rest,
    onClick: (e: any) => {
      onClickOrigin && onClickOrigin(e)
      logLinkClicked({logCategory, url: props.href})
    },
  })
}
