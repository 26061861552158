import {connect} from 'react-redux'
import {compose} from 'recompose'
import {reduxForm} from 'redux-form'
import View, {FormData, Props} from '../../components/organisms/LoginForm'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {Forms} from '../../services/forms'
import Navigation from '../../services/navigation/Navigation'
import SignInUseCase, {
  SignInUseCaseParams,
} from '../../useCases/auth/SignInUseCase'
import * as selectors from './selectors'
import {HandleSubmitProps} from '../../lib/form/types'
import {withRouter, RouteComponentProps} from 'react-router'
import queryString from 'query-string'

export default compose<Props & HandleSubmitProps, {}>(
  withRouter,
  connect(
    (state: IReduxState, ownProps: RouteComponentProps): Props => {
      const redirect =
        (queryString.parse(ownProps.location.search).redirect as string) ||
        undefined
      return {
        submitForm: v => {
          const values = selectors.getValues(state)
          UseCaseCommander.create().command<SignInUseCaseParams>(SignInUseCase)(
            {...values, redirect},
          )
        },
        clickSignUpButton: () => {
          // TODO: usecase
          Navigation.create().push('signUp')
        },
        clickResetPasswordButton: () => {
          // TODO: usecase
          Navigation.create().push('requestResetPassword')
        },
        submitDisabled: selectors.isSubmitDisabled(state),
        isProcessing: useCaseSelectors.isProcessing(SignInUseCase)(state),
      }
    },
  ),
  reduxForm<FormData>({
    form: Forms.login,
  }),
)(View)
