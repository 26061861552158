import {useEffect, useMemo, useState} from 'react'

export interface Props {
  src: string
}

export enum ImageStatus {
  none = 'none',
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed',
}

export default function useImage(src: string) {
  const [imageStatus, setImageStatus] = useState<ImageStatus>(ImageStatus.none)
  useMemo(() => {
    src && setImageStatus(ImageStatus.loading)
  }, [src])
  useEffect(() => {
    if (!src) return
    const img = document.createElement('img')
    const onload = () => {
      setImageStatus(ImageStatus.loaded)
    }
    const onerror = () => {
      setImageStatus(ImageStatus.failed)
    }
    img.addEventListener('load', onload)
    img.addEventListener('error', onerror)

    img.src = src
    return () => {
      img.removeEventListener('load', onload)
      img.removeEventListener('error', onerror)
    }
  })
  return imageStatus
}
