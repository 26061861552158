import {format, parseISO} from 'date-fns'
import * as React from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import NoteTimelineComment from '../../../containers/NoteTimelineComment'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import {spacing} from '../../../styles/mixins/spacing'
import space from '../../../styles/variables/space'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import {paperMarginTop} from '../../atoms/Paper'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import ViewportObserver from '../../layouts/ViewportObserver'
import NoteTimelineDiary from '../NoteTimelineDiary'
import NoteTimelineMessage from '../NoteTimelineMessage'

const View = styled.div`
  flex-flow: column nowrap;
  ${spacing};
`

const Pager = styled.div`
  padding-top: ${space.xxl}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export enum NoteTimelineItemPropsType {
  diary,
  editable,
  message,
  noteUpdate,
}

export interface NoteTimelineItemProps {
  type: NoteTimelineItemPropsType
  avatarSrc: string
  date: string
  timestamp: string
  name: string
  comment?: string
  message?: string
  image?: string
  title?: string
  timelineId: string
  diary?: string
  deletable: boolean
  editable: boolean
  noteId: string
}

export interface Props {
  items: NoteTimelineItemProps[]
  hasNext: boolean
  onViewed: () => void
}

const HistoryButton = (props: any) => <Icon {...props} type="history" />

export default function NoteTimeline(props: Props) {
  const {items, onViewed, hasNext} = props
  const views = items.map(({type, ...item}) => {
    switch (type) {
      case NoteTimelineItemPropsType.message:
        return (
          <NoteTimelineMessage
            {...item}
            key={item.timelineId}
            message={item.message}
            mt={paperMarginTop}
          />
        )
      case NoteTimelineItemPropsType.noteUpdate:
        const message = (
          <Row flexWrap="nowrap">
            <Col>{item.message}</Col>
            <Col ml="auto">
              {
                <PushNavigationLink
                  noteId={item.noteId}
                  routeKey="history"
                  view={HistoryButton}
                />
              }
            </Col>
          </Row>
        )
        return (
          <NoteTimelineMessage
            {...item}
            key={item.timelineId}
            message={message}
            mt={paperMarginTop}
          />
        )
      case NoteTimelineItemPropsType.diary:
        return (
          <NoteTimelineDiary
            {...item}
            content={item.diary}
            key={item.timelineId}
            message={item.message}
            mt={paperMarginTop}
            noteId={item.noteId}
            timestamp={item.timestamp}
          />
        )
      case NoteTimelineItemPropsType.editable:
        return (
          <NoteTimelineComment
            {...item}
            comment={item.comment || ''}
            key={item.timelineId}
            mt={paperMarginTop}
            name="comment"
            timelineId={item.timelineId}
            title={item.title || ''}
          />
        )
      default:
        return null
    }
  })
  return (
    <View>
      {views}
      {hasNext && (
        <ViewportObserver onBelow={onViewed}>
          <Pager>
            <Loading />
            <Body mt="sm">読み込み中...</Body>
          </Pager>
        </ViewportObserver>
      )}
    </View>
  )
}
