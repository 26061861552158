import React, {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View, {Props} from '../../components/pages/EditNoteMembersPage'
import Note, {getNoteInvitableToMe} from '../../domain/note/Note'
import {getMemberUsers, getRequestUsers} from '../../domain/note/NoteRelatedUser'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as navigationSelectors from '../../selectors/app/navigation'
import FetchNoteMembersUseCase from '../../useCases/note/FetchNoteMembersUseCase'

export default function EditNoteMembersPage() {
  const noteId = navigationSelectors.getNoteId()

  useEffect(() => {
    noteId &&
      UseCaseCommander.create().command(FetchNoteMembersUseCase)({noteId})
  }, [noteId]) // NOTE: depsを指定しないと無限API呼び出し

  const note = useSelector(getNoteById(noteId)) as Note
  const {title = '', imageUrl: noteImageUrl = ''} = note

  const memberUsers = getMemberUsers(note)
  const requestUsers = getRequestUsers(note)

  // メンバー追加しないと「編集可」の説明文が意味をなさないので、メンバーが増えてから表示する
  const visibleEditableExplain = memberUsers.length > 1

  const invitable = useMemo(() => getNoteInvitableToMe(note), [noteId])

  const props = {
    title: `${title}のメンバー管理`,
    noteImageUrl,
    memberUsers,
    requestUsers,
    visibleEditableExplain,
    invitable,
  }

  return React.createElement<Props>(View, props)
}
