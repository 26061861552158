import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import * as ReduxForm from 'redux-form'
import {ConfigProps, getFormMeta} from 'redux-form'
import * as formSelectors from '../../infra/redux/form/selectors'
import ReduxProvider from '../../infra/redux/ReduxProvider'
import {FieldValidators} from '../validators/types'

export default function useForm(options: ConfigProps) {
  const {form, initialValues, onSubmit, ...otherMeta} = options
  const redux = ReduxProvider.create()
  const store = useSelector(formSelectors.getState)
  const formValues = store[form] && store[form].values

  useEffect(() => {
    redux.dispatch(ReduxForm.initialize(form, initialValues, false, otherMeta))

    return () => {
      redux.dispatch(ReduxForm.destroy(form))
    }
  }, [])

  const reset = useCallback(() => {
    redux.dispatch(ReduxForm.reset(form))
  }, [form])

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent<any>) => {
      e.preventDefault()
      onSubmit && (onSubmit as any)(/* FIXME: types*/ formValues)
    },
    [form, onSubmit, formValues],
  )

  const useField = useCallback(
    (name: string, validations?: FieldValidators) => {
      const value = formValues && formValues[name]
      const checkValidations = useCallback(
        (value: string) => {
          const syncErrors =
            validations &&
            validations.reduce((acc, validation) => {
              acc[name] = validation.validate(value)
              return acc
            }, {})
          if (syncErrors && Object.keys(syncErrors).length > 0) {
            redux.dispatch(ReduxForm.updateSyncErrors(form, syncErrors, ''))
          }
        },
        [form, name, value],
      )

      useEffect(() => {
        redux.dispatch(ReduxForm.registerField(form, name, 'Field'))
        checkValidations(value)
        return () => {
          redux.dispatch(ReduxForm.unregisterField(form, name))
        }
      }, [form, name, value])

      // const errors = getFormSyncErrors(form)(redux.getState())
      const formMeta = useSelector(getFormMeta(form))
      const meta = formMeta[name]

      const handleFocus = useCallback(
        (e: React.SyntheticEvent) => {
          return redux.dispatch(ReduxForm.focus(form, name))
        },
        [form, name, value],
      )

      const handleBlur = useCallback(
        (e: React.SyntheticEvent) => {
          const {target} = e
          const {value} = target as any
          return redux.dispatch(ReduxForm.blur(form, name, value))
        },
        [form, name, value],
      )

      const handleChange = useCallback(
        (e: React.SyntheticEvent) => {
          const {target} = e
          const {value} = target as any
          checkValidations(value)
          return redux.dispatch(ReduxForm.change(form, name, value))
        },
        [form, name, value],
      )

      return {
        meta,
        input: {
          name,
          value: value ? value : '',
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: handleChange,
        },
      }
    },
    [form, onSubmit, formValues],
  )

  return {
    store,
    isInvalid: ReduxForm.isInvalid(form)(redux.getState()),
    reset,
    handleSubmit,
    useField,
  }
}
