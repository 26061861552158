import {getUrlParams} from '../lib'
import * as localForage from 'localforage'
const storage: LocalForageDbMethods = localForage

const MOCK_API_ENDPOINT = process.env.REACT_APP_MOCK_API_ENDPOINT || ''
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || ''
const RES_API_ENDPOINT = process.env.REACT_APP_RES_API_ENDPOINT || ''
const LOG_API_ENDPOINT = process.env.REACT_APP_LOG_API_ENDPOINT || ''
const GA = process.env.REACT_APP_GA || ''
const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT
  ? parseInt(process.env.REACT_APP_API_TIMEOUT, 10)
  : 36000
const APP_VERSION = process.env.APP_VERSION || 'unknown'

const NODE_ENV = process.env.NODE_ENV

const ROOT_ELEMENT_ID = 'root'

const SITE_TITLE = process.env.REACT_APP_NAME || 'じぶんノート'

export enum DebugMode {
  mock = 'mock',
  none = 'none',
}

/**
 * ?d=mockなどで、API_ENDPOINTをMockServerに変更するなどのために使う
 * debugModeの値は、永続化されるので、`?d=` で空文字でリセットする
 */
export const getDebugMode = async (): Promise<DebugMode> => {
  if (process.env.NODE_ENV !== 'development') return DebugMode.none
  const debugModeParam = getUrlParams('d')
  if (debugModeParam === '' || DebugMode[debugModeParam as string]) {
    return (
      (await storage.setItem<DebugMode>(
        'debugMode',
        debugModeParam as DebugMode,
      )) || DebugMode.none
    )
  }
  const debugMode = await storage.getItem<DebugMode>('debugMode')
  return DebugMode[debugMode] || DebugMode.none
}

export {
  APP_VERSION,
  MOCK_API_ENDPOINT,
  API_ENDPOINT,
  RES_API_ENDPOINT,
  LOG_API_ENDPOINT,
  API_TIMEOUT,
  GA,
  NODE_ENV,
  SITE_TITLE,
  ROOT_ELEMENT_ID,
}
