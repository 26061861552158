import * as React from 'react'

/**
 * ドット区切りの文字列で値を取得する
 * @param keyString
 * @param obj
 * @param defaultValue
 */
export const getPath = (
  obj: KeySignature,
  keyString: string,
  defaultValue?: any,
): any => {
  const keys = keyString && keyString.length && keyString.split('.')
  return keys
    ? keys.reduce((acc, item) => {
        return acc && acc[item] ? acc[item] : defaultValue
      }, obj)
    : defaultValue
}

/**
 * objectのpropertyを左から優先して取得する
 * @param value
 * @param rest
 */
export const someOne = <V extends KeySignature | string>(
  value: V,
  ...rest: string[]
): any => {
  let ret = value
  if (typeof value === 'object') {
    rest.some(r => {
      if (ret[r]) {
        ret = ret[r]
      }
      return ret[r]
    })
  }
  return ret
}

export const randomText = (length: number) => {
  let ret = ''
  // const text = 'あいうえおかきくけこさしすせそたちつてとなにぬねの'
  const text = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const len = text.length
  for (let i = 0; i < length; i++) {
    ret += text[Math.floor(Math.random() * len)]
  }
  return ret
}

// FIXME: わけわかんくなった
/*
  【夏場】${summer}°C　【冬場】${winter}°C
    ↓
   ['【夏場】', raws[key], '°C　【冬場】', raws[key], '°C']
 */
export const templateSupplant = function(
  template: string,
  names: string[],
  raws: any[],
) {
  const {list} = raws.reduce(
    (acc: any, val: any, index) => {
      const {list, templ} = acc
      const name = names[index]
      // const [prefix = '', suffix = ''] = templ.split(name) || []
      // 半分に分割
      const [prefix = '', suffix = ''] = [
        templ.substr(0, templ.indexOf(name)),
        templ.substr(templ.indexOf(name) + name.length),
      ]
      const isLast = index === raws.length - 1
      return {
        list: isLast
          ? [...list, prefix, raws[index], suffix]
          : [...list, prefix, raws[index]],
        templ: suffix,
      }
    },
    {list: [], templ: template},
  )
  return list
}

export const getInterpolated = (
  filledText: string,
  getComponent: (props: {
    name: string
    key: string
    val: string
  }) => React.ReactNode,
) => {
  const pattern = /\$\{(\w+)\}|(\n)/g
  const regexp = new RegExp(pattern)
  const strings = []
  let match
  while ((match = regexp.exec(filledText)) !== null) {
    strings.push(match[0])
  }
  const raws = strings.map((val, index) => {
    const name = val.replace('${', '').replace('}', '')
    return getComponent({name, key: index.toString(), val})
  })
  return templateSupplant(filledText, strings, raws)
}

export const useDebounce = (
  fn: () => any,
  ms: number = 0,
  args: any[] = [],
) => {
  React.useEffect(() => {
    const handle = setTimeout(fn.bind(null, args), ms)

    return () => {
      clearTimeout(handle)
    }
  }, args)
}

/**
 * development時に使う用
 * @param name
 */
export const getUrlParams = (name: string) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search)
  return results === null
    ? undefined
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

/**
 * ゼロでパディングした文字列を返す。
 * (1, 3)  => "001"
 * (13, 3) => "013"
 *
 * @param num 対象の数字
 * @param length 長さ
 */
export const getZeroPadding = (num: number, length: number): string =>
  `000000000000000000${num}`.slice(-length)

/**
 * pick
 * @param obj
 * @param paths
 * @returns {Pick<T, K>}
 */
export function pick<T, K extends keyof T>(obj: T, paths: K[]) {
  return {
    ...paths.reduce((mem, key) => ({...mem, [key]: obj[key]}), {}),
  } as Pick<T, K>
}

/**
 * omit
 * @param obj
 * @param paths
 */
export function omit<T, K extends keyof T>(obj: T, paths: K[]) {
  return {
    ...Object.keys(obj)
      .filter(key => !paths.includes(key as K))
      .reduce((mem, key) => ({...mem, [key]: obj[key]}), {}),
  } as Omit<T, K>
}

export function hasSomeProperty(obj: any): boolean {
  return Array.isArray(obj)
    ? obj.some(val => {
        return hasSomeProperty(val)
      })
    : obj &&
        Object.entries(obj).some(([key, val]) => {
          return Array.isArray(val) || typeof val === 'object'
            ? hasSomeProperty(val)
            : val
        })
}
