import {useCallback} from 'react'
import * as React from 'react'
import {useSelector} from 'react-redux'
import View, {Props as ViewProps} from '../../components/layouts/NoteSwiper'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteSlideId} from '../../selectors/note'
import ChangeNoteSlideUseCase, {
  Params as ChangeNoteSlideUseCaseParams,
} from '../../useCases/app/ChangeNoteSlideUseCase'

type Props = ViewProps

export default function NoteSwiper(props: Props) {
  const slideIndex = useSelector(getCurrentNoteSlideId)
  const onChangeSwiperSlide = useCallback(
    slideIndex => {
      UseCaseCommander.create().command<ChangeNoteSlideUseCaseParams>(
        ChangeNoteSlideUseCase,
      )({slideIndex})
    },
    [slideIndex],
  )
  return React.createElement(View, {
    ...props,
    slideIndex,
    onChangeSwiperSlide,
  })
}
