import * as React from 'react'
import {useCallback} from 'react'
import styled from 'styled-components'
import {useModalState} from '../../../selectors/app/dom'
import LabeledIcon from '../LabeledIcon'

const View = styled.div``

export interface Props {
  iconType: string
  buttonLabel: string
  modal: React.ComponentType<any /*fixme: type*/>
}

export default function ActionButton(props: Props) {
  const [showModal, setShowModal, pushed] = useModalState(false)
  const {modal: M, buttonLabel, iconType, ...rest} = props
  const handleDismiss = useCallback(() => {
    setShowModal(false)
  }, [showModal, pushed])
  return (
    <View>
      <M {...rest} dismiss={() => handleDismiss()} show={showModal} />
      <LabeledIcon onClick={() => setShowModal(true)} type={iconType}>
        {buttonLabel}
      </LabeledIcon>
    </View>
  )
}
