import * as React from 'react'
import View from '../../components/atoms/Helmet'
import {SITE_TITLE} from '../../config'

interface Props {
  title?: string
  allowRobots?: boolean
}

export default function Helmet(props: Props) {
  return (
    <View
      allowRobots={props.allowRobots || false}
      siteTitle={SITE_TITLE}
      title={props.title || ''}
    />
  )
}
