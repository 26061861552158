import * as React from 'react'
import ReactDOM from 'react-dom'
import {Transition} from 'react-transition-group'
import styled from 'styled-components'
import {useRootElement} from '../../../selectors/app/dom'
import {TransitionState} from '../../../styles'
import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius
} from '../../../styles/mixins/border'
import transition from '../../../styles/mixins/transition'
import {typography} from '../../../styles/mixins/typography'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'

const View = styled.div<TransitionState>`
  z-index: ${zIndex.errorDialog};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${transition()};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
`

const Bg = styled.div<TransitionState>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  ${transition()};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
`

const Window = styled.div<TransitionState>`
  z-index: 10;
  position: relative;

  width: auto;
  min-width: 300px;
  max-width: 500px;

  display: flex;
  flex-flow: column nowrap;

  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.14);

  ${transition()};
  transform: ${({state}) => {
    switch (state) {
      case 'entering':
        return 'translateY(-40px)'
      case 'entered':
        return 'translateY(0px)'
      case 'exiting':
        return 'translateY(40px)'
      case 'exited':
        return 'translateY(-40px)'
      default:
        return 'translateY(0px)'
    }
  }};
`

const Header = styled.header`
  padding: ${space.sm}px ${space.md}px;

  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: ${colors.white};
  background-color: ${colors.brand};
  ${borderTopLeftRadius};
  ${borderTopRightRadius};
`

const CloseButton = styled.div`
  position: absolute;
  right: ${space.sm}px;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${typography};
`

const Content = styled.div`
  padding: ${space.sm}px ${space.md}px;

  ${borderBottomLeftRadius};
  ${borderBottomRightRadius};
  background-color: #fff;
`

export interface Props {
  transitionIn: boolean
  header?: React.ReactNode
  children?: React.ReactNode
  onClickClose?: () => void
}

export default function Dialog({
  header,
  children,
  onClickClose,
  transitionIn,
}: Props) {
  const rootElement = useRootElement()
  return ReactDOM.createPortal(
    <Transition
      in={transitionIn}
      mountOnEnter={true}
      timeout={{enter: 0, exit: 300}}
      unmountOnExit={true}
    >
      {state => (
        <View state={state}>
          <Bg onClick={onClickClose} state={state} />
          <Window state={state}>
            <Header>
              <Body weight={700}>{header}</Body>
              <CloseButton onClick={onClickClose} size="body2">
                <Icon size={18} type="cross" />
                閉じる
              </CloseButton>
            </Header>
            <Content>{children}</Content>
          </Window>
        </View>
      )}
    </Transition>,
    rootElement,
  )
}
