import deepmerge from 'deepmerge'
import {getStyleFromPropValue, getThemeValue} from '../variables'
import {SpaceType} from '../variables/space'
import {Breakpoint, defaultBreakpoints} from './breakpoints'

// FIXME: type
export type SystemProp = number | string | SpaceType

const properties = {
  m: 'margin',
  p: 'padding',
}

const directions = {
  t: 'Top',
  r: 'Right',
  b: 'Bottom',
  l: 'Left',
  x: ['Left', 'Right'],
  y: ['Top', 'Bottom'],
}

const spacingKeys = [
  'm',
  'mt',
  'mr',
  'mb',
  'ml',
  'mx',
  'my',
  'p',
  'pt',
  'pr',
  'pb',
  'pl',
  'px',
  'py',
  'margin',
  'marginLeft',
  'marginTop',
  'marginRight',
  'marginBottom',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
]

// FIXME: spacingKeys から型取得
export interface SpacingProps {
  m?: SystemProp
  mt?: SystemProp
  mb?: SystemProp
  ml?: SystemProp
  mr?: SystemProp
  mx?: SystemProp
  my?: SystemProp
  p?: SystemProp
  pt?: SystemProp
  pb?: SystemProp
  pl?: SystemProp
  pr?: SystemProp
  px?: SystemProp
  py?: SystemProp
}

// FIXME: spacingKeys から型取得
export type SpacingBreakPointsProps = {
  tablet?: SpacingProps
  desktop?: SpacingProps
} & SpacingProps

const getCssProperties = (prop: string) => {
  if (prop.length > 3) {
    return [prop]
  }
  const [a, b] = prop.split('')
  const property = properties[a]
  const direction = directions[b] || ''
  return Array.isArray(direction)
    ? direction.map(dir => property + dir)
    : [property + direction]
}

export const _spacing = (props: SpacingProps) => {
  return props
    ? Object.keys(props)
        .map(prop => {
          if (!spacingKeys.includes(prop)) {
            return
          }
          const cssProperties = getCssProperties(prop)
          const styleFromPropValue = getStyleFromPropValue(
            'spacing',
            cssProperties,
          )
          const propValue = props[prop]
          return styleFromPropValue(propValue)
        })
        .reduce((acc, item) => {
          return !item ? acc : deepmerge(acc, item, {clone: false})
        }, {})
    : {}
}

export const spacing = (props: SpacingBreakPointsProps) => {
  // TODO: phone, tablet, desktop でsort
  return Object.keys(props).reduce((acc, item) => {
    if (!defaultBreakpoints.keys.includes(item)) {
      return acc
    }
    acc[defaultBreakpoints.up(item as Breakpoint)] = _spacing(props[item])
    return acc
  }, _spacing(props))
}
