import {reset} from 'redux-form'
import ReduxProvider from '../../infra/redux/ReduxProvider'
import UseCase from '../lib/UseCase'

// import ApplicationService from '../../services/app/ApplicationService'

interface Props {
  reduxProvider: ReduxProvider
}

interface Params {
  form: string
}

export default class ResetFormUseCase extends UseCase {
  private _redux: ReduxProvider

  public constructor(props: Props) {
    super()
    this._redux = props.reduxProvider
  }

  public static create() {
    return new ResetFormUseCase({
      reduxProvider: ReduxProvider.create(),
    })
  }

  public async execute({form}: Params): Promise<void> {
    this._redux.dispatch(reset(form))
  }
}
