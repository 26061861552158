import * as React from 'react'
import {useCallback} from 'react'
import styled from 'styled-components'
import FormedNoteField from '../../../containers/FormedNoteField'
import ResourceImage from '../../../containers/ResourceImage'
import {
  hasFieldData,
  hasSomeFieldData,
} from '../../../domain/note/snapshot/Tree'
import {getInterpolated, hasSomeProperty, omit, pick} from '../../../lib'
import {dispatchWindowResize} from '../../../lib/dom'
import {borderRadius} from '../../../styles/mixins/border'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import {replaceNewLine} from '../../helpers'
import {NoteLeafComponentProps} from '../../helpers/snapshot'
import Col from '../../layouts/Col'
import {InterpolatedComponent} from '../../layouts/InterpolatedComponent'
import NoteFieldLayout from '../../layouts/NoteFieldLayout'
import NoteModal from '../../layouts/NoteModal'
import Row from '../../layouts/Row'
import {MenuItem} from '../../molecules/Menu'
import TelLink from '../../molecules/TelLink';

const Editable = (
  <Body as="span" color={colors.gray300} cursor="pointer">
    （編集する）
  </Body>
)
const Empty = (
  <Body as="span" color={colors.gray200}>
    （空白）
  </Body>
)
const getDefaultText = (disabled: boolean) => {
  return disabled ? Empty : Editable
}

const TextFieldValue = (props: NoteLeafComponentProps) => {
  const {
    data = {},
    disabled,
    name,
    attr: {suffix = ''},
  } = props
  const rest = pick<any, any>(props, ['onClick'])
  const ret = data[name] || getDefaultText(disabled)
  return (
    <Row
      align="baseline"
      cursor={disabled ? 'inherit' : 'pointer'}
      gutter={0}
      {...rest}
    >
      <Col>
        <Body>{replaceNewLine(ret)}</Body>
      </Col>
      {suffix && (
        <Col>
          <Body color={colors.gray400} type="body2">
            {suffix}
          </Body>
        </Col>
      )}
    </Row>
  )
}

const FillTextValue = (props: NoteLeafComponentProps) => {
  const {
    attr: {text},
    data = {},
    disabled,
  } = props
  const rest = pick<any, any>(props, ['onClick'])
  const ret = InterpolatedComponent(text, data, getDefaultText(disabled))
  return (
    <Body {...rest} cursor={disabled ? 'inherit' : 'pointer'}>
      {ret}
    </Body>
  )
}

const TelFieldValue = (props: NoteLeafComponentProps) => {
  const {data = {}, disabled, name} = props
  const telValue = (data[name] as string) || ''
  const rest = pick<any, any>(props, ['onClick'])
  const defaultComponent = getDefaultText(disabled)
  return (
    <Row
      align="baseline"
      cursor={disabled ? 'inherit' : 'pointer'}
      gutter={0}
      {...rest}
    >
      <Col>
        <Body>
          {(telValue && (
            <TelLink stopClickPropagation={true} telNumber={telValue} />
          )) ||
            defaultComponent}
        </Body>
      </Col>
    </Row>
  )
}

const CommaRow = styled(Row)<{disabled?: boolean}>`
  cursor: ${p => (p.disabled ? 'inherit' : 'pointer')};
  > div::after {
    content: '、';
  }
  > div:last-child::after {
    content: '';
  }
`
const CommaCol = styled(Col)`
  display: flex;
  align-items: baseline;
  flex-flow: row nowrap;
`
const CheckboxFieldValue = (props: NoteLeafComponentProps) => {
  const {
    attr: {options},
    data = {},
    disabled,
    name,
    type,
  } = props
  const rest = pick<any, any>(props, ['onClick'])
  const hasData = hasFieldData(type, name, data, options)
  const items = Object.keys(options).map(key => {
    const option = options[key]
    const {label} = option
    const list = getInterpolated(label, ({name, key, val}) => {
      return <span key={key}>{data[name]}</span>
    })
    const value = list.length > 0 ? list : label
    const checked = type === 'radio' ? data[name] === key : data[key]
    return (
      <React.Fragment key={key}>
        {checked && (
          <CommaCol key={key} mb="xs">
            <Body>{value}</Body>
          </CommaCol>
        )}
      </React.Fragment>
    )
  })
  return (
    <CommaRow {...rest}>{hasData ? items : getDefaultText(disabled)}</CommaRow>
  )
}

const ArrayWrap = styled.div<{disabled?: boolean}>`
  margin-top: ${-space.sm}px;
  cursor: ${p => (p.disabled ? 'inherit' : 'pointer')};
`
const ArrayGroup = styled.div`
  margin-top: ${space.sm}px;
  padding: ${space.sm}px;
  border: solid 1px ${colors.border};
  ${borderRadius};
`
const getArrayItemComponent = (type: string) => {
  const componentMap = {
    tel: (v: any) => <TelLink stopClickPropagation={true} telNumber={v} />,
  }
  const defaultComponent = (v: any) => replaceNewLine(v)
  return componentMap[type] || defaultComponent
}
const ArrayFieldValue = (props: NoteLeafComponentProps) => {
  const {
    attr: {items},
    data = {},
    disabled,
    name,
  } = props
  const rest = pick<any, any>(props, ['onClick'])
  const array = data[name] || []
  const hasData = hasSomeProperty(array)
  const ret = array.map((values: KeySignature, index: number) => {
    const group = Object.keys(items).map((key, j) => {
      const value = values[key]
      const {label = '', type = ''} = items[key] || {}
      return value ? (
        <Body key={j} mb="xs">
          <Body color={colors.gray400} type="caption">
            {label}
          </Body>
          {getArrayItemComponent(type)(value)}
        </Body>
      ) : null
    })
    return (
      <ArrayGroup key={index} {...rest}>
        {group}
      </ArrayGroup>
    )
  })
  return (
    <ArrayWrap {...rest}>{hasData ? ret : getDefaultText(disabled)}</ArrayWrap>
  )
}

const BlankFileIcon = styled(Icon)`
  padding: ${space.md}px 0;
  width: 140px;
  height: 100px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${colors.gray200};
`
const ImageFieldValue = (props: NoteLeafComponentProps) => {
  const rest = pick<any, any>(props, ['onClick'])
  const {attr, data = {}, name} = props
  const src = data[name]
  const handleLoaded = useCallback(() => {
    // 画像読みこび後のnotesiwperの高さ調整
    dispatchWindowResize()
  }, [])

  return src ? (
    <ResourceImage
      {...rest}
      {...attr}
      name={name}
      onLoaded={handleLoaded}
      src={src}
    />
  ) : (
    <BlankFileIcon {...rest} size={46} type="picture"></BlankFileIcon>
  )
}
const MultiFieldValue = (props: NoteLeafComponentProps) => {
  const {
    attr: {items},
    data = {},
    disabled,
  } = props
  const rest = pick<any, any>(props, ['onClick'])
  const hasSomeData = hasSomeFieldData(data, items)
  const ret = Object.keys(items).map((key, j) => {
    const item = items[key]
    const {label = '', type = 'text', options = {}} = item || {}
    const ValueComponent = getValueComponent(type)
    const hasData = hasFieldData(type, key, data, options)
    return (
      hasData && (
        <Body key={j} mb="xs">
          <Body color={colors.gray400} type="body2">
            {label}
          </Body>
          <ValueComponent
            {...props}
            attr={item}
            data={data}
            label={label}
            name={key}
            type={type}
          />
        </Body>
      )
    )
  })
  return <div {...rest}>{hasSomeData ? ret : getDefaultText(disabled)}</div>
}

const getValueComponent = (type: string) => {
  return (
    {
      address: TextFieldValue,
      tel: TelFieldValue,
      array: ArrayFieldValue,
      checkbox: CheckboxFieldValue,
      freeText: TextFieldValue,
      fillText: FillTextValue,
      image: ImageFieldValue,
      multiText: MultiFieldValue,
      radio: CheckboxFieldValue,
      text: TextFieldValue,
    }[type] || TextFieldValue
  )
}

interface OwnProps {
  disabled: boolean
  showEditModal: boolean
  setShowEditModal: (val: boolean) => void
  menuItems: MenuItem[]
  policyMenuItems: MenuItem[]
  showMenu: boolean
  setShowMenu: (val: boolean) => void
  showPolicyMenu: boolean
  setShowPolicyMenu: (val: boolean) => void
}

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  NoteLeafComponentProps &
  OwnProps

export default function NoteField(props: Props) {
  const rest = omit(props, ['menuItems', 'policyMenuItems'])
  const {
    attr: {label = ''} = {},
    disabled,
    showEditModal,
    setShowEditModal,
    type,
  } = props
  const ValueComponent = getValueComponent(type)
  const handleDismiss = useCallback(() => {
    dispatchWindowResize()
    setShowEditModal(false)
  }, [])
  const handleSetShowEditModal = useCallback(
    (val: boolean) => {
      !disabled && setShowEditModal(val)
    },
    [disabled],
  )
  return (
    <NoteFieldLayout
      {...props}
      label={label}
      onClickEdit={() => handleSetShowEditModal(true)}
    >
      <ValueComponent onClick={() => handleSetShowEditModal(true)} {...props} />
      {!disabled && (
        <NoteModal
          dismiss={() => handleSetShowEditModal(false)}
          transitionIn={showEditModal}
        >
          <FormedNoteField
            {...rest}
            label={`${label}の変更`}
            onCancel={() => handleDismiss()}
            onSubmit={() => handleDismiss()}
          />
        </NoteModal>
      )}
    </NoteFieldLayout>
  )
}
