import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/HeaderRight'
import * as sessionSelectors from '../../selectors/app/session'

export default function HeaderRight() {
  const isAuthorized = useSelector(sessionSelectors.isAuthorized)

  return React.createElement(View, {
    isAuthorized,
  })
}
