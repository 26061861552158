import {
  API_ENDPOINT,
  DebugMode,
  getDebugMode,
  MOCK_API_ENDPOINT,
  RES_API_ENDPOINT,
  LOG_API_ENDPOINT,
} from '../../config'
import IAccessTokenRepository from '../../domain/auth/IAccessTokenRepository'
import AccessTokenRepository from '../repositories/auth/AccessTokenRepository'
import AppAPIClient from './AppAPIClient'
import ResAPIClient from './ResAPIClient'
import LogAPIClient from './LogAPIClient'

export default class AppAPI {
  private _accessTokenRepository: IAccessTokenRepository

  public static create() {
    if (AppAPI._instance) {
      return AppAPI._instance
    }
    return (AppAPI._instance = new AppAPI({
      accessTokenRepository: AccessTokenRepository.create(),
    }))
  }

  private static _instance: AppAPI
  private _client: AppAPIClient | null
  private _resClient: ResAPIClient | null
  private _logClient: LogAPIClient | null

  public constructor(props: {accessTokenRepository: IAccessTokenRepository}) {
    this._accessTokenRepository = props.accessTokenRepository
    this._client = null
    this._resClient = null
    this._logClient = null
  }

  /**
   * clearClient
   */
  public clearClient() {
    this._client = null
    this._resClient = null
    this._logClient = null
  }

  /**
   * getClient
   */
  public async getClient(): Promise<AppAPIClient> {
    if (this._client) {
      return this._client
    }
    const endpoint =
      (await getDebugMode()) === DebugMode.mock
        ? MOCK_API_ENDPOINT
        : API_ENDPOINT
    const accessToken = await this._accessTokenRepository.get()
    this._client = new AppAPIClient({
      endpoint,
      token: accessToken as string,
    })
    return this._client
  }

  public async getResourceClient(): Promise<ResAPIClient> {
    if (this._resClient) {
      return this._resClient
    }
    this._resClient = new ResAPIClient({
      endpoint: RES_API_ENDPOINT,
    })
    return this._resClient
  }

  public getLogClient(): LogAPIClient {
    if (this._logClient) {
      return this._logClient
    }
    this._logClient = new LogAPIClient({
      endpoint: LOG_API_ENDPOINT,
    })
    return this._logClient
  }
}
