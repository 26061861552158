import ISnapshotRepository from '../../domain/note/ISnapshotRepository'
import NoteId from '../../domain/note/NoteId'
import SnapshotRepository from '../../infra/repositories/note/SnapshotRepository'
import UseCase from '../lib/UseCase'

// import ApplicationService from '../../services/app/ApplicationService'

interface Props {
  snapshotRepository: ISnapshotRepository
}

interface Params {
  form: string
  id: NoteId
  data: KeySignature
  onSuccess?: () => void // FIXME: なるべくcallback使わない
}

export default class UpdateNoteUseCase extends UseCase {
  private _snapshotRepository: ISnapshotRepository

  public constructor(props: Props) {
    super()
    this._snapshotRepository = props.snapshotRepository
  }

  public static create() {
    return new UpdateNoteUseCase({
      snapshotRepository: SnapshotRepository.create(),
    })
  }

  public async execute({form, id, data, onSuccess}: Params): Promise<void> {
    this.registerSuccess(async () => {
      onSuccess && onSuccess()
    })
    /*
    this.registerComplete(async () => {
      this.subUseCaseExecutor(ResetFormUseCase)({form})
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '更新しました',
      })
    })
    */
    await this._snapshotRepository.update(id, data)
  }
}
