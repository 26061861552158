import Note from '../../../../domain/note/Note'
import union from 'lodash.union'
import NoteId from '../../../../domain/note/NoteId'
import INoteNotifications from '../../../../domain/note/notifications/INoteNotifications'
import {
  NoteMailNotifications,
  NoteMailNotificationType,
} from '../../../../domain/note/notifications/NoteMailNotificationType'
import AppAPI from '../../../api/AppAPI'
import {toNoteMailNotificationTypes} from '../../../api/translators/note'
import {actions} from '../../../redux/api/entities'
import {
  NoteMailNotificationTypeSchema,
  NoteSchema,
} from '../../../redux/api/schemata'
import {getNoteById} from '../../../redux/api/selectors'
import ReduxProvider from '../../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteNotifications implements INoteNotifications {
  private api: AppAPI
  private redux: ReduxProvider

  private mergedNoteWithState<AnyNote extends Note>({
    id,
    newProps,
  }: {
    id: string
    newProps: AnyNote
  }): AnyNote {
    const note = getNoteById(id)(this.redux.getState())
    const newNote = {...note, ...newProps}
    return newNote
  }

  /**
   * create
   *
   */
  public static create(): NoteNotifications {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteNotifications({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async getTypes(): Promise<NoteMailNotifications> {
    const client = await this.api.getClient()
    const {allMailTypes} = await client.getNoteRelationMe()
    const types = toNoteMailNotificationTypes(allMailTypes)
    this.redux.dispatch(actions.store(types, [NoteMailNotificationTypeSchema]))
    return types
  }

  public async notice(
    noteId: NoteId,
    type: NoteMailNotificationType,
  ): Promise<void> {
    const client = await this.api.getClient()
    const {relationToMe: {mailTypes: preMailTypes = []} = {}} = getNoteById(
      noteId,
    )(this.redux.getState())
    const res = await client.updateNoteRelationMe({
      noteId,
      mailTypes: union([...preMailTypes, type]).join(','),
    })
    const note = this.mergedNoteWithState({id: res.id, newProps: res})
    this.redux.dispatch(actions.store(note, NoteSchema))
  }

  public async unNotice(
    noteId: NoteId,
    type: NoteMailNotificationType,
  ): Promise<void> {
    const client = await this.api.getClient()
    const preNote = getNoteById(noteId)(this.redux.getState())
    const {relationToMe: {mailTypes: preMailTypes = []} = {}} = preNote
    const res = await client.updateNoteRelationMe({
      noteId,
      mailTypes: preMailTypes.filter(t => t !== type).join(','),
    })
    const note = this.mergedNoteWithState({id: res.id, newProps: res})
    this.redux.dispatch(actions.store(note, NoteSchema))
  }
}
