import BaseAPIClient from './BaseAPIClient'
import {ResourceRequest, ResourceResponse} from './types'

interface Res {
  body: any
  ok: boolean
  status: number
}

export default class ResAPIClient extends BaseAPIClient {
  public constructor({endpoint}: {endpoint: string}) {
    super({endpoint})
  }

  protected getDefaultOptions(): KeySignature {
    return {}
  }

  public async post(path: string, body: object = {}): Promise<Response> {
    const url = `${this.endpoint}${path}`
    return await this.send('post', url, {
      body,
    })
  }

  public async upload(params: ResourceRequest): Promise<ResourceResponse> {
    const body = new FormData()
    Object.keys(params).map(key => {
      return body.append(key, params[key])
    })
    return await this.post('/v1/resource/upload', body)
  }
}
