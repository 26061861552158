import ISnapshotRepository from '../../domain/note/ISnapshotRepository'
import NoteId from '../../domain/note/NoteId'
import SnapshotRepository from '../../infra/repositories/note/SnapshotRepository'
import ApplicationService from '../../services/app/ApplicationService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

// import ApplicationService from '../../services/app/ApplicationService'

interface Props {
  snapshotRepository: ISnapshotRepository
}

export interface Params {
  noteId: NoteId
  map: KeySignature
}

export default class UpdateNoteEmergencyUseCase extends UseCase {
  private _snapshotRepository: ISnapshotRepository

  public constructor(props: Props) {
    super()
    this._snapshotRepository = props.snapshotRepository
  }

  public static create() {
    return new UpdateNoteEmergencyUseCase({
      snapshotRepository: SnapshotRepository.create(),
    })
  }

  public async execute({noteId, map}: Params): Promise<void> {
    ApplicationService.create().toBusy()
    this.registerComplete(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: 'ポリシーを変更しました',
      })
      ApplicationService.create().toUnbusy()
    })
    await this._snapshotRepository.updatePolicy(noteId, map)
  }
}
