import * as React from 'react'
import {createGlobalStyle} from 'styled-components'
import {fontFaceCss, iconContentCss} from '../styles/fonts'
import normalize from '../styles/misc/normalize'
import reset from '../styles/misc/reset'
import swiper from '../styles/vendor/swiper' // FIXME: node_modulesから呼び出したい
import lightbox from '../styles/vendor/lightbox'
import calendar from '../styles/vendor/reactCalendar'

/**
 * configureStyles
 */
export default function configureStyles() {
  const Global = createGlobalStyle`
    ${normalize}
    ${reset}
    ${fontFaceCss}
    ${iconContentCss}
    ${calendar}
    ${lightbox}
    ${swiper}
  `
  return () => <Global />
}
