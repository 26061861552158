import * as localForage from 'localforage'
import AccessToken from '../../../domain/auth/AccessToken'
import IAccessTokenRepository from '../../../domain/auth/IAccessTokenRepository'

/**
 * AccessTokenRepository
 */
export default class AccessTokenRepository implements IAccessTokenRepository {
  public static STORAGE_KEY: string = 'jnote::accessToken'
  private static _instance: AccessTokenRepository
  private _storage: LocalForageDbMethods

  /**
   * create
   */
  public static create() {
    if (AccessTokenRepository._instance) {
      return AccessTokenRepository._instance
    }
    return (AccessTokenRepository._instance = new AccessTokenRepository({
      storage: localForage,
    }))
  }

  /**
   * constructor
   *
   * @param props - {storage}
   */
  public constructor(props: {storage: LocalForage}) {
    this._storage = props.storage
  }

  /**
   * get
   */
  public async get(): Promise<AccessToken | null> {
    const val = await this._storage.getItem<AccessToken | null>(
      AccessTokenRepository.STORAGE_KEY,
    )
    if (!val) {
      return null
    }
    // fetch
    return val as AccessToken
  }

  /**
   * save
   *
   * @param token - AccessToken
   */
  public async save(token: AccessToken): Promise<AccessToken> {
    const ret = await this._storage.setItem<AccessToken>(
      AccessTokenRepository.STORAGE_KEY,
      token,
    )
    return ret
  }

  /**
   * remove
   */
  public async remove(): Promise<void> {
    const ret = await this._storage.removeItem(
      AccessTokenRepository.STORAGE_KEY,
    )
    return ret
  }
}
