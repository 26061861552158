import {createSelector} from 'reselect'
import {IReduxState} from '../'
import {EntitiesState} from '../api/entities'
import {TreeSchema} from '../api/schemata'
import {getAll, getEntities} from '../api/selectors'
import {NoteState} from './index'

/**
 * getState
 *
 * @param state - ReduxState
 */
export const getState = (state: IReduxState) => state.note

export const getFilterQuery = createSelector(
  getState,
  (state: NoteState) => {
    const {filterQuery} = state
    return filterQuery
  },
)

export const getFilteredNoteFields = createSelector(
  getState,
  getEntities,
  (state: NoteState, entities: EntitiesState) => {
    const {filteredTreeIds} = state
    return getAll(entities, TreeSchema, filteredTreeIds)
  },
)
