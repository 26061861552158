import {UseCaseEvent, UseCaseEventUseCaseFailed} from './lib/types'

/**
 * getErrorByUseCseAction
 *
 * @param event - UseCaseEvent
 */
export function getErrorByUseCseEvent(event: UseCaseEvent): Error | undefined {
  return (event as UseCaseEventUseCaseFailed).error
}
