import * as React from 'react'
import {SyntheticEvent} from 'react'
import styled from 'styled-components'
import ImageUploadField from '../../../containers/ImageUploadField'
import {ReduxField} from '../../../hocs/withReduxField'
import {ResourceType} from '../../../infra/api/types'
import {FormsType, minLength, required} from '../../../services/forms'
import {spacing} from '../../../styles/mixins/spacing'
import space from '../../../styles/variables/space'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import Space, {SpaceProps} from '../../layouts/Space'
import LabeledInput from '../../molecules/LabeledInput'

const ICON_WIDTH = 240
const ICON_HEIGHT = 240

const View = styled.div`
  ${spacing};
  width: 100%;
`
const Content = styled.div`
  margin-top: ${space.lg}px;
  text-align: center;
`
const ButtonOuter = styled.div`
  margin-top: ${space.lg}px;
  width: 100%;
  text-align: center;
`
const Controls = styled.div`
  margin-top: ${space.md}px;
`

const ImageOuter = styled.div`
  margin-top: ${space.md}px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`

export interface IFormProps {
  submitForm: (e: SyntheticEvent<any>) => void
  submitDisabled: boolean
}

export interface IFormData {
  fullname: string
  nickname: string
  iconUrl: string
}

export type Props = {
  invisibleIconField?: boolean
  isDirty: boolean
  isProcessing: boolean
  handleSubmit: (event: any) => any
  initialValues: IFormData
  form: FormsType
  resourceType: ResourceType
} & SpaceProps &
  IFormProps

const minLen = minLength(2)

export default function UpdateProfileForm(props: Props) {
  return (
    <View {...props}>
      <Content>
        <form
          autoComplete="off"
          onSubmit={props.handleSubmit(props.submitForm)}
        >
          {!props.invisibleIconField && (
            <ImageOuter>
              <ImageUploadField
                form={props.form}
                formField="iconUrl"
                height={ICON_HEIGHT}
                label="アカウント画像"
                resourceType={props.resourceType}
                url={props.initialValues.iconUrl}
                width={ICON_WIDTH}
              />
            </ImageOuter>
          )}

          <Controls>
            <ReduxField
              component={LabeledInput}
              label="お名前（フルネーム）"
              name="fullname"
              placeholder="あなたのフルネームを入力する"
              validators={[required, minLen]}
            />
          </Controls>

          <Controls>
            <ReduxField
              component={LabeledInput}
              label="ニックネーム"
              name="nickname"
              placeholder="あなたのニックネームを入力する"
            />
          </Controls>

          <ButtonOuter>
            <Button
              disabled={props.isProcessing || !props.isDirty}
              expanded={true}
              size="large"
            >
              変更する
              <Space ml={space.xs}>
                {props.isProcessing ? (
                  <Loading size={18} />
                ) : (
                  <Icon type="user" />
                )}
              </Space>
            </Button>
          </ButtonOuter>
        </form>
      </Content>
    </View>
  )
}
