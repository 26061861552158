import * as React from 'react'
import {AvatarSize} from '../../atoms/Avatar'
import Paper from '../../atoms/Paper'
import Body from '../../atoms/Body'
import TimelineHeader from '../../molecules/TimelineHeader'

export interface Props {
  avatarSrc: string
  date: string
  message: React.ReactNode
  content?: string
}

export default function NoteTimelineMessage(props: Props) {
  const {avatarSrc, content, date, message, ...rest} = props
  return (
    <Paper {...rest}>
      <TimelineHeader
        avatarSize={AvatarSize.small}
        avatarSrc={avatarSrc}
        date={date}
        title={message}
      />
      {content && (
        <>
          <Body mt="xs">{content}</Body>
          <Body as="span" maxLength={50} mt="xs">もっと見る</Body>
        </>
      )}
    </Paper>
  )
}
