import NotificationManager from '../../infra/app/NotificationManager'
import {INotificationManager} from '../../services/app/INotificationManager'
import UseCase from '../lib/UseCase'

interface Props {
  noticeManager: INotificationManager
}

interface Params {
  id: string
}

export default class DismissNotificationUseCase extends UseCase {
  private _noticeManager: INotificationManager

  public constructor(props: Props) {
    super()
    this._noticeManager = props.noticeManager
  }

  public static create() {
    return new DismissNotificationUseCase({
      noticeManager: NotificationManager.create(),
    })
  }

  public async execute({id}: Params): Promise<void> {
    if (id) {
      this._noticeManager.dismiss(id)
    }
  }
}
