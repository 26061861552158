import {getFormValues, isInvalid} from 'redux-form'
import {createSelector} from 'reselect'
import {IFormData} from '../../components/organisms/ResetPassowrdForm'
import {resetPasswordForm} from '../../services/forms'

export const getValues = createSelector(
  getFormValues(resetPasswordForm.id) as any /*FIXME*/,
  (values: IFormData) => {
    const {newPassword = ''} = values || {}
    return {newPassword}
  },
)

export const isSubmitDisabled = createSelector(
  isInvalid(resetPasswordForm.id),
  (formIsInvalid: boolean) => {
    return formIsInvalid
  },
)
