import style from './style'

export const borderWidth = style({prop: 'borderWidth', themeKey: 'border'})
export const borderColor = style({prop: 'borderColor', themeKey: 'border'})
export const borderStyle = style({prop: 'borderStyle', themeKey: 'border'})
export const borderRadius = style({prop: 'borderRadius', themeKey: 'border'})
export const borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  themeKey: 'border',
})
export const borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  themeKey: 'border',
})
export const borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  themeKey: 'border',
})
export const borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  themeKey: 'border',
})
