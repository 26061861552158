import * as React from 'react'
import {useCallback, useState} from 'react'
import View from '../../components/organisms/UserNotificationsButton'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import FetchUserTimelineUseCase from '../../useCases/account/FetchUserTimelineUseCase'
import PushNavigationUseCase from '../../useCases/app/PushNavigationUseCase'

export default function UserNotificationsButton() {
  const [isShowModal, setShowModal] = useState(false)
  const onClickButton = useCallback(() => {
    setShowModal(!isShowModal)
    UseCaseCommander.create().command(FetchUserTimelineUseCase)()
  }, [isShowModal])

  const hideModal = useCallback(() => {
    isShowModal && setShowModal(false)
  }, [isShowModal])

  const handleClickMore = useCallback(() => {
    UseCaseCommander.create().command(PushNavigationUseCase)({
      routeKey: 'userNotifications',
    })
    setShowModal(false)
  }, [])

  return React.createElement(View, {
    onClickButton,
    isShowModal,
    hideModal,
    onClickMore: handleClickMore,
  })
}
