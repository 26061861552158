import * as React from 'react'
import styled from 'styled-components'
import LoginForm from '../../../containers/LoginForm'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import container from '../../../styles/mixins/container'
import media from '../../../styles/mixins/media'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Icon from '../../atoms/Icon'
import Paper from '../../atoms/Paper'

const View = styled.div`
  padding-top: ${space.lg}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

const Header = styled.header`
  text-align: center;
`

const Outer = styled.div``

const Panel = styled(Paper)`
  ${container};
  padding-top: ${space.xl}px;
  padding-bottom: ${space.xl}px;
  width: 100%;
  ${media.tablet`
    max-width: 500px;
  `};
`

const CreateAccount = styled.div`
  ${container};
  text-align: center;
`

export default function LoginPage() {
  return (
    <View>
      <Panel>
        <Header>
          <Heading size={'heading1'}>ログイン</Heading>
        </Header>
        <Outer>
          <LoginForm />
        </Outer>
      </Panel>

      <CreateAccount>
        <Body mt="xl">
          まだアカウントをお持ちでない方は<br />アカウントを作成しましょう。
        </Body>
        <PushNavigationLink
          routeKey="signUp"
          view={props => (
            <Button
              backgroundColor={colors.success}
              mt="md"
              type="button"
              {...props}
            >
              新規アカウント作成
              <Icon ml="xs" type="user-plus" />
            </Button>
          )}
        />
      </CreateAccount>
    </View>
  )
}
