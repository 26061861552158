import INoteInvitationCodeRepository from '../../../domain/note/INoteInvitationCodeRepository'
import NoteInvitationCode from '../../../domain/note/NoteInvitationCode'
import AppAPI from '../../api/AppAPI'
import * as noteTranslators from '../../api/translators/note'
import {actions} from '../../redux/api/entities'
import {NoteInvitationCodeSchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteInvitationCodeRepository
  implements INoteInvitationCodeRepository {
  private api: AppAPI
  private redux: ReduxProvider

  public static create(): NoteInvitationCodeRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteInvitationCodeRepository({api, redux})
  }

  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async getInvitationCode(noteId: string): Promise<NoteInvitationCode> {
    const client = await this.api.getClient()
    const res = await client.getNoteInvitationCode({noteId})
    const entity = noteTranslators.toNoteInvitationCode(res)
    this.redux.dispatch(actions.store(entity, NoteInvitationCodeSchema))
    return entity
  }

  // refreshInvitationCode(noteId: string): Promise<NoteInvitation>
  public async refreshInvitationCode(
    noteId: string,
  ): Promise<NoteInvitationCode> {
    const client = await this.api.getClient()
    const res = await client.refreshNoteInvitationCode({noteId})
    const entity = noteTranslators.toNoteInvitationCode(res)
    this.redux.dispatch(actions.store(entity, NoteInvitationCodeSchema))
    return entity
  }
}
