import * as React from 'react'
import {useRef} from 'react'
import ReactDOM from 'react-dom'
import {Transition} from 'react-transition-group'
import styled from 'styled-components'
import {useRootElement} from '../../../selectors/app/dom'
import {TransitionState} from '../../../styles'
import {borderRadius} from '../../../styles/mixins/border'
import transition from '../../../styles/mixins/transition'
import useMediaQuery from '../../../styles/useMediaQuery'
import useWindowHeight from '../../../styles/useWindowHeight'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'

const View = styled.div<TransitionState & {isPhone: boolean}>`
  z-index: ${zIndex.dialog};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  ${transition()};
  //opacity: ${p => (p.state === 'entered' ? 1 : 0)};
  ${p =>
    !p.isPhone &&
    `
     opacity: ${p.state === 'entered' ? 1 : 0};
  `};
`

const Bg = styled.div<TransitionState>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  ${transition()};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
`

const Window = styled.div<TransitionState & {isPhone: boolean; height: number}>`
  z-index: 10;
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  transform: ${({isPhone, state}) => {
    const y = isPhone ? '100%' : '40%'
    switch (state) {
      case 'entering':
        return `translateY(${y})`
      case 'entered':
        return 'translateY(0%)'
      case 'exiting':
        return `translateY(${y})`
      case 'exited':
        return `translateY(${y})`
      default:
        return 'translateY(0%)'
    }
  }};

  ${p =>
    p.isPhone
      ? `
    position: fixed;
    width: 100vw;
    height: ${p.height}px;
    max-height: ${p.height}px;
    ${transition({property: 'transform'})};
  `
      : `
    max-height: ${p.height}px;
    width: auto;
    min-width: 400px;
    max-width: 600px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.14);
    ${transition({property: 'transform'})};
  `}
`

const Content = styled.div<{isPhone: boolean}>`
  overflow-y: auto;
  ${p =>
    p.isPhone
      ? `
  // padding: ${space.sm}px ${space.md}px;
  `
      : `
  padding: ${space.sm}px ${space.md}px;
  `};
  ${borderRadius};

  height: 100%;

  background-color: #fff;
`

export interface Props {
  transitionIn: boolean
  children?: React.ReactNode
  dismiss?: () => void
}

// TODO: 共通化
export default function NoteModal({children, dismiss, transitionIn}: Props) {
  const windowElement = useRef<HTMLDivElement>(null)
  const rootElement = useRootElement()
  const windowHeight = useWindowHeight()
  const isPhone = !useMediaQuery('tablet')
  return ReactDOM.createPortal(
    <Transition
      in={transitionIn}
      mountOnEnter={true}
      timeout={transitionIn ? 0 : 300}
      unmountOnExit={true}
    >
      {state => (
        <View isPhone={isPhone} state={state}>
          <Bg onClick={dismiss} state={state} />
          <Window
            height={windowHeight}
            isPhone={isPhone}
            ref={windowElement}
            state={state}
          >
            <Content isPhone={isPhone}>{children}</Content>
          </Window>
        </View>
      )}
    </Transition>,
    rootElement,
  )
}
