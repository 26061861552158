import {UseCaseConstructor} from '../../../useCases/lib/types'
import ChangeNoteSlideUseCase from '../../../useCases/app/ChangeNoteSlideUseCase'
import CloseSettingsModalUseCase from '../../../useCases/app/CloseSettingsModalUseCase'
import DismissErrorDialogUseCase from '../../../useCases/app/DismissErrorDialogUseCase'
import DismissNotificationUseCase from '../../../useCases/app/DismissNotificationUseCase'
import HideModalUseCase from '../../../useCases/app/HideModalUseCase'
import UpdateCurrentNoteIdUseCase from '../../../useCases/app/UpdateCurrentNoteIdUseCase'

const useCaseBlackListMap = [
  ChangeNoteSlideUseCase,
  CloseSettingsModalUseCase,
  DismissErrorDialogUseCase,
  DismissNotificationUseCase,
  HideModalUseCase,
  UpdateCurrentNoteIdUseCase,
].reduce((map: KeySignature, useCase: UseCaseConstructor) => {
  map[useCase.name] = true
  return map
}, {})

export const isUseCaseNoLogging = (UseCase: UseCaseConstructor): boolean =>
  useCaseBlackListMap[UseCase.name] || false
