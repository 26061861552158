import * as React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {getFormValues} from 'redux-form'
import FormedImageField, {
  Props,
} from '../../components/molecules/FormedImageField'
import {ResourceType} from '../../infra/api/types'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {NoteLeafComponentProps} from '../../components/helpers/snapshot'
import {getResourceUploadToken} from '../../selectors/api'
import RemoveNoteResourceUseCase, {
  Params as RemoveNoteResourceUseCaseParams,
} from '../../useCases/resource/RemoveNoteResourceUseCase'
import UploadNoteResourceUseCase from '../../useCases/resource/UploadNoteResourceUseCase'

type StateProps = Props & {
  name: string
  token: string
}

export default compose<Props, NoteLeafComponentProps>(
  connect(
    (state: IReduxState, ownProps: Props): StateProps => {
      const {form, name} = ownProps
      const token = getResourceUploadToken(state)
      const formValues = getFormValues(form)(state)
      return {
        ...ownProps,
        formValues,
        name,
        token,
        isProcessing: useCaseSelectors.isProcessing(
          UploadNoteResourceUseCase,
          'FormedImageField',
        )(state),
      }
    },
    {},
    props => {
      const {form, name, token, onClickCancel} = props
      return {
        ...props,
        onClickRemove: () => {
          UseCaseCommander.create().command<RemoveNoteResourceUseCaseParams>(
            RemoveNoteResourceUseCase,
          )({
            form,
            field: name,
            type: ResourceType.note,
          })
        },
        onClickCancel: () => {
          UseCaseCommander.create().command(RemoveNoteResourceUseCase)({
            form,
            field: name,
            type: ResourceType.note,
          })
          onClickCancel()
        },
        onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files) {
            return
          }
          UseCaseCommander.create().command(
            UploadNoteResourceUseCase,
            'FormedImageField',
          )({
            form,
            field: name,
            type: ResourceType.note,
            token,
            file: e.target.files && e.target.files.item(0),
          })
        },
      }
    },
  ),
)(FormedImageField)
