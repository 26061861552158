import * as React from 'react'
import styled from 'styled-components'
import {getValue as getCheckboxRadioHistoryItemValue} from '../../../components/molecules/CheckboxHistoryItem'
import {getValue as getTextHistoryItemValue} from '../../../components/molecules/TextHistoryItem'
import {Props} from '../../../containers/NoteHistoryItem'
import NoteHistoryItemLayout from '../../../containers/NoteHistoryItemLayout'
import {
  hasSomeFieldData,
  TreeLeafTypes,
} from '../../../domain/note/snapshot/Tree'
import Code from '../../atoms/Code'

const PrevCode = styled(Code)`
  color: #b30000;
  background: #fadad7;
`

const getValue = (items: KeySignature, data: KeySignature, name: string) => {
  const ret =
    data &&
    Object.keys(items).map(key => {
      let value = null
      const attr = items[key] || {}
      const {label = '', type = 'text', options = {}} = attr
      switch (type) {
        case TreeLeafTypes.checkbox:
        case TreeLeafTypes.radio:
          const [first] = getCheckboxRadioHistoryItemValue(options, data, name)
          value = first
          break
        default:
          value = getTextHistoryItemValue(key, data, attr)
          break
      }
      return (
        value && (
          <div key={key}>
            {label}: {value}
          </div>
        )
      )
    })
  return ret
}

export default function MultiHistoryItem(props: Props) {
  const {previous, data, name, ...rest} = props
  const {attr: {items = {}} = {}} = props
  const hasPrevious = hasSomeFieldData(previous, items)
  const hasData = hasSomeFieldData(data, items)
  const prevData = hasPrevious && (
    <PrevCode>{getValue(items, PrevCode, name)}</PrevCode>
  )
  const currentData = hasData && <Code>{getValue(items, data, name)}</Code>
  return (
    <NoteHistoryItemLayout
      currentData={currentData}
      name={name}
      prevData={prevData}
      {...rest}
    />
  )
}
