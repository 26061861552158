import {isInvalid} from 'redux-form'
import {createSelector} from 'reselect'
import {Forms} from '../../services/forms'

export const isSubmitDisabled = createSelector(
  isInvalid(Forms.note),
  (formIsInvalid: boolean) => {
    return formIsInvalid
  },
)
