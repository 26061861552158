import {passwordMaxLengthValidator, passwordMinLengthValidator} from '../../domain/auth/Password'
import * as validators from '../../lib/validators'
import {injectMessage} from '../../lib/validators/utils'

export const required = injectMessage('必須項目です', validators.required)

/**
 * minLength
 *
 * @param min - min
 */
export const minLength = (min: number) =>
  injectMessage(
    (rule: {min: number}) => `入力が短すぎます。${rule.min}文字以上です`,
    validators.minLength(min),
  )

/**
 * maxLength
 *
 * @param max - max
 */
export const maxLength = (max: number) =>
  injectMessage(
    (rule: {max: number}) => `入力が長すぎます。${rule.max}文字以内です`,
    validators.maxLength(max),
  )

export const passwordMinLength = injectMessage(
  (rule: {min: number}) => `入力が短すぎます。${rule.min}文字以上です`,
  passwordMinLengthValidator,
)
export const passwordMaxLength = injectMessage(
  (rule: {max: number}) => `入力が長すぎます。${rule.max}文字以内です`,
  passwordMaxLengthValidator,
)

export const emailFormat = injectMessage(
  'メールアドレスを正しく入力してください',
  validators.emailFormat,
)

export const dateDayFormat = injectMessage(
  '日付を正しい形式で入力してください(例 2000-01-01)',
  validators.dateDayFormat,
)

export const dateDayISOFormat = injectMessage(
  '正しい日付を入力してください',
  validators.dateDayISOFormat,
)

// 空文字もOK
export const optionalDateTimeFormat = injectMessage(
  '時刻を正しい形式で入力してください(例 00:00)',
  validators.optionalDateTimeFormat,
)

// 空文字もOK
export const optionalDateTimeISOFormat = injectMessage(
  '正しい時刻を入力してください',
  validators.optionalDateTimeISOFormat,
)

export const Forms = {
  account: 'account',
  comment: 'comment',
  timelineComment: (timelineId: string) => `timelineComment-${timelineId}`,
  diaryComment: (trackId: string) => `diaryComment-${trackId}`,
  track: (trackId: string) => `track-${trackId}`,
  noteTimeline: 'noteTimeline',
  note: 'note',
  noteAttrs: 'noteAttrs',
  updateProfile: 'updateProfile',
  login: 'login',
  signUp: 'signup',
  requestResetPassword: 'requestResetPassword',
  resetPassword: 'resetPassword',
}

export type FormsType = keyof typeof Forms

export const loginForm = {
  id: 'login',
}

export const signUpForm = {
  id: 'signup',
}

export const resetPasswordForm = {
  id: 'resetPassword',
}
