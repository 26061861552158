import * as React from 'react'
import styled from 'styled-components'
import Helmet from '../../../containers/Helmet'
import Nav from '../../../containers/Nav'
import {RouteKey} from '../../../routes'
import Header from '../../organisms/Header'

const View = styled.div``

const Content = styled.div``

export interface HeaderProps {
  title?: string
}

interface Props {
  back?: boolean | RouteKey
  title?: string
  titleTag?: string
  header?: React.ComponentType<HeaderProps>
  nav?: React.ComponentType
  children?: React.ReactNode
  allowRobots?: boolean
}

// fixme: name
export default function DefaultPage(props: Props) {
  const {back, header, children, nav, title, titleTag, allowRobots} = props
  const CustomHeader = header || Header
  const CustomMenu = nav || Nav
  return (
    <View>
      <Helmet allowRobots={allowRobots} title={titleTag || title} />

      <CustomMenu />
      <CustomHeader back={back} title={title} />

      <Content>{children}</Content>
    </View>
  )
}
