export const zIndex = {
  indicator: 9000,
  errorDialog: 8500,
  dialog: 8000,
  notification: 7500,
  modal: 7000,
  header: 6000,
  nav: 5000,
  menu: 4000,
}
