import * as React from 'react'
import styled from 'styled-components'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Paper from '../../atoms/Paper'
import {replaceNewLine} from '../../helpers'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

const Column = styled(Col)`
  display: flex;
`

export interface StatusItemProps {
  iconType: string
  label?: string
}

export interface Props {
  list: StatusItemProps[]
}

export default function StatusBar(props: Props) {
  const {list, ...rest} = props
  const items = list.map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.iconType && (
          <Col mt="sm">
            <Row align="center" gutter={2}>
              {item.iconType && (
                <Column>
                  <Icon size={17} type={item.iconType} />
                </Column>
              )}
              <Column>
                <Body fontSize={8} lineHeight={1.0}>
                  {replaceNewLine(item.label)}
                </Body>
              </Column>
            </Row>
          </Col>
        )}
      </React.Fragment>
    )
  })
  return (
    <Paper {...rest}>
      <Row align="center" gutter={space.md} mt={-space.sm}>
        {items}
      </Row>
    </Paper>
  )
}
