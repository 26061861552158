import * as React from 'react'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {defaultBreakpoints} from '../../../styles/mixins/breakpoints'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Paper from '../../atoms/Paper'
import ToggleButton from '../../atoms/ToggleButton'
import Col from '../../layouts/Col'
import Modal, {Props as ViewProps} from '../../layouts/Modal'
import Row from '../../layouts/Row'
import ToggleButtonGroup from '../../molecules/ToggleButtonGroup'

const View = styled(Paper)`
  width: 90vw;

  ${defaultBreakpoints.up('tablet')} {
    max-width: 300px;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const FeelingIcon = styled(Icon)`
  font-size: 40px;
`

export interface ToggleButtonItemProps {
  value: string
  iconType: string
  label: string
}

export type Props = ViewProps & {
  content: string
  trackId?: string
  timestamp?: string
  isProcessing: boolean
  list: ToggleButtonItemProps[]
  onSubmit: (value: string) => void
}

export default function PostTrackToggleIconsModal(props: Props) {
  const {content, dismiss, list, isProcessing, onSubmit} = props
  const [value, setValue] = useState(content)
  useEffect(() => setValue(content), [content])
  const items = list.map(item => (
    <ToggleButton col={12 / list.length} key={item.value} value={item.value}>
      <FeelingIcon type={item.iconType} />
      <Body type="caption">{item.label}</Body>
    </ToggleButton>
  ))
  const handleDismiss = () => {
    setValue(content)
    dismiss && dismiss()
  }
  const handleChange = (e: any, v: any) => {
    setValue(v)
  }
  const handleSubmit = () => {
    onSubmit(value)
  }
  return (
    <Modal {...props} dismiss={handleDismiss} paper={false}>
      <View>
        <Center>
          <ToggleButtonGroup
            align="center"
            exclusive
            onChange={handleChange}
            value={value}
          >
            {items}
          </ToggleButtonGroup>
        </Center>
        <Row justify="center" mt="lg">
          <Col>
            <Button
              backgroundColor={colors.gray400}
              onClick={handleDismiss}
              type="button"
            >
              キャンセル
            </Button>
          </Col>
          <Col>
            <Button
              disabled={props.isProcessing}
              onClick={handleSubmit}
              type="button"
            >
              {isProcessing ? '送信中...' : '投稿する'}
            </Button>
          </Col>
        </Row>
      </View>
    </Modal>
  )
}
