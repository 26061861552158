import * as React from 'react'
import styled from 'styled-components'
import DiaryHeader from '../../../containers/DiaryHeader'
import NoteNav from '../../../containers/NoteNav'
import NoteTrackList from '../../../containers/NoteTrackList'
import {NoteTrackStatusBar} from '../../../containers/NoteTrackStatusBar'
import {defaultBreakpoints} from '../../../styles/mixins/breakpoints'
import {containerStyle} from '../../../styles/mixins/container'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Paper, {paperMarginTop} from '../../atoms/Paper'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import TrackActions from '../../organisms/TrackActions'
import DefaultPage from '../../templates/DefaultPage'

const View = styled.div`
  padding-bottom: ${space.xxl * 2}px;
  overflow: hidden;
  ${defaultBreakpoints.up('tablet')} {
    ${containerStyle('tablet')};
  }
`

const CalenderNavigation = styled(Row)``
const PrevCalenderButton = styled(Col)`
  cursor: pointer;
`
const TodayCalenderButton = styled(Col)`
  cursor: pointer;
`
const NextCalenderButton = styled(Col)`
  cursor: pointer;
`

const Actions = styled(Paper)`
  padding-left: 0;
  padding-right: 0;
  z-index: 1000;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
`

export interface Props {
  title: string
  editable: boolean
  prevDate: string
  nextDate: string
  onClickPrevDate: () => void
  onClickToday: () => void
  onClickNextDate: () => void
}

export default function NoteTrackPage(props: Props) {
  const {
    editable,
    title,
    prevDate,
    onClickPrevDate,
    nextDate,
    onClickNextDate,
    onClickToday,
  } = props
  return (
    <DefaultPage back={true} header={DiaryHeader} nav={NoteNav} title={title}>
      <View>
        {editable && (
          <Actions mt={paperMarginTop}>
            <TrackActions />
          </Actions>
        )}
        <CalenderNavigation gutter={space.xxs} mt="xxs">
          <PrevCalenderButton col={4.5} onClick={onClickPrevDate}>
            <Paper>
              <Row
                align="center"
                flexWrap="nowrap"
                gutter={space.xxs}
                justify="center"
              >
                <Col>
                  <Icon size={16} type="arrow-left" />
                </Col>
                <Col>
                  <Body type="body2">{prevDate}</Body>
                </Col>
              </Row>
            </Paper>
          </PrevCalenderButton>
          <TodayCalenderButton col={3} onClick={onClickToday}>
            <Paper>
              <Row align="center" justify="center">
                <Col>
                  <Body type="body2">今日</Body>
                </Col>
              </Row>
            </Paper>
          </TodayCalenderButton>
          <NextCalenderButton col={4.5} onClick={onClickNextDate}>
            <Paper>
              <Row
                align="center"
                flexWrap="nowrap"
                gutter={space.xxs}
                justify="center"
              >
                <Col>
                  <Body type="body2">{nextDate}</Body>
                </Col>
                <Col>
                  <Icon size={16} type="arrow-right" />
                </Col>
              </Row>
            </Paper>
          </NextCalenderButton>
        </CalenderNavigation>
        <NoteTrackStatusBar mt="xxs" />
        <NoteTrackList />
      </View>
    </DefaultPage>
  )
}
