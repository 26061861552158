import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/molecules/LightboxImages'
import {getResourceLatestItem} from '../../selectors/api/resource'

interface Props {
  name: string
  src: string
  width?: number | string
  height?: number | string
  hasLightbox?: boolean
  onLoaded?: () => void
}

// TODO: 複数対応
export default function ResourceImage(props: Props) {
  const {name, onLoaded, width, hasLightbox, height, src} = props
  const {url: newUrl = ''} = useSelector(getResourceLatestItem(name)) || {}
  const sources = [newUrl || src]
  return React.createElement(View, {
    sources,
    width,
    height,
    hasLightbox,
    onLoaded,
  })
}
