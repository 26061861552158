import * as React from 'react'
import styled from 'styled-components'
import BackButton from '../../../containers/BackButton'
import HamburgerButton from '../../../containers/HamburgerButton'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import HeaderLayout from '../../layouts/HeaderLayout'
import Row from '../../layouts/Row'
import LabeledIcon from '../../molecules/LabeledIcon'

const ExternalLink = styled.a`
  color: ${colors.body};
`

export interface Props {
  url: string
  onClickHistoryButton: () => void
}

export default function NoteHeader(props: Props) {
  const {url, onClickHistoryButton} = props
  const right = (
    <Row gutter={space.md - 1}>
      {url && (
        <Col>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <ExternalLink href={url} target="_blank">
            <LabeledIcon type="heart-pulse">たすけてQR</LabeledIcon>
          </ExternalLink>
        </Col>
      )}
      <Col>
        <LabeledIcon onClick={onClickHistoryButton} type="history">
          履歴
        </LabeledIcon>
      </Col>
      <Col>
        <HamburgerButton />
      </Col>
    </Row>
  )
  return (
    <HeaderLayout left={<BackButton routeKey="noteCover" />} right={right} />
  )
}
