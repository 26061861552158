import Note from '../../../domain/note/Note'
import NoteEmergency from '../../../domain/note/NoteEmergency'
import NoteEmergencyCode from '../../../domain/note/NoteEmergencyCode'
import NoteInvitation from '../../../domain/note/NoteInvitation'
import NoteInvitationCode from '../../../domain/note/NoteInvitationCode'
import {NoteMailNotifications} from '../../../domain/note/notifications/NoteMailNotificationType'
import {
  NoteResponse,
  NotesEmergencyCodeResponse,
  NotesEmergencyResponse,
  NotesInvitationCodeResponse,
  NotesInvitationResponse,
  NotesRelationMeMailTypeResponse,
} from '../types'

export const toNotes = (response: NoteResponse[]): Note[] => {
  return response.map(res => {
    return {...res} as Note
  })
}

export function noteInvitationResponseToEntity(
  response: NotesInvitationResponse,
  token: string,
): NoteInvitation {
  return {
    ...response,
    token, // idAttribute of NoteInvitationSchema Entity
  }
}

export const toNoteInvitationCode = (
  response: NotesInvitationCodeResponse,
): NoteInvitationCode => {
  return response
}

export const toNoteEmergencyCode = (
  response: NotesEmergencyCodeResponse,
): NoteEmergencyCode => {
  return response
}

export const toNoteEmergency = (
  response: NotesEmergencyResponse,
): NoteEmergency => {
  return response
}

export const toNoteMailNotificationTypes = (
  response: NotesRelationMeMailTypeResponse[],
): NoteMailNotifications => {
  return Object.keys(response).map(key => {
    const {type, help, label} = response[key]
    return {type, help, label}
  })
}
