import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View, {Props} from '../../components/molecules/LabeledIcon'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import RefreshTimelineUseCase from '../../useCases/timeline/RefreshTimelineUseCase'

export default function NoteCoverRefreshButton() {
  const noteId = useSelector(getCurrentNoteId)
  const onClick = useCallback(() => {
    UseCaseCommander.create().command(RefreshTimelineUseCase)({noteId})
  }, [noteId])
  return React.createElement<Props>(
    View,
    {
      title: 'ノートのタイムラインを更新します',
      type: 'refresh',
      onClick,
    },
    '更新',
  )
}
