import {css} from 'styled-components'
import {getThemeValue} from '../variables'

export const fontTracking = (tracking = 0) => {
  const ls = tracking / 1000
  return css`
    letter-spacing: ${ls}em;
  `
}

export const getFontSize = (size: number | string = 10) => {
  return typeof size === 'number'
    ? `${size / 10}rem`
    : getThemeValue({
        themeKey: 'typography',
        path: `${size}.fontSize`,
      }) || size
}

export const fontSize = (size: number | string = 10) => {
  const fz = getFontSize(size)
  return css`
    font-size: ${fz};
  `
}

export const font = (size: number | string, lin = 1.0, tracking = 0) => {
  const rem = getFontSize(size)
  const linS = typeof size === 'number' ? (size * lin) / size : lin
  return css`
    font-size: ${rem};
    line-height: ${linS};
    ${fontTracking(tracking)}
  `
}
