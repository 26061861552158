import * as React from 'react'
import {useSelector} from 'react-redux'
import {AccountListItem} from '../../components/organisms/AccountList'
import View from '../../components/organisms/AccountPage'
import {ModalType} from '../../infra/redux/app/types'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import HideModalUseCase from '../../useCases/app/HideModalUseCase'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from '../../useCases/app/PushNavigationUseCase'

export default function AccountPage() {
  const {email = ''} = useSelector(getMe) || {}
  const list: AccountListItem[] = [
    {
      icon: 'user',
      content: 'アカウント情報の変更',
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'updateProfile',
        })
      },
    },
    {
      icon: 'envelope',
      content: 'メールアドレスの変更（サポートにご連絡ください)',
      value: email,
    },
    {
      icon: 'key-hole',
      content: 'パスワードの変更',
      onClick: () => {
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({
          routeKey: 'requestResetPassword',
        })
      },
    },
  ]
  return React.createElement(View, {
    list,
    handleClickClose: () => {
      UseCaseCommander.create().command(HideModalUseCase)({
        type: ModalType.account,
      })
    },
  })
}
