import {createSelector} from 'reselect'
import {getMe} from '../api'

export const hasNoteCreatable = createSelector(
  getMe,
  (me: KeySignature): boolean => {
    const {capability: {totalOwnableNotes = 0} = {}} = me || {}
    const {ownedNotes = []} = me || {}
    return totalOwnableNotes > ownedNotes.length
  },
)
