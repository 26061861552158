import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/atoms/BackButton'
import {RouteKey} from '../../routes'
import {getCurrentNoteId} from '../../selectors/note'
import Navigation from '../../services/navigation/Navigation'

export interface Props {
  routeKey?: RouteKey
  [key: string]: any
}

export default function BackButton(props: Props) {
  const noteId = useSelector(getCurrentNoteId)
  const {routeKey, ...params} = props
  return React.createElement(View, {
    onClick: () => {
      routeKey
        ? Navigation.create().push(routeKey, {noteId, ...params})
        : Navigation.create().goBackOrPush('index')
    },
  })
}
