import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'
import ToggleMenuUseCase from './ToggleMenuUseCase'

interface Props {
  navigation: Navigation
}

export interface PushSearchNavigationUseCaseParams {
  [key: string]: any
}

/**
 * SignUpUseCase
 */
export default class PushNavigationUseCase extends UseCase {
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new PushNavigationUseCase({
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - PushNavigationUseCaseParams
   */
  public async execute(
    params: PushSearchNavigationUseCaseParams,
  ): Promise<void> {
    this.subUseCaseExecutor(ToggleMenuUseCase)({hide: true})
    this._navigation.pushSearch(params)
  }
}
