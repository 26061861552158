import {ApiErrorType} from './types'

/**
 * APIError
 */
export default class APIError extends Error {
  public error: string

  public constructor({error}: {error: any}) {
    super(error)
    this.error = error
  }

  /**
   * 認証エラー
   */
  public get unauthorized(): boolean {
    return this.error === ApiErrorType.unauthorized
  }

  // TODO:
  public get invalidAccount(): boolean {
    return (
      this.error === ApiErrorType.invalid ||
      this.error === ApiErrorType.suspended ||
      this.error === ApiErrorType.verifying
    )
  }
}
