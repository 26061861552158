import {RelationType, TrackRelationType} from '../../infra/api/types'
import ContactableUser from '../account/ContactableUser'
import {NoteMailNotificationType} from './notifications/NoteMailNotificationType'
import Snapshot from './snapshot/Snapshot'
import NoteTimeline from './timeline/NoteTimeline'

export interface NoteAttributesProps {
  title: string
  personName: string
  imageUrl: string
  personImageUrl: string
}

export interface NoteRelationProps {
  type: RelationType
  trackType: TrackRelationType
  mailTypes: NoteMailNotificationType[]
}

export type NotePropsBase = {
  id: string
  relationToMe: NoteRelationProps
} & NoteAttributesProps

export interface OwnedNoteProps {
  numMembers: number
  numRequests: number
  needsHelpGuide: boolean // ISSUE: 汎用的なチュートリアルシステムができたら機能置き換え
}

export interface MembersProps {
  members: ContactableUser[]
  membersRelationMap: {[userId: string]: NoteRelationProps}
}

export interface RequestingUsersProps {
  requestingUsers: ContactableUser[]
}

export interface SnapshotProps {
  snapshot: Snapshot
}

export interface TimelineProps {
  timeline: NoteTimeline
}

export default interface Note
  extends NotePropsBase,
    Partial<SnapshotProps>,
    Partial<OwnedNoteProps>,
    Partial<MembersProps>,
    Partial<RequestingUsersProps>,
    Partial<TimelineProps> {}

export const NullNoteObject: Note = {
  id: '',
  relationToMe: {
    type: RelationType.none,
    trackType: TrackRelationType.none,
    mailTypes: [],
  },
  title: '',
  personName: '',
  imageUrl: '',
  personImageUrl: '',
  numMembers: 0,
  numRequests: 0,
  needsHelpGuide: false,
  members: [],
  membersRelationMap: {},
  requestingUsers: [],
}

export const getNoteDetailEditableByRelationType = (
  relationType: RelationType,
): boolean => {
  const editableList = [RelationType.owner, RelationType.editor]
  return editableList.includes(relationType)
}

export const getNoteDetailEditable = (note: Note): boolean => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  return getNoteDetailEditableByRelationType(type)
}

export const getNoteEditableToMe = (note: Note): boolean => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  const editableList = [RelationType.owner, RelationType.editor]
  return editableList.includes(type)
}

export const getNoteCoverEditableToMe = (note: Note): boolean => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  const editableList = [RelationType.owner]
  return editableList.includes(type)
}

export const getNoteInvitableToMe = (note: Note) => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  const invitableList = [RelationType.owner]
  return invitableList.includes(type)
}

export const getNoteMembersEditableToMe = (note: Note) => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  const memberEditableList = [RelationType.owner]
  return memberEditableList.includes(type)
}

export const getNoteEmergencyCodeShowable = (note: Note): boolean => {
  const {relationToMe: {type} = {type: RelationType.none}} = note || {}
  const editableList = [RelationType.owner]
  return editableList.includes(type)
}
