import * as React from 'react'
import styled from 'styled-components'
import {ReduxFieldProps} from '../../../lib/form/types'
import {iconCss} from '../../../styles/fonts'
import {fontSize} from '../../../styles/mixins/font'
import {body1} from '../../../styles/variables'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

const View = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const LabelBody = styled.div`
  margin-left: ${space.xxs}px;
  width: 1%;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  color: inherit;
  ${fontSize(body1.fontSize)};
`

const Icon = styled.i<{content: string}>`
  display: inline-block;
  vertical-align: middle;
  color: ${colors.gray300};
  ${fontSize(body1.fontSize + 7)};

  ${iconCss}

  &::before {
    content: '${props => props.content}';
  }
`
const Input = styled.input`
  position: absolute;
  left: -9999px;
  opacity: 0;
`
const Label = styled.label<{checkContent: string}>`
  padding: 0;
  margin: 0;
  
  display: flex;
  align-items: flex-start;
  //flex-flow: row wrap;
  flex-flow: row wrap;
  
  cursor: pointer;

  ${Input}:checked + ${Icon} {
    color: ${colors.brand};

    &::before {
      content: '${props => props.checkContent}';
    }
  }
  ${Input}:disabled + ${Icon} {
    color: ${colors.gray300};
  }
`

const Control = styled.div`
  padding: 0;
  margin: 0;

  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
`

export type CheckboxRadioOption =
  | {
      label: string
      name?: string
      value?: string
    }
  | string

export type Props = {
  checked?: boolean
  label: string
  control?: React.ReactNode
} & ReduxFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>

/**
 * スイッチ
 * FIXME: redux-form 依存
 */
export default function CheckboxRadio({
  control,
  name,
  label,
  type = 'checkbox',
  ...rest
}: Props) {
  // FIXME: redux-formの型に依存したくない }: MarginProps & WrappedCustomFieldProps) {
  const checkContent = type === 'checkbox' ? '\\e999' : '\\e959'
  const content = type === 'checkbox' ? '\\e98d' : '\\e99d'
  return (
    <View>
      <Label checkContent={checkContent}>
        <Input name={name} type={type} {...rest} {...rest.input} />
        <Icon content={content} />
        <LabelBody>{label}</LabelBody>
      </Label>
      <Control>{control}</Control>
    </View>
  )
}
