import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import PostEditNoteTrack from '../../../containers/PostEditNoteTrack'
import {NoteTrackDataType} from '../../../domain/note/track/NoteTrack'
import {getModalState} from '../../../infra/redux/router/selectors'
import Modal, {Props as ModalProps} from '../../layouts/Modal'

export type Props = ModalProps & {
  trackId?: string
  type: NoteTrackDataType
}

export default function PostEditNoteTrackModal(props: Props) {
  const {dismiss, trackId, type} = props
  const statedModal = useSelector(getModalState)
  /*
    MEMO: useCallbackは使わない（push stateの値の変更によって関数が変更になる)
    useCallback使う場合は、push stateの値をdepsに設定する
  */
  const handleDismiss = useCallback(() => {
    dismiss && dismiss()
  }, [statedModal])
  return (
    <Modal {...props} dismiss={handleDismiss} paper={false}>
      <PostEditNoteTrack
        dismiss={handleDismiss}
        trackId={trackId}
        type={type}
      />
    </Modal>
  )
}
