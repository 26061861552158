import ISnapshotRepository from '../../domain/note/ISnapshotRepository'
import SnapshotRepository from '../../infra/repositories/note/SnapshotRepository'
import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'

interface Props {
  snapshotRepository: ISnapshotRepository
}

/**
 * SignUpUseCase
 */
export default class FetchNoteSnapshotUseCase extends UseCase {
  private _snapshotRepository: ISnapshotRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._snapshotRepository = props.snapshotRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteSnapshotUseCase({
      snapshotRepository: SnapshotRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({id}: {id: string}): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(() => {
      applicationService.toUnbusy()
    })
    await this._snapshotRepository.get(id)
  }
}
