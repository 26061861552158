import * as React from 'react'
import Body from '../../../components/atoms/Body'
import StretchyInput from '../../../components/molecules/StretchyInput'
import StretchyTextarea from '../../../components/molecules/StretchyTextarea'
import {ReduxField} from '../../../hocs/withReduxField'
import {getInterpolated} from '../../../lib'
import {
  getComponentFromTemplate,
  TemplateComponent,
} from '../../helpers/snapshot/attr'

const ReduxInput = (props: any /*ReduxFieldProps*/) => {
  return <StretchyInput {...props} {...props.input} />
}

const ReduxTextarea = (props: any /*ReduxFieldProps*/) => {
  return (
    <StretchyTextarea flex="1 1 auto" width="1%" {...props} {...props.input} />
  )
}

export interface FillTextAttrs {
  type: 'text' | 'date' | 'number'
}

const getDataValue = (
  name: string,
  data: KeySignature,
  defaultValue: React.ReactNode = null,
) => {
  const {[name]: ret = ''} = data || {}
  return typeof ret === 'string' ? ret || defaultValue : JSON.stringify(ret)
}

export const InterpolatedComponent = (
  template: string,
  data: KeySignature,
  defaultValue: React.ReactNode = null,
) => {
  return getInterpolated(template, ({name, key, val}) => {
    const Comp = getComponentFromTemplate(val as TemplateComponent)
    return Comp ? (
      React.createElement(Comp, {key})
    ) : (
      <span key={`${name}`}>{getDataValue(name, data, defaultValue)}</span>
    )
  })
}

export const InterpolatedReduxComponent = (
  filledText: string,
  fillTextAttrs: KeySignature<FillTextAttrs> = {},
  textarea: boolean = false,
  disabled: boolean = false,
) => {
  const list = getInterpolated(filledText, ({name, key, val}) => {
    const {[name]: attr = {}} = fillTextAttrs || {}
    const Comp = getComponentFromTemplate(val as TemplateComponent)
    const componentKey = `${name}.${key}`
    return Comp ? (
      React.createElement(Comp, {key: componentKey})
    ) : (
      <ReduxField
        {...attr}
        component={textarea ? ReduxTextarea : ReduxInput}
        disabled={disabled}
        key={componentKey}
        name={name}
      />
    )
  })
  return list.length
    ? list.map((item: any, index: number) => {
        return typeof item === 'string' ? <Body key={index}>{item}</Body> : item
      })
    : filledText
}
