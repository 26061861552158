import style from './style'

const transform = (value: number | string) => {
  if (typeof value === 'number') {
    if (value <= 1) {
      return `${value * 100}%`
    }
    return `${value}px`
  }
  return value
}
export const top = style({prop: 'top', transform})
export const bottom = style({prop: 'bottom', transform})
export const left = style({prop: 'left', transform})
export const right = style({prop: 'right', transform})
