import {Email} from '../../domain/auth/Email'
import IAuthenticationService from '../../domain/auth/IAuthenticationService'
import AuthenticationService from '../../infra/services/AuthenticationService'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'

interface Props {
  authenticationService: IAuthenticationService
  navigation: Navigation
}

interface Params {
  email: Email
}

/**
 * SignUpUseCase
 */
export default class RequestResetPasswordUseCase extends UseCase {
  private _authenticationService: IAuthenticationService
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._authenticationService = props.authenticationService
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new RequestResetPasswordUseCase({
      authenticationService: AuthenticationService.create(),
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: Params): Promise<void> {
    await this._authenticationService.requestResetPassword(params.email)
    this._navigation.push('confirmEmailVerification')
  }
}
