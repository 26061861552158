import * as React from 'react'
import styled from 'styled-components'
import NoteCard, {Props as NoteCardProps} from '../../../containers/NoteCard'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import {SpaceProps} from '../../layouts/Space'

const View = styled.div`
  display: block;

  ${spacing};
`

const Header = styled.div``

const NewNoteButton = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  color: ${colors.primary};
`

export interface Props extends SpaceProps {
  addable: boolean
  title?: string
  items: NoteCardProps[]
  onClickAddButton?: () => void
}

const CreateButton = (props: any) => (
  <Button {...props} expanded={true} size="large">
    ノートを作成する
  </Button>
)

export default function NoteList({
  addable = false,
  items,
  title,
  onClickAddButton,
  ...rest
}: Props) {
  const list =
    items &&
    items.map(item => {
      const {noteId} = item
      return (
        <Col col={12} desktop={{col: 6}} key={noteId} mt="sm">
          <NoteCard {...item} />
        </Col>
      )
    })
  return (
    <View {...rest}>
      {list.length > 0 ? (
        <>
          {title && (
            <Header>
              <Row align="center">
                <Col>{title && <Heading type="heading5">{title}</Heading>}</Col>
                <Col ml="auto">
                  {addable && (
                    <NewNoteButton onClick={onClickAddButton}>
                      <Icon mr="xs" size={18} type="plus-circle" />
                      <Body type="body2">新しくノートを作成する</Body>
                    </NewNoteButton>
                  )}
                </Col>
              </Row>
            </Header>
          )}
          <Row>{list}</Row>
        </>
      ) : (
        <PushNavigationLink routeKey="createNote" view={CreateButton} />
      )}
    </View>
  )
}
