import AccessToken from '../../domain/auth/AccessToken'

/**
 * APIレスポンスをAccessTokenに変換する
 *
 * @param res - APIレスポンス
 */
export function toAccessToken(res: {bearerToken: string}): AccessToken {
  const {bearerToken} = res
  return bearerToken
}
