import * as React from 'react'
import styled from 'styled-components'
import LabeledIcon from '../../molecules/LabeledIcon'

const View = styled.div``

export interface Props {
  isShowMenu: boolean
  handleClickMenu: () => void
}

export default function HamburgerMenu(props: Props) {
  return (
    <View>
      {props.isShowMenu ? (
        <LabeledIcon onClick={props.handleClickMenu} type="cross">
          メニュー
        </LabeledIcon>
      ) : (
        <LabeledIcon onClick={props.handleClickMenu} type="menu">
          メニュー
        </LabeledIcon>
      )}
    </View>
  )
}
