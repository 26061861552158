import * as React from 'react'
import {useCallback, useState} from 'react'
import {ViewCallbackProperties} from 'react-calendar'
import {useSelector} from 'react-redux'
import View from '../../components/molecules/DatePicker'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {getTrackDateStringsOfMonth} from '../../selectors/note/track'
import FetchTrackDailySumsUseCase from '../../useCases/track/FetchTrackDailySumsUseCase'

export interface Props {
  date: Date
  weekly?: boolean
  onChange?: (value: Date) => void
}

export default function TrackDatePicker(props: Props) {
  const {date, ...rest} = props
  const [showCal, setShowCal] = useState(false)
  const [activeStartDate, setActiveStartDate] = useState(date)
  const noteId = useSelector(getCurrentNoteId)

  const badgeDates = useSelector(
    getTrackDateStringsOfMonth(noteId, activeStartDate),
  )

  const handleSetShowCal = useCallback(
    (val: boolean) => {
      val &&
        UseCaseCommander.create().command(FetchTrackDailySumsUseCase)({
          noteId,
          date,
        })
      setShowCal(val)
      setActiveStartDate(date)
    },
    [noteId, date, activeStartDate],
  )

  const handleActiveDateChange = useCallback(
    ({activeStartDate}: ViewCallbackProperties) => {
      UseCaseCommander.create().command(FetchTrackDailySumsUseCase)({
        noteId,
        date: activeStartDate,
      })
      setActiveStartDate(activeStartDate)
    },
    [activeStartDate, date],
  )

  return React.createElement(View, {
    ...rest,
    onActiveDateChange: handleActiveDateChange,
    date: date,
    showCal,
    setShowCal: handleSetShowCal,
    badgeDates,
  })
}
