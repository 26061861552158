import * as React from 'react'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'

const View = styled.div`
  position: relative;
  text-align: center;
  background-color: #fff;
  ${borderRadius};
  ${boxShadow};
  color: #000;
`

export interface Props {
  children: React.ReactNode
}

export default function Card(props: Props) {
  const {children} = props
  return <View>{children}</View>
}
