import {connect} from 'react-redux'
import {compose} from 'recompose'
import {SpaceProps} from '../../components/layouts/Space'
import View, {Props} from '../../components/organisms/ErrorDialog'
import {IReduxState} from '../../infra/redux'
import * as errorSelectors from '../../infra/redux/error/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import DismissErrorDialogUseCase from '../../useCases/app/DismissErrorDialogUseCase'

type StateProps = Props

export default compose<Props, SpaceProps>(
  connect(
    (state: IReduxState): StateProps => {
      // TODO: fix
      const {
        items: [error],
      } = errorSelectors.getError(state)
      return {
        dismiss: () => {
          // TODO: close
          UseCaseCommander.create().command(DismissErrorDialogUseCase)({
            id: error && error.id,
          })
        },
        message: (error && error.message) || '',
      }
    },
  ),
)(View)
