import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/History'
import {getHistoryPagination} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {useRouter} from '../../lib/router'
import * as apiSelectors from '../../selectors/api'
import FetchNoteHistoryPageUseCase from '../../useCases/note/FetchNoteHistoryPageUseCase'
import FetchNoteHistoryUseCase, {
  Params as FetchNoteHistoryUseCaseParams,
} from '../../useCases/note/FetchNoteHistoryUseCase'

const LIMIT = 20

interface UrlParams {
  noteId: string
}

export default function NoteHistory() {
  const {
    match: {
      params: {noteId},
    },
    location: {search},
  } = useRouter<UrlParams>()
  const urlParams = new URLSearchParams(search)
  const itemId = urlParams.get('itemId')
  const userId = urlParams.get('userId')
  // const list = useSelector(apiSelectors.getNoteHistoryGroupByDate(noteId))
  const list = useSelector(apiSelectors.getNoteHistory(noteId))
  useEffect(() => {
    const params = {
      noteId,
      limit: LIMIT,
      ...(itemId && {itemId}),
      ...(userId && {userId}),
    }
    UseCaseCommander.create().command<FetchNoteHistoryUseCaseParams>(
      FetchNoteHistoryUseCase,
    )(params)
  }, [noteId, itemId, userId])

  const {nextOffset = 0} = useSelector(getHistoryPagination)
  // FIXME: あやうい
  const hasNext = nextOffset !== null && nextOffset !== undefined
  const params = {
    noteId,
    offset: nextOffset,
    limit: LIMIT,
    ...(itemId && {itemId}),
    ...(userId && {userId}),
  }
  const onViewed = useCallback(() => {
    UseCaseCommander.create().command(FetchNoteHistoryPageUseCase)(params)
  }, [noteId, nextOffset, itemId, userId])

  return React.createElement(View, {
    hasNext,
    list,
    onViewed,
  })
}
