import Logger from '../../domain/log/Logger'
import {LogCategory, LogEvent} from '../../domain/log/loggerTypes'
import {NoteAttributesProps} from '../../domain/note/Note'

// ノート作成
export const logNoteCreated = (noteId: string, params: NoteAttributesProps) =>
  Logger.post({
    category: LogCategory.noteCreate,
    event: LogEvent.submit,
    data: params,
    contentId: noteId, // 作成直後で currentNoteId がまだセットされる前であるため、 noteId を外挿する
  })

// ノート詳細表示
export const logNoteDetailPageShowed = () =>
  Logger.post({category: LogCategory.noteDetail, event: LogEvent.show})

// ノート詳細で h1 がスライドされた
export const logNoteDetailTabToggled = (slideIndex: number) =>
  Logger.post({
    category: LogCategory.noteDetail,
    event: LogEvent.toggle,
    intVal: slideIndex,
  })

export const logLinkClicked = (props: {
  logCategory: LogCategory
  url?: string
}) =>
  Logger.post({
    category: props.logCategory,
    event: LogEvent.transition,
    strVal: props.url,
  })
