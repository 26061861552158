import {bindActionCreators} from 'redux'
import {IErrorManager} from '../../services/error/IErrorManager'
import {createAppError} from '../../services/error/translator'
import * as reduxError from '../redux/error'
import ReduxProvider from '../redux/ReduxProvider'

type ErrorActionCreator = typeof reduxError.actions

interface Props {
  actions: ErrorActionCreator
  redux: ReduxProvider
}

/**
 * ErrorManager
 */
export default class ErrorManager implements IErrorManager {
  private redux: ReduxProvider
  private actions: ErrorActionCreator

  /**
   * create
   */
  public static create() {
    const redux = ReduxProvider.create()
    const actions = bindActionCreators(reduxError.actions, action =>
      redux.dispatch(action),
    )
    return new ErrorManager({redux, actions})
  }

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    this.redux = props.redux
    this.actions = props.actions
  }

  /**
   * ダイアログ形式のエラーを表示する
   *
   * @param error - Error
   */
  public showDialog(error: Error) {
    this.actions.show(createAppError(error))
  }

  /**
   * エラーを非表示にする
   *
   * @param id
   */
  public dismiss(id: string) {
    this.actions.dismiss(id)
  }
}
