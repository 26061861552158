import {schema} from 'normalizr'
import {createSelector} from 'reselect'
import {IReduxState} from '../'
import Note, {NullNoteObject} from '../../../domain/note/Note'
import {ApiState} from './'
import {EntitiesState} from './entities'
import {denormalize} from './normalizer'
import {
  HistorySchema,
  NoteMailNotificationTypeSchema,
  NoteSchema,
  NoteTimelineItemSchema,
  NoteTimelineSchema,
  NoteTrackDailySumsSchema,
  NoteTrackItemSchema,
  NoteTrackSchema,
  UserSchema,
  UserTimelineSchema
} from './schemata'

/**
 * getApi
 *
 * @param state - ReduxState
 */
export const getApi = (state: IReduxState) => state.api

/**
 * getEntities
 *
 * @param state - ReduxState
 */
export const getEntities = (state: IReduxState) => getApi(state).entities

/**
 * getOne
 *
 * @param entity - Entity
 */
export const getOne = (entity: schema.Entity) => {
  return createSelector(
    getEntities,
    (entities: EntitiesState) => {
      const {key} = entity
      const ids = Object.keys(entities[key] || {})
      const ret = denormalize({
        entities,
        input: {[key]: ids},
        schema: {[key]: [entity]},
      })
      const values = ret[key]
      if (values.length > 1) {
        throw new TypeError('getOne must get only one entity')
      }
      return values.length ? values[0] : null
    },
  )
}

/**
 * getAll
 *
 * @param entities - EntitiesState
 * @param {Entity} entity
 * @param {string[]} ids
 * @returns {any}
 */
export const getAll = (
  entities: EntitiesState,
  entity: schema.Entity,
  ids: string[],
) => {
  const {key} = entity
  const ret = denormalize({
    entities,
    input: {[key]: ids},
    schema: {[key]: [entity]},
  })
  return ret[key]
}

/**
 * getAllData
 *
 * @param entity - Entity
 */
export const getAllData = (entity: schema.Entity) => {
  return createSelector(
    getEntities,
    getApi,
    (entities: EntitiesState, api: ApiState) => {
      const {key} = entity
      const {ids = []} = api[key]
      if (!entities.hasOwnProperty(key)) {
        return []
      }
      const ret = denormalize({
        entities,
        input: {[key]: ids},
        schema: {[key]: [entity]},
      })
      return ret[key]
    },
  )
}

// FIXME: getAllDataで順番を保持した状態で取得するのが正解？
// nestしたschemaのresultを格納する方法があるのか
export const getEntity = (entity: schema.Entity) => {
  return createSelector(
    getEntities,
    (entities: EntitiesState) => {
      const {key} = entity
      return entities.hasOwnProperty(key) ? entities[key] : []
    },
  )
}

export const getLatestOne = (entity: schema.Entity) => {
  return createSelector(
    getAllData(entity),
    (data: any[]) => {
      const [one = {}] = data ? data.reverse() : []
      return one
    },
  )
}

export const getById = <EntityType = any>(
  entity: schema.Entity,
  defaultObject: EntityType | null = null,
) => (id: string) =>
  createSelector<IReduxState, EntitiesState, EntityType>(
    getEntities,
    (entities: EntitiesState) => {
      const {key} = entity
      const ret =
        entities[key] &&
        denormalize({
          entities,
          input: {[key]: [id]},
          schema: {[key]: [entity]},
        })
      const values = ret && ret[key]
      return (typeof values === 'object' && values[0]) || defaultObject
    },
  )

export const getPagination = (entity: schema.Entity) =>
  createSelector(
    getApi,
    (api: ApiState) => {
      const {key} = entity
      const {pagination = {}} = api[key] || {}
      return pagination
    },
  )

export const getNoteById = getById<Note>(NoteSchema, NullNoteObject)
export const getUserById = getById(UserSchema)

export const getNoteTimeline = getById(NoteTimelineSchema)
export const getNoteTimelineItemById = getById(NoteTimelineItemSchema)
export const getNoteTimelineItems = getAllData(NoteTimelineItemSchema)
export const getTimelinePagination = getPagination(NoteTimelineItemSchema)


export const getNoteTrack = getById(NoteTrackSchema)
export const getNoteTrackItemById = getById(NoteTrackItemSchema)
export const getNoteTrackItems = getAllData(NoteTrackItemSchema)
export const getNoteTrackDailySumItems = getEntity(NoteTrackDailySumsSchema)

export const getUsers = getEntity(UserSchema)
export const getUserTimeline = getAllData(UserTimelineSchema)

export const getHistoryPagination = getPagination(HistorySchema)

export const getNoteMailNotificationTypes = getEntity(
  NoteMailNotificationTypeSchema,
)
