import INoteMemberRepository from '../../domain/note/INoteMemberRepository'
import {UpdatableRelationType, UpdatableTrackRelationType} from '../../domain/note/NoteRelatedUser'
import NoteMemberRepository from '../../infra/repositories/note/NoteMemberRepository'
import ApplicationService from '../../services/app/ApplicationService'
import {NotificationPrinciple, NotificationType} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  noteMemberRepository: INoteMemberRepository
}

export interface Params {
  noteId: string
  userId: string
  fullname?: string
  relationType?: UpdatableRelationType
  trackRelationType?: UpdatableTrackRelationType
  onComplete: () => void
}

export default class UpdateNoteMemberRelationUseCase extends UseCase {
  private noteMemberRepository: INoteMemberRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteMemberRepository = props.noteMemberRepository
  }

  /**
   * create
   */
  public static create() {
    return new UpdateNoteMemberRelationUseCase({
      noteMemberRepository: NoteMemberRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({
    noteId: id,
    userId,
    fullname,
    relationType,
    trackRelationType,
    onComplete,
  }: Params): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: fullname
          ? `${fullname}さんの権限を変更しました`
          : `権限を変更しました`,
      })
      onComplete && onComplete()
    })

    await this.noteMemberRepository.updateNoteMember({
      id,
      userId,
      ...(relationType && {relationType}),
      ...(trackRelationType && {trackRelationType}),
    })
  }
}
