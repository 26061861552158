import {UseCaseConstructor} from '../../../useCases/lib/types'
import Logger from '../../../domain/log/Logger'
import {LogCategory} from '../../../domain/log/loggerTypes'
import {isUseCaseNoLogging} from './blacklist'

const filterUseCaseParams = (params: any): any => {
  if (params && typeof params === 'object') {
    return {
      ...params,
      ...((params.password && {password: '**secret**'}) || {}),
      ...((params.newPassword && {newPassword: '**secret**'}) || {}),
    }
  }
  return params
}

export const logUsecaseCommandCommanded = ({
  params,
  UseCase,
}: {
  params: any
  UseCase: UseCaseConstructor
}): void => {
  const event = UseCase.name
  const data = filterUseCaseParams(params)

  if (isUseCaseNoLogging(UseCase)) {
    return
  }

  Logger.post({
    category: LogCategory.usecase,
    event,
    data,
  })
}
