import {createSelector} from 'reselect'
import {IReduxState} from '../'
import {AppState} from './index'
import {ModalType} from './types'

/**
 * getState
 *
 * @param state - ReduxState
 */
export const getState = (state: IReduxState) => state.app

export const isShowNav = createSelector(
  getState,
  (state: AppState): boolean => {
    return state.nav
  },
)

export const isShowModal = (type: ModalType) =>
  createSelector(
    getState,
    (state: AppState): boolean => {
      const {[type]: modal} = state.modal
      return modal && modal.show
    },
  )
