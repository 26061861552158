import * as React from 'react'
import {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {NoteLeafComponentProps} from '../../components/helpers/snapshot'
import {Props} from '../../components/molecules/FormedField'
import View from '../../components/organisms/NoteField'
import {getNoteDetailEditableByRelationType} from '../../domain/note/Note'
import {NodePolicyTypes} from '../../domain/note/snapshot/Tree'
import {RelationType} from '../../infra/api/types'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {useModalState} from '../../selectors/app/dom'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'
import UpdateNoteEmergencyUseCase, {Params as UpdateNoteEmergencyUseCaseParams} from '../../useCases/note/UpdateNoteEmergencyUseCase'

export type Props = NoteLeafComponentProps & {}
export default function NoteField(props: Props) {
  const {noteId, name, policyType} = props
  const [showMenu, setShowMenu] = useState(false)
  const [showPolicyMenu, setShowPolicyMenu] = useState(false)
  const [showEditModal, setShowEditModal] = useModalState(false)
  const {relationToMe} = useSelector(getNoteById(noteId))
  const disabled = !getNoteDetailEditableByRelationType(relationToMe.type)
  const hasPolicy = policyType && policyType !== NodePolicyTypes.default

  const setEnableEmergencyMenuItem = {
    label: `緊急情報にする`,
    subLabel: `たすけてQRページに表示する設定にします\n(QRコードを知っている人は誰でも閲覧可)`,
    iconType: 'heart-pulse',
    onClick: () => {
      setShowPolicyMenu(false)
      setShowMenu(false)
      UseCaseCommander.create().command<UpdateNoteEmergencyUseCaseParams>(
        UpdateNoteEmergencyUseCase,
      )({
        noteId,
        map: {
          [name]: NodePolicyTypes.emergency,
        },
      })
    },
  }
  const setDisableEmergencyMenuItem = {
    label: `緊急情報にしない`,
    subLabel: `たすけてQRページに表示しない設定にします`,
    iconType: 'heart-pulse',
    onClick: () => {
      setShowPolicyMenu(false)
      setShowMenu(false)
      UseCaseCommander.create().command<UpdateNoteEmergencyUseCaseParams>(
        UpdateNoteEmergencyUseCase,
      )({
        noteId,
        map: {
          [name]: NodePolicyTypes.default,
        },
      })
    },
  }
  const emergencyMenuItem = [
    {subheader: 'グループ'},
    hasPolicy ? setDisableEmergencyMenuItem : setEnableEmergencyMenuItem,
  ]
  const menuItems = useMemo(() => {
    const commonItems = [
      {
        label: `項目の履歴を見る`,
        iconType: 'history',
        onClick: () => {
          UseCaseCommander.create().command<PushNavigationUseCaseParams>(
            PushNavigationUseCase,
          )({routeKey: 'history', noteId, query: {itemId: name}})
        },
      },
    ]
    const editorsMenuItems = [
      {
        label: `編集する`,
        iconType: 'pen',
        onClick: () => {
          setShowMenu(false)
          setShowEditModal(true)
        },
      },
    ]
    const policyMenuItems = hasPolicy ? [] : emergencyMenuItem
    const map = {
      [RelationType.editor]: [...editorsMenuItems, ...commonItems],
      [RelationType.owner]: [
        ...editorsMenuItems,
        ...commonItems,
        ...policyMenuItems,
      ],
    }
    return map[relationToMe.type] || commonItems
  }, [noteId, hasPolicy])

  const policyMenuItems = useMemo(() => {
    return emergencyMenuItem
  }, [hasPolicy])

  return React.createElement(View, {
    ...props,
    disabled,
    setShowEditModal,
    showEditModal,
    menuItems: relationToMe.type === RelationType.none ? [] : menuItems,
    policyMenuItems: !disabled && hasPolicy ? policyMenuItems : [],
    showMenu,
    setShowMenu,
    showPolicyMenu,
    setShowPolicyMenu,
  })
}
