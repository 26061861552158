import IUseCase from './IUseCase'
import IUseCaseExecutor from './IUseCaseExecutor'

export interface UseCaseConstructor<T = IUseCase>
  extends SingletonClassConstructor<T> {
  name: string
  displayName?: string
}

export enum UseCaseEventTypes {
  Change = 'change',
  CommandAccepted = 'COMMAND_ACCEPTED',
  CommandCanceled = 'COMMAND_CANCELED',
  UsecaseFailed = 'USECASE_FAILED',
  UsecaseSucceeded = 'USECASE_SUCCEEDED',
}

export interface UseCaseCommand {
  id: string
  params: any
  parentId?: string
  UseCase: UseCaseConstructor
}

export type UseCaseCommander<P> = (params?: P) => UseCaseCommand

export interface UseCaseContext {
  executor: IUseCaseExecutor
}

export interface UseCaseEventCommandAccepted {
  command: UseCaseCommand
  type: UseCaseEventTypes.CommandAccepted
}
export interface UseCaseEventCommandCanceled {
  command: UseCaseCommand
  type: UseCaseEventTypes.CommandCanceled
}
export interface UseCaseEventUseCaseSucceeded {
  command: UseCaseCommand
  result: any
  type: UseCaseEventTypes.UsecaseSucceeded
}
export interface UseCaseEventUseCaseFailed {
  command: UseCaseCommand
  error: Error
  type: UseCaseEventTypes.UsecaseFailed
}
export type UseCaseEvent =
  | UseCaseEventCommandAccepted
  | UseCaseEventCommandCanceled
  | UseCaseEventUseCaseSucceeded
  | UseCaseEventUseCaseFailed
