import {connect} from 'react-redux'
import {compose} from 'recompose'
import {SpaceProps} from '../../components/layouts/Space'
import View, {Props} from '../../components/organisms/SnackbarNotifications'
import {IReduxState} from '../../infra/redux'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as appSelectors from '../../selectors/app'
import DismissNotificationUseCase from '../../useCases/app/DismissNotificationUseCase'

type StateProps = Props

export default compose<Props, SpaceProps>(
  connect(
    (state: IReduxState): StateProps => {
      // TODO: fix
      const notifications = appSelectors.getSnackbarNotifications(state)
      const items = notifications.map(n => {
        return {
          id: n.id,
          iconType: n.type,
          message: n.message,
        }
      })
      return {
        onClickClose: (id: string) => {
          UseCaseCommander.create().command(DismissNotificationUseCase)({id})
        },
        items,
      }
    },
  ),
)(View)
