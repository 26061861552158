import * as React from 'react'
import ReactDOM from 'react-dom'
import {Transition} from 'react-transition-group'
import styled from 'styled-components'
import {useRootElement} from '../../../selectors/app/dom'
import {TransitionState} from '../../../styles'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadowFloating} from '../../../styles/mixins/boxShadow'
import transition from '../../../styles/mixins/transition'
import {zIndex} from '../../../styles/variables/zIndex'
import Paper from '../../atoms/Paper'

const View = styled.div<TransitionState>`
  z-index: ${zIndex.dialog};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Bg = styled.div<TransitionState>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  ${transition()};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
`

const PaperWindow = styled(Paper)<TransitionState>`
  ${boxShadowFloating};
  ${borderRadius};
  z-index: 10;
  position: relative;

  min-width: 260px;
  min-height: 120px;
  //width: 100%;
  //height: 100vh;

  display: flex;
  flex-flow: column nowrap;

  ${transition({duration: 0.2})};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
  transform: ${({state}) => {
    switch (state) {
      case 'entering':
        return `scale(1.3)`
      case 'entered':
        return 'scale(1.0)'
      case 'exiting':
        return `scale(0.8)`
      case 'exited':
        return `scale(0.8)`
      default:
        return 'scale(0.8)'
    }
  }};
`

const SimpleWindow = styled.div<TransitionState>`
  ${boxShadowFloating};
  z-index: 10;
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  ${transition({duration: 0.2})};
  opacity: ${p => (p.state === 'entered' ? 1 : 0)};
  transform: ${({state}) => {
    switch (state) {
      case 'entering':
        return `scale(1.3)`
      case 'entered':
        return 'scale(1.0)'
      case 'exiting':
        return `scale(0.8)`
      case 'exited':
        return `scale(0.8)`
      default:
        return 'scale(0.8)'
    }
  }};
`

const Content = styled.div`
  overflow-y: auto;
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
`

export interface Props {
  children?: React.ReactNode
  dismiss?: () => void
  header?: React.ReactNode
  show: boolean
  paper?: boolean
}

export default function Modal(props: Props) {
  const {children, dismiss, show, paper = true} = props
  const rootElement = useRootElement()
  const Window = paper ? PaperWindow : SimpleWindow
  return ReactDOM.createPortal(
    <Transition
      in={show}
      mountOnEnter={true}
      timeout={show ? 0 : 300}
      unmountOnExit={true}
    >
      {state => (
        <View state={state}>
          <Bg onClick={() => dismiss && dismiss()} state={state} />
          <Window state={state}>
            <Content>{children}</Content>
          </Window>
        </View>
      )}
    </Transition>,
    rootElement,
  )
}
