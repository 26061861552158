import {getById} from '../../infra/redux/api/selectors'
import {NoteInvitationSchema} from '../../infra/redux/api/schemata'
import {createSelector} from 'reselect'
import NoteInvitation, {
  NullNoteInvitation,
} from '../../domain/note/NoteInvitation'

export const getNoteInvitationByToken = (token: string) =>
  createSelector(
    getById(NoteInvitationSchema)(token),
    (entity): NoteInvitation => entity || NullNoteInvitation,
  )
