import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'

export const HEADER_HEIGHT = 70

const View = styled.div`
  z-index: ${zIndex.header};
  position: fixed;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  top: 0;
  left: 0;
  padding: 0 0 0 ${space.sm}px;
  display: flex;
  align-items: center;

  background-color: ${colors.header};
  border-bottom: solid 1px ${colors.border};
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.14);
`

const Left = styled.div`
  width: 1%;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Center = styled.div`
  flex: 1 1 auto;
`

const Right = styled.div`
  //margin-right: ${space.xs}px;
  margin-left: auto;
  //flex: 0 0 60px;
  //min-width: 60px;
  z-index: 10;
  position: relative;
  padding: ${space.xs}px;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
`

export interface Props {
  left: React.ReactNode
  center?: React.ReactNode
  right: React.ReactNode
}

export default function HeaderLayout(props: Props) {
  const {left, center, right} = props
  return (
    <View>
      <Left>{left}</Left>
      {center && <Center>{center}</Center>}
      <Right>{right}</Right>
    </View>
  )
}
