import {createSelector} from 'reselect'
import {UseCaseConstructor} from '../../../useCases/lib/types'
import {IReduxState} from '../index'
import {UseCaseSummary} from './index'
import {getUseCaseName} from '../../../useCases/lib/UseCase'

export const getState = (state: IReduxState) => state.useCase
export const getSummary = (state: IReduxState) => getState(state).summary

export const isProcessing = (
  UseCaseClass: UseCaseConstructor,
  useCaseId?: string,
) => {
  return createSelector(
    getSummary,
    (summary: UseCaseSummary) => {
      const useCaseName = getUseCaseName(UseCaseClass)
      const {
        [useCaseName]: {
          [useCaseId || useCaseName]: {processing = false} = {},
        } = {},
      } = summary
      return processing
    },
  )
}
