import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteTimelineInputForm'
import {getThumbUrl} from '../../domain/resource/Resource'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import useForm from '../../lib/form/useForm'
import {getMe} from '../../selectors/api'
import {getCurrentNoteId} from '../../selectors/note'
import {Forms, required} from '../../services/forms'
import PostTrackCommentUseCase from '../../useCases/track/PostTrackCommentUseCase'

export interface Props {
  avatarSrc?: string
  trackId: string
}

export default function NoteDiaryCommentInput(props: Props) {
  const {trackId} = props
  const noteId = useSelector(getCurrentNoteId)
  const usersMe = useSelector(getMe)
  const form = Forms.diaryComment(trackId)
  const onSubmit = useCallback(
    (values: any) => {
      UseCaseCommander.create().command(PostTrackCommentUseCase, form)({
        noteId,
        trackId,
        data: values,
      })
    },
    [noteId, trackId],
  )
  const {useField, handleSubmit, isInvalid} = useForm({
    form,
    enableReinitialize: true,
    initialValues: {
      comment: '',
    },
    onSubmit,
  })

  const isProcessing = useSelector(
    useCaseSelectors.isProcessing(PostTrackCommentUseCase, form),
  )

  const {input: commentFieldProps} = useField('comment', [required])
  const {input: imageFieldProps} = useField('image')

  return React.createElement(View, {
    avatarSrc: getThumbUrl(usersMe.iconUrl),
    hasImageField: false,
    commentFieldProps,
    imageFieldProps,
    form,
    handleSubmit,
    submitDisabled: isInvalid || isProcessing,
    isProcessing,
  })
}
