import * as React from 'react'
import styled from 'styled-components'
import UploadTrackImageField from '../../../containers/UploadTrackImageField'
import {ReduxField} from '../../../hocs/withReduxField'
import {HandleSubmitProps} from '../../../lib/form/types'
import {
  dateDayFormat,
  dateDayISOFormat,
  optionalDateTimeFormat,
  optionalDateTimeISOFormat,
  required
} from '../../../services/forms'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import {replaceNewLine} from '../../helpers'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import StretchyTextarea, {Props as TextareaProps} from '../../molecules/StretchyTextarea'
import LightboxImages from '../LightboxImages'
import ShrinkedBody from '../ShrinkedBody'
import StretchyInput from '../StretchyInput'

const View = styled.div`
  ${spacing};
  overflow: hidden;
`

const FieldOuter = styled.div`
  ${spacing};
`

const Outer = styled.div`
  ${spacing};
`

export interface FormProps {
  submitForm: (values: any) => any
  submitDisabled: boolean
  isProcessing: boolean
  initialValues: any
  form: string
}

export type Props = FormProps & {
  text: string
  editing: boolean
  name: string
  onClickCancelButton: () => void
  placeholder?: string
  shrinkable?: boolean
  textarea?: TextareaProps
  timestampEditable?: boolean
  image?: string
  timelineId?: string
}

const ReduxTextarea = (props: any) => {
  return <StretchyTextarea {...props} {...props.input} p="xs" />
}

const ReduxInput = (props: any) => {
  return <StretchyInput {...props} {...props.input} p="xs" />
}

export default function EditableText(props: Props & HandleSubmitProps) {
  const {
    text,
    editing,
    onClickCancelButton,
    name,
    image,
    placeholder,
    shrinkable,
    textarea,
    timestampEditable,
    timelineId,
  } = props
  const sources = image ? [image] : []
  return (
    <View>
      {editing ? (
        <form onSubmit={props.handleSubmit(props.submitForm)}>
          <ReduxField
            {...textarea}
            component={ReduxTextarea}
            name={name}
            placeholder={placeholder}
            validators={[required]}
          />
          {timestampEditable && (
            <Row align="center" gutter={space.xs} mt="md">
              <Col>
                <Icon ml="xxs" mr="xxs" size={14} type="clock" />
              </Col>
              <Col>
                <FieldOuter>
                  <ReduxField
                    component={ReduxInput}
                    name="timestampDate"
                    type="date"
                    validators={[required, dateDayFormat, dateDayISOFormat]}
                  />
                </FieldOuter>
              </Col>
              <Col col="fit">
                <FieldOuter ml="sm">
                  <ReduxField
                    component={ReduxInput}
                    minWidth="6em"
                    name="timestampTime"
                    placeholder=""
                    type="time"
                    validators={[
                      optionalDateTimeFormat,
                      optionalDateTimeISOFormat,
                    ]}
                  />
                </FieldOuter>
              </Col>
            </Row>
          )}

          <UploadTrackImageField
            form={props.form}
            mt="md"
            name="image"
            sources={sources}
            useReduxForm
          />

          <Row justify="center" mt="lg">
            <Col>
              <Button
                backgroundColor={colors.gray400}
                onClick={() => onClickCancelButton()}
                type="reset"
              >
                キャンセル
              </Button>
            </Col>
            <Col>
              <Button
                disabled={props.submitDisabled || props.isProcessing}
                type="submit"
              >
                {props.isProcessing ? '送信中...' : '投稿する'}
              </Button>
            </Col>
          </Row>
        </form>
      ) : (
        <>
          {shrinkable ? (
            <ShrinkedBody>{text}</ShrinkedBody>
          ) : (
            <Body>{replaceNewLine(text)}</Body>
          )}

          {image && (
            <Outer mt="xs">
              <LightboxImages
                hasLightbox={true}
                height="auto"
                maxWidth="100%"
                sources={[image]}
                width="auto"
              />
            </Outer>
          )}
        </>
      )}
    </View>
  )
}
