import {connect} from 'react-redux'
import {compose} from 'recompose'
import {isDirty, isValid, reduxForm, formValueSelector} from 'redux-form'
import View, {FormData, Props} from '../../components/organisms/NoteForm'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {Forms} from '../../services/forms'
import CreateNoteUseCase from '../../useCases/note/CreateNoteUseCase'
import {HandleSubmitProps} from '../../lib/form/types'
import {
  getSampleNoteCoverImageUrlByRandom,
  getSampleChildFaceImageUrlByRandom,
} from '../../domain/resource/SampleResources'
import {getMe} from '../../selectors/api'

const sampleImageUrl = getSampleNoteCoverImageUrlByRandom()
const samplePersonImageUrl = getSampleChildFaceImageUrlByRandom()

export default compose<Props & HandleSubmitProps, {}>(
  connect(
    (state: IReduxState): Props => {
      const inputPersonName = formValueSelector(Forms.noteAttrs)(
        state,
        'personName',
      )
      const submitDisabled =
        !isValid(Forms.noteAttrs)(state) || !isDirty(Forms.noteAttrs)(state)

      const {capability} = getMe(state)

      const initialImageUrl =
        (capability.useDefaultNoteCoverImage && sampleImageUrl) || undefined
      const initialPersonImageUrl =
        (capability.useDefaultNoteCoverImage && samplePersonImageUrl) ||
        undefined
      return {
        initialValues: {
          imageUrl: initialImageUrl,
          personImageUrl: initialPersonImageUrl,
        },
        submitForm: (values: any) => {
          UseCaseCommander.create().command(CreateNoteUseCase)({
            ...values,
          })
        },
        submitDisabled,
        isProcessing: useCaseSelectors.isProcessing(CreateNoteUseCase)(state),
        submitButtonLabel: 'ノートを作成する',
        personNameFieldLabel: 'ノートの主役になる方のお名前',
        noteTitleFieldLabel: 'ノートのタイトル',
        titlePlaceHolder:
          '例：' + (inputPersonName || '○○') + 'さんを知ってもらうためのノート',
      }
    },
  ),
  reduxForm<FormData>({
    form: Forms.noteAttrs,
  }),
)(View)
