import {getPath} from '../../lib'
import {ContainerSize} from '../mixins/container'
import colors from './colors'
import {GUTTER, GUTTER_DESKTOP} from './dom'
import ease from './ease'
import space from './space'

export type StyleValueProp = number | string

export const duration = {
  fadeIn: 0.5,
}

export const theme = {
  formField: {
    lineHeight: '1.6em',
  },
  shadow: {
    boxShadow:
      '0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16)',
  },
  border: {
    borderColor: colors.gray200,
    borderRadius: '5px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  spacing: {
    ...space,
  },
  transition: {
    transition: `0.3s ${ease.easeOutCubic}`,
    transitionTimingFunction: ease.easeOutCubic,
    transitionDuration: '0.3s',
  },
  // https://material.io/design/typography/the-type-system.html#type-scale
  container: {
    phone: {
      paddingLeft: GUTTER,
      paddingRight: GUTTER,
    },
    tablet: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: GUTTER_DESKTOP,
      paddingRight: GUTTER_DESKTOP,
      maxWidth: ContainerSize.lg,
    },
    desktop: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: GUTTER_DESKTOP,
      paddingRight: GUTTER_DESKTOP,
      maxWidth: ContainerSize.lg,
    },
  },
  typography: {
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: 0,
    listSubheader: {
      fontSize: 12,
    },
    icon1: {
      color: colors.body,
      fontSize: 22,
    },
    icon2: {
      color: colors.body,
      fontSize: 18,
    },
    body1: {
      color: colors.body,
      fontSize: 15,
    },
    body2: {
      color: colors.body,
      fontSize: 13,
    },
    caption: {
      fontSize: 12,
    },
    overline: {
      fontSize: 10,
    },
    heading1: {
      fontSize: '3.0rem',
      fontWeight: 100,
    },
    heading2: {
      fontSize: '2.6rem',
      fontWeight: 100,
    },
    heading3: {
      fontSize: '2.2rem',
      fontWeight: 100,
    },
    heading4: {
      fontSize: '1.8rem',
      fontWeight: 300,
    },
    heading5: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    heading6: {
      fontSize: '1.4rem',
      fontWeight: 300,
    },
    // for note snapshot
    h1: {
      fontSize: '2.0rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.7rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.7rem',
      fontWeight: 400,
    },
  },
}

export const body1 = theme.typography.body1
export const body2 = theme.typography.body2

/**
 * themeから値を取得する
 * @param themeKey
 * @param path
 */
export const getThemeValue = ({
  themeKey,
  path,
}: {
  themeKey?: string
  path: string
}): string => {
  const themeValue = themeKey ? theme[themeKey] : theme
  return getPath(themeValue, path)
}

export const getStyleFromPropValue = (
  themeKey: string,
  cssProperties: any[],
  makePath: (propValue: string, cssProperty: string) => string = (
    propValue: string,
    cssProperty: string,
  ) => propValue,
) => {
  // FIXME: type
  return (propValue: StyleValueProp) =>
    cssProperties.reduce((acc: any, cssProperty: string) => {
      acc[cssProperty] =
        getThemeValue({
          path: makePath(propValue as string, cssProperty),
          themeKey,
        }) || propValue
      return acc
    }, {})
}
