import {ease} from '../'
import style from './style'

interface TransitionProperty {
  delay?: number
  duration?: number
  easing?: string
  property?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const transition = ({
  delay = 0,
  duration = 0.3,
  easing = 'ease' || ease.easeOutCubic,
  property = 'all',
}: TransitionProperty = {}) => {
  return `
    transition: ${property} ${duration}s ${easing} ${delay}s;
  `
}

export default transition

// export const transition = style({
//   prop: 'transition',
//   themeKey: 'transition',
// })
export const transitionDuration = style({
  prop: 'transitionDuration',
  themeKey: 'transition',
})
export const transitionTimingFunction = style({
  prop: 'transitionTimingFunction',
  themeKey: 'transition',
})
