import * as React from 'react'

export enum NotificationType {
  snackbar = 'snackbar',
  toast = 'toast',
}

export enum NotificationPrinciple {
  informational = 'informational',
  temporary = 'temporary',
}

export const NOTIFICATION_TEMPORARY_MSEC = 1.4 * 1000

export interface Notification {
  principle?: NotificationPrinciple
  type: NotificationType
  id: string
  message: React.ReactNode
  icon?: string /*icon*/
}
