import UsersMe from './UsersMe'
import Note from '../note/Note'
import {getRouteUrl} from '../../routes'

export default interface MeNotice {
  message: string
  link?: {
    label: string
    path: string
  }
}

const getJoinRequestsNotice = (note: Note): MeNotice | null => {
  const {numRequests = 0, title, id: noteId} = note
  if (numRequests === 0) {
    return null
  }
  return {
    message: `「${title}」への参加申請が ${numRequests} 件きています。`,
    link: {
      label: 'メンバー管理',
      path: getRouteUrl('editNoteMembers', {noteId}),
    },
  }
}

const JNOTE_EXPLANATION_MESSAGE = `
「じぶんノート」は、普段から関わっているご家族、ご友人、医療・介護関係者へ、広くご本人のことを伝えることができるツールです。
`

const getRecommendCreateNoteNotice = () => ({
  message: `「じぶんノート」へのご登録ありがとうございます。${JNOTE_EXPLANATION_MESSAGE}`,
  link: {
    label: 'まずはノートを作成する',
    path: getRouteUrl('createNote') /* FIXME: 依存をとりのぞく */,
  },
})

const getWelcomeNotice = () => ({
  message: `「じぶんノート」へのようこそ。${JNOTE_EXPLANATION_MESSAGE}`,
})

export function getMeNotices(me: UsersMe): MeNotice[] {
  const {ownedNotes = [], followingNotes = []} = me

  const ownedNoteNotices = ownedNotes
    .map(note => [getJoinRequestsNotice(note)])
    .flat()
    .filter(nullable => nullable !== null) as MeNotice[]

  const notices = [...ownedNoteNotices]
  if (notices.length > 0) {
    return notices
  }

  // まだ何のノートもない人には、ノート作成を促す
  if (ownedNotes.length === 0 && followingNotes.length === 0) {
    return [getRecommendCreateNoteNotice()]
  }

  // TODO: 自分のノートの項目をうめるようリコメンド
  // TODO: 招待状のリコメンド

  return [getWelcomeNotice()]
}
