import {Schema} from 'normalizr'
import {handleActions} from '../../helpers'
import {normalize} from '../normalizer'
import {ActionPayload, ActionType, ClearAllAction, StoreAction, StorePagination} from '../types'

export const actions = {
  clearAll: (): ClearAllAction => {
    return {
      type: ActionType.ClearAll,
    }
  },
  store: (
    resource: ActionPayload,
    schema: Schema,
    pagination?: StorePagination,
  ): StoreAction => {
    const {entities, result} = normalize({
      resource,
      schema,
    })
    return {
      meta: {
        result,
        schema,
        pagination,
      },
      payload: entities,
      type: ActionType.Store,
    }
  },
  spend: (id: string, schema: Schema): StoreAction => {
    return {
      meta: {
        schema,
      },
      payload: id,
      type: ActionType.Spend,
    }
  },
  clear: (schema: Schema): StoreAction => {
    return {
      meta: {
        result: [],
        schema,
      },
      payload: schema,
      type: ActionType.Clear,
    }
  },
}

export const handlers: KeySignature = {
  [ActionType.ClearAll]: (state: EntitiesState, action: ClearAllAction) => {
    return {}
  },
  [ActionType.Store]: (state: EntitiesState, action: StoreAction) => {
    const entities = action.payload
    const newEntities = Object.keys(entities).reduce(
      (e, key) => {
        const prevEntities = e[key] || {}
        return {...e, [key]: {...prevEntities, ...entities[key]}}
      },
      {...state},
    )
    return {...state, ...newEntities}
  },
  [ActionType.Spend]: (state: EntitiesState, action: StoreAction) => {
    const {key} = action.meta.schema as {key: string}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {[action.payload]: ignore = {}, ...newEntities} = state[key] || {}
    return {...state, [key]: newEntities}
  },
  [ActionType.Clear]: (state: EntitiesState, action: StoreAction) => {
    const {key} = action.payload
    return {...state, [key]: null}
  },
}

// FIXME: type
export interface EntitiesState {
  [key: string]: any
}

const reducer = handleActions(handlers, {})

export default reducer
