import {createBrowserHistory} from 'history'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Root from './components/Root'
import {ROOT_ELEMENT_ID} from './config'
import configureRedux from './infra/redux/configure'
import ReduxProvider from './infra/redux/ReduxProvider'
import {actions as useCaseActions} from './infra/redux/useCase/index'
import registerServiceWorker from './registerServiceWorker'
import routes from './routes/routes'
import Navigation from './services/navigation/Navigation'
import ApplicationInitializeUseCase from './useCases/app/ApplicationInitializeUseCase'
import {createCommander} from './useCases/lib/UseCase'

const history = createBrowserHistory()
const redux = configureRedux({history})

Navigation.create().setRoutes(routes)
ReduxProvider.create().setContext(redux.store)

const command = createCommander(ApplicationInitializeUseCase)()
ReduxProvider.create().dispatch(useCaseActions.command({command}))

ReactDOM.render(
  <Root history={history} store={redux.store} />,
  document.getElementById(ROOT_ELEMENT_ID),
)

registerServiceWorker()
