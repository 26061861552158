import {useEffect} from 'react'
import * as GoogleAnalytics from 'react-ga'
import {GA, NODE_ENV} from '../../config'
import {useRouter} from '../../lib/router'

GA && GoogleAnalytics.initialize(GA, {debug: NODE_ENV === 'development'})

export default function Tracker() {
  const {location: {pathname = ''} = {}} = useRouter()
  useEffect(() => {
    GoogleAnalytics.pageview(pathname)
  }, [pathname])
  return null
}
