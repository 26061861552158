import {ConnectedRouter} from 'connected-react-router'
import {History} from 'history'
import * as React from 'react'
import {Provider} from 'react-redux'
import {Store} from 'redux'
import {IReduxState} from '../infra/redux'
import {Action} from '../infra/redux/types'
import App from './App'

interface IProps {
  history: History
  store: Store<IReduxState, Action>
}

/**
 * Root
 */
export default class Root extends React.Component<IProps> {
  /**
   * componentShouldUpdate
   */
  public shouldComponentUpdate() {
    return false
  }

  /**
   * render
   */
  public render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <App />
        </ConnectedRouter>
      </Provider>
    )
  }
}
