import * as React from 'react'
import {TransitionGroup} from 'react-transition-group'
import styled from 'styled-components'
import {zIndex} from '../../../styles/variables/zIndex'
import TransitionRiseUp from '../../layouts/TransitionRiseUp'
import Snackbar, {Props as SnackbarProps} from '../../molecules/Snackbar'

const View = styled.div`
  z-index: ${zIndex.notification};
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`

const Bar = styled(Snackbar)`
  margin-top: 1px;
  min-width: 300px;
`

interface ListedSnackbarProps extends SnackbarProps {
  id: string
}

export interface Props {
  onClickClose: (id: string) => void
  items: ListedSnackbarProps[]
}

export default function SnackbarNotifications(props: Props) {
  const items = props.items.map((item, index) => {
    return (
      <TransitionRiseUp key={index} timeout={300}>
        <Bar
          iconType={item.iconType}
          message={item.message}
          onClickClose={() => props.onClickClose(item.id)}
        />
      </TransitionRiseUp>
    )
  })
  return (
    <View>
      <TransitionGroup>{items}</TransitionGroup>
    </View>
  )
}
