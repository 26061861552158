import * as React from 'react'
import {useMemo, useState} from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import {replaceNewLine} from '../../helpers'

export interface Props {
  children: string
  expandLabel?: string
  maxLength?: number
}

const View = styled.div`
  ${spacing};
`

const Inner = styled.div`
  display: inline;
`

const Content = styled(Body)<{exposed: boolean}>`
  display: inline;
  ${({exposed}) =>
    !exposed &&
    `
    p:last-of-type {
      display: inline;
    }
  `}
`

const MoreButton = styled(Body)`
  display: inline;
  color: ${colors.blue100};
  cursor: pointer;
`

const MAX = 200
export default function ShrinkedBody(props: Props) {
  const {children, expandLabel = 'もっと見る', maxLength = MAX, ...rest} = props
  const isOver = useMemo(() => {
    return children.length >= maxLength
  }, [children])
  const [exposed, setExposed] = useState(!isOver)
  const content = useMemo(() => {
    return exposed ? children : children.substring(0, maxLength)
  }, [children, exposed])
  return (
    <View {...rest}>
      <Inner>
        <Content exposed={exposed}>{replaceNewLine(content)}</Content>
        {!exposed && (
          <MoreButton onClick={() => setExposed(true)}>
            ...{expandLabel}
          </MoreButton>
        )}
      </Inner>
    </View>
  )
}
