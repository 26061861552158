import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Heading from '../../atoms/Heading'
import Icon from '../../atoms/Icon'

const View = styled.div`
  ${spacing};
`

const Item = styled.div``

const Link = styled.div`
  display: inline;
  color: ${colors.primary};
  cursor: pointer;
`

export interface ItemProps {
  message: string
  link?: {
    label: string
    onClick: () => void
  }
}

export interface Props {
  meName: string
  items: ItemProps[]
}

export default function MeNoticeBoard(props: Props) {
  const {items, meName} = props
  return (
    <View>
      <Heading mb="sm" type="heading5">
        <Icon mr="xs" size={20} type="alarm" />
        {meName}へのお知らせ
      </Heading>
      {items.map((item, index) => {
        const {message, link} = item
        // ISSUE: Item のコンポーネント分離
        return (
          <Item key={index}>
            <Body>
              {message}
              {(link && <Link onClick={link.onClick}>→{link.label}</Link>) ||
                ''}
            </Body>
          </Item>
        )
      })}
    </View>
  )
}
