import * as React from 'react'
import {useRef} from 'react'
import styled from 'styled-components'
import ResourceImage from '../../../containers/ResourceImage'
import {ReduxField} from '../../../hocs/withReduxField'
import {height, maxHeight, maxWidth, width} from '../../../styles/mixins/sizing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import {Props as FormedFieldProps} from '../FormedField'
import LabeledIcon from '../LabeledIcon'

interface ViewProps {
  width?: number | string
  height?: number | string
}

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`

const Inner = styled.div<ViewProps>`
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
`
const Image = styled.div<ViewProps>`
  margin-top: ${space.sm}px;
  position: relative;
  ${width};
  ${maxWidth};
  ${height};
  ${maxHeight};

  background-color: ${colors.gray200};
`

const Indicator = styled.div`
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`

const BlankFileIcon = styled(Icon)`
  padding: ${space.md}px 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #fff;
`
const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps & {
    onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClickRemove: () => void
    formValues: KeySignature<string>
  }

const ReduxInput = (props: any /*ReduxFieldProps*/) => {
  return <FileInput {...props} {...props.input} />
}

// TODO: ファイルの削除 / 複数data形式
export default function FormedImageField(props: Props) {
  const {
    anyActive,
    anyTouched,
    disabled,
    formErrorMessage,
    formDirty,
    width,
    height,
    name,
    help,
    label,
    isProcessing,
    onClickCancel,
    onClickHistory,
    onClickRemove,
    onChangeFile,
    attr,
    formValues,
    initialValues,
  } = props
  const fileElement = useRef<HTMLInputElement>(null)
  const onCancel = () => {
    onClickCancel()
    const {current} = fileElement
    current && (current.value = '')
  }
  const {[name]: initialSrc = ''} = initialValues || {}
  const {[name]: src = ''} = formValues || {}
  return (
    <FormedFieldLayout
      active={anyActive || anyTouched}
      dirty={formDirty}
      disabled={disabled}
      error={formErrorMessage}
      help={help}
      isProcessing={isProcessing}
      label={label}
      onClickCancel={onCancel}
      onClickHistory={onClickHistory}
    >
      <Inner>
        <Header>
          <LabeledIcon
            my={-space.sm}
            onClick={onClickRemove}
            p="sm"
            type="cross"
          >
            削除
          </LabeledIcon>
          <LabeledIcon my={-space.sm} p="sm" type="upload2">
            <FileInput
              disabled={disabled}
              onChange={onChangeFile}
              ref={fileElement}
              type="file"
            />
            変更
          </LabeledIcon>
        </Header>
        <Image width={200} {...attr}>
          {initialSrc || src ? (
            <React.Fragment>
              <ReduxField component={ReduxInput} name={name} type="text" />
              <ResourceImage
                hasLightbox={false}
                height={height}
                name={name}
                src={src}
                width={width}
              />
            </React.Fragment>
          ) : (
            <BlankFileIcon size={64} type="upload2">
              <Body fontWeight={700} mt={space.md}>
                画像を選択する
              </Body>
              <FileInput
                disabled={disabled}
                onChange={onChangeFile}
                ref={fileElement}
                type="file"
              />
            </BlankFileIcon>
          )}
          {props.isProcessing && (
            <Indicator>
              <Loading size={30} />
            </Indicator>
          )}
        </Image>
      </Inner>
    </FormedFieldLayout>
  )
}
