import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View, {
  Props as ViewProps,
} from '../../components/layouts/NoteSectionGroup'
import {useIndicate} from '../../selectors/app/dom'
import {getCurrentNoteSlideId} from '../../selectors/note'
import {windowScrollTop} from '../../services/app/WindowService'

type Props = ViewProps & {
  slideId: number
}

const INDICATOR_DURATION = 30

export default function NoteSectionGroup(props: Props) {
  const {slideId} = props
  const currentSlideId = useSelector(getCurrentNoteSlideId)
  const active = slideId === currentSlideId
  // タブなどの描画を優先する(ｶｸﾂｷ防止)
  const loaded = useIndicate(INDICATOR_DURATION, () => active)

  useEffect(() => {
    // TODO: WindowServiceに移行
    slideId === currentSlideId && windowScrollTop()
  }, [currentSlideId])
  const ret = loaded ? React.createElement(View, props) : null

  return active ? ret : null
}
