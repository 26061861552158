/**
 * ログの大カテゴリ
 */
export enum LogCategory {
  usecase = 'usecase',

  noteCreate = 'note_create',
  noteDetail = 'note_detail',
  noteHistory = 'note_history',

  nav = 'nav', // 右上のハンバーガーメニューに関するもの
  externalLink = 'external_link',
  help = 'help',
}

/**
 * ログのイベント。大カテゴリの中のサブカテゴリ。
 * アクション的ななにかを入れる。
 */
export enum LogEvent {
  show = 'show',
  submit = 'submit', // 新規作成や投稿系
  update = 'update', // 更新・変更系（既存の情報更新）
  share = 'share', // メール等でシェアする行為
  toggle = 'toggle', // ページ内でタブ移動した等
  transition = 'transition', // タップで遷移等
}
