import {connectRouter} from 'connected-react-router'
import {History} from 'history'
import {RouterState} from 'connected-react-router'
import {combineReducers, Reducer} from 'redux'
import {FormStateMap, reducer as form} from 'redux-form'
import api, {ApiState} from './api'
import app, {AppState} from './app'
import note, {NoteState} from './note'
import error, {ErrorState} from './error'
import {Action} from './types'
import useCase, {UseCaseState} from './useCase'

export interface IReduxState {
  api: ApiState
  app: AppState
  note: NoteState
  error: ErrorState
  form: FormStateMap
  router: RouterState
  useCase: UseCaseState
}

const rootReducer = (history: History) =>
  combineReducers<IReduxState, Action>({
    api,
    app,
    note,
    error,
    form,
    router: connectRouter(history) as Reducer<RouterState, Action>, // FIXME: 型
    useCase,
  })

export default rootReducer
