import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {Props as ModalProps} from '../../components/layouts/Modal'
import View from '../../components/organisms/PostTrackToggleIconsModal'
import {NoteTrackItemType} from '../../domain/note/track/NoteTrack'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId, getCurrentTrackDate} from '../../selectors/note'
import {getTrackItemByDataType} from '../../selectors/note/track'
import DeleteNoteTrackUseCase from '../../useCases/track/DeleteNoteTrackUseCase'
import PostNoteTrackUseCase from '../../useCases/track/PostNoteTrackUseCase'

export type Props = ModalProps

const type = 'feeling'

const useCaseId = 'PostNoteTrackFeelingModal'

export default function PostNoteTrackFeelingModal(props: Props) {
  const noteId = useSelector(getCurrentNoteId)
  const date = useSelector(getCurrentTrackDate)
  const item = useSelector(getTrackItemByDataType(noteId, date, type))
  const {data: {content = ''} = {}, id: trackId = ''} = item || {}

  const list = [
    {
      label: '嬉しい',
      iconType: 'happy',
      value: 'happy',
    },
    {
      label: '普通',
      iconType: 'neutral',
      value: 'neutral',
    },
    {
      label: '悲しい',
      iconType: 'sad',
      value: 'sad',
    },
  ]

  const handleSubmit = useCallback(
    value => {
      if (value) {
        UseCaseCommander.create().command(PostNoteTrackUseCase, useCaseId)({
          type: NoteTrackItemType.noteTrack,
          data: {type, content: value},
          timestamp: date,
          noteId,
          trackId,
          onComplete: () => {
            props.dismiss && props.dismiss()
          },
        })
      } else {
        trackId
          ? UseCaseCommander.create().command(
              DeleteNoteTrackUseCase,
              useCaseId,
            )({
              noteId,
              trackId,
              onComplete: () => {
                props.dismiss && props.dismiss()
              },
            })
          : props.dismiss && props.dismiss()
      }
    },
    [type, noteId, trackId, date],
  )

  const isPostProcessing = useSelector(
    useCaseSelectors.isProcessing(PostNoteTrackUseCase, useCaseId),
  )
  const isDeleteProcessing = useSelector(
    useCaseSelectors.isProcessing(DeleteNoteTrackUseCase, useCaseId),
  )
  return React.createElement(View, {
    ...props,
    list,
    content,
    onSubmit: handleSubmit,
    isProcessing: isPostProcessing || isDeleteProcessing,
  })
}
