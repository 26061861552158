import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteEmergencyCodeDialog'
import NoteEmergencyCode from '../../domain/note/NoteEmergencyCode'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {getNoteEmergencyCodeById} from '../../selectors/note/emergency'
import Navigation from '../../services/navigation/Navigation'
import FetchNoteEmergencyCodeUseCase, {Params as FetchNoteEmergencyCodeUseCaseParams} from '../../useCases/note/FetchNoteEmergencyCodeUseCase'
import RefreshNoteEmergencyCodeUseCase, {Params as RefreshNoteEmergencyCodeUseCaseParams} from '../../useCases/note/RefreshNoteEmergencyCodeUseCase'

export interface Props {
  isShow: boolean
  dismiss: () => void
}

export default function NoteEmergencyCodeDialog(props: Props) {
  const {isShow, dismiss} = props
  const noteId = useSelector(getCurrentNoteId)

  const noteEmergencyCode: NoteEmergencyCode =
    useSelector(getNoteEmergencyCodeById(noteId)) || {}

  const {
    personName = '',
    imageUrl = '',
    emergencyInfo: {url = '', urlQrCodeData = ''} = {},
  } = noteEmergencyCode || {}

  useEffect(() => {
    if (isShow) {
      UseCaseCommander.create().command<FetchNoteEmergencyCodeUseCaseParams>(
        FetchNoteEmergencyCodeUseCase,
      )({noteId})
    }
  }, [noteId, isShow])

  const isFetching = useSelector(
    useCaseSelectors.isProcessing(FetchNoteEmergencyCodeUseCase),
  )

  return React.createElement(View, {
    isFetching,
    isShow,
    personName,
    imageUrl,
    urlQrCodeData,
    url,
    dismiss: () => {
      dismiss()
    },
    onClickShowPrintPage: () => {
      Navigation.create().push('noteEmergencyCodePrint', {noteId})
    },
    onClickRefreshQrCode: () => {
      UseCaseCommander.create().command<RefreshNoteEmergencyCodeUseCaseParams>(
        RefreshNoteEmergencyCodeUseCase,
      )({noteId})
    },
  })
}
