import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteTimelineInputForm'
import {isProcessing} from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import useForm from '../../lib/form/useForm'
import {getCurrentNoteId} from '../../selectors/note'
import {Forms, required} from '../../services/forms'
import PostTimelineCommentUseCase from '../../useCases/timeline/PostTimelineCommentUseCase'

export interface Props {
  avatarSrc?: string
  timelineId: string
}

export default function NoteTimelineCommentInput(props: Props) {
  const {timelineId} = props
  const noteId = useSelector(getCurrentNoteId)
  const form = Forms.timelineComment(timelineId)
  const {useField, handleSubmit, isInvalid} = useForm({
    form,
    enableReinitialize: true,
    initialValues: {
      comment: '',
      image: '',
    },
    onSubmit: (values: any /* FIXME: type */) => {
      UseCaseCommander.create().command(PostTimelineCommentUseCase, form)({
        noteId,
        timelineId,
        data: values,
      })
    },
  })

  const {input: commentFieldProps} = useField('comment', [required])
  const {input: imageFieldProps} = useField('image')

  return React.createElement(View, {
    commentFieldProps,
    imageFieldProps,
    form,
    handleSubmit,
    submitDisabled: isInvalid,
    isProcessing: useSelector(isProcessing(PostTimelineCommentUseCase, form)),
  })
}
