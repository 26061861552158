import union from 'lodash.union'
import {schema} from 'normalizr'
import {combineReducers} from 'redux'
import {handleActions} from '../helpers'
import entities, {EntitiesState} from './entities'
import {
  HistorySchema,
  MeSchema,
  NoteSchema,
  NoteTimelineItemSchema,
  NoteTrackItemSchema,
  ResourceSchema,
  UserSchema,
  UserTimelineSchema
} from './schemata'
import {ActionType, ClearAllAction, StoreAction} from './types'

interface IdsState {
  ids: string[]
  pagination: {}
}

/**
 * 単純なidリストの格納
 */
const createStoreIdsReducer = (entity: schema.Entity) => {
  const {key} = entity
  return {
    [key]: handleActions(
      {
        [ActionType.ClearAll]: (state: IdsState, action: ClearAllAction) => {
          return []
        },
        [ActionType.Clear]: (state: IdsState, action: StoreAction) => {
          const {schema} = action.meta
          const {key: actionSchemaKey = ''} = schema as {key: string}
          return actionSchemaKey === key ? [] : state
        },
        [ActionType.Store]: (state: IdsState, action: StoreAction) => {
          const {schema} = action.meta
          const entity = Array.isArray(schema) ? schema[0] : schema
          const {key: actionSchemaKey = ''} = entity as {key: string}
          if (actionSchemaKey === key) {
            const {ids = []} = state
            const {pagination} = state
            const result = [...ids, action.meta.result].flat()
            return {
              ids: union(result),
              pagination: {
                ...pagination,
                ...action.meta.pagination,
              },
            }
          }
          return state
        },
        [ActionType.Spend]: (state: IdsState, action: StoreAction) => {
          const {ids = [], ...rest} = state
          return {...rest, ids: ids.filter(id => id !== action.payload)}
        },
      } as KeySignature,
      [],
    ),
  } as KeySignature
}

export interface ApiState {
  readonly entities: EntitiesState

  readonly [key: string]: IdsState | EntitiesState
}

export const reducers = {
  entities,
  ...createStoreIdsReducer(MeSchema),
  ...createStoreIdsReducer(NoteSchema),
  ...createStoreIdsReducer(HistorySchema),
  ...createStoreIdsReducer(NoteTimelineItemSchema),
  ...createStoreIdsReducer(NoteTrackItemSchema),
  ...createStoreIdsReducer(UserSchema),
  ...createStoreIdsReducer(ResourceSchema),
  ...createStoreIdsReducer(UserTimelineSchema),
}

export default combineReducers<ApiState>(reducers)
