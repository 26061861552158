import * as React from 'react'
import styled from 'styled-components'
import GeneratedNoteForm from '../../../containers/GeneratedNoteForm'
import NoteDetailHeader from '../../../containers/NoteDetailHeader'
import NoteNav from '../../../containers/NoteNav'
import Container from '../../layouts/Container'
import DefaultPage from '../../templates/DefaultPage'

const View = styled(Container)`
  //background-color: #fff;
`

export interface Props {
  title: string
}

export default function NoteDetailPage(props: Props) {
  const {title} = props
  return (
    <DefaultPage
      back="noteCover"
      header={NoteDetailHeader}
      nav={NoteNav}
      title={title}
    >
      <View>
        <GeneratedNoteForm />
      </View>
    </DefaultPage>
  )
}
