import IResourceRepository from '../../../domain/resource/IResourceRepository'
import Resource from '../../../domain/resource/Resource'
import {ResourceUploadToken} from '../../../domain/resource/ResourceUploadToken'
import {getResourceItems} from '../../../selectors/api/resource'
import AppAPI from '../../api/AppAPI'
import {toResource} from '../../api/translators/resource'
import {ResourceType} from '../../api/types'
import {actions} from '../../redux/api/entities'
import {ResourceSchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class ResourceRepository implements IResourceRepository {
  private api: AppAPI
  private redux: ReduxProvider

  public static create(): ResourceRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new ResourceRepository({api, redux})
  }

  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async upload({
    file,
    id,
    type,
    token,
  }: {
    file: any
    id: string
    type: ResourceType
    token: ResourceUploadToken
  }): Promise<Resource> {
    const client = await this.api.getResourceClient()
    const res = await client.upload({
      file,
      id,
      type,
      token: token,
    })
    const state = this.redux.getState()
    const items = getResourceItems(id)(state)
    this.redux.dispatch(
      actions.store({id, type, items: [res, ...items]}, ResourceSchema),
    )
    return toResource(res)
  }

  public remove(params: {id: string; type: ResourceType}): Promise<void> {
    const {id} = params
    return this.redux.dispatch(actions.spend(id, ResourceSchema))
  }
}
