import * as React from 'react'
import styled from 'styled-components'
import {fontSize} from '../../../styles/mixins/font'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'

const View = styled.div`
  padding: 0 ${space.md}px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  background-color: #000;
  color: #fff;
`

const Inner = styled.div`
  padding: ${space.md}px 0;

  display: flex;
  justify-content: center;
  align-items: center;
`
const MarkIcon = styled(Icon)`
  ${fontSize('heading5')};
  ${spacing};
`
const Content = styled(Body)`
  text-align: left;
  ${spacing};
`
const Close = styled(Icon)`
  margin-left: auto;
  padding: ${space.xxs}px;

  border: solid 1px ${colors.white};
  color: ${colors.white};
  border-radius: 50%;
  cursor: pointer;
  ${fontSize('heading5')};
`

export interface Props {
  className?: string
  iconType?: string
  message: React.ReactNode
  onClickClose?: () => void
}

function Snackbar(props: Props) {
  return (
    <View className={props.className}>
      <Inner>
        {props.iconType && <MarkIcon pr="xs" type={props.iconType} />}
        <Content mr="md">{props.message}</Content>
      </Inner>
      <Close onClick={props.onClickClose} type="cross" />
    </View>
  )
}

export default Snackbar
