import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'
import INoteMemberRepository from '../../domain/note/INoteMemberRepository'
import NoteMemberRepository from '../../infra/repositories/note/NoteMemberRepository'
import {RelationType} from '../../infra/api/types'
import {UpdatableRelationType} from '../../domain/note/NoteRelatedUser'

interface Props {
  noteMemberRepository: INoteMemberRepository
}

export enum NoteMemberChangeType {
  accept,
  update,
  delete,
}

export interface Params {
  changeType: NoteMemberChangeType
  noteId: string
  userId: string
  relationType?: UpdatableRelationType
}

export default class ChangeNoteMemberRelationTypeUseCase extends UseCase {
  private noteMemberRepository: INoteMemberRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteMemberRepository = props.noteMemberRepository
  }

  /**
   * create
   */
  public static create() {
    return new ChangeNoteMemberRelationTypeUseCase({
      noteMemberRepository: NoteMemberRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({
    changeType,
    noteId: id,
    userId,
    relationType,
  }: Params): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
    })

    switch (changeType) {
      case NoteMemberChangeType.accept:
        await this.noteMemberRepository.acceptNoteMember({id, userId})
        break
      case NoteMemberChangeType.update:
        if (
          relationType === RelationType.watcher ||
          relationType === RelationType.editor
        ) {
          await this.noteMemberRepository.updateNoteMember({
            id,
            userId,
            relationType,
          })
        } else {
          throw new TypeError(
            `updatable relation type must be watcher or editor but input is ${relationType}`,
          )
        }
        break
      case NoteMemberChangeType.delete:
        await this.noteMemberRepository.deleteNoteMember({id, userId})
        break
    }
  }
}
