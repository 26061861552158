import * as React from 'react'
import {SyntheticEvent, useCallback} from 'react'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {RouteKey} from '../../routes'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from '../../useCases/app/PushNavigationUseCase'

interface Props {
  routeKey: RouteKey
  view: React.ComponentType<{onClick: (e: SyntheticEvent) => void}>
  [key: string]: any
}

export default function PushNavigationLink(props: Props) {
  const {routeKey, view, ...rest} = props
  const onClick = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({
      routeKey,
      ...rest,
    })
  }, [view])
  return React.createElement(view, {
    onClick,
  })
}
