import {Action as ReduxAction, Dispatch, MiddlewareAPI} from 'redux'
import {actions as errorActions} from '../error'
import {createAppError} from '../../../services/error/translator'
import {
  UseCaseEvent,
  UseCaseEventUseCaseFailed,
} from '../../../useCases/lib/types'
import UseCaseExecutor from '../../../useCases/lib/UseCaseExecutor'
import {getErrorByUseCseEvent} from '../../../useCases/translators'
import ReduxProvider from '../ReduxProvider'
import {Action} from '../types'
import {actions} from './'
import {ActionType, IUseCaseActionCommandedType} from './types'

/**
 * createUseCaseMiddleware
 */
export function createUseCaseMiddleware() {
  const executor = new UseCaseExecutor()
  executor.onChange((e: UseCaseEvent) => {
    ReduxProvider.create().dispatch(actions.report(e))
    if ((e as UseCaseEventUseCaseFailed).error) {
      const appError = createAppError(getErrorByUseCseEvent(e))
      ReduxProvider.create().dispatch(errorActions.show(appError))
    }
  })

  /**
   * UseCaseExecutor
   *
   * @param {MiddlewareAPI<S>} api
   * @return {(next: Dispatch<S>) => <A extends Action>(action: any) => A}
   *
   * fixme: MiddlewareAPI type なぞの型エラー
   */
  function useCaseMiddleware<S>(api: MiddlewareAPI<any, S>) {
    return (next: Dispatch<Action>) => {
      return <A extends ReduxAction>(action: any): A => {
        if (action.type === ActionType.CommandCommanded) {
          const {command} = (action as IUseCaseActionCommandedType).payload
          executor.execute(command)
        }
        return next(action)
      }
    }
  }

  return useCaseMiddleware
}
