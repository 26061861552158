import {Omit} from 'recompose'
import NotificationManager from '../../infra/app/NotificationManager'
import {INotificationManager} from '../../services/app/INotificationManager'
import {Notification} from '../../services/app/Notification'
import UseCase from '../lib/UseCase'

interface Props {
  noticeManager: INotificationManager
}

export type Params = Omit<Notification, 'id'>

export default class NoticeUseCase extends UseCase {
  private _noticeManager: INotificationManager

  public constructor(props: Props) {
    super()
    this._noticeManager = props.noticeManager
  }

  public static create() {
    return new NoticeUseCase({
      noticeManager: NotificationManager.create(),
    })
  }

  public async execute(params: Params): Promise<void> {
    this._noticeManager.show(params)
  }
}
