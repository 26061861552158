import * as React from 'react'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {height, maxWidth, width} from '../../../styles/mixins/sizing'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'

export type ObjectFitType = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
// TODO: 必要になれば、 ObjectPositionType の追加、 object-position に対応する

export const View = styled.figure`
  ${width};
  ${height};
  ${spacing};
`

export const StyledPlaceholder = styled.img<{
  bgColor: string
  objectFit: string
}>`
  ${maxWidth};
  ${width};
  ${height};
  background-color: ${p => p.bgColor};
  object-fit: ${p => p.objectFit};
  ${borderRadius};
`

export type Props = SpacingProps & {
  src?: string
  maxWidth?: number | string
  width?: number | string
  height?: number | string
  objectFit?: ObjectFitType
  borderRadius?: string
  bgColor?: string
}

export default function PlaceholderImage({
  // bgColor = 'transparent',
  bgColor = colors.gray200,
  borderRadius = '0',
  maxWidth = 'none',
  width = '100%',
  height = '100%',
  objectFit = 'contain',
  src,
  ...rest
}: Props) {
  const placeholderViewBoxAttr =
    (typeof height === 'number' &&
      typeof width === 'number' &&
      `viewBox='0 0 ${width} ${height}'`) ||
    ''
  const placeholderSource = `data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' ${placeholderViewBoxAttr}/%3E`
  return (
    <View {...rest} height={height} width={width}>
      <StyledPlaceholder
        bgColor={bgColor}
        borderRadius={borderRadius}
        height={height}
        maxWidth={maxWidth}
        objectFit={objectFit}
        src={src || placeholderSource}
        width={width}
      />
    </View>
  )
}
