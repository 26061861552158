import {connect} from 'react-redux'
import {compose} from 'recompose'
import {FormProps, reduxForm} from 'redux-form'
import View, {IFormData, IFormProps, Props} from '../../components/organisms/UpdateProfileForm'
import {ResourceType} from '../../infra/api/types'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import {getFormSelector} from '../../selectors/form'
import {Forms} from '../../services/forms'
import UpdateProfileUseCase from '../../useCases/account/UpdateProfileUseCase'
import * as selectors from './selectors'

interface OwnProps {
  invisibleIconField?: boolean
  onSuccess?: () => void
}
type StateProps = IFormProps &
  FormProps<IFormData, IFormProps> & {
    initialValues: Partial<IFormData>
  }

const FORM = Forms.updateProfile

export default compose<Props, OwnProps>(
  connect(
    (state: IReduxState, ownProps: OwnProps): StateProps => {
      const {invisibleIconField, onSuccess} = ownProps
      const {fullname, nickname, iconUrl} = getMe(state)
      return {
        iconUrl,
        invisibleIconField,
        initialValues: {
          fullname,
          nickname,
          iconUrl,
        },
        FORM,
        resourceType: ResourceType.user,
        submitForm: values => {
          UseCaseCommander.create().command(UpdateProfileUseCase)({
            ...values,
            onSuccess,
          })
        },
        isDirty: getFormSelector(FORM).isDirty(state),
        submitDisabled: selectors.isSubmitDisabled(state),
        isProcessing: useCaseSelectors.isProcessing(UpdateProfileUseCase)(
          state,
        ),
      }
    },
  ),
  reduxForm<IFormData, IFormProps>({
    form: FORM,
    enableReinitialize: true,
  }),
)(View)
