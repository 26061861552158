import * as React from 'react'
import styled from 'styled-components'
import container from '../../../styles/mixins/container'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import EditNoteForm, {FormData} from '../../../containers/EditNoteForm'

const View = styled.div`
  ${container};
`

const Content = styled.div`
  margin-top: ${space.lg}px;
  text-align: center;
`

const Description = styled.div`
  margin: ${space.lg}px;
  text-align: left;
`

export interface Props {
  noteId: string
  formInitialValues: FormData
}

export default function EditNoteCoverPage(props: Props) {
  const {noteId, formInitialValues} = props
  const disabled = !noteId
  return (
    <View>
      {!disabled && (
        <Content>
          <EditNoteForm initialValues={formInitialValues} noteId={noteId} />
          <Description>
            <Body>
              この情報は、あなたがこのノートに招待した人だけ、見ることができます。
            </Body>
          </Description>
        </Content>
      )}
    </View>
  )
}
