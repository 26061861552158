import * as React from 'react'
import {useRef, useState} from 'react'
import styled from 'styled-components'
import FollowingList from '../../../containers/FollowingList'
import MeNoticeBoard from '../../../containers/MeNoticeBoard'
import OwnedList from '../../../containers/OwnedList'
import {useDebounce} from '../../../lib'
import {borderRadius} from '../../../styles/mixins/border'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import {default as BaseInput} from '../../atoms/Input'
import Container from '../../layouts/Container'
import HelpLink from '../../molecules/HelpLink'
import IndexHeader from '../../organisms/IndexHeader'
import DefaultPage from '../../templates/DefaultPage'

const View = styled.div`
  ${spacing}
`

const HelpOuter = styled(Body)`
  text-align: right;
`

const SearchOuter = styled.div`
  ${borderRadius};
  margin-top: ${space.md}px;
  border: solid 1px ${colors.border};
  padding: ${space.xxs}px ${space.xs}px ${space.xxs}px 0;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
`

const Input = styled(BaseInput)`
  padding-left: 34px;
  background-color: transparent;
`

const ResetButton = styled(Icon)`
  padding: ${space.sm}px;
  position: absolute;
  right: 0px;
  color: ${colors.gray400};
`

const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
`
const SearchButtonIcon = styled(Icon)`
  margin-left: ${space.xxs}px;
`

export interface Props {
  hasNote: boolean
  onChangeSearchInput: (query: string) => void
}

export default function IndexPage(props: Props) {
  const {onChangeSearchInput, hasNote} = props
  const [query, setQuery] = useState('')
  const inputElement = useRef<HTMLInputElement>(null)
  const handleClickClose = () => {
    const {current} = inputElement
    if (current) {
      current.value = ''
      setQuery('')
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }
  useDebounce(
    () => {
      onChangeSearchInput(query)
    },
    100,
    [query],
  )
  return (
    <DefaultPage header={IndexHeader} titleTag="ノート一覧">
      <View mt="xl" pb="xxl">
        <Container>
          <MeNoticeBoard />
          {hasNote && (
            <SearchOuter>
              <SearchButton type="submit">
                <SearchButtonIcon size={16} type="magnifier" />
              </SearchButton>
              <Input
                onChange={handleChange}
                placeholder="名前でノートを検索する"
                ref={inputElement}
              />
              {query && (
                <ResetButton
                  onClick={handleClickClose}
                  size={12}
                  type="cross"
                />
              )}
            </SearchOuter>
          )}
          <OwnedList mt="lg" />
          <FollowingList mt="xl" />
          <HelpOuter mt="xl">
            <HelpLink
              title="よくある質問"
              url="https://jnote.jp/faq/"
            ></HelpLink>
          </HelpOuter>
        </Container>
      </View>
    </DefaultPage>
  )
}
