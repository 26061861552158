import INoteTimelineRepository from '../../domain/note/timeline/INoteTimelineRepository'
import NoteTimelineRepository from '../../infra/repositories/note/NoteTimelineRepository'
import FormService from '../../infra/services/FormService'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'
import FetchTimelineUseCase from './FetchTimelineUseCase'

interface Props {
  noteTimelineRepository: INoteTimelineRepository
  formService: IFormService
}

interface Params {
  noteId: string
}

export default class RefreshTimelineUseCase extends UseCase {
  private _noteTimelineRepository: INoteTimelineRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTimelineRepository = props.noteTimelineRepository
    this._formService = props.formService
  }

  public static create() {
    return new RefreshTimelineUseCase({
      noteTimelineRepository: NoteTimelineRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute(params: Params): Promise<void> {
    this.registerComplete(() => {
      this.subUseCaseExecutor(FetchTimelineUseCase)(params)
    })
    await this._noteTimelineRepository.clear()
  }
}
