import * as React from 'react'
import styled from 'styled-components'
import container from '../../../styles/mixins/container'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import CreateNoteForm from '../../../containers/CreateNoteForm'
import HelpLink from '../../molecules/HelpLink'
import Heading from '../../atoms/Heading'

const View = styled.div`
  ${container};
`

const Content = styled.div`
  margin-top: ${space.lg}px;
  text-align: center;
`

const Description = styled.div`
  margin: ${space.lg}px;
  text-align: left;
`

const HelpOuter = styled.div`
  text-align: right;
`

export default function CreateNotePage() {
  return (
    <View>
      <Content>
        <Description>
          <Body>
            <br />
            あなたは誰のためのノートをつくりますか？
            <br />
            <br />
            じぶんのためのノートですか？
            <br />
            お子さんのため？ ご家族のため？ 大切な人のため？
            <br />
            <br />
            誰をノートの主役にするか決めたら、ノートを作成しましょう。
          </Body>
        </Description>
        <Heading mb="md" size={'heading3'}>
          ノートの作成
        </Heading>
        <CreateNoteForm />

        <Description>
          <Body>
            ※
            この情報は、あなたがこのノートに招待した人だけ、見ることができます。
            はじめは、あなただけがアクセス可能な状態ではじまります。
          </Body>
          <Body>
            ※ 情報のプライバシーは厳重に管理されます。安心してご入力ください。
          </Body>
          <Body>※ 内容はあとから変更できます。</Body>
          <HelpOuter>
            <Body>
              <HelpLink
                title="じぶんノートとは何ですか？"
                url="https://jnote.jp/faq/whats-jnote/"
              />
            </Body>
          </HelpOuter>
        </Description>
      </Content>
    </View>
  )
}
