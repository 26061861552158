import * as React from 'react'
import styled from 'styled-components'
import {Props} from '../../../containers/NoteHistoryItem'
import NoteHistoryItemLayout from '../../../containers/NoteHistoryItemLayout'
import Code from '../../atoms/Code'
import {InterpolatedComponent} from '../../layouts/InterpolatedComponent'

const PrevCode = styled(Code)`
  color: #b30000;
  background: #fadad7;
`

export default function FillTextHistoryItem(props: Props) {
  const {previous, data, ...rest} = props
  const {
    attr: {text: template},
  } = props
  const prevData = InterpolatedComponent(template, previous)
  const currentData = InterpolatedComponent(template, data)
  return (
    <NoteHistoryItemLayout
      currentData={<Code>{currentData}</Code>}
      prevData={<PrevCode>{prevData}</PrevCode>}
      {...rest}
    />
  )
}
