import INoteEmergencyCodeRepository from '../../domain/note/INoteEmergencyCodeRepository'
import NoteEmergencyCodeRepository from '../../infra/repositories/note/NoteEmergencyCodeRepository'
import UseCase from '../lib/UseCase'

export interface Props {
  noteEmergencyCodeRepository: INoteEmergencyCodeRepository
}

export interface Params {
  noteId: string
}

export default class FetchNoteEmergencyCodeUseCase extends UseCase {
  private noteEmergencyCodeRepository: INoteEmergencyCodeRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteEmergencyCodeRepository = props.noteEmergencyCodeRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteEmergencyCodeUseCase({
      noteEmergencyCodeRepository: NoteEmergencyCodeRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({noteId}: Params): Promise<void> {
    await this.noteEmergencyCodeRepository.getCode(noteId)
  }
}
