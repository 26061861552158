import APIError from './APIError'

interface Res {
  body: any
  ok: boolean
  status: number
}

export default abstract class BaseAPIClient {
  protected readonly endpoint: string

  protected constructor({endpoint}: {endpoint: string}) {
    this.endpoint = endpoint
  }

  protected getDefaultOptions(): KeySignature {
    return {}
  }

  public async send(
    method: 'get' | 'post',
    url: string,
    options: KeySignature = {},
  ): Promise<any> {
    const {headers: defaultHeaders, ...defaultOps} = this.getDefaultOptions()
    const {headers: optionsHeaders, ...opts} = options
    const headers = {
      ...optionsHeaders,
      ...defaultHeaders,
    }
    const res = await fetch(url, {
      method,
      headers,
      ...defaultOps,
      ...opts,
    })
    if (res.ok) {
      return await res.json()
    } else {
      const ret = await res.json()
      throw new APIError(ret)
      return ret
    }
  }

  public async get(path: string, params: KeySignature = {}): Promise<any> {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&')
    const url = queryString
      ? `${this.endpoint}${path}?${queryString}`
      : `${this.endpoint}${path}`
    return await this.send('get', url)
  }

  public async post(path: string, params: object = {}): Promise<any> {
    const url = `${this.endpoint}${path}`
    return await this.send('post', url, {
      body: JSON.stringify(params),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }
}
