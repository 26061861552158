import * as React from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import UpdateProfileForm from '../../../containers/UpdateProfileForm'
import {spacing} from '../../../styles/mixins/spacing'
import {replaceNewLine} from '../../helpers'
import Dialog from '../../layouts/Dialog'

const Inner = styled.div`
  ${spacing}
  z-index: 10;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  text-align: left;
`

export interface Props {
  isShow: boolean
  message: string
  dismiss: () => void
  onSuccess?: () => void
}

export default function EditAccountDialog(props: Props) {
  const {isShow, message, onSuccess} = props
  const messages = replaceNewLine(message, {splitter: '\\n'})
  return (
    <Dialog
      header="あなたのアカウント情報の編集"
      onClickClose={props.dismiss}
      transitionIn={isShow}
    >
      <Inner pb="sm" pt="sm">
        {message && <Body>{messages}</Body>}
        <UpdateProfileForm invisibleIconField={true} onSuccess={onSuccess} />
      </Inner>
    </Dialog>
  )
}
