import {format, formatDistance} from 'date-fns'
import {ja} from 'date-fns/locale'
import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'

const View = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  text-align: left;
  border-radius: 10px;
  background-color: #fff;
`

const Indent = styled.div`
  padding-left: ${space.lg}px;
`

const EditDate = styled(Body)`
  color: ${colors.gray300};
`

const Header = styled(Body)`
  ${spacing};
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

const HeaderLeft = styled.div`
  margin-right: ${space.xs}px;
`
const HeaderRight = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  flex: 1 1 auto;
  width: 1%;
`

const FieldName = styled(Body)`
  color: ${colors.brand};
  cursor: pointer;
`
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.brand};
`

const UserName = styled(Body)`
  cursor: pointer;
`

const Center = styled.div`
  text-align: center;
  ${spacing}
`

const Diff = styled.div`
  ${spacing};
`

export interface Props {
  userId: string
  name: string
  attr: KeySignature
  prevData: React.ReactNode
  currentData: React.ReactNode
  type: string
  date: Date
  label: string
  fullname: string
  userIconUrl: string
  onClickLabel: () => void
  onClickFullname: () => void
}

const getIconType = (type: string) => {
  const map = {
    date: 'calendar-31',
    tel: 'telephone',
    checkbox: 'list3',
    image: 'picture2',
  }
  return map[type] || 'document'
}

export default function HistoryItemLayout({
  attr,
  currentData,
  prevData,
  label,
  date,
  type,
  fullname,
  userIconUrl,
  onClickLabel,
  onClickFullname,
  ...rest
}: Props) {
  return (
    <View {...rest}>
      <Indent>
        <EditDate mr="sm" size="body2">
          {formatDistance(date, new Date(), {locale: ja})}前 （
          {format(date, 'yyyy年MM月dd日 HH:mm')}）
        </EditDate>
      </Indent>
      <Header my="sm" type="body2">
        <HeaderLeft>
          <Avatar
            onClick={() => onClickFullname()}
            size={24}
            src={userIconUrl}
          />
        </HeaderLeft>
        <HeaderRight>
          <UserAvatar onClick={() => onClickFullname()}>
            <UserName type="body2">
              {fullname ? `${fullname}さん` : 'あなた'}
            </UserName>
          </UserAvatar>
          が
          <FieldName onClick={() => onClickLabel()} type="body2">
            「{label || 'ラベルなし'}」
          </FieldName>
          を変更しました。
        </HeaderRight>
      </Header>
      <Indent>
        {prevData && (
          <Diff>
            {prevData}
            <Center py={space.xxs}>
              <Icon size={10} type="arrow-down" weight={700} />
            </Center>
          </Diff>
        )}
        {currentData}
      </Indent>
    </View>
  )
}
