import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'

const Divider = styled.hr`
  ${spacing};
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  &::after {
    content: '';
    display: block;
    border-bottom: solid 1px ${colors.border};
  }
`

export default Divider
