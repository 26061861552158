import INoteInvitationRepository from '../../domain/note/INoteInvitationRepository'
import NoteInvitationRepository from '../../infra/repositories/note/NoteInvitationRepository'
import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'

interface Props {
  noteInvitationRepository: INoteInvitationRepository
}

export default class FetchNoteInvitationUseCase extends UseCase {
  private noteInvitationRepository: INoteInvitationRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteInvitationRepository = props.noteInvitationRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteInvitationUseCase({
      noteInvitationRepository: NoteInvitationRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({token}: {token: string}): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
    })
    await this.noteInvitationRepository.getInvitation({token})
  }
}
