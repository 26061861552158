import * as React from 'react'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/NoteCoverPage'
import UsersMe from '../../domain/account/UsersMe'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import * as navigationSelectors from '../../selectors/app/navigation'
import {getCurrentNote} from '../../selectors/note'
import UpdateCurrentNoteIdUseCase from '../../useCases/app/UpdateCurrentNoteIdUseCase'
import FetchNoteUseCase from '../../useCases/note/FetchNoteUseCase'

export default function NotesCoverPage() {
  const routeNoteId = navigationSelectors.getNoteId()
  const {id: noteId, title} = useSelector(getCurrentNote)
  const {fullname} = useSelector(getMe) as UsersMe

  useMemo(() => {
    UseCaseCommander.create().command(UpdateCurrentNoteIdUseCase)({
      noteId: routeNoteId,
    })
  }, [noteId])

  useEffect(() => {
    noteId && UseCaseCommander.create().command(FetchNoteUseCase)({id: noteId})
  }, [noteId, fullname]) // NOTE: depsを指定しないと無限API呼び出し

  return React.createElement(View, {title})
}
