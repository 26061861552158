import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/molecules/StatusBar'
import {getCurrentNoteId, getCurrentTrackDate} from '../../selectors/note'
import {
  getTrackItemByDataType,
  getTrackItemsSumQuantityByDataType,
  NoteTrackDataTypeIconMap,
  NoteTrackDataTypeQuantityUnitMap
} from '../../selectors/note/track'
import {SpacingBreakPointsProps} from '../../styles/mixins/spacing'

export type Props = SpacingBreakPointsProps

export function NoteTrackStatusBar(props: Props) {
  const noteId = useSelector(getCurrentNoteId)
  const date = useSelector(getCurrentTrackDate)
  const {data: {content: feelingIcon = ''} = {}} = useSelector(
    getTrackItemByDataType(noteId, date, 'feeling'),
  )
  const {data: {content: weatherIcon = ''} = {}} = useSelector(
    getTrackItemByDataType(noteId, date, 'weather'),
  )
  const {length: mealLength, quantity: mealSumQuantity} = useSelector(
    getTrackItemsSumQuantityByDataType(noteId, date, 'meal'),
  )
  const {length: peeLength, quantity: peeSumQuantity} = useSelector(
    getTrackItemsSumQuantityByDataType(noteId, date, 'pee'),
  )
  const {length: fecesLength, quantity: fecesSumQuantity} = useSelector(
    getTrackItemsSumQuantityByDataType(noteId, date, 'feces'),
  )
  const {length: seizureLength} = useSelector(
    getTrackItemsSumQuantityByDataType(noteId, date, 'seizure'),
  )
  const {length: pillsLength} = useSelector(
    getTrackItemsSumQuantityByDataType(noteId, date, 'pills'),
  )
  const list = [
    {
      iconType: NoteTrackDataTypeIconMap['meal'],
      label: `${mealLength}回\n${mealSumQuantity}${NoteTrackDataTypeQuantityUnitMap['meal']}`,
    },
    {
      iconType: NoteTrackDataTypeIconMap['pee'],
      label: `${peeLength}回\n${peeSumQuantity}${NoteTrackDataTypeQuantityUnitMap['pee']}`,
    },
    {
      iconType: NoteTrackDataTypeIconMap['feces'],
      label: `${fecesLength}回\n${fecesSumQuantity}${NoteTrackDataTypeQuantityUnitMap['feces']}`,
    },
    {
      iconType: NoteTrackDataTypeIconMap['pills'],
      label: `${pillsLength}回`,
    },
    {
      iconType: NoteTrackDataTypeIconMap['seizure'],
      label: `${seizureLength}回`,
    },
    {
      iconType: feelingIcon,
    },
    {
      iconType: weatherIcon,
    },
  ]

  return list.length > 0 ? React.createElement(View, {list, ...props}) : null
}
