import {format, formatDistance} from 'date-fns'
import {ja} from 'date-fns/locale'
import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import {replaceNewLine} from '../../helpers'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import EditRemoveActions from '../EditRemoveActions'

const View = styled.div`
  // padding: ${space.md}px ${space.sm}px;
`

const CommentOuter = styled.div`
  padding: ${space.xs}px;
  background-color: ${colors.gray100};
  border-radius: 14px;
`

export interface Props {
  threadId: string
  commentId: string
  avatarSrc?: string
  avatarName: string
  comment: string
  date: string
  editable: boolean
  deletable: boolean
  onClickEditButton?: () => void
  onClickRemoveButton?: () => void
}

export default function Comment(props: Props) {
  const {
    avatarSrc,
    comment,
    date,
    avatarName,
    editable,
    deletable,
    onClickEditButton = () => {},
    onClickRemoveButton = () => {},
  } = props
  return (
    <View>
      <Row align="start">
        <Col col="auto">
          <Avatar src={avatarSrc} />
        </Col>
        <Col col="fit">
          <CommentOuter>
            <Row>
              <Col>
                <Body type="caption">{avatarName}</Body>
                <Body color={colors.gray300} type="overline">
                  {date}
                </Body>
              </Col>
              <Col ml="auto">
                <EditRemoveActions
                  deletable={deletable}
                  editable={editable}
                  onClickEditButton={onClickEditButton}
                  onClickRemoveButton={onClickRemoveButton}
                />
              </Col>
            </Row>
            <Body>{replaceNewLine(comment)}</Body>
          </CommentOuter>
        </Col>
      </Row>
    </View>
  )
}
