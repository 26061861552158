import * as React from 'react'
import styled from 'styled-components'
import UserNotificationList from '../../../containers/UserNotificationList'
import {spacing} from '../../../styles/mixins/spacing'
import Heading from '../../atoms/Heading'
import Paper from '../../atoms/Paper'
import Container from '../../layouts/Container'
import DefaultPage from '../../templates/DefaultPage'

const View = styled(Container)``

const ListOuter = styled.div`
  ${spacing};
`

export interface Props {
  userName: string
}

export default function UserNotificationsPage(props: Props) {
  const {userName} = props
  const title = `${userName}へお知らせ`
  return (
    <DefaultPage back={true} title={title}>
      <View>
        <Paper py="xl">
          <Heading type="heading4">{title}</Heading>
          <ListOuter mt="md">
            <UserNotificationList limit={0} />
          </ListOuter>
        </Paper>
      </View>
    </DefaultPage>
  )
}
