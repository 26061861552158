import {
  denormalize as defaultDenormalize,
  normalize as defaultNormalize,
  Schema,
} from 'normalizr'

/**
 * normalize
 *
 * @param params - params
 *
 * FIXME: ts版のbaseapiclientと結合したら、リソースとSchemaのマップを作る
 */
export function normalize(params: {resource: any; schema: Schema}) {
  const {resource, schema} = params
  return defaultNormalize(resource, schema)
}

/**
 * denormalize
 *
 * @param params - params
 */
export function denormalize(params: {
  input: any
  schema: Schema
  entities: any
}) {
  const {input, schema, entities} = params
  return defaultDenormalize(input, schema, entities)
}
