import * as React from 'react'
import styled from 'styled-components'
import {height, width} from '../../../styles/mixins/sizing'
import LazyImage from '../../atoms/LazyImage'
import imageUrl from '../../../assets/images/buttons/share-line-wide.png'

const DEFAULT_WIDTH = 57
const DEFAULT_HEIGHT = 20

export interface Props {
  width?: string | number
  height?: string | number
  url?: string
  text?: string
}

const View = styled.div`
  position: relative;
  display: flex;
  ${width};
  ${height};
  cursor: pointer;
`

export default function ShareLineButton(props: Props) {
  const {
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    url = '',
    text = '',
  } = props
  const shareLink = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
    url,
  )}&text=${encodeURIComponent(text)}&from=line_scheme`
  return (
    <View height={height} width={width}>
      <a href={shareLink} rel="noopener noreferrer" target="_blank">
        <LazyImage bgColor={'transparent'} src={imageUrl} />
      </a>
    </View>
  )
}
