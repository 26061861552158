import * as React from 'react'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/NoteDetailPage'
import Note, {getNoteDetailEditable} from '../../domain/note/Note'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {useRouter} from '../../lib/router'
import {getCurrentNote} from '../../selectors/note'
import UpdateCurrentNoteIdUseCase from '../../useCases/app/UpdateCurrentNoteIdUseCase'
import FetchNoteSnapshotUseCase from '../../useCases/note/FetchNoteSnapshotUseCase'
import RemoveNoteSnapshotUseCase from '../../useCases/note/RemoveNoteSnapshotUseCase'
import {logNoteDetailPageShowed} from '../../services/log'
import ChangeNoteSlideUseCase from '../../useCases/app/ChangeNoteSlideUseCase'

interface Props {
  slideIndex?: number
}

export default function NoteDetailPage(props: Props) {
  const {
    match: {
      params: {noteId: urlNoteId},
    },
  } = useRouter<{noteId: string}>()
  const note = useSelector(getCurrentNote) as Note
  const {id: noteId} = note
  const forceSlideIndex = props.slideIndex || undefined

  useMemo(() => {
    UseCaseCommander.create().command(UpdateCurrentNoteIdUseCase)({
      noteId: urlNoteId,
    })
  }, [noteId])
  if (forceSlideIndex !== undefined) {
    useEffect(() => {
      UseCaseCommander.create().command(ChangeNoteSlideUseCase)({
        slideIndex: forceSlideIndex,
      })
    }, [noteId])
  }

  useEffect(() => {
    if (noteId) {
      UseCaseCommander.create().command(FetchNoteSnapshotUseCase)({id: noteId})
      logNoteDetailPageShowed()
    }
    return () => {
      UseCaseCommander.create().command(RemoveNoteSnapshotUseCase)({id: noteId})
    }
  }, [noteId]) // NOTE: depsを指定しないと無限API呼び出し

  const editable = getNoteDetailEditable(note)
  const {title: noteTitle = ''} = note
  const title = editable ? noteTitle : `${noteTitle}(閲覧のみ)`

  return React.createElement(View, {
    title,
  })
}
