import {ILogService, LoggerParams} from '../../domain/log/Logger'
import LogAPIClient from '../api/LogAPIClient'
import ReduxProvider from '../redux/ReduxProvider'
import {getMe} from '../../selectors/api'
import {getCurrentNoteId} from '../../selectors/note'

// 一分間に送信可能なログの回数。もしこれを超えるとクライアントがバグっているのでそれ以降何もしない。
const MAX_COUNT_PER_MINUTE = 30

export default class LogService implements ILogService {
  private _redux: ReduxProvider
  private _logApi: LogAPIClient
  private _postedCount = 0
  private _hasBroken = false

  public constructor(props: {client: LogAPIClient; redux: ReduxProvider}) {
    this._logApi = props.client
    this._redux = props.redux
    setInterval(() => {
      this._postedCount = 0
    }, 60 * 1000)
  }

  private assertPost(): boolean {
    if (this._hasBroken) {
      return false
    }
    if (this._postedCount > MAX_COUNT_PER_MINUTE) {
      this._hasBroken = true
      console &&
        console.error(`LogService: Too many post!: count=${this._postedCount}`)
      return false
    }
    return true
  }

  private makeLogBase(): {userId?: string; contentId?: string} {
    const state = this._redux.getState()
    const me = getMe(state)
    const noteId = getCurrentNoteId(state)

    const userId = (me && me.id) || undefined
    const contentId = noteId || undefined
    return {
      userId,
      contentId,
    }
  }

  public async post(params: LoggerParams): Promise<void> {
    if (!this.assertPost()) {
      return
    }
    this._postedCount++
    const logBase = this.makeLogBase()
    const {data: freeData, ...rest} = params
    await this._logApi.postLog({
      ...logBase,
      freeData,
      ...rest,
    })
  }
}
