import IUseCase from './IUseCase'
import {
  UseCaseConstructor,
  UseCaseCommand,
  UseCaseCommander,
  UseCaseContext,
} from './types'

/**
 * UseCase の識別子を返す
 * UseCaseを識別するためのサービス内で一意なID (名前のようなもの)
 *
 * @param UseCaseClass - 対象の UseCase class
 */
export function getUseCaseName(UseCaseClass: UseCaseConstructor): string {
  return UseCaseClass.displayName || UseCaseClass.name
}

/**
 * UseCaseCommand から UseCase の識別子を返す
 *
 * @param useCaseCommand - 対象の UseCaseCommand
 */
export function getUseCaseIdFromCommand(
  useCaseCommand: UseCaseCommand,
): string {
  return useCaseCommand.id
}

/**
 * createCommander
 *
 * @param useCase - UseCaseClass
 * @param useCaseId?
 */
export function createCommander<P>(
  useCase: UseCaseConstructor,
  useCaseId?: string,
): UseCaseCommander<P> {
  return (params?: P): UseCaseCommand => {
    return {
      UseCase: useCase,
      id: useCaseId || getUseCaseName(useCase),
      params,
    }
  }
}

/**
 * createUseCaseByCommand
 *
 * @param command - UseCaseCommand
 */
export function createUseCaseByCommand(command: UseCaseCommand): IUseCase {
  const useCase = command.UseCase.create()
  useCase.setId(command.id)
  return useCase
}

/**
 * UseCase
 */
export default abstract class UseCase<P extends {} = {}>
  implements IUseCase<P> {
  // private static displayName?: string

  private _id: string | undefined
  private _context: UseCaseContext | undefined
  private _completeHandler: (() => void) | undefined
  private _successHandler: (() => void) | undefined
  private _errorHandler: ((error: Error) => void) | undefined

  /**
   * ユースケース実行後に毎回実行される処理を登録する
   *
   * @param handler - execute完了後に実行されるハンドラ
   */
  public registerComplete(handler: () => void) {
    this._completeHandler = handler
  }

  public registerSuccess(handler: () => void) {
    this._successHandler = handler
  }

  /**
   * ユースケースがエラーになった後に毎回実行される処理を登録する
   *
   * @param handler - execute完了後にエラーであれば実行されるハンドラ
   */
  public registerError(handler: (error: Error) => void) {
    this._errorHandler = handler
  }

  /**
   * ユースケース完了後に呼び出すハンドラ
   */
  public onComplete(): void {
    if (this._completeHandler) {
      this._completeHandler()
    }
  }

  /**
   * ユースケース実行成功後に呼び出すハンドラ(エラー時以外）
   */
  public onSuccess(): void {
    if (this._successHandler) {
      this._successHandler()
    }
  }

  /**
   * onError
   *
   * @param error - Error
   */
  public onError(error: Error): void {
    if (this._errorHandler) {
      this._errorHandler(error)
    }
  }

  /**
   * UseCaseContextをセットする
   *
   * FIXME: この仕組みは微妙かも
   *
   * @param context - UseCaseContext
   */
  public setContext(context: UseCaseContext): void {
    this._context = context
  }

  /**
   * UseCaseContextを返す
   */
  public get context(): UseCaseContext {
    if (!this._context) {
      throw new TypeError('must call setContext before use useCase.context')
    }
    return this._context
  }

  /**
   * setId
   * @param id - string
   */
  public setId(id: string): void {
    this._id = id
  }

  /**
   * Command idを返す
   */
  public get id(): string {
    if (!this._id) {
      throw new TypeError('must call setId before use useCase.id')
    }
    return this._id
  }

  /**
   * 子UseCaseを実行する
   *
   * @param UseCaseClass - 実行対象のUseCase Class
   * FIXME: UseCaseClass: anyのanyの部分
   */
  public subUseCaseExecutor<P>(
    UseCaseClass: UseCaseConstructor,
  ): (params?: P) => Promise<any> {
    const commander = createCommander(UseCaseClass)
    const parentId = this.id
    return async (params?: P): Promise<any> => {
      const command = commander(params)
      const ret = await this.context.executor.execute({...command, parentId})
      return ret
    }
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public abstract async execute(params: P): Promise<any>
}
