import {useCallback, useState} from 'react'
import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import ease from '../../../styles/variables/ease'

const WIDTH = 56
const HEIGHT = 28

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  defaultChecked: boolean
  disabled?: boolean
}

const View = styled.div<{disabled?: boolean}>`
  display: inline-flex;
  position: relative;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  cursor: ${p => (p.disabled ? 'normal' : 'pointer')};
`

const Input = styled.input`
  z-index: 20;
  position: absolute;
  width: 100%;
  height: 100%;
  //left: -9999px;
  opacity: 0;
  cursor: pointer;
`

const Circle = styled.div<{checked: boolean}>`
  //padding: 10px 10px;
  flex: 0 0 auto;
  display: inline-flex;
  transition: 0.2s ${ease.easeOutExpo};
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 50%;
  transform: ${p => p.checked && `translate(${HEIGHT}px, -50%)`};
`

const Thumb = styled.div`
  width: ${HEIGHT}px;
  height: ${HEIGHT}px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`

const Labels = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 10px;
  line-height: ${HEIGHT}px;
`
const OnLabel = styled.div<{checked: boolean}>`
  padding-left: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 50%;
  transition: 0.2s ${ease.easeOutExpo};
  transform: translateY(-50%);
  background-color: ${colors.primary};
  border-radius: ${HEIGHT / 2}px;
  text-align: left;
  opacity: ${p => (p.checked ? 1 : 0)};
  color: #fff;
`
const OffLabel = styled.div<{checked: boolean}>`
  padding-right: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 50%;
  transition: 0.2s ${ease.easeOutExpo};
  transform: translateY(-50%);
  background-color: ${colors.gray200};
  border-radius: ${HEIGHT / 2}px;
  text-align: right;
  opacity: ${p => (p.checked ? 0 : 1)};
  color: ${colors.gray400};
`

/**
 * スイッチ
 */
export default function Switch(props: Props) {
  const {defaultChecked, ...rest} = props
  const [checked, setChecked] = useState(defaultChecked)
  const handleChange = useCallback(e => {
    const {onChange} = rest
    const {target: {checked = false} = {}} = e
    setChecked(checked)
    onChange && onChange(e)
  }, [])
  return (
    <View>
      <Input
        type="checkbox"
        {...rest}
        defaultChecked={checked}
        onChange={handleChange}
      />
      <Circle checked={checked}>
        <Thumb />
      </Circle>
      <Labels>
        <OnLabel checked={checked}>On</OnLabel>
        <OffLabel checked={checked}>Off</OffLabel>
      </Labels>
    </View>
  )
}
