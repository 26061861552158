import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import {replaceNewLine} from '../../helpers'

export interface Props {
  primary: string
  primaryWeight?: number
  secondary?: string
}

const View = styled.div`
  margin-left: ${space.xs}px;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
`

export default function ListItemText(props: Props) {
  const {primary, primaryWeight, secondary = ''} = props
  return (
    <View>
      <Body type="body1" weight={primaryWeight}>
        {primary}
      </Body>
      {secondary && (
        <Body color={colors.gray400} type="caption">
          {replaceNewLine(secondary)}
        </Body>
      )}
    </View>
  )
}
