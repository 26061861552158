import * as React from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {HandleSubmitProps} from '../../../lib/form/types'
import {emailFormat, passwordMaxLength, passwordMinLength, required} from '../../../services/forms'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import TextButton from '../../atoms/TextButton'
import Space from '../../layouts/Space'
import LabeledCheckboxRadio from '../../molecules/LabeledCheckboxRadio'
import LabeledInput from '../../molecules/LabeledInput'

const View = styled(Space)``
const Header = styled.header`
  text-align: center;
`
const Content = styled.div`
  margin-top: ${space.lg}px;
  text-align: center;
`
const ButtonOuter = styled.div`
  margin-top: ${space.lg}px;
  width: 100%;
  text-align: center;
`
const CheckboxOuter = styled.div`
  margin-top: ${space.lg}px;
  color: ${colors.gray400};
`
const Controls = styled.div`
  margin-top: ${space.md}px;
`

export interface FormData {
  email: string
  password: string
  isAutoLogout: boolean
}

export interface Props {
  submitDisabled: boolean
  clickSignUpButton: () => void
  clickResetPasswordButton: () => void
  isProcessing: boolean
  submitForm: (values: any) => any
}

export default function LoginForm(props: Props & HandleSubmitProps) {
  return (
    <View {...props}>
      <Content>
        <form onSubmit={props.handleSubmit(props.submitForm)}>
          <Controls>
            <ReduxField
              autoFocus={true}
              component={LabeledInput}
              label="メールアドレス"
              name="email"
              placeholder="メールアドレスを入力する"
              type="email"
              validators={[required, emailFormat]}
            />
          </Controls>

          <Controls>
            <ReduxField
              component={LabeledInput}
              label="パスワード"
              name="password"
              placeholder="パスワードを入力する"
              type="password"
              validators={[required, passwordMinLength, passwordMaxLength]}
            />
          </Controls>

          <ButtonOuter>
            <Button
              backgroundColor={colors.primary}
              disabled={props.isProcessing}
              expanded={true}
              size="large"
            >
              ログインする
              <Space ml={space.xs}>
                {props.isProcessing ? (
                  <Loading size={18} />
                ) : (
                  <Icon type="enter" />
                )}
              </Space>
            </Button>
          </ButtonOuter>

          <CheckboxOuter>
            <LabeledCheckboxRadio
              options={[
                {
                  name: 'isAutoLogout',
                  label: '自動的にログアウトする',
                },
              ]}
            />
          </CheckboxOuter>
        </form>

        <Space mt={space.lg}>
          <TextButton onClick={props.clickResetPasswordButton}>
            パスワードをお忘れになった方
          </TextButton>
        </Space>
      </Content>
    </View>
  )
}
