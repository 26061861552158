import INoteTimelineRepository from '../../domain/note/timeline/INoteTimelineRepository'
import {NoteTimelineItemType} from '../../domain/note/timeline/NoteTimeline'
import NoteTimelineRepository from '../../infra/repositories/note/NoteTimelineRepository'
import FormService from '../../infra/services/FormService'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTimelineRepository: INoteTimelineRepository
  formService: IFormService
}

interface Params {
  noteId: string
  offset: number
  limit: number
}

const TimelineFilterTypes = [
  NoteTimelineItemType.noteCreated,
  NoteTimelineItemType.noteComment,
  NoteTimelineItemType.noteDiary,
  NoteTimelineItemType.noteUpdated,
  NoteTimelineItemType.noteAttrsUpdated,
]

export default class FetchTimelineUseCase extends UseCase {
  private _noteTimelineRepository: INoteTimelineRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTimelineRepository = props.noteTimelineRepository
    this._formService = props.formService
  }

  public static create() {
    return new FetchTimelineUseCase({
      noteTimelineRepository: NoteTimelineRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({noteId, offset, limit}: Params): Promise<void> {
    await this._noteTimelineRepository.get({
      noteId,
      types: TimelineFilterTypes,
      offset,
      limit,
    })
  }
}
