import INoteHistoryRepository from '../../../domain/note/INoteHistoryRepository'
import Note from '../../../domain/note/Note'
import NoteHistory from '../../../domain/note/NoteHistory'
import AppAPI from '../../api/AppAPI'
import {actions} from '../../redux/api/entities'
import {HistorySchema, NoteSchema, UserSchema} from '../../redux/api/schemata'
import {getNoteById} from '../../redux/api/selectors'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteHistoryRepository implements INoteHistoryRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteHistoryRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteHistoryRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  private mergedNoteWithState<AnyNote extends Note>({
    id,
    newProps,
  }: {
    id: string
    newProps: AnyNote
  }): AnyNote {
    const note = getNoteById(id)(this.redux.getState())
    const newNote = {...note, ...newProps}
    return newNote
  }

  public clear() {
    this.redux.dispatch(actions.clear(HistorySchema))
  }

  public async get(params: {
    noteId: string
    offset?: number
    limit?: number
  }): Promise<NoteHistory[]> {
    const {noteId, ...rest} = params
    const client = await this.api.getClient()
    const {history, users, pager, ...newNote} = await client.fetchNoteHistory({
      id: noteId,
      ...rest,
    })
    const note = this.mergedNoteWithState({
      id: noteId,
      newProps: newNote as Note,
    })
    this.redux.dispatch(actions.store(users, [UserSchema]))
    this.redux.dispatch(actions.store(history, [HistorySchema], pager))
    this.redux.dispatch(actions.store(note, NoteSchema))
    return history
  }
}
