import * as React from 'react'
import {LabeledIconListItem} from '../../layouts/LabledIconList'
import AccountList from '../AccountList'

export interface Props {
  list: LabeledIconListItem[]
  handleClickClose: () => void
}

export default function AccountPage({list, handleClickClose}: Props) {
  return <AccountList list={list} />
}
