const placeholderColor = (color: string) => {
  return `
    &::placeholder {
        color: ${color};
    }
    &::-webkit-input-placeholder {
        color: ${color};
    }
    &::-moz-placeholder {
        opacity: 1;
        color: ${color};
    }
    &:-moz-placeholder {
        opacity: 1;
        color: ${color};
    }
    &:-ms-input-placeholder {
        color: ${color};
    }
  `
}

export default placeholderColor
