import * as React from 'react'
import styled from 'styled-components'
import NoteTrackDiary from '../../../containers/NoteTrackDiary'
import NoteTrackItem from '../../../containers/NoteTrackItem'
import {NoteTrackDataType} from '../../../domain/note/track/NoteTrack'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Loading from '../../atoms/Loading'
import {paperMarginTop} from '../../atoms/Paper'
import Empty from '../../molecules/Empty'

const View = styled.div``

const LoadingOuter = styled.div`
  padding-top: ${space.xxl}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`

// FIXME: 場所
export enum NoteTrackItemPropsType {
  diary,
  track,
}

// FIXME: 場所
export interface NoteTrackItemProps {
  avatarSrc: string
  avatarName: string
  name: string
  date: string
  timestamp: Date
  data: {
    type: NoteTrackDataType
    quantity?: number // TOOD: unitはどうする
    memo?: string
    image?: string
    [key: string]: any
  }
  type: NoteTrackItemPropsType
  deletable: boolean
  editable: boolean
  trackId: string
  noteId: string
  title: string
  image: string
}

export interface Props {
  fetching: boolean
  list: NoteTrackItemProps[]
}

const TrackIgnoreListMap: NoteTrackDataType[] = ['weather', 'feeling']

export default function NoteTrackList(props: Props) {
  const {fetching, list} = props
  const items = list.map(item => {
    const {
      type,
      trackId,
      data: {type: dataType},
    } = item
    if (TrackIgnoreListMap.includes(dataType)) {
      return null
    }
    if (type === NoteTrackItemPropsType.diary) {
      const {diary} = item.data
      return (
        <NoteTrackDiary
          {...item}
          diary={diary || ''}
          key={item.trackId}
          mt={paperMarginTop}
          name="diary"
          timestamp={item.timestamp}
          trackId={item.trackId}
        />
      )
    } else {
      const {
        data: {type, image},
      } = item
      return (
        <NoteTrackItem
          {...item}
          image={image}
          key={trackId}
          mt="xxs"
          type={type}
        />
      )
    }
  })
  return (
    <View>
      {!fetching && !items.length ? (
        <Empty body={<span>日々の記録をしてみましょう！</span>} />
      ) : (
        items
      )}
      {!items.length && fetching && (
        <LoadingOuter>
          <Loading />
          <Body mt="sm">読み込み中...</Body>
        </LoadingOuter>
      )}
    </View>
  )
}
