import * as React from 'react'
import {Transition} from 'react-transition-group'
import {
  TransitionProps,
  TransitionStatus,
} from 'react-transition-group/Transition'
import styled from 'styled-components'
import {TransitionState} from '../../../styles'
import transition from '../../../styles/mixins/transition'

const View = styled.div<TransitionState>`
  display: block;
  ${transition({duration: 0.3})};
  transform: translateX(${({state}) => (state === 'entered' ? '0' : '100%')});
  pointer-events: none;
  > div {
    pointer-events: auto;
  }
`

export interface Props extends TransitionProps {
  children?: React.ReactNode
}

export default function TransitionRiseRight({children, ...rest}: Props) {
  return (
    <Transition {...rest}>
      {(state: TransitionStatus) => <View state={state}>{children}</View>}
    </Transition>
  )
}
