import IResourceRepository from '../../domain/resource/IResourceRepository'
import {ResourceUploadToken} from '../../domain/resource/ResourceUploadToken'
import {ResourceType} from '../../infra/api/types'
import ResourceRepository from '../../infra/repositories/resource/ResourceRepository'
import UseCase from '../lib/UseCase'
import FormService from '../../infra/services/FormService'
import IFormService from '../lib/IFormService'

interface Props {
  formService: IFormService
  resourceRepository: IResourceRepository
}

interface Params {
  file: any
  form: string
  field: string
  type: ResourceType
  token: ResourceUploadToken
}

export default class UploadNoteResourceUseCase extends UseCase {
  private formService: IFormService
  private resourceRepository: IResourceRepository

  public constructor(props: Props) {
    super()
    this.resourceRepository = props.resourceRepository
    this.formService = props.formService
  }

  public static create() {
    return new UploadNoteResourceUseCase({
      resourceRepository: ResourceRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({token, ...rest}: Params): Promise<void> {
    const {form, field} = rest
    const {url} = await this.resourceRepository.upload({
      token: token,
      id: field,
      ...rest,
    })
    this.formService.change(form, field, url)
  }
}
