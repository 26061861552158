import {endOfWeek, startOfWeek} from 'date-fns'
import * as React from 'react'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteTrackTimetable'
import {NoteTrackDataType} from '../../domain/note/track/NoteTrack'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId, getCurrentTrackDate} from '../../selectors/note'
import {getTrackTable} from '../../selectors/note/track'
import FetchTrackWeekUseCase from '../../useCases/track/FetchTrackWeekUseCase'

export interface Props {
  types: NoteTrackDataType[]
}

// TODO: 命名
export default function TrackWeekTable(props: Props) {
  const {types} = props
  const date = useSelector(getCurrentTrackDate)
  const beginDate = useMemo(() => startOfWeek(date), [date])
  const endDate = useMemo(() => endOfWeek(date), [date])

  const noteId = useSelector(getCurrentNoteId)
  const week = useSelector(getTrackTable(noteId, types, beginDate, endDate))

  const fetching = useSelector(
    useCaseSelectors.isProcessing(FetchTrackWeekUseCase),
  )

  useEffect(() => {
    date &&
      UseCaseCommander.create().command(FetchTrackWeekUseCase)({
        noteId,
        begin: beginDate,
        end: endDate,
      })
  }, [noteId, date])

  return React.createElement(View, {date, week, fetching})
}
