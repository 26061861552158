import {format, parseISO} from 'date-fns'
import * as React from 'react'
import styled from 'styled-components'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import colors from '../../../styles/variables/colors'
import {AvatarSize} from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Paper from '../../atoms/Paper'
import {replaceNewLine} from '../../helpers'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import TimelineHeader from '../../molecules/TimelineHeader'

export interface Props {
  avatarSrc: string
  date: string
  message: React.ReactNode
  content?: string
  timestamp: string
  noteId: string
}

const Clickable = styled.div`
  cursor: pointer;
`

const ShrinkedBody = styled(Body)`
  display: inline;
  p:last-child {
    display: inline;
    br {
      display: none;
    }
  }
`

const DiaryButton = (props: any) => <Icon {...props} type="calendar-check" />
const MoreButton = (props: any) => (
  <Body {...props} as="span" color={colors.blue100}>
    ...もっと見る
  </Body>
)

const SHRINK_LENGTH = 35

export default function NoteTimelineDiary(props: Props) {
  const {avatarSrc, content, date, message, timestamp, noteId, ...rest} = props
  const d = format(parseISO(timestamp), 'yyyy-MM-dd')
  const title = (
    <Row flexWrap="nowrap">
      <Col>{message}</Col>
      <Col ml="auto">
        <DiaryButton />
      </Col>
    </Row>
  )
  const shrink = content && content.substring(0, SHRINK_LENGTH)
  const isShrink = content && content.length > SHRINK_LENGTH
  return (
    <Paper {...rest}>
      <PushNavigationLink
        date={d}
        noteId={noteId}
        routeKey="track"
        view={props => (
          <Clickable {...props}>
            <TimelineHeader
              avatarSize={AvatarSize.small}
              avatarSrc={avatarSrc}
              date={date}
              title={title}
            />
            {content && (
              <>
                {isShrink ? (
                  <>
                    <ShrinkedBody mt="xs">
                      {replaceNewLine(shrink)}
                    </ShrinkedBody>
                    <MoreButton />
                  </>
                ) : (
                  <Body mt="xs">{replaceNewLine(shrink)}</Body>
                )}
              </>
            )}
          </Clickable>
        )}
      />
    </Paper>
  )
}
