import * as React from 'react'
import BackButton from '../../../containers/BackButton'
import HamburgerButton from '../../../containers/HamburgerButton'
import TrackDatePicker from '../../../containers/TrackDatePicker'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import HeaderLayout from '../../layouts/HeaderLayout'
import Row from '../../layouts/Row'
import LabeledIcon from '../../molecules/LabeledIcon'

export interface Props {
  date: Date
  onClickWeekTableLink: () => void
  onChange: (value: Date) => void
}

export default function DiaryHeader(props: Props) {
  const {date, onChange, onClickWeekTableLink} = props
  const right = (
    <Row gutter={space.sm + 5}>
      <Col>
        <LabeledIcon onClick={onClickWeekTableLink} type="resize-handle">
          日別
        </LabeledIcon>
      </Col>
      <Col>
        <HamburgerButton />
      </Col>
    </Row>
  )
  return (
    <HeaderLayout
      center={<TrackDatePicker date={date} onChange={onChange} />}
      left={<BackButton routeKey="noteCover" />}
      right={right}
    />
  )
}
