import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import space from '../../../styles/variables/space'

export interface Props {
  children: React.ReactNode
}

const View = styled.div`
  padding: ${space.xs}px 0 ${space.xl}px;
  display: block;
  ${spacing};
`

export default function List(props: Props) {
  const {children, ...rest} = props
  return <View {...rest}>{children}</View>
}
