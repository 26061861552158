import IResourceRepository from '../../domain/resource/IResourceRepository'
import {ResourceUploadToken} from '../../domain/resource/ResourceUploadToken'
import {ResourceType} from '../../infra/api/types'
import ResourceRepository from '../../infra/repositories/resource/ResourceRepository'
import UseCase from '../lib/UseCase'
import IFormService from '../lib/IFormService'
import FormService from '../../infra/services/FormService'
import {Forms, FormsType} from '../../services/forms'

interface Props {
  resourceRepository: IResourceRepository
  formService: IFormService
}

interface FormParams {
  form: FormsType
  field: string
}

interface Params {
  file: any
  id: string
  type: ResourceType
  token: ResourceUploadToken
  formParams?: FormParams
}

export default class UploadResourceUseCase extends UseCase {
  private resourceRepository: IResourceRepository
  private formService: IFormService

  public constructor(props: Props) {
    super()
    this.resourceRepository = props.resourceRepository
    this.formService = props.formService
  }

  public static create() {
    return new UploadResourceUseCase({
      resourceRepository: ResourceRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({token, formParams, ...rest}: Params): Promise<void> {
    const {url} = await this.resourceRepository.upload({
      token: token,
      ...rest,
    })
    if (formParams) {
      this.formService.change(formParams.form, formParams.field, url)
    }
  }
}
