import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import {NoteRelationProps} from '../../../domain/note/Note'
import {getThumbUrl} from '../../../domain/resource/Resource'
import {RelationType} from '../../../infra/api/types'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Col from '../../layouts/Col'
import Dialog from '../../layouts/Dialog'
import Row from '../../layouts/Row'
import DropdownButton, {DropdownMenuItem} from '../DropdownButton'
import LabeledIcon from '../LabeledIcon'

const View = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const UserInfoView = styled(Body)<{hasDeleted?: boolean}>`
  display: flex;
  align-items: center;
  text-decoration: ${p => (p.hasDeleted ? 'line-through' : 'none')};
`

const Multiline = styled.div``

const Email = styled(Body)`
  color: ${colors.gray400};
`

const YouLabel = styled(Body)`
  display: inline;
  color: ${colors.gray400};
`

const Controls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  > * {
    padding-left: ${space.md}px;
    white-space: nowrap;
  }
`

export interface Props {
  user: {
    fullname: string
    iconUrl: string
    email: string
    relation: NoteRelationProps
  }
  isDeletable: boolean
  isMe?: boolean
  showAuthorityDropdownButton: boolean
  setShowAuthorityDropdownButton: (val: boolean) => void
  memberAuthorityMenuItems: DropdownMenuItem[]
  handleAcceptClick: () => void
  handleDeleteClick: () => void
}

export default function EditNoteMemberItem({
  user: {iconUrl, fullname, email, relation},
  memberAuthorityMenuItems,
  isDeletable,
  isMe,
  showAuthorityDropdownButton,
  setShowAuthorityDropdownButton,
  handleAcceptClick,
  handleDeleteClick,
}: Props) {
  const [confirmDialog, setConfirmDialog] = useState(false)
  const isEditor = relation.type === RelationType.editor
  const isWatcher = relation.type === RelationType.watcher
  const isRequesting = relation.type === RelationType.requesting
  const handleDelete = () => {
    handleDeleteClick()
    setConfirmDialog(false)
  }
  return (
    <View>
      <Dialog
        header="メンバーを削除します"
        onClickClose={() => setConfirmDialog(false)}
        transitionIn={confirmDialog}
      >
        <Body type="body2">
          {fullname}をメンバーから削除してもよろしいですか？
        </Body>
        <Row justify="center" mt="md">
          <Col>
            <Button
              backgroundColor={colors.gray400}
              onClick={() => setConfirmDialog(false)}
            >
              キャンセル
            </Button>
          </Col>
          <Col>
            <Button onClick={handleDelete}>削除する</Button>
          </Col>
        </Row>
      </Dialog>

      <UserInfoView>
        <Avatar mr="xs" src={getThumbUrl(iconUrl)} />
        <Multiline>
          <Body>
            {' '}
            {fullname || '名無しさん'}
            {isMe && <YouLabel type="body2">(あなた)</YouLabel>}
          </Body>
          {email && <Email type="overline">({email})</Email>}
        </Multiline>
      </UserInfoView>

      <Controls>
        {(isEditor || isWatcher) && (
          <DropdownButton
            items={memberAuthorityMenuItems}
            setShow={setShowAuthorityDropdownButton}
            show={showAuthorityDropdownButton}
          />
        )}

        {isRequesting && (
          <LabeledIcon
            color={colors.success}
            onClick={handleAcceptClick}
            type="user-plus"
          >
            許可
          </LabeledIcon>
        )}
        {isDeletable && (
          <LabeledIcon
            color={colors.red100}
            onClick={() => setConfirmDialog(true)}
            type="user-minus"
          >
            削除
          </LabeledIcon>
        )}
      </Controls>
    </View>
  )
}
