import * as React from 'react'
import {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/GeneratedNoteForm'
import {getNoteDetailEditableByRelationType} from '../../domain/note/Note'
import {
  getFilteredNoteFields,
  getFilterQuery,
} from '../../infra/redux/note/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {generate, generateFiltered} from '../../components/helpers/snapshot'
import {
  getContentedSnapshot,
  getSnapshot,
  getSnapshotAt,
} from '../../selectors/api/snapshot'
import {getCurrentNote} from '../../selectors/note'
import FilterNoteFieldsUseCase, {
  Params as FilterNoteFieldsUseCaseParams,
} from '../../useCases/note/FilterNoteFieldsUseCase'

export default function GeneratedNoteForm() {
  const {id: noteId, relationToMe, needsHelpGuide} = useSelector(getCurrentNote)
  const editable = getNoteDetailEditableByRelationType(relationToMe.type)
  const visibleGuide = !!(editable && needsHelpGuide) // ISSUE: 汎用的なチュートリアルシステムができたら機能置き換え

  const snapshot = editable
    ? useSelector(getSnapshot)
    : useSelector(getContentedSnapshot)
  const snapshotAt = useSelector(getSnapshotAt)
  // TODO: 見つからない場合の表示。検索queryをstateにいれる
  const filterQuery = useSelector(getFilterQuery)
  const filteredSnapshot = useSelector(getFilteredNoteFields)
  // 必ずmemoizeしないと重たい
  const generated = useMemo(() => {
    return generate(noteId, relationToMe.type, snapshot)
  }, [snapshotAt])
  const filtered = useMemo(() => {
    return generateFiltered(noteId, relationToMe.type, filteredSnapshot)
  }, [filterQuery, snapshotAt])

  const onChangeSearchInput = useCallback(
    (query: string) => {
      UseCaseCommander.create().command<FilterNoteFieldsUseCaseParams>(
        FilterNoteFieldsUseCase,
      )({query: query})
    },
    [filterQuery],
  )

  // eslint-disable-next-line react/no-children-prop
  return React.createElement(View, {
    onChangeSearchInput,
    children: filterQuery ? filtered : generated,
    visibleGuide,
  })
}
