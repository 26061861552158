import {LogCategory, LogEvent} from './loggerTypes'

/**
 * ログにポストするデータ
 */
export interface LoggerParams {
  category: LogCategory
  event?: LogEvent | string
  intVal?: number
  strVal?: string

  contentId?: string // noteId など、表示中のものの ID。 noteId は自動で入るが、上書きしたい場合に指定

  data?: any // なんでもいれれるもの LogAPIClient では freeData
}

export interface ILogService {
  post(params: LoggerParams): Promise<void>
}

class NopLogService implements ILogService {
  public async post(params: LoggerParams): Promise<void> {
    console && console.log('NopLogService.post: ', params)
  }
}

export default class Logger {
  private static _service: ILogService = new NopLogService()

  public static init(service: ILogService) {
    this._service = service
  }

  public static post(params: LoggerParams) {
    this._service.post(params).catch(e => {
      console && console.error('Logger.post:', e, params)
    })
  }
}
