import {parseISO, startOfDay} from 'date-fns'
import {createSelector} from 'reselect'
import Note, {NullNoteObject} from '../../domain/note/Note'
import NoteId from '../../domain/note/NoteId'
import {dateToTimeStr} from '../../domain/note/track/NoteTrack'
import {getThumbUrl} from '../../domain/resource/Resource'
import {NoteState} from '../../infra/redux/note'
import * as noteSelectors from '../../infra/redux/note/selectors'
import {getFollowingList, getOwnedList} from '../api'
import {getNotes} from '../api/note'

export const getCurrentNoteIdByState = createSelector(
  noteSelectors.getState,
  (state: NoteState) => {
    return state.currentNoteId
  },
)

export const getCurrentNoteId = createSelector(
  getCurrentNoteIdByState,
  (noteId: NoteId) => {
    return noteId
  },
)

export const getCurrentNoteIdWithRoute = (routeNoteId: string) =>
  createSelector(
    getCurrentNoteIdByState,
    (noteId: NoteId) => {
      return routeNoteId || noteId
    },
  )

export const getCurrentNoteSlideId = createSelector(
  noteSelectors.getState,
  (state: NoteState) => {
    return state.currentNoteSlideId
  },
)

export const getCurrentTrackDateStr = createSelector(
  noteSelectors.getState,
  (state: NoteState) => {
    const {currentTrackDate} = state
    return currentTrackDate
  },
)

export const getCurrentTrackDate = createSelector(
  getCurrentTrackDateStr,
  (dateStr: string) => {
    const ret = dateStr ? parseISO(dateStr) : new Date()
    return startOfDay(ret)
  },
)

export const getCurrentTrackDateWithRoute = (routeDate: string) =>
  createSelector(
    getCurrentTrackDate,
    (currentTrackDate: Date) => {
      return routeDate
        ? parseISO(`${routeDate} ${dateToTimeStr(new Date())}`)
        : currentTrackDate
    },
  )

export const getCurrentNote = createSelector(
  getCurrentNoteId,
  getNotes,
  (noteId: string, notes: Note[]): Note => {
    const [note] = notes.filter(n => n.id === noteId)
    return note || NullNoteObject
  },
)

export const getNoteCardList = (notes: Note[]) => {
  // presentational component用の変換関数はここでいいのか
  return notes.map(note => {
    return {
      noteId: note.id,
      body: note.title,
      headerIconUrl: getThumbUrl(note.personImageUrl),
      headerTitle: note.personName,
      thumbnailImageUrl: note.imageUrl,
    }
  })
}

export const getOwnedNoteCardList = (query: string) =>
  createSelector(
    getOwnedList,
    (notes: Note[]) => {
      const filtered = notes.filter(note => note.personName.includes(query))
      return getNoteCardList(filtered)
    },
  )

export const getFollowingNoteCardList = (query: string) =>
  createSelector(
    getFollowingList,
    (notes: Note[]) => {
      const filtered = notes.filter(note => note.personName.includes(query))
      return getNoteCardList(filtered)
    },
  )

export const getSearchNoteQuery = createSelector(
  noteSelectors.getState,
  (state: NoteState) => {
    return state.searchNoteQuery
  },
)
