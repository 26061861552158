import {useEffect, useState} from 'react'
import {Breakpoint, defaultBreakpoints} from './mixins/breakpoints'

const matched = (query: string) => {
  return window.matchMedia(query).matches
}

export default function useMediaQuery(breakpoint: Breakpoint) {
  // FIXME: replace
  const query = defaultBreakpoints.up(breakpoint).replace('@media ', '')
  const [match, setMatch] = useState(matched(query))

  useEffect(() => {
    const updateMatch = () => setMatch(matched(query))

    updateMatch()
    const matchMedia = window.matchMedia(query)
    if (typeof matchMedia.addEventListener === 'function') {
      window.matchMedia(query).addEventListener('change', updateMatch)
    } else {
      window.matchMedia(query).addListener(updateMatch)
    }
    return () => {
      if (typeof matchMedia.removeEventListener === 'function') {
        window.matchMedia(query).removeEventListener('change', updateMatch)
      } else {
        window.matchMedia(query).removeListener(updateMatch)
      }
    }
  }, [query])

  return match
}
