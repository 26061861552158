import IResourceRepository from '../../domain/resource/IResourceRepository'
import {ResourceType} from '../../infra/api/types'
import ResourceRepository from '../../infra/repositories/resource/ResourceRepository'
import FormService from '../../infra/services/FormService'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  formService: IFormService
  resourceRepository: IResourceRepository
}

export interface Params {
  id?: string
  form: string
  field: string
  type: ResourceType
}

export default class RemoveNoteResourceUseCase extends UseCase {
  private formService: IFormService
  private resourceRepository: IResourceRepository

  public constructor(props: Props) {
    super()
    this.resourceRepository = props.resourceRepository
    this.formService = props.formService
  }

  public static create() {
    return new RemoveNoteResourceUseCase({
      resourceRepository: ResourceRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute(params: Params): Promise<void> {
    const {form, field, id, type} = params
    this.resourceRepository.remove({id: id ? id : field, type})
    this.formService.change(form, field, '')
  }
}
