import {createSelector} from 'reselect'
import Note from '../../../../domain/note/Note'
import NoteId from '../../../../domain/note/NoteId'
import {
  NoteMailNotifications,
  NoteMailNotificationType,
} from '../../../../domain/note/notifications/NoteMailNotificationType'
import {
  getNoteById,
  getNoteMailNotificationTypes,
} from '../../../../infra/redux/api/selectors'

export interface NoteMailNotificationTypeProp {
  help: string
  type: NoteMailNotificationType
  label: string
  checked: boolean
}

export type NoteMailNotificationTypeProps = NoteMailNotificationTypeProp[]

export const getNoteMailNotificationsTypesForView = (noteId: NoteId) =>
  createSelector(
    getNoteById(noteId),
    getNoteMailNotificationTypes,
    (
      note: Note,
      notifications: NoteMailNotifications,
    ): NoteMailNotificationTypeProps => {
      const {relationToMe: {mailTypes = []} = {}} = note
      return Object.keys(notifications).map(key => {
        const {label, help, type} = notifications[key]
        return {
          type,
          label,
          help,
          checked: mailTypes.includes(type),
        }
      })
    },
  )
