import * as React from 'react'
import styled, {keyframes} from 'styled-components'
import rotate from '../../../styles/keyframes/rotate'
import animation from '../../../styles/mixins/animation'
import colors from '../../../styles/variables/colors'

const dashKeyframe = () => {
  return keyframes`
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  `
}

const colorKeyframe = () => {
  return keyframes`
    100%,
    0% {
      stroke: ${colors.blue100};
    }
    40% {
      stroke: ${colors.red100};
    }
    66% {
      stroke: ${colors.green100};
    }
    80%,
    90% {
      stroke: ${colors.yellow100};
    }
  `
}

const View = styled.div<{size: number}>`
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Circle = styled.svg<{size: number}>`
  ${animation({
    duration: 2.0,
    easing: 'linear',
    iteration: 'infinite',
    keyframe: rotate(),
  })};
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Path = styled.circle`
  stroke-width: 3px;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dashKeyframe()} 1.5s ease-in-out infinite,
    ${colorKeyframe()} 6s ease-in-out infinite;
  stroke-linecap: round;
`

export interface Props {
  size?: number
}

const Loading = ({size = 60}: Props) => {
  return (
    <View size={size}>
      <Circle size={size} viewBox="25 25 50 50">
        <Path cx="50" cy="50" fill="none" r="20" />
      </Circle>
    </View>
  )
}

export default Loading
