import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import LazyImage from '../../atoms/LazyImage'
import Card from '../../molecules/Card'
import CardHeader from '../../molecules/CardHeader'

const Thumbnail = styled.div`
  z-index: 10;
  width: 100%;
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
`

const Content = styled.div`
  padding: ${space.sm}px ${space.sm}px;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
`

const ContentBody = styled(Body)`
  text-align: left;
  color: ${colors.gray400};
`

const Action = styled(Body)`
  display: flex;
  align-items: center;
  color: ${colors.primary};
`

export interface Props {
  headerTitle: string
  thumbnailImageUrl: string
  body?: string
  onClickThumbnail?: () => void
  onClickHeader?: () => void
  action: React.ReactNode
}

export default function NoteInvitationCard(props: Props) {
  const {action, body, headerTitle, thumbnailImageUrl, onClickThumbnail} = props
  return (
    <Card>
      <CardHeader title={headerTitle} />
      <Thumbnail onClick={onClickThumbnail && (e => onClickThumbnail())}>
        <LazyImage
          hasIndicator={true}
          height="100%"
          objectFit="cover"
          src={thumbnailImageUrl}
          width="100%"
        />
      </Thumbnail>
      <Content>
        <ContentBody type="body2">{body}</ContentBody>
        <Action ml="auto">{action}</Action>
      </Content>
    </Card>
  )
}
