/* tslint:disable:object-literal-sort-keys */
const unit = 16

const space = {
  xxs: 0.25 * unit, // 4px
  xs: 0.5 * unit, // 8px
  sm: 0.75 * unit, // 12px
  md: 1.25 * unit, // 20px
  lg: 2.0 * unit, // 32px
  xl: 3.25 * unit, // 52px
  xxl: 5.25 * unit, // 84px
}

export const VIEWPORT = 375
export const VIEWPORT_DESKTOP = 1800

export const getVw = (pixel: number, viewport: number = VIEWPORT) => {
  const rate = 100 / viewport
  return `${rate * pixel}vw`
}

export type SpaceType = keyof typeof space

export default space
