import * as React from 'react'
import {ReduxField} from '../../../hocs/withReduxField'
import {ReduxFieldProps} from '../../../lib/form/types'
import colors from '../../../styles/variables/colors'
import LineyInput from '../../atoms/LineyInput'
import Col from '../../layouts/Col'
import Body from '../../atoms/Body'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import Row from '../../layouts/Row'
import {Props as FormedFieldProps} from '../FormedField'

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps

const ReduxFormedInput = ({
  disabled,
  formDirty,
  meta,
  formMeta,
  label,
  help,
  isProcessing,
  onClickCancel,
  onClickHistory,
  attr: {suffix = ''},
  valid,
  ...rest
}: Props & ReduxFieldProps) => {
  const {active = false, touched = false, visited = false, error = ''} =
    meta || {}
  const err = touched ? error : ''
  return (
    <FormedFieldLayout
      active={active || visited}
      dirty={formDirty}
      disabled={disabled}
      error={err}
      help={help}
      isProcessing={isProcessing}
      label={label}
      onClickCancel={onClickCancel}
      onClickHistory={onClickHistory}
      valid={valid}
    >
      <Row align="flex-end" width="100%">
        <Col col="fit">
          <LineyInput disabled={disabled} {...rest} {...rest.input} />
        </Col>
        {suffix && (
          <Col>
            <Body color={colors.gray400} type="body2">
              {suffix}
            </Body>
          </Col>
        )}
      </Row>
    </FormedFieldLayout>
  )
}

/**
 * type: text
 */
export default function FormedTextField(props: Props) {
  const {attr: {validators = []} = {}} = props
  return (
    <ReduxField
      {...props}
      component={ReduxFormedInput}
      type={props.type}
      validators={validators}
    />
  )
}
