import {go, goBack, goForward, push, replace} from 'connected-react-router'
import {History} from 'history'
import queryString from 'query-string'
import {matchPath} from 'react-router-dom'
import {getHistory} from '../../infra/history'
import ReduxProvider from '../../infra/redux/ReduxProvider'
import {getRouteUrl, RouteKey} from '../../routes'
import {RouteCustomProps} from '../../routes/routes'

/**
 * Navigation
 */
export default class Navigation {
  /**
   * create
   */
  public static create() {
    if (Navigation._instance) {
      return Navigation._instance
    }
    return (Navigation._instance = new Navigation({
      redux: ReduxProvider.create(),
      history: getHistory(),
      window,
    }))
  }

  private static _instance: Navigation
  private routes: KeySignature = {}
  private redux: ReduxProvider
  private window: Window
  public history: History

  /**
   * constructor
   */
  public constructor(props: {
    redux: ReduxProvider
    history: History
    window: Window
  }) {
    this.redux = props.redux
    this.window = props.window
    this.history = props.history
  }

  /**
   * Routesを設定する
   * @param routes
   */
  public setRoutes(routes: KeySignature) {
    this.routes = routes
  }

  /**
   * go
   *
   * @param index
   */
  public go(index: number) {
    this.redux.dispatch(go(index))
  }

  /**
   * goBack
   */
  public goBack() {
    this.redux.dispatch(goBack())
  }

  /**
   * goForward
   */
  public goForward() {
    this.redux.dispatch(goForward())
  }

  /**
   * 履歴がある場合は「戻る」、ない場合は所定のpathに遷移する
   *
   * @param route - Route
   */
  public goBackOrPush(routeKey: RouteKey, params?: KeySignature<string>) {
    if (this.history.length > 1) {
      this.goBack()
    } else {
      this.push(routeKey, params)
    }
  }

  /**
   * push
   *
   * @param routeKey
   * @param params
   */
  public push(
    routeKey: RouteKey,
    params?: KeySignature<string>,
    query?: KeySignature,
    hash?: string,
  ) {
    const path = getRouteUrl(routeKey, params)
    this.redux.dispatch(
      push({
        pathname: path,
        hash,
        ...(query && {search: queryString.stringify(query)}),
      }),
    )
  }

  public pushByPath(path: string) {
    this.redux.dispatch(push({pathname: path}))
  }

  public pushSearch(query: KeySignature) {
    this.redux.dispatch(
      push({
        search: queryString.stringify(query),
      }),
    )
  }

  public pushState(state: {}) {
    this.redux.dispatch(push({state}))
  }

  /**
   * push modal
   * @param routeKey
   * @param params
   */
  public pushModal(routeKey: RouteKey, params?: KeySignature<string>) {
    const path = getRouteUrl(routeKey, params)
    this.redux.dispatch(push(path, {modal: true}))
  }

  /**
   * replace
   *
   * @param path - string
   */
  public replace(path: string) {
    this.redux.dispatch(replace(path))
  }

  /**
   * redux で replace を dispatch すると push と同じ挙動をし、
   * ページ遷移しリクエストが発生するため、historyにいれてパスを変えるだけの
   * 目的であればこちらを使う。
   */
  public replaceByHistory(routeKey: RouteKey, params?: KeySignature<string>) {
    const path = getRouteUrl(routeKey, params)
    this.history.replace(path)
  }

  /**
   * 外部リンクを別ウィンドウで開く
   *
   * @param url
   */
  public openExternal(url: string) {
    this.window.open(url, '_blank')
  }

  /**
   * 現在のLocationを返す
   * @returns {Location}
   */
  public getLocation() {
    return this.history.location
  }

  /**
   * 現在のRouteを返す
   *
   * @returns {RouteCustomProps}
   */
  public isCurrentRouteKey(routeKey: RouteKey): boolean {
    const result =
      this.routes &&
      Object.keys(this.routes).find(key => {
        const route = this.routes[key]
        return !!matchPath(this.history.location.pathname, {
          strict: true,
          ...(route as RouteCustomProps),
        })
      })
    if (!result) {
      throw new TypeError('Route Props does not exists.')
    }
    return result === routeKey
  }

  /**
   * 現在のRouteを返す
   *
   * @returns {RouteCustomProps}
   */
  public getCurrentRoute(): RouteCustomProps {
    const result =
      this.routes &&
      Object.keys(this.routes).find(key => {
        const route = this.routes[key]
        return !!matchPath(this.history.location.pathname, {
          strict: true,
          ...(route as RouteCustomProps),
        })
      })
    if (!result) {
      throw new TypeError('Route Props does not exists.')
    }
    return this.routes[result]
  }

  public getRouteByPathname(pathname: string): RouteCustomProps {
    const result =
      this.routes &&
      Object.keys(this.routes).find(key => {
        const route = this.routes[key]
        return !!matchPath(pathname, {
          strict: true,
          ...(route as RouteCustomProps),
        })
      })
    // FIXME: storybookのためにerror抑制
    // if (!result) {
    //   throw new TypeError('Route Props does not exists.')
    // }
    return result ? this.routes[result] : {}
  }
}
