import {
  NoteTimelineCommentsResponse,
  RelationType,
} from '../../../infra/api/types'
import User from '../../account/User'

export type NoteTimelineId = string

/**
 * タイムライン要素の種類です。
 */
export enum NoteTimelineItemType {
  noteComment = 'note_comment',
  noteCreated = 'note_created',
  noteUpdated = 'note_updated',
  noteAttrsUpdated = 'note_attrs_updated',
  noteDiary = 'note_diary',
}

export type NoteTimelineComment = NoteTimelineCommentsResponse

/**
 * タイムライン要素の共通プロパティです。
 */
interface NoteTimelineItemBase {
  id: NoteTimelineId
  type: NoteTimelineItemType
  userId: string
  timestamp: string
  comments: NoteTimelineComment[]
}

/**
 * ノートが作成された際のタイムライン要素です。
 */
export type NoteCreatedItem = NoteTimelineItemBase & {
  type: NoteTimelineItemType.noteCreated
  data: any
}

/**
 * ノートが更新された際のタイムライン要素です。
 */
export type NoteUpdatedItem = NoteTimelineItemBase & {
  type: NoteTimelineItemType.noteUpdated
  data: {
    labels: string[] // 更新された要素のラベル（ node の attr.label 相当 ）、最大５個くらいまで保管
    isOmit: boolean // labels が省略されたものかどうか
  }
}

/**
 * ノートのカバー属性が更新された際のタイムライン要素です。
 */
export type NoteAttrsUpdatedItem = NoteTimelineItemBase & {
  type: NoteTimelineItemType.noteAttrsUpdated
  data: any
}

/**
 * ノートのコメント投稿されたタイムライン要素です。
 */
export type NoteCommentItem = NoteTimelineItemBase & {
  type: NoteTimelineItemType.noteComment
  data: {comment: string}
}

/**
 * ノートのタイムラインのタイムライン要素のタイプです。
 */
export type NoteTimelineItem =
  | NoteCreatedItem
  | NoteUpdatedItem
  | NoteAttrsUpdatedItem
  | NoteCommentItem

/**
 * ノートのタイムラインのデータです。
 */
export default interface NoteTimeline {
  noteId: string
  timelineAt: string
  items: {[key: string]: NoteTimelineItem}
  users: {[key: string]: User}
  pager: {
    offset: number
    limit: number
    totalCount: number
    nextOffset: number
  }
}

export const getNullNoteTimeline = (noteId: string): NoteTimeline => ({
  noteId,
  timelineAt: new Date().toString(),
  items: {},
  users: {},
  pager: {
    offset: 0,
    limit: 0,
    totalCount: 0,
    nextOffset: 0,
  },
})

// export const getNoteTimeline = (note: Note): NoteTimeline =>
//   note.timeline || getNullNoteTimeline(note.id)

export const noteTimelineMySelf = (user: User, item: {userId: string}) => {
  return user && item && user.id === item.userId
}

export const deletableNoteTimeline = (
  user: User,
  timelineItem: NoteTimelineItem,
  relationTypeToMe: RelationType,
) => {
  const editableList = [RelationType.owner]
  return (
    noteTimelineMySelf(user, timelineItem) ||
    editableList.includes(relationTypeToMe)
  )
}

export const editableNoteTimeline = (
  user: User,
  timelineItem: NoteTimelineItem,
) => {
  return noteTimelineMySelf(user, timelineItem)
}

export const deletableNoteTimelineComment = (
  user: User,
  item: NoteTimelineComment,
  relationTypeToMe: RelationType,
) => {
  const editableList = [RelationType.owner]
  return (
    noteTimelineMySelf(user, item) || editableList.includes(relationTypeToMe)
  )
}

export enum FetchTimelineSortType {
  desc = 'desc',
  asc = 'asc',
}

export const TrackFilterTypes = [NoteTimelineItemType.noteDiary]

export function makeBeginEndCondition(
  baseDate: Date,
): {begin: string; end: string} {
  const begin = new Date(baseDate.getTime())
  begin.setHours(0, 0, 0, 0)
  const end = new Date(begin.getTime())
  end.setHours(23, 59, 59, 999)
  return {
    begin: begin.toISOString(),
    end: end.toISOString(),
  }
}

export interface TimelineCondition {
  types: NoteTimelineItemType[]
  sort: FetchTimelineSortType
  begin: string
  end: string
}

export function makeTrackCondition(baseDate: Date): TimelineCondition {
  return {
    types: TrackFilterTypes,
    sort: FetchTimelineSortType.asc,
    ...makeBeginEndCondition(baseDate),
  }
}
