import INoteEmergencyRepository from '../../../domain/note/INoteEmergencyRepository'
import AppAPI from '../../api/AppAPI'
import {toNoteEmergency} from '../../api/translators/note'
import {actions} from '../../redux/api/entities'
import {NoteEmergencySchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'
import NoteEmergency from '../../../domain/note/NoteEmergency'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteEmergencyRepository
  implements INoteEmergencyRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteEmergencyRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteEmergencyRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async getByToken({token}: {token: string}): Promise<NoteEmergency> {
    const client = await this.api.getClient()
    const res = await client.getNoteEmergency({token})
    const entity = toNoteEmergency(res)
    this.redux.dispatch(actions.store(entity, NoteEmergencySchema))
    return entity
  }
}
