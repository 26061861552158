import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/NoteHistoryPage'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {useRouter} from '../../lib/router'
import UpdateCurrentNoteIdUseCase from '../../useCases/app/UpdateCurrentNoteIdUseCase'

export default function NoteHistoryPage() {
  const {
    match: {
      params: {noteId},
    },
  } = useRouter<{noteId: string}>()
  const {title} = useSelector(getNoteById(noteId))

  useEffect(() => {
    UseCaseCommander.create().command(UpdateCurrentNoteIdUseCase)({noteId})
  })

  return React.createElement(View, {
    title: `${title}の編集履歴`,
  })
}
