import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteDetailHeader'
import {getNoteEmergencyCodeShowable} from '../../domain/note/Note'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNote} from '../../selectors/note'
import {getNoteEmergencyCodeById} from '../../selectors/note/emergency'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'
import FetchNoteEmergencyCodeUseCase, {Params as FetchNoteEmergencyCodeUseCaseParams} from '../../useCases/note/FetchNoteEmergencyCodeUseCase'

export default function NoteDetailHeader() {
  const note = useSelector(getCurrentNote)
  const {id: noteId} = note
  const emergencyCodeShowable = getNoteEmergencyCodeShowable(note)
  const noteEmergencyCode = useSelector(getNoteEmergencyCodeById(noteId)) || {}
  const {emergencyInfo: {url = ''} = {}} = noteEmergencyCode || {}

  const handleClickHisotryButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({
      routeKey: 'history',
      noteId,
    })
  }, [])

  useEffect(() => {
    emergencyCodeShowable &&
      UseCaseCommander.create().command<FetchNoteEmergencyCodeUseCaseParams>(
        FetchNoteEmergencyCodeUseCase,
      )({noteId})
  }, [noteId, emergencyCodeShowable])

  return React.createElement(View, {
    url,
    onClickHistoryButton: handleClickHisotryButton,
  })
}
