import IAccountRegistryService from '../../domain/account/IAccountRegistryService'
import AccessToken from '../../domain/auth/AccessToken'
import IAccessTokenRepository from '../../domain/auth/IAccessTokenRepository'
import {EmailVerificationToken} from '../../domain/varification/EmailVerificationToken'
import * as apiTranslator from '../api/ApiTranslator'
import AppAPI from '../api/AppAPI'
import AppAPIClient from '../api/AppAPIClient'
import {actions} from '../redux/api/entities'
import {MeSchema} from '../redux/api/schemata'
import ReduxProvider from '../redux/ReduxProvider'
import AccessTokenRepository from '../repositories/auth/AccessTokenRepository'

/**
 * AccountRegistryService
 */
export default class AccountRegistryService implements IAccountRegistryService {
  private _api: AppAPI
  private _redux: ReduxProvider
  private _accessTokenRepository: IAccessTokenRepository

  /**
   * constructor
   */
  public constructor(accessTokenRepository: IAccessTokenRepository) {
    this._accessTokenRepository = accessTokenRepository
    this._api = AppAPI.create()
    this._redux = ReduxProvider.create()
  }

  /**
   * create
   */
  public static create() {
    const accessTokenRepository = AccessTokenRepository.create()
    return new AccountRegistryService(accessTokenRepository)
  }

  /**
   * signUp
   */
  public async signUp({
    redirect,
    ...params
  }: {
    fullname: string
    email: string
    password: string
    redirect?: string
  }): Promise<void> {
    const client: AppAPIClient = await this._api.getClient()
    const bridgeData = redirect && {redirect}
    await client.signUp({...params, bridgeData})
  }

  public async verifyToken(
    verificationToken: EmailVerificationToken,
  ): Promise<{accessToken: AccessToken; redirect?: string}> {
    const client: AppAPIClient = await this._api.getClient()
    const res = await client.signUpVerify({
      token: verificationToken,
    })
    const newToken = apiTranslator.toAccessToken(res)
    await this._accessTokenRepository.save(newToken)
    const redirect =
      (typeof res.bridgeData === 'object' && res.bridgeData.redirect) ||
      undefined
    return {accessToken: newToken, redirect}
  }

  /**
   * updateEmail
   * @param params
   * @returns {Promise<void>}
   */
  public async updateProfile({
    fullname,
    nickname,
    iconUrl,
  }: {
    fullname: string
    nickname: string
    iconUrl: string
  }): Promise<void> {
    const client: AppAPIClient = await this._api.getClient()
    const res = await client.updateMe({
      fullname,
      nickname,
      iconUrl,
    })
    this._redux.dispatch(actions.store(res, MeSchema))
  }
}
