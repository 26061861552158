import * as React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const View = styled.div`
  font-size: 8px;
  font-weight: bold;
  line-height: 6px;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: #ff0000;
  border: 4px solid #ff0000;
  color: #ffffff;
  text-align: center;
`

/**
 * アイコンにつける赤いバッジ
 */
export function Badge({children}: Props) {
  return <View>{children}</View>
}
