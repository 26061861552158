import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View, {Props as ViewProps} from '../../components/organisms/NoteTimelineComment'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import DeleteNoteTimelineUseCase, {Params as DeleteNoteTimelineUseCaseParams} from '../../useCases/timeline/DeleteNoteTimelineUseCase'

// export type Props = Omit<ViewProps, 'onClickRemoveButton'>
export type Props = ViewProps

export default function NoteTimelineComment(props: Props) {
  const noteId = useSelector(getCurrentNoteId)
  const {timelineId} = props
  const handleClickRemoveButton = useCallback(() => {
    UseCaseCommander.create().command<DeleteNoteTimelineUseCaseParams>(
      DeleteNoteTimelineUseCase,
    )({noteId, timelineId})
  }, [noteId, timelineId])
  return React.createElement(View, {
    ...props,
    onClickRemoveButton: handleClickRemoveButton,
  })
}
