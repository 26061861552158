import debounce from 'lodash.debounce'

// TODO: 再帰
export function containsElement(
  target: HTMLElement,
  elements: (HTMLElement | null)[],
) {
  return elements.some(
    element => element === target || (element && element.contains(target)),
  )
}

export function dispatchWindowResize() {
  debounce(() => window.dispatchEvent(new Event('resize')), 100)()
}
