import * as React from 'react'
import {useState} from 'react'
import View from '../../components/organisms/NoteTrackTimetableTabs'

const typesMap = {
  0: ['meal'],
  1: ['pee', 'feces'],
  2: ['pills'],
  3: ['seizure'],
}

export default function TrackWeekTableTabs() {
  const [tab, setTab] = useState(0)

  return React.createElement(View, {
    types: typesMap[tab],
    tab,
    setTab,
  })
}
