import * as React from 'react'
import styled from 'styled-components'
import {TreeContainerType} from '../../../domain/note/snapshot/Tree'
import {
  spacing,
  SpacingProps as ViewProps,
} from '../../../styles/mixins/spacing'
import ListSubheader from '../../atoms/ListSubheader'
import Body from '../../atoms/Body'
import {replaceNewLine} from '../../helpers'

type MediaProps = {
  desktop?: ViewProps
} & ViewProps

export type SpaceProps = {
  children?: React.ReactNode
} & MediaProps

const View = styled.div``

const Content = styled.div`
  ${spacing};
`

export interface Props {
  id: string
  children: React.ReactNode
  type: TreeContainerType
  title: string
  description: string
}

export default function NoteSectionGroup(props: Props) {
  const {id} = props
  return (
    <View key={id} {...props}>
      <ListSubheader>{props.title}</ListSubheader>
      {props.description && (
        <Body my="sm" px="xs">
          {replaceNewLine(props.description)}
        </Body>
      )}
      <Content>{props.children}</Content>
    </View>
  )
}
