import * as React from 'react'
import {useSelector} from 'react-redux'
import Icon from '../../components/atoms/Icon'
import View from '../../components/molecules/Select'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import Navigation from '../../services/navigation/Navigation'
import space from '../../styles/variables/space'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from '../../useCases/app/PushNavigationUseCase'
import UpdateCurrentNoteIdUseCase, {
  Params as UpdateCurrentNoteIdUseCaseParams,
} from '../../useCases/app/UpdateCurrentNoteIdUseCase'
import {getNotesSelect} from './selectors'

const NOTE_INDEX_ID = '__index__'

export default function NoteSelect() {
  const items = useSelector(getNotesSelect)

  return React.createElement(View, {
    width: '100%',
    rowCount: 2,
    items: [
      {
        id: NOTE_INDEX_ID,
        noAvatar: true,
        title: (
          <div>
            <Icon mr={space.xs} type="arrow-left" />
            ノート一覧へ戻る
          </div>
        ),
      },
      ...items,
    ],
    onClickItem: (noteId: string) => {
      if (noteId === NOTE_INDEX_ID) {
        Navigation.create().push('index')
      } else {
        UseCaseCommander.create().command<UpdateCurrentNoteIdUseCaseParams>(
          UpdateCurrentNoteIdUseCase,
        )({noteId})
        UseCaseCommander.create().command<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({routeKey: 'noteCover', noteId})
      }
    },
  })
}
