import styled from 'styled-components'
import container from '../../../styles/mixins/container'
import {maxWidth, width} from '../../../styles/mixins/sizing'

const Container = styled.div`
  ${container};
  ${width};
  ${maxWidth};
`

export default Container
