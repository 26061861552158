import {actions} from '../../infra/redux/app'
import * as selectors from '../../infra/redux/app/selectors'
import {ModalType} from '../../infra/redux/app/types'
import ReduxProvider from '../../infra/redux/ReduxProvider'

/**
 * ApplicationService
 */
export default class ApplicationService {
  private static _instance: ApplicationService
  private redux: ReduxProvider

  /**
   * getInstance
   */
  public static create(): ApplicationService {
    if (ApplicationService._instance) {
      return ApplicationService._instance
    }
    const redux = ReduxProvider.create()
    return (ApplicationService._instance = new ApplicationService({redux}))
  }

  /**
   * constructor
   *
   * @param props - props
   */
  public constructor(props: {redux: ReduxProvider}) {
    this.redux = props.redux
  }

  /**
   * toInitialized
   */
  public toInitialized() {
    this.redux.dispatch(actions.toInitialized())
  }

  /**
   * toBusy
   */
  public toBusy() {
    this.redux.dispatch(actions.toBusy())
  }

  /**
   *  toUnbusy
   */
  public toUnbusy() {
    this.redux.dispatch(actions.toUnbusy())
  }

  public toggleMenu() {
    const isShowMenu = selectors.isShowNav(this.redux.getState())
    isShowMenu
      ? this.redux.dispatch(actions.hideNav())
      : this.redux.dispatch(actions.showNav())
  }

  public hideMenu() {
    this.redux.dispatch(actions.hideNav())
  }

  /**
   *
   */
  public showModal(type: ModalType) {
    this.redux.dispatch(actions.showModal(type))
  }
  /**
   *
   */
  public hideModal(type: ModalType) {
    this.redux.dispatch(actions.hideModal(type))
  }
}
