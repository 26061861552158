import IMeRepository from '../../domain/account/IMeRepository'
import MeRepository from '../../infra/repositories/account/MeRepository'
import UseCase from '../lib/UseCase'

interface Props {
  meRepository: IMeRepository
}

export default class FetchUserMeUseCase extends UseCase {
  private _meRepository: IMeRepository

  public constructor(props: Props) {
    super()
    this._meRepository = props.meRepository
  }

  public static create() {
    return new FetchUserMeUseCase({
      meRepository: MeRepository.create(),
    })
  }

  public async execute(): Promise<void> {
    await this._meRepository.get()
  }
}
