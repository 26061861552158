import * as React from 'react'
import {SyntheticEvent} from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {emailFormat, passwordMaxLength, passwordMinLength, required} from '../../../services/forms'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import {SpaceProps} from '../../layouts/Space'
import LabeledInput from '../../molecules/LabeledInput'

const Form = styled.form`
  ${spacing}
`
const ButtonOuter = styled.div`
  margin-top: ${space.lg}px;
  width: 100%;
  text-align: center;
`
const Controls = styled.div`
  margin-top: ${space.md}px;
`

export interface IFormProps {
  submitForm: (e: SyntheticEvent<any>) => void // FIXME: onSubmitForm だとreactのwarningになる
  submitDisabled: boolean
}

export interface IFormData {
  fullname: string
  email: string
  password: string
}

export type Props = SpaceProps &
  IFormProps & {
    isProcessing: boolean
    handleSubmit: (a: any) => any // FIXME: type
    onClickTerms: () => void
  }

export default function SignUpForm(props: Props) {
  const {onClickTerms, ...rest} = props
  return (
    <Form {...rest} onSubmit={props.handleSubmit(props.submitForm)}>
      <Controls>
        <ReduxField
          autoComplete="email"
          component={LabeledInput}
          label="メールアドレス"
          name="email"
          placeholder="メールアドレスを入力する"
          type="email"
          validators={[required, emailFormat]}
        />
      </Controls>

      <Controls>
        <ReduxField
          autoComplete="off"
          component={LabeledInput}
          label="パスワード"
          name="password"
          placeholder="パスワードを入力する"
          type="password"
          validators={[required, passwordMinLength, passwordMaxLength]}
        />
      </Controls>

      <Controls>
        <Body textAlign="left" type="caption">
          [アカウント登録]をクリックすることで、
          <Body
            as="span"
            color={colors.link}
            onClick={onClickTerms}
            type="caption"
          >
            利用規約
          </Body>
          に同意するものとします。
        </Body>
      </Controls>

      <ButtonOuter>
        <Button
          backgroundColor={colors.success}
          disabled={props.isProcessing}
          expanded={true}
          px={2}
          size="large"
          // disabled={props.submitDisabled}
        >
          <Row align="center" flexWrap="nowrap" gutter={space.xxs}>
            <Col>
              <Body fontSize={17}>利用規約に同意してアカウント登録</Body>
            </Col>
            <Col>
              {props.isProcessing ? (
                <Loading size={18} />
              ) : (
                <Icon type="user-plus" />
              )}
            </Col>
          </Row>
        </Button>
      </ButtonOuter>
    </Form>
  )
}
