import {format} from 'date-fns'
import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/WeeklyHeader'
import NoteManager from '../../infra/app/NoteManager'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as navigationSelectors from '../../selectors/app/navigation'
import {getCurrentNoteId, getCurrentTrackDateWithRoute} from '../../selectors/note'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'

export default function WeeklyHeader() {
  const noteId = useSelector(getCurrentNoteId)
  const routeDate = navigationSelectors.getDate()
  const currentTrackDate = useSelector(getCurrentTrackDateWithRoute(routeDate))

  useEffect(() => {
    NoteManager.create().setCurrentTrackDate(currentTrackDate)
  }, [routeDate])

  const onChange = useCallback(
    (value: Date) => {
      const routeDate = format(value, 'yyyy-MM-dd')
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({routeKey: 'trackWeek', date: routeDate, noteId})
    },
    [routeDate, noteId],
  )

  const onClickTrackLink = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'track', date: routeDate, noteId})
  }, [routeDate, noteId])

  return React.createElement(View, {
    date: currentTrackDate,
    onChange,
    onClickTrackLink,
  })
}
