import {useState} from 'react'
import * as React from 'react'
import {FieldArray, WrappedFieldArrayProps} from 'redux-form'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {borderRadius} from '../../../styles/mixins/border'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import Row from '../../layouts/Row'
import {Props as FormedFieldProps} from '../FormedField'
import StretchyInput from '../StretchyInput'
import StretchyTextarea from '../StretchyTextarea'

const Header = styled.header`
  padding: ${space.sm}px 0;
  text-align: right;
`

const FieldSectionHeader = styled.div`
  padding: ${space.sm}px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const FieldSection = styled.div`
  margin-top: ${space.sm}px;
  padding: 0 ${space.sm}px ${space.sm}px;
  ${borderRadius};
  border: solid 1px ${colors.border};
`

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps & {
    items: KeySignature
  }

const ReduxInput = (props: any /*ReduxFieldProps*/) => {
  return (
    <Row align="center">
      <Col col="auto">
        <Body>{props.label}</Body>
      </Col>
      <Col col="fit">
        <StretchyInput {...props} {...props.input} />
      </Col>
    </Row>
  )
}

const ReduxTextarea = (props: any /*ReduxFieldProps*/) => {
  return (
    <Row align="center">
      <Col col="auto">
        <Body>{props.label}</Body>
      </Col>
      <Col col="fit">
        <StretchyTextarea {...props} {...props.input} />
      </Col>
    </Row>
  )
}

interface CustomFieldArrayProps {
  disabled: boolean
  items: KeySignature
  onChangeFieldArray: (dirty: boolean) => void
}

type FieldValue = KeySignature
type FieldArrayProps = WrappedFieldArrayProps<FieldValue> &
  CustomFieldArrayProps

const FieldList = ({
  fields,
  disabled,
  items,
  onChangeFieldArray,
  meta,
}: FieldArrayProps) => {
  fields.length === 0 && fields.push({})
  const {dirty} = meta
  // FIXME: onChangeFieldArrayを呼ぶタイミングがわかりにくい
  onChangeFieldArray(dirty)
  const onRemove = (index: number) => {
    fields.remove(index)
    onChangeFieldArray(true)
  }
  const onPush = () => {
    fields.push({})
    onChangeFieldArray(true)
  }
  return (
    <div>
      {fields.map((field: string, index: number) => (
        <FieldSection key={`${field}.${index}`}>
          {!disabled && (
            <FieldSectionHeader>
              <Icon onClick={() => onRemove(index)} size={20} type="cross" />
            </FieldSectionHeader>
          )}
          <Row align="center" key={field}>
            {items &&
              Object.keys(items).map(key => {
                const p = items[key]
                // FIXME: FieldArrayでField Validation使えない？
                const {validators = [], ...rest} = p // eslint-disable-line @typescript-eslint/no-unused-vars
                const {type} = p
                const component =
                  type === 'textarea' ? ReduxTextarea : ReduxInput
                return (
                  <Col col={12} key={`${key}`} mt="xs">
                    <ReduxField
                      component={component}
                      disabled={disabled}
                      name={`${field}.${key}`}
                      {...rest}
                    />
                  </Col>
                )
              })}
          </Row>
        </FieldSection>
      ))}
      {!disabled && (
        <Header>
          <Button onClick={() => onPush()} type="button">
            追加する <Icon fontWegith={700} ml="xs" type="plus-circle" />
          </Button>
        </Header>
      )}
    </div>
  )
}

export default function FormedArrayField({
  anyTouched,
  anyActive,
  disabled,
  items,
  formDirty,
  formErrorMessage,
  isProcessing,
  onClickCancel,
  name,
  ...rest
}: Props) {
  const [touch, setTouch] = useState(false)
  const handleChangeFieldArray = (dirty: boolean) => {
    setTouch(dirty)
  }
  return (
    <FormedFieldLayout
      active={touch || anyActive || anyTouched}
      dirty={formDirty}
      disabled={disabled}
      error={formErrorMessage}
      isProcessing={isProcessing}
      onClickCancel={onClickCancel}
      {...rest}
    >
      <FieldArray
        component={FieldList}
        disabled={disabled}
        items={items}
        name={name}
        onChangeFieldArray={handleChangeFieldArray}
      />
    </FormedFieldLayout>
  )
}
