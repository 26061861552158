import {format, formatDistance, parseISO} from 'date-fns'
import {ja} from 'date-fns/locale'
import {createSelector} from 'reselect'
import {
  NoteTimelineItemProps,
  NoteTimelineItemPropsType,
} from '../../components/organisms/NoteTimeline'
import User from '../../domain/account/User'
import UsersMe from '../../domain/account/UsersMe'
import Note from '../../domain/note/Note'
import NoteId from '../../domain/note/NoteId'
import {
  deletableNoteTimeline,
  deletableNoteTimelineComment,
  editableNoteTimeline,
  NoteTimelineItem,
  NoteTimelineItemType,
} from '../../domain/note/timeline/NoteTimeline'
import {getThumbUrl} from '../../domain/resource/Resource'
import {RelationType} from '../../infra/api/types'
import {
  getNoteById,
  getNoteTimelineItemById,
  getNoteTimelineItems,
  getUsers,
} from '../../infra/redux/api/selectors'
import {getMe} from '../../selectors/api'
import {getCurrentNote} from '../../selectors/note'

export const getNoteTimelineItem = (
  noteId: NoteId,
  me: UsersMe,
  item: NoteTimelineItem,
  user: User,
  relationTypeToMe: RelationType,
): NoteTimelineItemProps => {
  const propsBase = {
    avatarSrc: (user && getThumbUrl(user.iconUrl)) || '',
    name: (user && user.fullname) || '',
    date: item && format(parseISO(item.timestamp), 'yyyy年MM月dd日 HH:mm'),
    timestamp: item.timestamp,
    type: NoteTimelineItemPropsType.message,
    deletable: deletableNoteTimeline(me, item, relationTypeToMe),
    editable: item && editableNoteTimeline(me, item),
    timelineId: item && item.id,
    noteId,
  }
  const map = {
    [NoteTimelineItemType.noteCreated]: () => {
      return {
        ...propsBase,
        message: `このノートを作成しました。`,
      }
    },
    [NoteTimelineItemType.noteComment]: () => {
      const {comment, image} = item.data
      return {
        ...propsBase,
        type: NoteTimelineItemPropsType.editable,
        comment,
        title: `${propsBase.name}がコメントしました`,
        image,
      }
    },
    [NoteTimelineItemType.noteDiary]: () => {
      const {diary} = item.data
      return {
        ...propsBase,
        diary,
        type: NoteTimelineItemPropsType.diary,
        message: `${propsBase.name}が${format(
          parseISO(item.timestamp),
          'M月d日',
        )}の日記を書きました`,
      }
    },
    [NoteTimelineItemType.noteAttrsUpdated]: () => {
      return {
        ...propsBase,
        message: 'ノートの表紙を変更しました。',
      }
    },
    [NoteTimelineItemType.noteUpdated]: () => {
      const NUM_SHOW_LABELES = 3
      const {data: {labels = [], isOmit = false} = {}} = item
      const isOmitting = NUM_SHOW_LABELES < labels.length || isOmit
      const omitPart = (isOmitting && '...他') || ''
      const labelPart = labels
        .slice(0, NUM_SHOW_LABELES) // 表示似合わせて slice
        .map((label: string) => `「${label}」`)
        .join('')
      const message = `${propsBase.name}が${labelPart}${omitPart}を更新しました。`
      return {
        ...propsBase,
        type: NoteTimelineItemPropsType.noteUpdate,
        message,
      }
    },
  }
  return item && (map[item.type]() as NoteTimelineItemProps)
}

export const getTimelineItemsByNoteId = (noteId: string) =>
  createSelector(
    getNoteTimelineItems, // note切り替えでrefreshされるので
    (items: NoteTimelineItem[]) => {
      return items
    },
  )

export const getTimelineItems = (noteId: string) =>
  createSelector(
    getMe,
    getNoteById(noteId),
    getTimelineItemsByNoteId(noteId),
    getUsers,
    (
      me: UsersMe,
      note: Note,
      timelineItems: NoteTimelineItem[],
      users: User[],
    ) => {
      return timelineItems
        .filter(v => v)
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return 1
          }
          if (a.timestamp > b.timestamp) {
            return -1
          }
          return 0
        })
        .map(item => {
          const user = (item && users[item.userId]) || {}
          return getNoteTimelineItem(
            noteId,
            me,
            item,
            user,
            note.relationToMe.type,
          )
        })
    },
  )

export const getTimelineComments = (timelineId: string) =>
  createSelector(
    getNoteTimelineItemById(timelineId),
    getCurrentNote,
    getUsers,
    (timelineItem: NoteTimelineItem, note: Note, users: User[]) => {
      const {id: noteId} = note
      const {comments = []} = timelineItem
      return comments
        .filter(v => v)
        .map(comment => {
          const {data: {comment: content = ''} = {}} = comment
          const user = (comment && users[comment.userId]) || {}
          return {
            avatarSrc: (user && getThumbUrl(user.iconUrl)) || '',
            avatarName: (user && user.fullname) || '',
            comment: content,
            date:
              comment &&
              `${formatDistance(parseISO(comment.createdAt), new Date(), {
                locale: ja,
              })}前`,
            deletable: deletableNoteTimelineComment(
              user,
              comment,
              note.relationToMe.type,
            ),
            editable: false,
            threadId: timelineId,
            commentId: comment.id,
            noteId,
          }
        })
    },
  )
