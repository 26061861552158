import * as React from 'react'
import styled from 'styled-components'
import withForwardRef from '../../../hocs/ref'
import {fontSize} from '../../../styles/mixins/font'
import placeholderColor from '../../../styles/mixins/placeholder'
import {body2} from '../../../styles/variables'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

const StyledInput = styled.input`
  padding: ${space.sm}px ${space.md}px;

  width: 100%;
  border: none;
  background-color: transparent;

  ${fontSize(body2.fontSize)};
  ${placeholderColor(colors.gray300)};

  transition: border 0.3s ease;
`

export type Props = React.InputHTMLAttributes<HTMLInputElement>

/**
 * Text入力
 */
export default withForwardRef(StyledInput)
