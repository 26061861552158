import * as React from 'react'
import styled from 'styled-components'
import transition from '../../../styles/mixins/transition'
import {
  typography,
  TypographyBreakPointsProps,
} from '../../../styles/mixins/typography'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

interface StyledProps {
  backgroundColor?: string
  borderColor?: string
  color?: string
  expanded?: boolean
}

export type Props =
  // React.ButtonHTMLAttributes<HTMLButtonElement> &
  StyledProps &
    TypographyBreakPointsProps & {
      active?: boolean
      color?: string
      children: React.ReactNode
    }

const StyledButton = styled.div<StyledProps & {active: boolean}>`
  ${transition};
  ${typography}
  
  padding: ${space.sm}px ${space.lg}px;
  width: auto !important;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  appearance: none;
  border: none;
  border-bottom: solid 1px ${p => (p.active ? p.borderColor : colors.gray300)};
  background-color: ${p => p.backgroundColor};
  color: ${p => p.color};
  opacity: ${p => (p.active ? 1.0 : 0.5)};

  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    border: solid 1px ${colors.gray400};
    color: ${colors.gray400};
    background-color: transparent;
    opacity: 0.5;
    cursor: auto;
  }
`

/**
 * Tab
 */
export default function Tab({
  active = false,
  color = colors.black,
  borderColor = colors.brand,
  backgroundColor = 'transparent',
  children,
  ...props
}: Props) {
  return (
    <StyledButton
      active={active}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
      {...props}
    >
      {children}
    </StyledButton>
  )
}
