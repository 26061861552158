import * as React from 'react'
import FormedArrayField from '../../components/molecules/FormedArrayField'
import FormedCheckboxRadioField from '../../components/molecules/FormedCheckboxRadioField'
import FormedFillTextField from '../../components/molecules/FormedFillTextField'
import FormedMultiTextField from '../../components/molecules/FormedMultiTextField'
import FormedTextareaField from '../../components/molecules/FormedTextareaField'
import FormedTextField from '../../components/molecules/FormedTextField'
import FormedNoteImageField from '../../containers/FormedNoteImageField'
import {
  TreeContainerType,
  TreeContainerTypes,
  TreeLeafType,
  TreeType
} from '../../domain/note/snapshot/Tree'
import NoteField from '../NoteField'

const containerComponentMap: Record<
  TreeContainerType,
  React.ComponentType<any> | string
> = {
  root: 'div',
  h1: 'div',
  h2: 'div',
  h3: 'div',
}

const leafComponentMap: Record<TreeLeafType, React.ComponentType<any>> = {
  array: FormedArrayField,
  text: FormedTextField,
  freeText: FormedTextareaField,
  fillText: FormedFillTextField,
  multiText: FormedMultiTextField,
  checkbox: FormedCheckboxRadioField,
  radio: FormedCheckboxRadioField,
  image: FormedNoteImageField,
  address: FormedTextareaField,
  tel: FormedTextField,
  date: FormedTextField,
  number: FormedTextField,
}

export const getContainerComponentType = (type: TreeContainerType) => {
  return containerComponentMap[type]
}

export const getLeafComponentType = (type: TreeLeafType) => {
  return leafComponentMap[type] || FormedTextField
}

export default function getNoteComponentType(type: TreeType) {
  return Object.keys(TreeContainerTypes).includes(type)
    ? getContainerComponentType(type as TreeContainerType)
    : NoteField
}
