import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'
import {spacing} from '../../../styles/mixins/spacing'
import space from '../../../styles/variables/space'

const Paper = styled.div`
  padding: ${space.sm}px ${space.sm}px;
  ${spacing}
  ${borderRadius};
  ${boxShadow};
  overflow: hidden;
  background-color: #fff;
`

export const paperMarginTop = 5

export default Paper
