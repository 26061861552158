import INoteTrackRepository from '../../domain/note/track/INoteTrackRepository'
import {makeTrackDailySumsCondition} from '../../domain/note/track/NoteTrack'
import NoteTrackRepository from '../../infra/repositories/note/NoteTrackRepository'
import UseCase from '../lib/UseCase'

interface Props {
  noteTrackRepository: INoteTrackRepository
}

interface Params {
  noteId: string
  date: Date
}

export default class FetchTrackDailySumsUseCase extends UseCase {
  private _noteTrackRepository: INoteTrackRepository

  public constructor(props: Props) {
    super()
    this._noteTrackRepository = props.noteTrackRepository
  }

  public static create() {
    return new FetchTrackDailySumsUseCase({
      noteTrackRepository: NoteTrackRepository.create(),
    })
  }

  public async execute({date, noteId}: Params): Promise<void> {
    const condition = makeTrackDailySumsCondition(date)
    await this._noteTrackRepository.getDailySums({noteId, ...condition})
  }
}
