import * as React from 'react'
import styled from 'styled-components'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {color} from '../../../styles/mixins/typography'
import {Badge} from '../../atoms/Badge'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'

const View = styled.div<{cursor: string} & SpacingProps>`
  ${spacing};
  ${color}
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: ${p => p.cursor};
  position: relative;
`

const Label = styled(Body)`
  ${spacing};

  white-space: nowrap;
`

const BadgeOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export interface Props {
  type: string
  children?: React.ReactNode
  noCursor?: boolean
  badgeNum?: number
  iconSize?: number
  labelSize?: number
}

const LabeledIcon = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      type,
      noCursor = false,
      badgeNum,
      iconSize = 22,
      labelSize = 9,
      ...rest
    }: Props,
    ref,
  ) => {
    const cursor = (noCursor && 'default') || 'pointer'
    return (
      <View {...rest} cursor={cursor} ref={ref}>
        {(badgeNum && (
          <BadgeOuter>
            <Badge>{badgeNum}</Badge>
          </BadgeOuter>
        )) ||
          ''}
        <Icon size={iconSize} type={type} />
        <Label mt="xxs" size={labelSize}>
          {children}
        </Label>
      </View>
    )
  },
)

export default LabeledIcon
