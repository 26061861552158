import * as React from 'react'
import {useRef} from 'react'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import ListItem from '../../layouts/ListItem'
import ListItemText from '../ListItemText'
import Menu, {ActionMenuItem} from '../Menu'

const View = styled.div`
  cursor: pointer;
`

const Button = styled.div`
  ${borderRadius};
  border: solid 1px ${colors.border};
  padding: ${space.xs}px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export type DropdownMenuItem = ActionMenuItem & {
  active?: boolean
}

export interface Props {
  show: boolean
  setShow: (val: boolean) => void
  items: DropdownMenuItem[]
}

export default function DropdownButton(props: Props) {
  const {items, show, setShow} = props
  // const [showMenu, setShowMenu] = useState(false)
  const element = useRef<HTMLDivElement>(null)

  const [current] = items.filter(item => item.active)
  const list = items.map((props, index) => {
    const {active, label, onClick, subLabel = ''} = props as DropdownMenuItem
    const weight = active ? 700 : 400
    return (
      <ListItem key={index} onClick={onClick}>
        <ListItemText
          primary={label}
          primaryWeight={weight}
          secondary={subLabel}
        />
        {active && (
          <Icon
            color={colors.success}
            ml="xs"
            size={18}
            type="check"
            weight={700}
          ></Icon>
        )}
      </ListItem>
    )
  })
  return (
    <View>
      <Button onClick={() => setShow(!show)} ref={element}>
        <Body>{current.label}</Body>
        <Icon ml="xs" type="chevron-down"></Icon>
      </Button>
      {show && (
        <Menu onClose={() => setShow(false)} trigger={element.current}>
          {list}
        </Menu>
      )}
    </View>
  )
}
