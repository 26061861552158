const colors = {
  brand: '#5FBEC0',
  black: '#000',
  blue100: '#5FBEC0',
  body: '#3e3e3e',
  error: '#EA987F',
  green100: '#A6D09B',
  gray25: '#fcfcfc',
  gray50: '#f8f8f8',
  gray70: '#f6f6f6',
  gray100: '#F4F4F4',
  gray200: '#DEDEDE',
  gray300: '#b9b9b9',
  gray350: '#9e9e9e',
  gray400: '#848484',
  gray800: '#515151',
  red100: '#EA987F',
  brown100: '#c6a87c',
  yellow100: '#F8C762',
  white: '#ffffff',
  transparent: 'transparent',
}

export default {
  ...colors,
  bg: colors.gray50,
  body: '#3e3e3e',
  header: '#fff',
  border: colors.gray200,
  overlay: 'rgba(255,255,255, 0.4)',
  overlayBlack: 'rgba(0, 0, 0, 0.2)',
  success: colors.green100,
  primary: colors.brand,
  input: '#fffaf0',
  link: colors.blue100,
  blank: colors.gray200,
}
