import {
  addDays,
  endOfDay,
  endOfMonth,
  format,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth
} from 'date-fns'
import {
  NoteTimelineCommentsResponse,
  RelationType,
  TrackRelationType
} from '../../../infra/api/types'
import User from '../../account/User'
import Note from '../Note'

export type NoteTrackId = string

// FIXME: 場所
export const NoteTrackDataTypes = {
  feeling: 'feeling',
  weather: 'weather',
  meal: 'meal', // 食事
  pee: 'pee', // しっこ
  feces: 'feces', // うんこ
  seizure: 'seizure', // 発作
  pills: 'pills', // 発作
  weight: 'weight', // 体重
  temperature: 'temperature', // 体温
  // bloodPressure: 'bloodPressure', // 血圧
  spo2: 'spo2', // 酸素濃度
  heartRate: 'heartRate', // 心拍数
}
export type NoteTrackDataType = keyof typeof NoteTrackDataTypes

export enum NoteTrackItemType {
  noteDiary = 'note_diary',
  noteTrack = 'note_track',
}

export type NoteTrackComment = NoteTimelineCommentsResponse

export interface NoteTrackItem {
  data: any
  id: NoteTrackId
  type: NoteTrackItemType
  userId: string
  timestamp: string
  comments: NoteTrackComment[]
}

/**
 * 日々の記録のデータです。
 */
export default interface NoteTrack {
  noteId: string
  timelineAt: string
  items: {[key: string]: NoteTrackItem}
  users: {[key: string]: User}
  pager: {
    offset: number
    limit: number
    totalCount: number
    nextOffset: number
  }
}

export const noteTrackMySelf = (user: User, item: {userId: string}) => {
  return user && item && user.id === item.userId
}

export const deletableNoteTrack = (
  user: User,
  item: NoteTrackItem,
  relationTypeToMe: RelationType,
) => {
  const deletableList = [RelationType.owner]
  return noteTrackMySelf(user, item) || deletableList.includes(relationTypeToMe)
}

export const editableNoteTrack = (user: User, timelineItem: NoteTrackItem) => {
  return noteTrackMySelf(user, timelineItem)
}

export const deletableNoteTrackComment = (
  user: User,
  item: NoteTrackComment,
  relationTypeToMe: RelationType,
) => {
  const deletableList = [RelationType.owner]
  return noteTrackMySelf(user, item) || deletableList.includes(relationTypeToMe)
}

export enum FetchTrackSortType {
  desc = 'desc',
  asc = 'asc',
}

export const TrackFilterTypes = [
  NoteTrackItemType.noteDiary,
  NoteTrackItemType.noteTrack,
]

export function makeBeginEndCondition(
  baseDate: Date,
): {begin: string; end: string} {
  const begin = startOfDay(baseDate)
  const end = endOfDay(baseDate)
  return {
    begin: begin.toISOString(),
    end: end.toISOString(),
  }
}

export function makeWeekBeginEndCondition(
  begin: Date,
  end: Date,
): {begin: string; end: string} {
  return {
    begin: begin.toISOString(),
    end: end.toISOString(),
  }
}

export function makeCalendarBeginEndCondition(
  baseDate: Date,
): {begin: string; end: string} {
  const begin = addDays(startOfMonth(startOfDay(baseDate)), -6)
  const end = addDays(endOfMonth(endOfDay(baseDate)), 6)
  return {
    begin: begin.toISOString(),
    end: end.toISOString(),
  }
}

export interface TrackCondition {
  types: NoteTrackItemType[]
  sort?: FetchTrackSortType
  begin: string
  end: string
}

export function makeTrackCondition(baseDate: Date): TrackCondition {
  return {
    types: TrackFilterTypes,
    sort: FetchTrackSortType.asc,
    ...makeBeginEndCondition(baseDate),
  }
}

export function makeTrackWeekCondition(begin: Date, end: Date): TrackCondition {
  return {
    types: TrackFilterTypes,
    sort: FetchTrackSortType.asc,
    ...makeWeekBeginEndCondition(begin, end),
  }
}

export function makeTrackDailySumsCondition(baseDate: Date): TrackCondition {
  return {
    types: TrackFilterTypes,
    ...makeCalendarBeginEndCondition(baseDate),
  }
}

// FIXME: Noteが引数でいいのか
export const getNoteTrackEditableToMe = (note: Note): boolean => {
  const {relationToMe: {trackType = TrackRelationType.none} = {}} = note || {}
  const editableList = [TrackRelationType.editor]
  return editableList.includes(trackType)
}

export const stringToTimestamp = (s: string): Date | null =>
  isValid(parseISO(s)) ? parseISO(s) : null

export const dateTimeStrToTimestamp = (
  date: string = format(new Date(), 'yyyy-MM-dd'),
  time: string = format(new Date(), 'HH:mm'),
): Date | null => stringToTimestamp(`${date} ${time}`)

export const dateToDateStr = (t: Date) => format(t, 'yyyy-MM-dd')
export const dateToTimeStr = (t: Date) => format(t, 'HH:mm')
export const dateToTimestampStr = (date: Date) => {
  return `${dateToDateStr(date)} ${dateToTimeStr(date)}`
}

export const stringToDateStr = (s: string): string =>
  dateToTimeStr(stringToTimestamp(s) || new Date())
