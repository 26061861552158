import * as React from 'react'
import ConfirmEmailVerificationPage from '../components/pages/ConfirmEmailVerificationPage'
import CreateNotePage from '../components/pages/CreateNotePage'
import NotFoundPage from '../components/pages/NotFoundPage'
import RequestResetPasswordPage from '../components/pages/RequestResetPasswordPage'
import ResetPasswordPage from '../components/pages/ResetPasswordPage'
import SignUpPage from '../components/pages/SignUpPage'
import UpdateProfilePage from '../components/pages/UpdateProfilePage'
import DefaultPage from '../components/templates/DefaultPage'
import AccountPage from '../containers/AccountPage'
import ActivateEmailVerification from '../containers/ActivateEmailVerification'
import EditNoteCoverPage from '../containers/EditNoteCoverPage'
import EditNoteMembersPage from '../containers/EditNoteMembersPage'
import IndexPage from '../containers/IndexPage'
import LoginPage from '../containers/LoginPage'
import NoteEmergencyCodePrintPage from '../containers/NoteEmergencyCodePrintPage'
import NoteHistoryPage from '../containers/NoteHistoryPage'
import NoteInvitationPage from '../containers/NoteInvitationPage'
import NoteCoverPage from '../containers/NotesCoverPage'
import NoteDetailPage from '../containers/NotesDetailPage'
import NoteEmergencyPage from '../containers/NotesEmergencyPage'
import NoteTrackPage from '../containers/NoteTrackPage'
import NoteTrackWeekPage from '../containers/NoteTrackWeekPage'
import SettingsNotificationsPage from '../containers/SettingsNotificationsPage'
import UserNotificationsPage from '../containers/UserNotificationsPage'

export enum RouteScope {
  public = 'public',
  private = 'private',
}

export interface RouteCustomProps {
  component: any
  exact?: boolean
  path: string
  scope: RouteScope
}

const routes = {
  index: {
    component: () => <IndexPage />,
    exact: true,
    path: '/',
    scope: RouteScope.public,
  },
  login: {
    component: () => (
      <DefaultPage titleTag="ログイン">
        <LoginPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/login',
    scope: RouteScope.public,
  },
  signUp: {
    component: () => <SignUpPage />,
    exact: true,
    path: '/signup',
    scope: RouteScope.public,
  },
  signUpVerify: {
    component: () => (
      <DefaultPage title={'アカウント作成'}>
        <ActivateEmailVerification />
      </DefaultPage>
    ),
    exact: true,
    path: '/signup-verify/:emailVerificationToken',
    scope: RouteScope.public,
    header: {
      title: 'アカウント作成',
    },
  },
  confirmEmailVerification: {
    component: () => (
      <DefaultPage title={'認証用メールを送信しました'}>
        <ConfirmEmailVerificationPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/confirm-email',
    scope: RouteScope.public,
  },
  requestResetPassword: {
    component: () => (
      <DefaultPage back={true} title={'パスワードリセット要求'}>
        <RequestResetPasswordPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/request-reset-password',
    scope: RouteScope.public,
  },
  resetPassword: {
    component: () => (
      <DefaultPage title={'パスワードリセット'}>
        <ResetPasswordPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/reset-password/:email/:token',
    scope: RouteScope.public,
  },
  account: {
    component: () => (
      <DefaultPage back={true} title={'設定'}>
        <AccountPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/account',
    scope: RouteScope.private,
  },
  updateProfile: {
    component: () => (
      <DefaultPage back={true} title={'アカウント情報の編集'}>
        <UpdateProfilePage />
      </DefaultPage>
    ),
    exact: true,
    path: '/update-profile',
    scope: RouteScope.private,
  },
  userNotifications: {
    component: () => <UserNotificationsPage />,
    exact: true,
    path: '/notifications',
    scope: RouteScope.private,
  },
  noteCover: {
    component: () => <NoteCoverPage />,
    exact: true,
    path: '/notes/:noteId',
    scope: RouteScope.private,
  },
  noteDetail: {
    component: () => <NoteDetailPage />,
    exact: true,
    path: '/notes/:noteId/detail',
    scope: RouteScope.private,
  },
  noteContacts: {
    component: () => <NoteDetailPage slideIndex={1} />,
    exact: true,
    path: '/notes/:noteId/contacts',
    scope: RouteScope.private,
    header: {
      back: true,
    },
  },
  track: {
    component: () => <NoteTrackPage />,
    exact: true,
    path: '/notes/:noteId/track/:date?',
    scope: RouteScope.private,
  },
  trackWeek: {
    component: () => <NoteTrackWeekPage />,
    exact: true,
    path: '/notes/:noteId/track-week/:date?',
    scope: RouteScope.private,
  },
  history: {
    component: () => <NoteHistoryPage />,
    exact: true,
    path: '/notes/:noteId/history',
    scope: RouteScope.private,
  },
  createNote: {
    component: () => (
      <DefaultPage back={true} title={'新しいノートを作る'}>
        <CreateNotePage />
      </DefaultPage>
    ),
    exact: true,
    path: '/create-note',
    scope: RouteScope.private,
  },
  editNoteCover: {
    component: () => (
      <DefaultPage back={true} title={'ノート表紙の編集'}>
        <EditNoteCoverPage />
      </DefaultPage>
    ),
    exact: true,
    path: '/notes/:noteId/edit',
    scope: RouteScope.private,
  },
  noteInvitation: {
    component: () => (
      <DefaultPage title="じぶんノートへの招待状">
        <NoteInvitationPage />
      </DefaultPage>
    ),
    path: '/invite/:token',
    scope: RouteScope.public,
  },
  noteEmergency: {
    component: () => (
      <DefaultPage title="たすけてQR">
        <NoteEmergencyPage />
      </DefaultPage>
    ),
    path: '/emergency/:token',
    scope: RouteScope.public,
  },
  noteEmergencyCodePrint: {
    component: () => <NoteEmergencyCodePrintPage />,
    exact: true,
    path: '/notes/:noteId/emergency/print',
    scope: RouteScope.private,
  },
  editNoteMembers: {
    component: () => <EditNoteMembersPage />,
    path: '/notes/:noteId/members/edit',
    scope: RouteScope.private,
  },
  settingsNoteNotifications: {
    component: () => <SettingsNotificationsPage />,
    path: '/notes/:noteId/notifications',
    scope: RouteScope.private,
  },
  notFound: {
    component: () => (
      <DefaultPage title="404 Not Found">
        <NotFoundPage />
      </DefaultPage>
    ),
    path: '**',
    scope: RouteScope.public,
  },
}

export default routes
