import * as React from 'react'
import {useSelector} from 'react-redux'
import {default as View, ItemProps} from '../../components/organisms/MeNoticeBoard'
import MeNotice, {getMeNotices} from '../../domain/account/MeNotice'
import UsersMe, {getMeName} from '../../domain/account/UsersMe'
import {getMe} from '../../selectors/api'
import Navigation from '../../services/navigation/Navigation'

const mapNoticeToItemProp = ({message, link}: MeNotice): ItemProps => {
  const linkProps = link && {
    label: link.label,
    onClick: () => {
      Navigation.create().pushByPath(link.path) // ISSUE: UseCase
    },
  }
  return {
    message,
    link: linkProps,
  }
}

export default function MeNoticeBoard() {
  const me = useSelector(getMe) as UsersMe
  const notices = getMeNotices(me)
  const meName = getMeName(me)

  return React.createElement(View, {
    meName,
    items: notices.map(mapNoticeToItemProp),
  })
}
