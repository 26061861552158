import INoteInvitationCodeRepository from '../../domain/note/INoteInvitationCodeRepository'
import NoteInvitationCodeRepository from '../../infra/repositories/note/NoteInvitationCodeRepository'
import ApplicationService from '../../services/app/ApplicationService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  noteInvitationCodeRepository: INoteInvitationCodeRepository
}

export interface Params {
  noteId: string
}

export default class RefreshNoteInvitationCodeUseCase extends UseCase {
  private noteInvitationCodeRepository: INoteInvitationCodeRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteInvitationCodeRepository = props.noteInvitationCodeRepository
  }

  /**
   * create
   */
  public static create() {
    return new RefreshNoteInvitationCodeUseCase({
      noteInvitationCodeRepository: NoteInvitationCodeRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({noteId}: Params): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: 'QRコードを変更しました',
      })
    })
    await this.noteInvitationCodeRepository.refreshInvitationCode(noteId)
  }
}
