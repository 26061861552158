import * as React from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import {spacing} from '../../../styles/mixins/spacing'
import Dialog from '../../layouts/Dialog'

const Inner = styled.div`
  z-index: 10;
  position: relative;
  display: flex;

  text-align: center;
  ${spacing}
`

export interface Props {
  message: string
  dismiss: () => void
}

// TODO: 文言
export default function ErrorDialog(props: Props) {
  return (
    <Dialog
      header="エラーが発生しました"
      onClickClose={props.dismiss}
      transitionIn={!!props.message}
    >
      <Inner p="lg">
        <Body>{props.message}</Body>
      </Inner>
    </Dialog>
  )
}
