import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import Body from '../../../components/atoms/Body'
import Button from '../../../components/atoms/Button'
import {height, width} from '../../../styles/mixins/sizing'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Icon from '../../atoms/Icon'
import LazyImage from '../../atoms/LazyImage'
import Loading from '../../atoms/Loading'
import TextButton from '../../atoms/TextButton'
import Col from '../../layouts/Col'
import Dialog from '../../layouts/Dialog'
import Row from '../../layouts/Row'
import HelpLink from '../../molecules/HelpLink'
import ShareLineButton from '../../molecules/ShareLineButton'

const Inner = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  text-align: center;
  ${spacing}
`

const Note = styled.div`
  display: flex;
  align-items: center;
`
const Thumbnail = styled.div`
  ${width};
  ${height};
  ${spacing};
`

const Links = styled.div`
  margin: ${space.md}px 0;
`

const Link = styled.div`
  margin: ${space.md}px 0;
`

const Description = styled.div`
  text-align: left;
`

const BottomButtons = styled.div`
  ${spacing};
  display: flex;
  align-items: center;
`

const RefreshButton = styled.div`
  color: ${colors.gray400};
  cursor: pointer;
  display: inline;
`

const ShareButton = styled.div`
  margin-left: auto;
  color: ${colors.gray400};
  cursor: pointer;
  display: inline;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${spacing}
`

const Emphasize = styled.span`
  font-weight: bold;
`

const LoadingOuter = styled.div`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LoadingContent = styled.div`
  height: 100%;
  z-index: 10;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.overlay};
`

export interface Props {
  isFetching: boolean
  isShow: boolean
  personName: string
  imageUrl: string
  url: string
  urlQrCodeData: string
  dismiss: () => void
  onClickShowPrintPage: () => void
  onClickRefreshQrCode: () => void
}

export default function NoteEmergencyCodeDialog(props: Props) {
  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    personName,
    imageUrl,
    url,
    isFetching,
    isShow,
    onClickShowPrintPage,
    onClickRefreshQrCode,
  } = props
  const handleRefreshButton = () => {
    setConfirmDialog(false)
    onClickRefreshQrCode()
  }
  return (
    <React.Fragment>
      <Dialog
        header="たすけてQRコード"
        onClickClose={props.dismiss}
        transitionIn={isShow}
      >
        {isFetching && (
          <LoadingOuter>
            <LoadingOverlay />
            <LoadingContent>
              <Loading />
              <Body mt="sm">読み込み中...</Body>
            </LoadingContent>
          </LoadingOuter>
        )}
        <Inner>
          <Note>
            <Thumbnail height={20} mr="xs" width={20}>
              <LazyImage objectFit="cover" src={imageUrl} />
            </Thumbnail>
            <Body>
              {personName}さんのたすけてQR情報
              <Icon ml="sm" size={20} type="heart-pulse" />
            </Body>
          </Note>

          <Body mt="sm" type="body2">
            <Description>
              　たすけてQRコードをスマートフォンのカメラ等で読み取ってもらうと、
              ご本人の情報や緊急連絡先などを知ってもらうことができます。
              緊急災害、急な発病、離れ離れになった時など、ご本人がご自分のことを相手に伝えることが難しい状況の時、
              <Emphasize>このＱＲコードがご本人を助けます。</Emphasize>
              詳しい使い方はこちら→
              <HelpLink
                title="たすけてQRコードとは"
                url="https://jnote.jp/faq/emergency-qr/"
              />
              <Body color={colors.gray400} type="overline">
                ※たすけてQRページは、QRコードを知っている人であれば誰でも見ることができます。公開する項目はしっかり考えて選択しましょう
              </Body>
            </Description>
            <Links>
              <Link>
                <TextButton onClick={() => onClickShowPrintPage()}>
                  <Body>QRコードを表示する（印刷用）</Body>
                </TextButton>
              </Link>
              <Link>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={url} rel="noopener" target="_blank">
                  <TextButton>
                    <Body>たすけてQRページを確認する</Body>
                  </TextButton>
                </a>
              </Link>
            </Links>
          </Body>
          <Body type="body2"></Body>
        </Inner>
        <BottomButtons mt="md">
          <RefreshButton onClick={() => setConfirmDialog(true)}>
            <Body type="caption">QRコードを変更する</Body>
          </RefreshButton>
          <ShareButton mr="xs">
            <ShareLineButton height="auto" url={url} width={86} />
          </ShareButton>
        </BottomButtons>

        <Dialog
          header="たすけてQRコードを変更する"
          onClickClose={() => setConfirmDialog(false)}
          transitionIn={confirmDialog}
        >
          <Body type="body2">
            今まで使っていたQRコード及びURLは使用できなくなります。
            <br />
            本当にたすけてQRコードを変更してもよろしいですか？
          </Body>
          <Buttons mt="md">
            <Row>
              <Col>
                <Button
                  backgroundColor={colors.gray400}
                  onClick={() => setConfirmDialog(false)}
                >
                  キャンセル
                </Button>
              </Col>
              <Col>
                <Button onClick={handleRefreshButton}>変更する</Button>
              </Col>
            </Row>
          </Buttons>
        </Dialog>
      </Dialog>
    </React.Fragment>
  )
}
