import * as React from 'react'
import styled from 'styled-components'
import RequestResetPasswordForm from '../../../containers/RequestResetPassordForm'
import Container from '../../layouts/Container'

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

export default function RequestResetPasswordPage() {
  return (
    <View>
      <Container>
        <RequestResetPasswordForm />
      </Container>
    </View>
  )
}
