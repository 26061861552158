import * as React from 'react'
import {LogCategory} from '../../../domain/log/loggerTypes'
import LoggableAnchor from '../../../containers/LoggableAnchor'
import Icon from '../../atoms/Icon'
import colors from '../../../styles/variables/colors'
import styled from 'styled-components'

const View = styled.div`
  display: inline;
  cursor: pointer;

  word-break: break-all;
  &,
  i,
  a {
    color: ${colors.blue100};
  }
`

/**
 * title を指定すると文字列は `[?] ヘルプ「${title}」` になる。
 * label を指定すると文字列は `[?] ${label}` になる。
 * children を指定すると[?]アイコンも出ずにすべて children を使う。
 */
export interface Props {
  url: string
  title?: string
  label?: string
  children?: React.ReactNode
}

/**
 * ヘルプリンクです。
 */
export default function HelpLink(props: Props) {
  const {label, title, url, children} = props

  return (
    <View>
      <LoggableAnchor
        href={url}
        logCategory={LogCategory.help}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children || (
          <>
            <Icon size={14} type="question-circle" />
            {label || `ヘルプ「${title}」`}
          </>
        )}
      </LoggableAnchor>
    </View>
  )
}
