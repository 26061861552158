import {bindActionCreators} from 'redux'
import {INotificationManager} from '../../services/app/INotificationManager'
import {
  Notification,
  NOTIFICATION_TEMPORARY_MSEC,
  NotificationPrinciple,
} from '../../services/app/Notification'
import * as reduxApp from '../redux/app'
import ReduxProvider from '../redux/ReduxProvider'

type AppActionCreator = typeof reduxApp.actions

interface Props {
  actions: AppActionCreator
  redux: ReduxProvider
}

let idIndex = 1

const createId = () => {
  const ret = idIndex++
  return ret.toString()
}

/**
 * ErrorManager
 */
export default class NotificationManager implements INotificationManager {
  private redux: ReduxProvider
  private actions: AppActionCreator

  /**
   * create
   */
  public static create() {
    const redux = ReduxProvider.create()
    const actions = bindActionCreators(reduxApp.actions, action =>
      redux.dispatch(action),
    )
    return new NotificationManager({redux, actions})
  }

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    this.redux = props.redux
    this.actions = props.actions
  }

  public show({
    message,
    type,
    icon,
    principle = NotificationPrinciple.informational,
  }: Notification) {
    const id = createId()
    this.actions.notice({
      id,
      icon,
      type,
      principle,
      message,
    })

    // FIXME: こんなんでいいのか
    if (principle === NotificationPrinciple.temporary) {
      setTimeout(() => {
        this.dismiss(id)
      }, NOTIFICATION_TEMPORARY_MSEC)
    }
  }

  public dismiss(id: string) {
    this.actions.dismissNotice(id)
  }
}
