import {createSelector} from 'reselect'
import {IReduxState} from '../../infra/redux'
import {AppState} from '../../infra/redux/app'
import * as selectors from '../../infra/redux/app/selectors'
import {NotificationType} from '../../services/app/Notification'

/**
 * getBusy
 *
 * @param state - ReduxState
 */
export const getBusy = (state: IReduxState) => selectors.getState(state).busy

export const getInitialized = (state: IReduxState) =>
  selectors.getState(state).initialized

export const getSnackbarNotifications = createSelector(
  selectors.getState,
  (state: AppState) => {
    return state.notice.filter(n => n.type === NotificationType.snackbar)
  },
)
export const getToastNotifications = createSelector(
  selectors.getState,
  (state: AppState) => {
    return state.notice.filter(n => n.type === NotificationType.toast)
  },
)
