import * as React from 'react'
import {useCallback, useState} from 'react'
import styled from 'styled-components'
import EditNoteTimelineComment from '../../../containers/EditNoteTimelineComment'
import NoteTimelineCommentInput from '../../../containers/NoteTimelineCommentInput'
import NoteTimelineCommentList from '../../../containers/NoteTimelineCommentList'
import {NoteTimelineItemType} from '../../../domain/note/timeline/NoteTimeline'
import {spacing} from '../../../styles/mixins/spacing'
import space from '../../../styles/variables/space'
import Divider from '../../atoms/Divider'
import Paper from '../../atoms/Paper'
import TimelineHeader from '../../molecules/TimelineHeader'

const TimelineItem = styled.div`
  ${spacing};
`

const Comment = styled.div`
  width: 100%;
  margin-top: ${space.xs}px;
`

const Footer = styled.div``

export interface Props {
  avatarSrc: string
  comment: string
  title: string
  name: string
  date: string
  timelineId: string
  editable: boolean
  deletable: boolean
  onClickRemoveButton?: () => void
  image?: string
}

export default function NoteTimelineComment(props: Props) {
  const {
    avatarSrc,
    comment,
    deletable,
    editable,
    title,
    name,
    date,
    onClickRemoveButton,
    timelineId,
    image,
    ...rest
  } = props
  const [editing, setEditing] = useState(false)
  const handleClickEditButton = useCallback(() => {
    setEditing(true)
  }, [])
  const handleDismiss = useCallback(() => {
    setEditing(false)
  }, [])
  return (
    <Paper {...rest}>
      <TimelineItem>
        <TimelineHeader
          avatarSrc={avatarSrc}
          date={date}
          deletable={deletable}
          editable={editable}
          onClickEditButton={handleClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
          title={title}
        />
        <Comment>
          <EditNoteTimelineComment
            content={comment}
            dismiss={handleDismiss}
            editing={editing}
            image={image}
            name={name}
            onComplete={handleDismiss}
            placeholder={'コメントを入力してください'}
            timelineId={timelineId}
            type={NoteTimelineItemType.noteComment}
          />
        </Comment>
        {!editing && timelineId && (
          <Footer>
            <Divider py="sm" />
            <NoteTimelineCommentList timelineId={timelineId} />
            <NoteTimelineCommentInput timelineId={timelineId} />
          </Footer>
        )}
      </TimelineItem>
    </Paper>
  )
}
