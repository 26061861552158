import IMeRepository from '../../domain/account/IMeRepository'
import IAccessTokenRepository from '../../domain/auth/IAccessTokenRepository'
import APIError from '../../infra/api/APIError'
import AppAPI from '../../infra/api/AppAPI'
import MeRepository from '../../infra/repositories/account/MeRepository'
import AccessTokenRepository from '../../infra/repositories/auth/AccessTokenRepository'
import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from './PushNavigationUseCase'
import {INoteManager} from '../../services/app/INoteManager'
import NoteManager from '../../infra/app/NoteManager'
import Logger from '../../domain/log/Logger'
import LogService from '../../infra/services/LogService'
import ReduxProvider from '../../infra/redux/ReduxProvider'

interface Props {
  api: AppAPI
  accessTokenRepository: IAccessTokenRepository
  meRepository: IMeRepository
  noteManager: INoteManager
}

export default class ApplicationInitializeUseCase extends UseCase {
  private _api: AppAPI
  private _accessTokenRepository: IAccessTokenRepository
  private _meRepository: IMeRepository
  private _noteManager: INoteManager

  public constructor(props: Props) {
    super()
    this._api = props.api
    this._accessTokenRepository = props.accessTokenRepository
    this._meRepository = props.meRepository
    this._noteManager = props.noteManager
  }

  public static create() {
    return new ApplicationInitializeUseCase({
      api: AppAPI.create(),
      accessTokenRepository: AccessTokenRepository.create(),
      meRepository: MeRepository.create(),
      noteManager: NoteManager.create(),
    })
  }

  public async execute(): Promise<void> {
    this._api.clearClient()
    this._noteManager.clear()
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(() => {
      applicationService.toUnbusy()
      applicationService.toInitialized()
    })
    Logger.init(
      new LogService({
        client: this._api.getLogClient(),
        redux: ReduxProvider.create(),
      }),
    )

    const token = await this._accessTokenRepository.get()
    if (token) {
      await this._meRepository.getByLocalFirst().catch((e: APIError) => {
        this.subUseCaseExecutor<PushNavigationUseCaseParams>(
          PushNavigationUseCase,
        )({routeKey: 'login'})
        if (e.unauthorized) {
          this._accessTokenRepository.remove()
        } else {
          throw e // tokenが切れた以外のエラーはダイアログを表示したいのでthrow
        }
      })
    }
  }
}
