import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import EditNoteTrack from '../../../containers/EditNoteTrack'
import {NoteTimelineItemType} from '../../../domain/note/timeline/NoteTimeline'
import {getModalState} from '../../../infra/redux/router/selectors'
import {defaultBreakpoints} from '../../../styles/mixins/breakpoints'
import Paper from '../../atoms/Paper'
import Modal, {Props as ViewProps} from '../../layouts/Modal'

export type Props = ViewProps & {
  content: string
  trackId?: string
  timestamp?: Date
  image: string
}

const View = styled(Paper)`
  width: 90vw;

  ${defaultBreakpoints.up('tablet')} {
    max-width: 600px;
  }
`

export default function PostDiaryModal(props: Props) {
  const {content, dismiss, image, timestamp, trackId} = props
  const statedModal = useSelector(getModalState)
  /*
    MEMO: useCallbackは使わない（push stateの値の変更によって関数が変更になる)
    useCallback使う場合は、push stateの値をdepsに設定する
  */
  const handleDismiss = useCallback(() => {
    dismiss && dismiss()
  }, [statedModal])
  return (
    <Modal {...props} dismiss={handleDismiss} paper={false}>
      <View>
        <EditNoteTrack
          content={content}
          dismiss={handleDismiss}
          editing={true}
          image={image}
          name="diary"
          onComplete={handleDismiss}
          placeholder={'日記を入力してください'}
          textarea={{
            minWidth: 300,
            minHeight: 150,
            maxHeight: 190,
          }}
          timestamp={timestamp}
          trackId={trackId}
          type={NoteTimelineItemType.noteDiary}
        />
      </View>
    </Modal>
  )
}
