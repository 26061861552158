import {RelationType, TrackRelationType} from '../../infra/api/types'
import ContactableUser from '../account/ContactableUser'
import User from '../account/User'
import Note, {NoteRelationProps} from './Note'

// メンバー管理関連
export const DefaultAcceptRelationTypeValue = RelationType.watcher
export type UpdatableRelationType = RelationType.watcher | RelationType.editor
export type UpdatableTrackRelationType =
  | TrackRelationType.watcher
  | TrackRelationType.editor

/**
 * NoteRelatedUser または NoteRelatedUser<ContactableUser> として使う。
 */
export type NoteRelatedUser<AnyUser extends User = User> = AnyUser & {
  noteId: string
  relation: NoteRelationProps
}

const NullNoteRelation: NoteRelationProps = {
  type: RelationType.none,
  trackType: TrackRelationType.none,
  mailTypes: [],
}

/**
 * ノートのメンバーのリストです。
 */
export const getMemberUsers = ({
  id: noteId,
  members = [],
  membersRelationMap = {},
}: Note): NoteRelatedUser<ContactableUser>[] =>
  members.map(user => ({
    ...user,
    noteId,
    relation: membersRelationMap[user.id] || NullNoteRelation,
  }))

/**
 * 申請中のユーザのリストです。
 */
export const getRequestUsers = ({
  id: noteId,
  requestingUsers = [],
}: Note): NoteRelatedUser<ContactableUser>[] =>
  requestingUsers.map(user => ({
    ...user,
    noteId,
    relation: {
      type: RelationType.requesting,
      trackType: TrackRelationType.none,
      mailTypes: [],
    },
  }))

/**
 * ノートのメンバーまたは申請を、削除可能であるどうかです。
 */
export const getDeletable = ({relation}: NoteRelatedUser) =>
  ![RelationType.none, RelationType.owner].includes(relation.type)

export const isFullMember = ({relation}: NoteRelatedUser) =>
  relation.type === RelationType.editor &&
  relation.trackType === TrackRelationType.editor

export const isEditMember = ({relation}: NoteRelatedUser) =>
  relation.type === RelationType.editor &&
  relation.trackType !== TrackRelationType.editor

export const isTrackMember = ({relation}: NoteRelatedUser) =>
  relation.type !== RelationType.editor &&
  relation.trackType === TrackRelationType.editor

export const isWatchOnlyMember = ({relation}: NoteRelatedUser) =>
  relation.type === RelationType.watcher &&
  relation.trackType === TrackRelationType.watcher
