import {format, parseISO} from 'date-fns'
import {createSelector} from 'reselect'
import UsersMe from '../../domain/account/UsersMe'
import NoteHistory from '../../domain/note/NoteHistory'
import {UserResponse} from '../../infra/api/types'
import {HistorySchema, MeSchema} from '../../infra/redux/api/schemata'
import * as apiSelectors from '../../infra/redux/api/selectors'
import {getAllData} from '../../infra/redux/api/selectors'

// TODO: ドメインモデルごとにディレクトリわけ

export const getMe = createSelector(
  apiSelectors.getOne(MeSchema),
  (me: UsersMe) => {
    return me || {}
  },
)

export const getResourceUploadToken = createSelector(
  getMe,
  (me: UsersMe) => {
    return me && me.resourceUploadToken
  },
)

export const getOwnedList = createSelector(
  getMe,
  (me: UsersMe) => {
    return me && me.ownedNotes
  },
)

export const getFollowingList = createSelector(
  getMe,
  (me: UsersMe) => {
    return me && me.followingNotes
  },
)

export const getNoteHistory = (id: string) =>
  createSelector(
    getAllData(HistorySchema),
    (histories: NoteHistory[]) => {
      return (
        (histories &&
          histories.map((history: NoteHistory) => {
            const {id: fieldName, attr: {label = ''} = {}} = history
            const {
              changeInfo: {
                previousData = {},
                userId: user = {},
                updatedAt = '',
              } = {},
            } = history || {}
            const {fullname = '', iconUrl = '', ...userRest} =
              (user as UserResponse) || {}
            return {
              userId: userRest.id,
              userIconUrl: iconUrl,
              name: fieldName,
              label,
              date: parseISO(updatedAt),
              previous: previousData,
              data: history.data,
              fullname: fullname,
              type: history.type,
              attr: history.attr,
            }
          })) ||
        []
      )
    },
  )

export const getNoteHistoryGroupByDate = (id: string) =>
  createSelector(
    getNoteHistory(id),
    history => {
      return history.reduce((acc, item) => {
        const date = item.date && format(item.date, 'yyyy-MM-dd')
        const {list = []} = acc[date] || {}
        acc[date] = {...acc[date], list: [...list, item]}
        return acc
      }, {})
    },
  )
