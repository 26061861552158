import * as React from 'react'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {generateFiltered} from '../../components/helpers/snapshot'
import View from '../../components/pages/NoteEmergencyPage'
import {RelationType} from '../../infra/api/types'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {useRouter} from '../../lib/router'
import {getNoteEmergencyNodes} from '../../selectors/note/emergency'
import FetchNoteEmergencyUseCase from '../../useCases/note/FetchNoteEmergencyUseCase'

export default function NoteEmergencyPage() {
  const {
    match: {
      params: {token},
    },
  } = useRouter<{token: string}>()
  const nodes = useSelector(getNoteEmergencyNodes(token))

  useEffect(() => {
    if (token) {
      UseCaseCommander.create().command(FetchNoteEmergencyUseCase)({token})
    }
  }, [token]) // NOTE: depsを指定しないと無限API呼び出し

  const generated = useMemo(() => {
    return nodes && generateFiltered('', RelationType.none, nodes)
  }, [token, nodes])

  return React.createElement(View, {
    children: generated,
  })
}
