import INoteHistoryRepository from '../../domain/note/INoteHistoryRepository'
import NoteHistoryRepository from '../../infra/repositories/note/NoteHistoryRepository'
import UseCase from '../lib/UseCase'

interface Props {
  noteHistoryRepository: INoteHistoryRepository
}

export interface Params {
  noteId: string
  offset?: number
  limit?: number
  fromDate?: Date
  toDate?: Date
  itemId?: string
  userId?: string
}

export default class FetchNoteHistoryPageUseCase extends UseCase {
  private noteHistoryRepository: INoteHistoryRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteHistoryRepository = props.noteHistoryRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteHistoryPageUseCase({
      noteHistoryRepository: NoteHistoryRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: Params): Promise<void> {
    await this.noteHistoryRepository.get(params)
  }
}
