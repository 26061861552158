import NoteId from '../../../domain/note/NoteId'
import INoteTimelineRepository from '../../../domain/note/timeline/INoteTimelineRepository'
import NoteTimeline, {
  NoteTimelineItem,
  NoteTimelineItemType,
} from '../../../domain/note/timeline/NoteTimeline'
import AppAPI from '../../api/AppAPI'
import {actions} from '../../redux/api/entities'
import {
  NoteTimelineCommentSchema,
  NoteTimelineItemSchema,
  NoteTimelineSchema,
  UserSchema,
} from '../../redux/api/schemata'
import {getNoteTimeline} from '../../redux/api/selectors'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteTimelineRepository implements INoteTimelineRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteTimelineRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteTimelineRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async get(params: {
    noteId: NoteId
    types: NoteTimelineItemType[]
    offset: number
    limit: number
  }): Promise<NoteTimeline | null> {
    const {noteId, offset, limit, types} = params
    const client = await this.api.getClient()
    const {timeline} = await client.fetchNoteTimeline({
      id: noteId,
      types: types && types.join(','),
      ...(offset && {offset}),
      ...(limit && {limit}),
    })
    const {items = {}, users = {}, pager = {}} = timeline || {}
    this.redux.dispatch(actions.store(items, [NoteTimelineItemSchema], pager))
    this.redux.dispatch(actions.store(users, [UserSchema]))
    return timeline ? (timeline as NoteTimeline) : null
  }

  public async clear() {
    this.redux.dispatch(actions.clear(NoteTimelineItemSchema))
  }

  public async post(params: {
    noteId: NoteId
    type: NoteTimelineItemType
    data: any
  }): Promise<NoteTimelineItem | null> {
    const {data, noteId, type} = params
    const client = await this.api.getClient()
    const timelineItem = await client.postNoteTimeline({
      id: noteId,
      type,
      data,
    })
    this.redux.dispatch(actions.store(timelineItem, NoteTimelineItemSchema))
    return timelineItem ? (timelineItem as NoteTimelineItem) : null
  }

  public async update(params: {
    noteId: NoteId
    type: NoteTimelineItemType
    timelineId: string
    data: any /*fixme: type*/
  }): Promise<void> {
    const {noteId, type, timelineId, data} = params
    const client = await this.api.getClient()
    const timeline = await client.updateNoteTimeline({
      id: noteId,
      type,
      timelineId,
      data,
    })
    this.redux.dispatch(actions.store(timeline, NoteTimelineItemSchema))
  }

  public async delete(params: {
    noteId: string
    timelineId: string
  }): Promise<void> {
    const {noteId, timelineId} = params
    const client = await this.api.getClient()
    await client.deleteNoteTimeline({
      id: noteId,
      timelineId,
    })
    this.redux.dispatch(actions.spend(timelineId, NoteTimelineItemSchema))
  }

  public async postComment(params: {
    noteId: NoteId
    timelineId: string
    data: {comment: string}
  }): Promise<NoteTimelineItem> {
    const {data, noteId, timelineId} = params
    const client = await this.api.getClient()
    const timelineItem = await client.postNoteTimelineComment({
      id: noteId,
      timelineId,
      data,
    })
    this.redux.dispatch(actions.store(timelineItem, NoteTimelineItemSchema))
    return timelineItem as NoteTimelineItem
  }

  public async deleteComment(params: {
    noteId: string
    timelineId: string
    commentId: string
  }): Promise<void> {
    const {commentId, noteId, timelineId} = params
    const client = await this.api.getClient()
    await client.deleteNoteTimelineComment({
      id: noteId,
      timelineId,
      commentId,
    })
    this.redux.dispatch(actions.spend(commentId, NoteTimelineCommentSchema))
  }
}
