import * as React from 'react'
import HamburgerButton from '../../../containers/HamburgerButton'
import HeaderLogo from '../../../containers/HeaderLogo'
import HeaderNavigation from '../../../containers/HeaderNavigation'
import {RouteKey} from '../../../routes'
import HeaderLayout from '../../layouts/HeaderLayout'
import {HeaderProps} from '../../templates/DefaultPage'

export type Props = HeaderProps & {
  back?: boolean | RouteKey
}

export default function Header(props: Props) {
  const {back} = props
  const left = back ? (
    <HeaderNavigation {...props} />
  ) : (
    <HeaderLogo {...props} />
  )
  return <HeaderLayout left={left} right={<HamburgerButton />} />
}
