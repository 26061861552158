import * as React from 'react'
import NoteEmergencyCodeDialog from '../../../containers/NoteEmergencyCodeDialog'
import {useModalState} from '../../../selectors/app/dom'
import LabeledIcon from '../../molecules/LabeledIcon'

export default function NoteEmergencyCodeButton() {
  const [showEmergencyCodeDialog, setShowEmergencyCode] = useModalState(false)

  return (
    <>
      <NoteEmergencyCodeDialog
        dismiss={() => setShowEmergencyCode(false)}
        isShow={showEmergencyCodeDialog}
      />
      <LabeledIcon
        onClick={() => setShowEmergencyCode(true)}
        type="heart-pulse"
      >
        たすけてQR
      </LabeledIcon>
    </>
  )
}
