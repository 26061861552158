export default interface Resource {
  url?: string
}

export const ThumbImageSize = {
  width: 80,
  height: 80,
}

export const getThumbUrl = (url: string = ''): string =>
  (url && url.indexOf('?size=') < 0 && `${url}?size=thumb`) || ''
