import {createSelector} from 'reselect'
import Note from '../../domain/note/Note'
import Snapshot from '../../domain/note/snapshot/Snapshot'
import {getContentedTree} from '../../domain/note/snapshot/Tree'
import {TreeSchema} from '../../infra/redux/api/schemata'
import {getById, getNoteById} from '../../infra/redux/api/selectors'
import {getCurrentNote, getCurrentNoteId} from '../note'

export const getSnapshotAt = createSelector(
  getCurrentNoteId,
  state => state,
  (noteId: any, state) => {
    const {snapshotAt = ''} = (getNoteById(noteId)(state) as any) || {}
    return snapshotAt
  },
)

export const getUpdatedAt = createSelector(
  getCurrentNoteId,
  state => state,
  (noteId: any, state) => {
    const {updatedAt = ''} = (getNoteById(noteId)(state) as any) || {}
    return updatedAt
  },
)

export const getSnapshot = createSelector(
  getCurrentNote,
  (note: Note) => {
    const {snapshot = undefined} = note || {}
    return snapshot
  },
)

export const getContentedSnapshot = createSelector(
  getCurrentNote,
  note => {
    const {snapshot = undefined} = note || {}
    return snapshot && getContentedTree(snapshot as Snapshot)
  },
)

// FIXME: rename treeてなんやねん...
export const getTreeById = getById(TreeSchema)
