import * as React from 'react'
import {ReduxField} from '../../../hocs/withReduxField'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import CheckboxRadioItems from '../../layouts/CheckboxRadioItems'
import Col from '../../layouts/Col'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import Row from '../../layouts/Row'
import Space from '../../layouts/Space'
import {Props as FormedFieldProps} from '../FormedField'
import StretchyInput from '../StretchyInput'
import StretchyTextarea from '../StretchyTextarea'
import colors from '../../../styles/variables/colors'

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps & {
    items: KeySignature
  }

const ReduxInput = (props: any /*ReduxFieldProps*/) => {
  return (
    <Row align="center">
      <Col col="auto">
        <Body>{props.label}</Body>
      </Col>
      <Col col="auto">
        <StretchyInput {...props} {...props.input} />
      </Col>
      {props.suffix && (
        <Col>
          <Body color={colors.gray400} type="body2">
            {props.suffix}
          </Body>
        </Col>
      )}
    </Row>
  )
}
const ReduxTextarea = (props: any /*ReduxFieldProps*/) => {
  return (
    <Row align="start">
      <Col col="auto">
        <Body>{props.label}</Body>
      </Col>
      <Col col="fit">
        <StretchyTextarea {...props} {...props.input} />
      </Col>
    </Row>
  )
}

const getComponentType = (type: string) => {
  return (
    {
      textarea: ReduxTextarea,
      text: ReduxInput,
      date: ReduxInput,
      number: ReduxInput,
    }[type] || ReduxInput
  )
}

const getComponent = (key: string, item: any, disabled: boolean) => {
  const {type} = item
  const {validators = []} = item
  return (
    <Row key={key}>
      {type === 'checkbox' || type === 'radio' ? (
        <Col col={12} key={key} mt="sm">
          <CheckboxRadioItems
            label={item.label}
            name={key}
            options={item.options}
            type={item.type}
          />
        </Col>
      ) : (
        <Col col={12} key={key} mt="sm">
          <ReduxField
            component={getComponentType(type)}
            disabled={disabled}
            name={key}
            {...item}
            validators={validators}
          />
        </Col>
      )}
    </Row>
  )
}

export default function FormedMultiTextField({
  anyTouched,
  anyActive,
  disabled,
  items,
  errors,
  formDirty,
  formErrorMessage,
  data,
  formMeta,
  label,
  isProcessing,
  onClickCancel,
  onClickHistory,
  help,
  ...rest
}: Props) {
  const inputs =
    items &&
    Object.keys(items).map(key => {
      const p = items[key]
      return getComponent(key, p, disabled)
    })
  return (
    <FormedFieldLayout
      active={anyActive || anyTouched || formDirty}
      dirty={formDirty}
      disabled={disabled}
      error={formErrorMessage}
      help={help}
      isProcessing={isProcessing}
      label={label}
      onClickCancel={onClickCancel}
      onClickHistory={onClickHistory}
    >
      <Space mt={-space.sm}>{inputs}</Space>
    </FormedFieldLayout>
  )
}
