import AccessToken from '../../domain/auth/AccessToken'
import {Email} from '../../domain/auth/Email'
import IAccessTokenRepository from '../../domain/auth/IAccessTokenRepository'
import IAuthenticationService from '../../domain/auth/IAuthenticationService'
import LoginError from '../../domain/auth/LoginError'
import {Password} from '../../domain/auth/Password'
import {ResetPasswordVerificationToken} from '../../domain/auth/ResetPasswordVerificationToken'
import APIError from '../api/APIError'
import * as apiTranslator from '../api/ApiTranslator'
import AppAPI from '../api/AppAPI'
import AppAPIClient from '../api/AppAPIClient'
import {actions as apiActions} from '../redux/api/entities'
import {MeSchema, NoteSchema} from '../redux/api/schemata'
import ReduxProvider from '../redux/ReduxProvider'
import AccessTokenRepository from '../repositories/auth/AccessTokenRepository'

/**
 * AuthenticationService
 */
export default class AuthenticationService implements IAuthenticationService {
  private _accessTokenRepository: IAccessTokenRepository
  private _api: AppAPI
  private _redux: ReduxProvider

  /**
   * create
   */
  public static create() {
    return new AuthenticationService(AccessTokenRepository.create())
  }

  /**
   * constructor
   *
   */
  public constructor(accessTokenRepository: IAccessTokenRepository) {
    this._accessTokenRepository = accessTokenRepository
    this._api = AppAPI.create()
    this._redux = ReduxProvider.create()
  }

  /**
   * login
   *
   * @param email
   * @param password
   * @return {Promise<AccessToken>}
   * @param isAutoLogout
   */
  public async signIn(
    email: Email,
    password: Password,
    isAutoLogout: boolean,
  ): Promise<AccessToken> {
    const client: AppAPIClient = await this._api.getClient()
    const res = await client
      .signIn({
        email: email,
        password: password,
        isAutoLogout,
      })
      .catch((error: APIError) => {
        if (error.invalidAccount) {
          return Promise.reject(new LoginError(error))
        }
        return Promise.reject(error)
      })
    const newToken = apiTranslator.toAccessToken(res)
    this._accessTokenRepository.save(newToken)
    this._redux.dispatch(apiActions.store(res, MeSchema))
    this._redux.dispatch(
      apiActions.store(
        [...res.ownedNotes, ...res.followingNotes],
        [NoteSchema],
      ),
    )
    return newToken
  }

  /**
   * logout
   */
  public async signOut(): Promise<void> {
    this._redux.dispatch(apiActions.clearAll())
    this._accessTokenRepository.remove()
    const client: AppAPIClient = await this._api.getClient()
    await client.signOut()
  }

  /**
   * resetPassword
   */
  public async resetPassword(
    token: ResetPasswordVerificationToken,
    newPassword: Password,
  ): Promise<AccessToken> {
    const client: AppAPIClient = await this._api.getClient()
    const res = await client.resetPassword({
      token: token,
      newPassword: newPassword,
    })
    const newToken = apiTranslator.toAccessToken(res)
    this._accessTokenRepository.save(newToken)
    return newToken
  }

  /**
   * requestResetPassword
   * @param params
   * @return {Promise<void>}
   */
  public async requestResetPassword(email: Email): Promise<void> {
    const client: AppAPIClient = await this._api.getClient()
    await client.requestResetPassword({
      email: email,
    })
  }
}
