import queryString from 'query-string'
import {connect} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router'
import {compose} from 'recompose'
import {FormProps, reduxForm} from 'redux-form'
import {SpaceProps} from '../../components/layouts/Space'
import View, {IFormData, IFormProps, Props} from '../../components/organisms/SignUpForm'
import {LogCategory} from '../../domain/log/loggerTypes'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getValues, isSubmitDisabled} from '../../selectors/form'
import {Forms} from '../../services/forms'
import {logLinkClicked} from '../../services/log'
import Navigation from '../../services/navigation/Navigation'
import SignUpUseCase, {SignUpUseCaseParams} from '../../useCases/auth/SignUpUseCase'

type StateProps = IFormProps &
  FormProps<IFormData, IFormProps> & {
    initialValues: Partial<IFormData>
  }

export default compose<Props, SpaceProps>(
  withRouter,
  connect(
    (state: IReduxState, ownProps: RouteComponentProps): StateProps => {
      const redirect =
        (queryString.parse(ownProps.location.search).redirect as string) ||
        undefined
      return {
        initialValues: {
          email: '',
          password: '',
        },
        submitForm: () => {
          const values = getValues(Forms.signUp)(state)
          UseCaseCommander.create().command<SignUpUseCaseParams>(SignUpUseCase)(
            {
              ...values,
              redirect,
            },
          )
        },
        submitDisabled: isSubmitDisabled(Forms.signUp)(state),
        isProcessing: useCaseSelectors.isProcessing(SignUpUseCase)(state),
        onClickTerms: () => {
          const url = 'https://jnote.jp/terms/'
          Navigation.create().openExternal(url)
          logLinkClicked({logCategory: LogCategory.externalLink, url})
        },
      }
    },
  ),
  reduxForm<IFormData, IFormProps>({
    form: Forms.signUp,
  }),
)(View)
