import * as React from 'react'
import {Props} from '../../../containers/NoteHistoryItem'
import NoteHistoryItemLayout from '../../../containers/NoteHistoryItemLayout'
import {getThumbUrl} from '../../../domain/resource/Resource'
import Body from '../../atoms/Body'
import LightboxImages from '../LightboxImages'

const getValue = (name: string, data: any) => {
  const {[name]: ret = data} = data || {}
  return {thumbSrc: getThumbUrl(ret), originalSrc: ret}
}

// TODO: どこか置き場へ
const THUMB_WIDTH = 150

// TODO: 画像クリックで大きな画像の表示
export default function ImageHistoryItem(props: Props) {
  const {previous, data, name, ...rest} = props
  const {thumbSrc: prevThumbSrc, originalSrc: prevOriginalSrc} = getValue(
    name,
    previous,
  )
  const {thumbSrc: currentThumbSrc, originalSrc: currentOriginalSrc} = getValue(
    name,
    data,
  )
  const prevData = prevOriginalSrc ? (
    <LightboxImages
      mainThumbSrc={prevThumbSrc}
      sources={[prevOriginalSrc]}
      width={THUMB_WIDTH}
    />
  ) : (
    <Body>(画像なし)</Body>
  )
  const currentData = currentOriginalSrc ? (
    <LightboxImages
      mainThumbSrc={currentThumbSrc}
      sources={[currentOriginalSrc]}
      width={THUMB_WIDTH}
    />
  ) : (
    <Body>(画像なし)</Body>
  )
  return (
    <NoteHistoryItemLayout
      currentData={currentData}
      name={name}
      prevData={prevData}
      {...rest}
    />
  )
}
