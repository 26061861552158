import {ActionCreatorsMapObject, bindActionCreators} from 'redux'
import {IReduxState} from './index'
import {Action, ReduxContext} from './types'

/**
 * ReduxProvider
 */
export default class ReduxProvider {
  /**
   * create
   */
  public static create() {
    if (ReduxProvider._instance) {
      return ReduxProvider._instance
    }
    return (ReduxProvider._instance = new ReduxProvider())
  }

  private static _instance: ReduxProvider
  private context: ReduxContext | undefined

  /**
   * setContext
   *
   * @param context - ReduxContext
   */
  public setContext(context: ReduxContext) {
    this.context = context
  }

  /**
   * dispatch
   *
   * @param {Action} action
   */
  public dispatch(action: Action): any {
    if (!this.context) {
      throw new ReferenceError('context is null.')
    }
    return this.context.dispatch(action)
  }

  /**
   * getState
   */
  public getState(): IReduxState {
    if (!this.context) {
      throw new ReferenceError('context is null.')
    }
    return this.context.getState()
  }

  /**
   * bind
   *
   * @param actionCreator - ActionCreatorsMapObject
   */
  public bind(actionCreator: ActionCreatorsMapObject): ActionCreatorsMapObject {
    if (!this.context) {
      throw new ReferenceError('context is null.')
    }
    return bindActionCreators(actionCreator, this.context.dispatch)
  }
}
