import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/CommentList'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {getTrackComments} from '../../selectors/note/track'
import DeleteNoteTrackCommentUseCase, {
  Params as DeleteNoteTrackCommentUseCaseParams,
} from '../../useCases/track/DeleteNoteTrackCommentUseCase'

export interface Props {
  trackId: string
}

export default function NoteTrackCommentList(props: Props) {
  const {trackId} = props
  const noteId = useSelector(getCurrentNoteId)
  const items = useSelector(getTrackComments(trackId))
  const onClickRemoveButton = useCallback(
    (commentId: string) => {
      UseCaseCommander.create().command<DeleteNoteTrackCommentUseCaseParams>(
        DeleteNoteTrackCommentUseCase,
      )({noteId, trackId, commentId})
    },
    [trackId],
  )
  return React.createElement(View, {
    items,
    onClickRemoveButton,
  })
}
