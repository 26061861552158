import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteInvitationCodeDialog'
import NoteInvitationCode, {
  makeInvitationMessage,
  makeInvitationShortMessage,
} from '../../domain/note/NoteInvitationCode'
import UsersMe from '../../domain/account/UsersMe'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import {getCurrentNoteId} from '../../selectors/note'
import {getNoteInvitationCodeById} from '../../selectors/note/invitation'
import FetchNoteInvitationCodeUseCase, {
  Params as FetchNoteInvitationCodeUseCaseParams,
} from '../../useCases/note/FetchNoteInvitationCodeUseCase'
import RefreshNoteInvitationCodeUseCase, {
  Params as RefreshNoteInvitationCodeUseCaseParams,
} from '../../useCases/note/RefreshNoteInvitationCodeUseCase'
import OpenMailerUseCase, {
  OpenMailerParams,
} from '../../useCases/note/OpenMailerUseCase'

export interface Props {
  isShow: boolean
  dismiss: () => void
}

export default function NoteInvitationCodeDialog(props: Props) {
  const {isShow, dismiss} = props
  const noteId = useSelector(getCurrentNoteId)

  const {fullname} = useSelector(getMe) as UsersMe
  const hasFullname = !!fullname

  const noteInvitationCode: NoteInvitationCode =
    useSelector(getNoteInvitationCodeById(noteId)) || {}

  const {
    title: noteTitle = '',
    personName = '',
    personImageUrl = '',
    imageUrl = '',
    inviteInfo: {url: inviteUrl = '', urlQrCodeData = ''} = {},
  } = noteInvitationCode || {}

  useEffect(() => {
    if (isShow && hasFullname) {
      UseCaseCommander.create().command<FetchNoteInvitationCodeUseCaseParams>(
        FetchNoteInvitationCodeUseCase,
      )({noteId})
    }
  }, [noteId, isShow, hasFullname])

  const inviteMessageText = makeInvitationMessage({
    meFullname: fullname,
    noteInvitationCode,
  })
  const inviteMessageShortText = makeInvitationShortMessage({
    noteInvitationCode,
  })

  return React.createElement(View, {
    isShow,
    hasFullname,
    noteTitle,
    personName,
    personImageUrl,
    noteImageUrl: imageUrl,
    inviteUrl,
    inviteMessageText,
    inviteMessageShortText,
    inviteQrCodeData: urlQrCodeData,
    dismiss: () => {
      dismiss()
    },
    onClickRefreshQrCode: () => {
      UseCaseCommander.create().command<RefreshNoteInvitationCodeUseCaseParams>(
        RefreshNoteInvitationCodeUseCase,
      )({noteId})
    },
    onClickSendByMail: () => {
      const subject = `${noteTitle}へのご招待`
      const body = inviteMessageText
      UseCaseCommander.create().command<OpenMailerParams>(OpenMailerUseCase)({
        subject,
        body,
      })
    },
  })
}
