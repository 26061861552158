import {connect} from 'react-redux'
import {compose} from 'recompose'
import {isDirty, isValid, reduxForm} from 'redux-form'
import View, {
  FormData as NoteFormFormData,
  Props,
} from '../../components/organisms/NoteForm'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {Forms} from '../../services/forms'
import {HandleSubmitProps} from '../../lib/form/types'
import UpdateNoteAttrsUseCase from '../../useCases/note/UpdateNoteAttrsUseCase'

export type FormData = NoteFormFormData

export interface OwnProps {
  noteId: string
  initialValues: FormData
}

export default compose<Props & HandleSubmitProps, OwnProps>(
  connect(
    (state: IReduxState, props: OwnProps): Props => {
      const {initialValues, noteId} = props
      const submitDisabled =
        !isValid(Forms.noteAttrs)(state) || !isDirty(Forms.noteAttrs)(state)
      return {
        initialValues,
        submitForm: (values: FormData) => {
          UseCaseCommander.create().command(UpdateNoteAttrsUseCase)({
            ...values,
            id: noteId,
          })
        },
        submitDisabled,
        isProcessing: useCaseSelectors.isProcessing(UpdateNoteAttrsUseCase)(
          state,
        ),
        submitButtonLabel: '更新する',
        personNameFieldLabel: 'お名前',
        noteTitleFieldLabel: 'ノートのタイトル',
        titlePlaceHolder: '○○を知ってもらうためのノート',
      }
    },
  ),
  reduxForm<FormData>({
    form: Forms.noteAttrs,
  }),
)(View)
