import {icons} from './icons'

export const iconContentMap = icons.reduce((acc, icon, index) => {
  const content = parseInt('e600', 16) + index
  return {...acc, [icon]: `\\${content.toString(16)}`}
}, {})

export function getIcon(type: string) {
  return iconContentMap[type]
}
