import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import {InterpolatedReduxComponent} from '../../layouts/InterpolatedComponent'
import {Props as FormedFieldProps} from '../FormedField'

const Inputs = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  ${spacing};
`

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps & {
    items: KeySignature
  }

/**
 * type: text
 */
export default function FormedFillTextField({
  anyTouched,
  anyActive,
  disabled,
  formDirty,
  formErrorMessage,
  attr,
  onClickHistory,
  ...rest
}: Props) {
  const {text = ''} = attr
  const inputs = InterpolatedReduxComponent(text, attr, false, disabled)
  return (
    <FormedFieldLayout
      active={anyActive || anyTouched}
      dirty={formDirty}
      disabled={disabled}
      error={formErrorMessage}
      onClickHistory={onClickHistory}
      {...rest}
    >
      <Inputs>{inputs}</Inputs>
    </FormedFieldLayout>
  )
}
