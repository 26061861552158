import * as React from 'react'
import styled from 'styled-components'
import media from '../../../styles/mixins/media'

interface MediaProps {
  size?: number
  top?: number
  bottom?: number
  left?: number
  right?: number
}

type ViewProps = {
  desktop?: MediaProps
} & MediaProps

export type MarginProps = {
  children?: React.ReactNode
} & ViewProps

const margin = ({size, top, bottom, left, right}: ViewProps) => {
  const marginCss = size ? `margin: ${size}px` : ''
  const marginTop = top ? `margin-top: ${top}px` : ''
  const marginBottom = bottom ? `margin-bottom: ${bottom}px` : ''
  const marginLeft = left ? `margin-left: ${left}px` : ''
  const marginRight = right ? `margin-right: ${right}px` : ''
  return `
    ${marginCss}
    ${marginTop}
    ${marginBottom}
    ${marginLeft}
    ${marginRight}
  `
}

const View = styled.div`
  ${(p: ViewProps) => margin(p)}
  ${media.desktop((p: ViewProps) => p.desktop && margin(p.desktop))}
`

export default function Margin(props: MarginProps) {
  return <View {...props}>{props.children}</View>
}
