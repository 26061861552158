import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteList'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as capabilitySelectors from '../../selectors/capability'
import * as noteSelectors from '../../selectors/note/'
import {SpacingProps} from '../../styles/mixins/spacing'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'

export type Props = SpacingProps

export default function OwnedList(props: Props) {
  const query = useSelector(noteSelectors.getSearchNoteQuery)
  const ownedList = useSelector(noteSelectors.getOwnedNoteCardList(query))
  const addable = useSelector(capabilitySelectors.hasNoteCreatable)
  const onClickAddButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({
      routeKey: 'createNote',
    })
  }, [])
  return React.createElement(View, {
    ...props,
    addable,
    title: 'ノート一覧',
    items: ownedList || [],
    onClickAddButton,
  })
}
