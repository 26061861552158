import * as React from 'react'
import {useMemo} from 'react'
import {Switch} from 'react-router'
import styled from 'styled-components'
import ApplicationInitializer from '../containers/ApplicationInitializer'
import Private from '../containers/Private'
import SnackbarNotifications from '../containers/SnackbarNotifications'
import ToastNotifications from '../containers/ToastNotifications'
import Tracker from '../containers/Tracker'
import getRoutes, {renderRoutes} from '../routes'
import configureStyles from '../styles/configureStyles'
import ApplicationIndicator from './../containers/ApplicationIndicator'
import ErrorDialog from './../containers/ErrorDialog'
import {HEADER_HEIGHT} from './layouts/HeaderLayout'

const View = styled.div`
  padding-top: ${HEADER_HEIGHT}px;
  z-index: 2;
  position: relative;
`

export default function App() {
  const GlobalStyles = configureStyles()
  const routes = useMemo(() => renderRoutes(getRoutes()), [])
  return (
    <React.Fragment>
      <Tracker />
      <GlobalStyles />
      <SnackbarNotifications />
      <ToastNotifications />
      <ApplicationIndicator />
      <ErrorDialog />
      <ApplicationInitializer>
        <View>
          <Private>
            <Switch>{routes}</Switch>
          </Private>
        </View>
      </ApplicationInitializer>
    </React.Fragment>
  )
}
