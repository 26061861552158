import IAccountRegistryService from '../../domain/account/IAccountRegistryService'
import {EmailVerificationToken} from '../../domain/varification/EmailVerificationToken'
import AccountRegistryService from '../../infra/services/AccountRegistryService'
import ApplicationService from '../../services/app/ApplicationService'
import Navigation from '../../services/navigation/Navigation'
import ApplicationInitializeUseCase from '../app/ApplicationInitializeUseCase'
import UseCase from '../lib/UseCase'
import NoticeUseCase from '../app/NoticeUseCase'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'

interface Props {
  accountRegistryService: IAccountRegistryService
  applicationService: ApplicationService
  navigation: Navigation
}

interface Params {
  emailVerificationToken: EmailVerificationToken
}

export default class VerifyEmailVerificationTokenUseCase extends UseCase {
  private _accountRegistryService: IAccountRegistryService
  private _applicationService: ApplicationService
  private _navigation: Navigation

  public constructor(props: Props) {
    super()
    this._accountRegistryService = props.accountRegistryService
    this._applicationService = props.applicationService
    this._navigation = props.navigation
  }

  public static create() {
    return new VerifyEmailVerificationTokenUseCase({
      accountRegistryService: AccountRegistryService.create(),
      applicationService: ApplicationService.create(),
      navigation: Navigation.create(),
    })
  }

  public async execute({emailVerificationToken}: Params): Promise<void> {
    this._applicationService.toBusy()
    this.registerComplete(() => {
      this._applicationService.toUnbusy()
    })
    const res = await this._accountRegistryService.verifyToken(
      emailVerificationToken,
    )
    await this.subUseCaseExecutor(ApplicationInitializeUseCase)()

    this.subUseCaseExecutor(NoticeUseCase)({
      principle: NotificationPrinciple.informational,
      type: NotificationType.snackbar,
      message: 'おめでとうございます！ アカウントの作成が完了しました。',
    })

    if (res.redirect) {
      this._navigation.pushByPath(res.redirect)
      return
    }
    this._navigation.push('index')
  }
}
