import {
  addDays,
  differenceInCalendarDays,
  endOfMonth,
  format,
  formatDistance,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfMonth
} from 'date-fns'
import {ja} from 'date-fns/locale'
import {createSelector} from 'reselect'
import {DayItem} from '../../components/molecules/Timetable'
import {NoteTrackItemProps, NoteTrackItemPropsType} from '../../components/organisms/NoteTrackList'
import User from '../../domain/account/User'
import UsersMe from '../../domain/account/UsersMe'
import Note from '../../domain/note/Note'
import NoteId from '../../domain/note/NoteId'
import {
  deletableNoteTrack,
  deletableNoteTrackComment,
  editableNoteTrack,
  NoteTrackDataType,
  NoteTrackItem,
  NoteTrackItemType,
  stringToTimestamp
} from '../../domain/note/track/NoteTrack'
import NoteTrackDailySum from '../../domain/note/track/NoteTrackDailySum'
import {getThumbUrl} from '../../domain/resource/Resource'
import {RelationType} from '../../infra/api/types'
import {
  getNoteById,
  getNoteTrackDailySumItems,
  getNoteTrackItemById,
  getNoteTrackItems,
  getUsers
} from '../../infra/redux/api/selectors'
import {getMe} from '../../selectors/api'
import {getCurrentNote} from '../../selectors/note'
import colors from '../../styles/variables/colors'

export const NoteTrackItemViewType = {
  diary: 'diary',
  track: 'track',
}

export const NoteTrackDataTypeLabelMap: Record<NoteTrackDataType, string> = {
  weather: '天気',
  feeling: '気分',
  pee: 'おしっこ',
  meal: 'ごはん',
  seizure: '発作',
  feces: 'うんち',
  pills: 'くすり',
  weight: '体重',
  temperature: '体温',
  // bloodPressure: '血圧',
  spo2: '酸素濃度',
  heartRate: '心拍数',
}

export const NoteTrackDataTypeIconMap: Record<NoteTrackDataType, string> = {
  weather: 'sun',
  feeling: 'happy',
  pee: 'drop2',
  meal: 'dinner2',
  seizure: 'confused',
  feces: 'poop',
  pills: 'pills',
  weight: 'weight',
  temperature: 'thermometer',
  // bloodPressure: 'dna',
  spo2: 'pulse',
  heartRate: 'heart-pulse',
}

export const NoteTrackDataTypeColorMap: Record<NoteTrackDataType, string> = {
  weather: '',
  feeling: '',
  pee: colors.blue100,
  meal: '',
  seizure: '',
  feces: colors.brown100,
  pills: '',
  weight: '',
  temperature: '',
  // bloodPressure: '',
  spo2: '',
  heartRate: '',
}

export const NoteTrackDataTypeQuantityUnitMap: Record<
  NoteTrackDataType,
  string
> = {
  weather: '',
  feeling: '',
  pee: 'ml',
  meal: 'g',
  seizure: '',
  feces: 'g',
  pills: '',
  weight: 'kg',
  temperature: '℃',
  // bloodPressure: 'mmHG',
  spo2: '%',
  heartRate: 'bpm',
}

export const getNoteTrackItemViewType = (trackItem: NoteTrackItem) => {
  const {
    type,
    data: {type: dataType},
  } = trackItem
  switch (type) {
    case NoteTrackItemType.noteDiary:
      return NoteTrackItemViewType.diary
    case NoteTrackItemType.noteTrack:
      return dataType
  }
}

export const getNoteTrackItem = (
  noteId: NoteId,
  me: UsersMe,
  item: NoteTrackItem,
  user: User,
  relationTypeToMe: RelationType,
): NoteTrackItemProps => {
  const type = getNoteTrackItemViewType(item)
  return {
    ...item.data,
    data: item.data,
    avatarSrc: (user && getThumbUrl(user.iconUrl)) || '',
    avatarName: (user && user.fullname) || '',
    date: item && format(parseISO(item.timestamp), 'yyyy年MM月dd日 HH:mm'),
    timestamp: stringToTimestamp(item.timestamp),
    type:
      item.type === NoteTrackItemType.noteDiary
        ? NoteTrackItemPropsType.diary
        : type,
    deletable: deletableNoteTrack(me, item, relationTypeToMe),
    editable: item && editableNoteTrack(me, item),
    trackId: item && item.id,
    noteId,
  }
}

export const getTrackItems = (noteId: string) =>
  createSelector(
    getNoteTrackItems,
    (items: NoteTrackItem[]) => {
      return items
    },
  )

// TODO: これが重たい
export const getTrackItemsForView = (noteId: string, date: Date) =>
  createSelector(
    getMe,
    getNoteById(noteId),
    getTrackItems(noteId),
    getUsers,
    (me: UsersMe, note: Note, trackItems: NoteTrackItem[], users: User[]) => {
      return trackItems
        .filter(item => {
          const {timestamp = ''} = item || {}
          return timestamp && isSameDay(parseISO(timestamp), date)
        })
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return -1
          }
          if (a.timestamp > b.timestamp) {
            return 1
          }
          return 0
        })
        .map(item => {
          const user = (item && users[item.userId]) || {}
          return getNoteTrackItem(
            noteId,
            me,
            item,
            user,
            note.relationToMe.type,
          )
        })
    },
  )

export const getTrackItemsByDate = (noteId: string, date: Date) =>
  createSelector(
    getNoteTrackItems,
    (items: NoteTrackItem[]) => {
      return items.filter(item => {
        const {timestamp = ''} = item || {}
        return timestamp && isSameDay(parseISO(timestamp), date)
      })
    },
  )

export const getTrackItemsByDataType = (
  noteId: string,
  date: Date,
  type: NoteTrackDataType,
) =>
  createSelector(
    getTrackItems(noteId),
    (trackItems: NoteTrackItem[]): NoteTrackItem[] => {
      const ret = trackItems.filter(track => {
        const {data: {type: dataType = ''} = {}, timestamp = ''} = track || {}
        return (
          type === dataType && timestamp && isSameDay(parseISO(timestamp), date)
        )
      })
      return ret || []
    },
  )

export const getTrackItemsSumQuantityByDataType = (
  noteId: string,
  date: Date,
  type: NoteTrackDataType,
) =>
  createSelector(
    getTrackItemsByDataType(noteId, date, type),
    (trackItems: NoteTrackItem[]): {length: number; quantity: number} => {
      return trackItems.reduce(
        (acc: {length: number; quantity: number}, track) => {
          const {length: accLength, quantity: accQuantity} = acc
          const {data: {quantity = 0} = {}} = track || {}
          // FIXME: as any とかparseIntとか雑？
          const q = quantity
            ? accQuantity + parseInt(quantity as any, 10)
            : accQuantity
          return {length: accLength + 1, quantity: q}
        },
        {length: 0, quantity: 0},
      )
    },
  )

export const getTrackItemByDataType = (
  noteId: string,
  date: Date,
  type: NoteTrackDataType,
) =>
  createSelector(
    getTrackItemsByDataType(noteId, date, type),
    (trackItems: NoteTrackItem[]): NoteTrackItem => {
      const [item] = trackItems
      return item || {}
    },
  )

export const getTrackComments = (trackId: string) =>
  createSelector(
    getNoteTrackItemById(trackId),
    getCurrentNote,
    getUsers,
    getMe,
    (trackItem: NoteTrackItem, note: Note, users: User[], me: UsersMe) => {
      const {id: noteId} = note
      const {comments = []} = trackItem || {}
      return comments
        .filter(v => v)
        .map(comment => {
          const {data: {comment: content = ''} = {}} = comment
          const user = (comment && users[comment.userId]) || {}
          return {
            avatarSrc: (user && getThumbUrl(user.iconUrl)) || '',
            avatarName: (user && user.fullname) || '',
            comment: content,
            date:
              comment &&
              `${formatDistance(parseISO(comment.createdAt), new Date(), {
                locale: ja,
              })}前`,
            deletable: deletableNoteTrackComment(
              me,
              comment,
              note.relationToMe.type,
            ),
            editable: false,
            threadId: trackId,
            commentId: comment.id,
            noteId,
          }
        })
    },
  )

export const getTrackTable = (
  noteId: string,
  types: NoteTrackDataType[],
  beginDate: Date,
  endDate: Date,
) =>
  createSelector(
    getTrackItems(noteId),
    (trackItems: NoteTrackItem[]) => {
      const length = differenceInCalendarDays(endDate, beginDate) + 1
      const dates = Array.from({length}, (v, k) => k)
      return dates.reduce((acc, item, dateIndex) => {
        const date = addDays(beginDate, dateIndex)
        const dayKey = format(date, 'M月d日')
        const ret = trackItems
          .filter(track => {
            const {timestamp} = track
            const viewType = getNoteTrackItemViewType(track)
            return (
              isSameDay(parseISO(timestamp), date) &&
              types.includes(viewType as any /* fixme: type*/)
            )
          })
          .reduce((acc, item): DayItem => {
            const {
              timestamp,
              data: {quantity = 0},
            } = item
            const viewType = getNoteTrackItemViewType(item)
            const hour = format(parseISO(timestamp), 'H')
            const {[hour]: accHour = {}} = acc as any /* fixme: type怒られる */
            const {
              [viewType]: {count: accCount = 0, quantity: accQuantity = 0} = {},
            } = accHour
            return {
              ...acc,
              [hour]: {
                ...accHour,
                [viewType]: {
                  type: viewType,
                  iconType: NoteTrackDataTypeIconMap[viewType],
                  color: NoteTrackDataTypeColorMap[viewType],
                  count: accCount + 1,
                  quantity: Number(quantity) + Number(accQuantity),
                },
              },
            }
          }, {})
        return {...acc, [dayKey]: ret}
      }, {})
    },
  )

/**
 * カレンダーで記録 or 日記がある場合に赤いポッチをつけるだけ
 *
 * @return ['2019-08-19', '2019-08-20']
 */
export const getTrackDateStringsOfMonth = (noteId: string, date: Date) => {
  // 前後６日
  const begin = startOfMonth(addDays(date, -6))
  const end = endOfMonth(addDays(date, 6))
  return createSelector(
    getNoteTrackDailySumItems,
    (dailySums: NoteTrackDailySum[]): string[] => {
      const ret = Object.keys(dailySums).filter(key => {
        const sum = dailySums[key]
        const {date} = sum
        const sumDate = parseISO(date)
        return isAfter(sumDate, begin) && isBefore(sumDate, end)
      })
      return ret
    },
  )
}
