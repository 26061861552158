import {connect} from 'react-redux'
import {match, withRouter} from 'react-router'
import {compose} from 'recompose'
import {FormProps, reduxForm} from 'redux-form'
import {SpaceProps} from '../../components/layouts/Space'
import View, {
  IFormData,
  IFormProps,
  Props,
} from '../../components/organisms/ResetPassowrdForm'
import {IReduxState} from '../../infra/redux'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {resetPasswordForm} from '../../services/forms'
import ResetPasswordUseCase from '../../useCases/auth/ResetPasswordUseCase'
import * as selectors from './selectors'

type StateProps = IFormProps &
  FormProps<IFormData, IFormProps> & {
    initialValues: Partial<IFormData>
  }

interface IOwnProps {
  match: match<{email: string; token: string}>
}

export default compose<Props, SpaceProps>(
  withRouter,
  connect(
    (state: IReduxState, ownProps: IOwnProps): StateProps => {
      // TODO: email の使いみち
      const {token} = ownProps.match.params
      return {
        initialValues: {
          newPassword: '',
        },
        submitForm: e => {
          const values = selectors.getValues(state)
          // Analytics.create().event({
          //   category: 'LoginForm',
          //   action: 'onSubmit',
          // })
          UseCaseCommander.create().command(ResetPasswordUseCase)({
            token,
            ...values,
          })
        },
        submitDisabled: selectors.isSubmitDisabled(state),
      }
    },
  ),
  reduxForm<IFormData, IFormProps>({
    form: resetPasswordForm.id,
  }),
)(View)
