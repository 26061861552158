import {css} from 'styled-components'
import {getThemeValue} from '../variables'
import {Breakpoint} from './breakpoints'
import clearFix from './clearFix'
import media from './media'

// FIXME: type
export type StyleValueProp = number | string

export interface ContainerProps {
  size: Breakpoint
}

export enum ContainerSize {
  lg = 700,
  md = 500,
  sm = 400,
}

interface MediaProps {
  size?: number
  gutter?: number
}

type Props = {
  tablet?: MediaProps
  desktop?: MediaProps
} & MediaProps

export const containerStyle = (size: Breakpoint) => {
  const ret = getThemeValue({
    path: `${size}`,
    themeKey: 'container',
  })
  return ret
}

const container = ({desktop, tablet, ...rest}: Props) => {
  return css`
    ${containerStyle('phone')};
    ${media.tablet(() => containerStyle('tablet'))}
    ${media.desktop(() => containerStyle('desktop'))}
    ${clearFix};
    margin-right: auto;
    margin-left: auto;
  `
}

export default container
