import * as React from 'react'
import styled from 'styled-components'
import ImageUploadField from '../../../containers/ImageUploadField'
import {ReduxField} from '../../../hocs/withReduxField'
import {ResourceType} from '../../../infra/api/types'
import {HandleSubmitProps} from '../../../lib/form/types'
import {Forms, required} from '../../../services/forms'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Loading from '../../atoms/Loading'
import Space from '../../layouts/Space'
import LabeledInput from '../../molecules/LabeledInput'

const View = styled.div``

const Content = styled.div`
  text-align: center;
`
const ButtonOuter = styled.div`
  margin-top: ${space.lg}px;
  width: 100%;
  text-align: center;
`
const Controls = styled.div`
  margin-top: ${space.md}px;
`

const IMAGE_HEIGHT = 160
const IMAGE_WIDTH = '100%'

const PERSON_IMAGE_WIDTH = 150
const PERSON_IMAGE_HEIGHT = 150

const CoverImageControl = styled(Controls)`
  img {
    object-fit: cover;
  }
`

const PersonImageControl = styled(Controls)`
  display: inline-block;
  text-align: center;
  width: ${PERSON_IMAGE_WIDTH}px;
  height: ${PERSON_IMAGE_WIDTH}px;
  border-radius: 50%;
  border: solid 3px #fff;
  overflow: hidden;
  z-index: 10;
`

export interface FormData {
  title: string
  personName: string
  imageUrl: string
  personImageUrl: string
}

export interface Props {
  isProcessing: boolean
  submitForm: (values: any) => any
  initialValues: Partial<FormData>
  submitButtonLabel: string
  submitDisabled: boolean
  personNameFieldLabel: string
  noteTitleFieldLabel: string
  titlePlaceHolder: string
}

export default function NoteForm(props: Props & HandleSubmitProps) {
  return (
    <View>
      <Content>
        <form
          autoComplete="off"
          onSubmit={props.handleSubmit(props.submitForm)}
        >
          <Controls>
            <ReduxField
              component={LabeledInput}
              label={props.noteTitleFieldLabel}
              name="title"
              placeholder={props.titlePlaceHolder}
              validators={[required]}
            />
          </Controls>

          <Controls>
            <ReduxField
              component={LabeledInput}
              label={props.personNameFieldLabel}
              name="personName"
              placeholder="ノートの主役になる方のお名前を入力"
              validators={[required]}
            />
          </Controls>

          <PersonImageControl>
            <ImageUploadField
              form={Forms.noteAttrs}
              formField="personImageUrl"
              height={PERSON_IMAGE_HEIGHT}
              label="本人写真"
              margin="0 auto"
              resourceType={ResourceType.note}
              url={props.initialValues.personImageUrl || ''}
              width={PERSON_IMAGE_WIDTH}
            />
          </PersonImageControl>

          <CoverImageControl>
            <ImageUploadField
              form={Forms.noteAttrs}
              formField="imageUrl"
              height={IMAGE_HEIGHT}
              label="ノートの表紙画像を選択する"
              objectFit="cover"
              resourceType={ResourceType.note}
              url={props.initialValues.imageUrl || ''}
              width={IMAGE_WIDTH}
            />
          </CoverImageControl>

          <ButtonOuter>
            <Button
              backgroundColor={colors.success}
              disabled={props.isProcessing || props.submitDisabled}
              expanded={true}
              size="large"
            >
              {props.submitButtonLabel}
              <Space ml={space.xs}>
                {props.isProcessing ? (
                  <Loading size={18} />
                ) : (
                  <Icon type="user" />
                )}
              </Space>
            </Button>
          </ButtonOuter>
        </form>
      </Content>
    </View>
  )
}
