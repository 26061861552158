import {Action as ReduxAction, Dispatch} from 'redux'
import {UseCaseCommand, UseCaseEvent} from '../../../useCases/lib/types'

export enum ActionType {
  CommandCommanded = 'app/useCase/COMMAND_COMMANDED',
  UsecaseEventEmitted = 'app/useCase/USECASE_EVENT_EMITTED',
}

export interface IUseCaseActionCommandedType extends ReduxAction<ActionType> {
  type: ActionType.CommandCommanded
  payload: {command: UseCaseCommand}
}

export interface IUseCaseActionUseCaseEventEmitted
  extends ReduxAction<ActionType> {
  type: ActionType.UsecaseEventEmitted
  payload: {event: UseCaseEvent}
}

export type Action =
  | IUseCaseActionCommandedType
  | IUseCaseActionUseCaseEventEmitted

export type UseCaseDispatch = Dispatch<Action>
