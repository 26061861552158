import * as React from 'react'
import styled from 'styled-components'
import space from '../../../styles/variables/space'
import Container from '../../layouts/Container'
import DefaultPage from '../../templates/DefaultPage'

const View = styled(Container)`
  padding-top: ${space.lg}px;
  padding-bottom: ${space.xxl}px;
`

export interface Props {
  children: React.ReactNode
}

export default function NoteEmergencyPage(props: Props) {
  const {children} = props
  return (
    <DefaultPage>
      <View>{children}</View>
    </DefaultPage>
  )
}
