import {useRouter} from '../../lib/router'

export const getRouteParams = <T>() => {
  const {
    match: {params},
  } = useRouter<T>()
  return params
}

// FIXME: selector内で使いたいが、hooks関数は使えない
export const getNoteId = () => {
  const {noteId} = getRouteParams()
  return noteId
}

// FIXME: selector内で使いたいが、hooks関数は使えない
export const getDate = () => {
  const {date} = getRouteParams()
  return date
}
