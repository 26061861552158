import * as React from 'react'
import {memo, useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/PostEditNoteTrack'
import {
  dateTimeStrToTimestamp,
  dateToDateStr,
  NoteTrackDataType,
  NoteTrackItemType,
  stringToDateStr
} from '../../domain/note/track/NoteTrack'
import {getNoteTrackItemById} from '../../infra/redux/api/selectors'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import useForm from '../../lib/form/useForm'
import {getCurrentNoteId, getCurrentTrackDate} from '../../selectors/note'
import {
  NoteTrackDataTypeLabelMap,
  NoteTrackDataTypeQuantityUnitMap
} from '../../selectors/note/track'
import ResetFormUseCase from '../../useCases/app/ResetFormUseCase'
import UploadResourceUseCase from '../../useCases/resource/UploadResourceUseCase'
import PostNoteTrackUseCase, {Params as PostNoteTrackUseCaseParams} from '../../useCases/track/PostNoteTrackUseCase'

export interface Props {
  dismiss: () => void
  trackId?: string
  type: NoteTrackDataType
}

const useCaseId = 'PostEditNoteTrackModal'

export default function PostEditNoteTrack(props: Props) {
  const {dismiss, trackId, type} = props
  const noteId = useSelector(getCurrentNoteId)
  const date = useSelector(getCurrentTrackDate)
  const item = trackId && useSelector(getNoteTrackItemById(trackId))
  const {data: {quantity = '', memo = '', image = ''} = {}, timestamp = ''} =
    item || {}
  const time = useMemo(() => {
    return stringToDateStr(timestamp)
  }, [date, timestamp])

  const isUpdate = trackId
  const form = isUpdate
    ? `update-track-form-${trackId}`
    : `create-track-form-${type}`

  const onSubmit = useCallback(
    (values: any) => {
      const {time, ...restValues} = values
      UseCaseCommander.create().command<PostNoteTrackUseCaseParams>(
        PostNoteTrackUseCase,
        useCaseId,
      )({
        type: NoteTrackItemType.noteTrack,
        data: {type, ...restValues},
        timestamp:
          dateTimeStrToTimestamp(dateToDateStr(date), time) || new Date(),
        noteId,
        trackId,
        onComplete: () => {
          props.dismiss && props.dismiss()
          !isUpdate &&
            UseCaseCommander.create().command(ResetFormUseCase)({form})
        },
      })
    },
    [noteId, trackId, date],
  )
  const {useField, handleSubmit, isInvalid} = useForm({
    form,
    initialValues: {
      memo,
      quantity,
      time,
      image,
    },
    onSubmit,
  })

  const handleClose = useCallback(() => {
    dismiss && dismiss()
  }, [dismiss])

  const {input: timeFieldProps} = useField('time')
  const {input: quantityFieldProps} = useField('quantity')
  const {input: memoFieldProps} = useField('memo')
  const {input: imageFieldProps} = useField('image')

  return React.createElement(View, {
    ...props,
    form,
    quantify: !!NoteTrackDataTypeQuantityUnitMap[type],
    quantityUnit: NoteTrackDataTypeQuantityUnitMap[type],
    title: `${NoteTrackDataTypeLabelMap[type]}の記録`,
    submitDisabled:
      isInvalid ||
      useSelector(useCaseSelectors.isProcessing(UploadResourceUseCase)),
    handleSubmit,
    timeFieldProps,
    quantityFieldProps,
    memoFieldProps,
    imageFieldProps,
    isProcessing: useSelector(
      useCaseSelectors.isProcessing(PostNoteTrackUseCase, useCaseId),
    ),
    onCancel: handleClose,
  })
}
