// FIXME: ファイル名
/**
 * create validator
 *
 * @param validator - validator
 */
import {FieldMessageCreator, FieldValidator, Validator} from './types'

/**
 * injectMessage
 *
 * @param msg - string
 * @param validator - Validator
 */
export function injectMessage(
  msg: string | FieldMessageCreator,
  validator: Validator | FieldValidator,
): FieldValidator {
  const message = typeof msg === 'function' ? msg(validator.rule) : msg
  return {
    name: validator.name,
    validate: (value: any) => {
      const ret = validator.validate(value)
      // FIXME: もっとスマートな型チェックを
      const isValidate = typeof ret === 'boolean'
      if (isValidate) {
        return ret ? undefined : message
      }
      // FieldValidateの場合
      return ret ? message : undefined
    },
  }
}
