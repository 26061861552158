import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/IndexPage'
import SignUpPage from '../../components/pages/SignUpPage'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as noteSelectors from '../../selectors/api/note'
import * as sessionSelectors from '../../selectors/app/session'
import FetchUserMeUseCase from '../../useCases/account/FetchUserMeUseCase'
import UpdateSearchNoteQueryUseCase from '../../useCases/note/UpdateSearchNoteQueryUseCase'

let navigationCount = 0

export default function IndexPage() {
  const isAuthorized = useSelector(sessionSelectors.isAuthorized)

  const handleChangeSearchInput = useCallback((query: string) => {
    UseCaseCommander.create().command(UpdateSearchNoteQueryUseCase)({query})
  }, [])

  useEffect(() => {
    navigationCount++
    // FIXME: ApplicationInitializeUseCase内でusersMeにfetchしているので、
    // ２回目以降にindexpageに遷移したときのみusersMeにfetchする
    if (navigationCount > 1) {
      UseCaseCommander.create().command(FetchUserMeUseCase)()
    }
  }, [])

  // TODO: 下記のノートはSignupPageには関係ないので、IndexPageのcontainerをつくる
  const hasNote = useSelector(noteSelectors.hasNote)

  return isAuthorized
    ? React.createElement(View, {
        hasNote,
        onChangeSearchInput: handleChangeSearchInput,
      })
    : React.createElement(SignUpPage)
}
