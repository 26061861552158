import {Notification} from '../../../services/app/Notification'
import {handleActions} from '../helpers'
import {
  Action,
  ActionType,
  DismissNoticeAction,
  ModalType,
  NoticeAction,
  UpdateBusyAction,
  UpdateInitializedAction,
  UpdateNavAction,
  UpdateModalAction,
} from './types'

export const actions = {
  toBusy: (): UpdateBusyAction => ({
    payload: true,
    type: ActionType.busy,
  }),
  toInitialized: (): UpdateInitializedAction => ({
    payload: true,
    type: ActionType.initialized,
  }),
  toUnbusy: (): UpdateBusyAction => ({
    payload: false,
    type: ActionType.busy,
  }),
  showNav: (): UpdateNavAction => ({
    payload: true,
    type: ActionType.nav,
  }),
  hideNav: (): UpdateNavAction => ({
    payload: false,
    type: ActionType.nav,
  }),
  showModal: (type: ModalType): UpdateModalAction => ({
    payload: {
      type,
      show: true,
    },
    type: ActionType.modal,
  }),
  hideModal: (type: ModalType): UpdateModalAction => ({
    payload: {
      type,
      show: false,
    },
    type: ActionType.modal,
  }),
  notice: (payload: Notification): NoticeAction => ({
    payload,
    type: ActionType.notice,
  }),
  dismissNotice: (id: string): DismissNoticeAction => ({
    payload: id,
    type: ActionType.dismissNotice,
  }),
}

export const handlers: KeySignature = {
  [ActionType.initialized]: (state: AppState, action: Action) => {
    return {...state, initialized: action.payload}
  },
  [ActionType.busy]: (state: AppState, action: Action) => {
    return {...state, busy: action.payload}
  },
  [ActionType.nav]: (state: AppState, action: Action) => {
    return {...state, nav: action.payload}
  },
  [ActionType.modal]: (state: AppState, action: UpdateModalAction) => {
    return {
      ...state,
      modal: {
        [action.payload.type]: {...action.payload},
      },
    }
  },
  [ActionType.notice]: (state: AppState, action: NoticeAction) => {
    return {...state, notice: [...state.notice, action.payload]}
  },
  [ActionType.dismissNotice]: (
    state: AppState,
    action: DismissNoticeAction,
  ) => {
    return {
      ...state,
      notice: state.notice.filter(n => n.id !== action.payload),
    }
  },
}

export interface AppState {
  readonly initialized: boolean
  readonly busy: boolean
  readonly nav: boolean
  readonly modal: KeySignature<{type: ModalType; show: boolean}>
  readonly notice: Notification[]
}

const initialState: AppState = {
  initialized: false,
  busy: false,
  nav: false,
  modal: {},
  notice: [],
}

const reducer = handleActions(handlers, initialState)

export default reducer
