import * as React from 'react'
import View, {
  Props as ViewProps,
} from '../../components/layouts/HistoryItemLayout'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import PushSearchNavigationUseCase, {
  PushSearchNavigationUseCaseParams,
} from '../../useCases/app/PushSearchNavigationUseCase'

export type Props = Omit<ViewProps, 'onClickLabel' | 'onClickFullname'>

export default function NoteHistoryItemLayout(props: Props) {
  const innerProps = {
    onClickLabel: () => {
      UseCaseCommander.create().command<PushSearchNavigationUseCaseParams>(
        PushSearchNavigationUseCase,
      )({itemId: props.name})
    },
    onClickFullname: () => {
      UseCaseCommander.create().command<PushSearchNavigationUseCaseParams>(
        PushSearchNavigationUseCase,
      )({userId: props.userId})
    },
    ...props,
  }
  return React.createElement<ViewProps>(View, innerProps)
}
