import {History} from 'history'
import createHistory from 'history/createBrowserHistory'

// TODO: src/infra/history.js でファイル配置がよいのか迷いあり

let singleton: History | null = null

/**
 * getHistory
 */
export function getHistory() {
  if (singleton) {
    return singleton
  }
  singleton = createHistory()
  return singleton
}

export {createHistory}
