import * as React from 'react'
import BackButton from '../../../containers/BackButton'
import HamburgerButton from '../../../containers/HamburgerButton'
import TrackDatePicker from '../../../containers/TrackDatePicker'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import HeaderLayout from '../../layouts/HeaderLayout'
import Row from '../../layouts/Row'
import LabeledIcon from '../../molecules/LabeledIcon'

export interface Props {
  date: Date
  onChange: (value: Date) => void
  onClickTrackLink: () => void
}

export default function WeeklyHeader(props: Props) {
  const {date, onChange, onClickTrackLink} = props
  const right = (
    <Row gutter={space.sm + 5}>
      <Col>
        <LabeledIcon onClick={onClickTrackLink} type="calendar-check">
          記録
        </LabeledIcon>
      </Col>
      <Col>
        <HamburgerButton />
      </Col>
    </Row>
  )
  return (
    <HeaderLayout
      center={<TrackDatePicker date={date} onChange={onChange} weekly={true} />}
      left={<BackButton routeKey="noteCover" />}
      right={right}
    />
  )
}
