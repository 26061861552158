import * as React from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Loading from '../../atoms/Loading'
import PlaceholderImage from '../../atoms/PlaceholderImage'

const View = styled.div<{width: string | number}>`
  position: relative;
  ${p =>
    p.width &&
    `width: ${typeof p.width === 'number' ? `${p.width}px` : p.width};`}
  height: auto;
  max-width: 100%;

  background-color: ${colors.brand};
`

const Indicator = styled.div`
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`

const Input = styled.input`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const HiddenInput = styled.input`
  display: none;
`

const Image = styled(PlaceholderImage)`
  width: 100%;
  height: auto;
`

const Label = styled(Body)`
  z-index: 10;
  padding: ${space.xs}px ${space.sm}px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-weight: 700;
`

export interface Props {
  label?: string
  url: string
  name: string
  height: number | string
  width: number | string
  onChangeFile: (e: any) => void
  isProcessing: boolean
}

export default function ImageUploadField(props: Props) {
  const {url, name, width, height, label} = props
  return (
    <View width={width}>
      <ReduxField component={HiddenInput} name={name} type="hidden" />
      <Input onChange={props.onChangeFile} type="file" />
      <Image height={height} src={url} width={width} />
      {props.isProcessing && (
        <Indicator>
          <Loading size={30} />
        </Indicator>
      )}
      {label && <Label>{label}</Label>}
    </View>
  )
}
