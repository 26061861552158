import 'intersection-observer'
import * as React from 'react'
import {useEffect, useRef} from 'react'
import styled from 'styled-components'

export interface Props extends IntersectionObserverInit {
  children: React.ReactNode
  onAbove?: (entry: IntersectionObserverEntry) => void
  onPreAbove?: (entry: IntersectionObserverEntry) => void
  onBelow?: (entry: IntersectionObserverEntry) => void
  onPreBelow?: (entry: IntersectionObserverEntry) => void
}

export const View = styled.div``

export default function ViewportObserver(props: Props) {
  const element = useRef<HTMLDivElement>(null)
  const {
    children,
    root = null,
    rootMargin = '0px',
    threshold = [0, 0.05],
    onAbove,
    onPreAbove,
    onBelow,
    onPreBelow,
  } = props
  const ratio = threshold instanceof Array && threshold[1]
  const option: IntersectionObserverInit = {
    root,
    rootMargin,
    threshold,
  }
  const observer = new IntersectionObserver(entries => {
    for (const entry of entries) {
      if (!entry.rootBounds) {
        break
      }
      const isAbove = entry.boundingClientRect.top > entry.rootBounds.top
      const isBelow = !isAbove
      const isDown = isAbove
      const isUp = isBelow
      if (isDown) {
        if (entry.intersectionRatio > ratio) {
          if (onBelow) {
            onBelow(entry)
          }
        } else if (entry.intersectionRatio <= 0) {
          if (onPreBelow) {
            onPreBelow(entry)
          }
        }
      }
      if (isUp) {
        if (entry.intersectionRatio > ratio) {
          if (onAbove) {
            onAbove(entry)
          }
        } else if (entry.intersectionRatio <= 0) {
          if (onPreAbove) {
            onPreAbove(entry)
          }
        }
      }
    }
  }, option)
  useEffect(() => {
    const {current} = element
    current && observer.observe(current)
    return () => {
      current && observer.observe(current)
      observer.disconnect()
    }
  })

  return <View ref={element}>{children}</View>
}
