import IAccountRegistryService from '../../domain/account/IAccountRegistryService'
import AccountRegistryService from '../../infra/services/AccountRegistryService'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'

interface Props {
  accountRegistryService: IAccountRegistryService
  navigation: Navigation
}

export interface SignUpUseCaseParams {
  fullname: string
  email: string
  password: string
  redirect?: string
}

/**
 * SignUpUseCase
 */
export default class SignUpUseCase extends UseCase {
  private _accountRegistryService: IAccountRegistryService
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._accountRegistryService = props.accountRegistryService
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new SignUpUseCase({
      accountRegistryService: AccountRegistryService.create(),
      navigation: Navigation.create(),
    })
  }

  public onError(error: Error): void {
    super.onError(error)
    // FIXME: 暫定的にindex
    this._navigation.push('index')
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({
    redirect,
    ...params
  }: SignUpUseCaseParams): Promise<any> {
    const signUpParams = {
      ...params,
      redirect,
    }

    await this._accountRegistryService.signUp(signUpParams).catch(() => {
      this._navigation.push('signUp')
    })
    this._navigation.push('confirmEmailVerification')
  }
}
