import * as React from 'react'
import styled from 'styled-components'
import {col} from '../../../styles/mixins/grid'
import {typography} from '../../../styles/mixins/typography'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

const View = styled.button<any>`
  ${col};
  padding: ${space.sm}px;

  width: ${p => (p.expanded ? '100%' : 'auto')};
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: solid 1px ${colors.gray200};

  ${p =>
    p.selected &&
    `
     background-color: ${colors.gray200};
 `};

  cursor: ${p => (!p.disabled ? 'pointer' : 'normal')};

  ${p =>
    !p.disabled &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.02);
      opacity: 0;
      transition: 0.3s ease;
    }
    &:hover&::after {
      opacity: 1;
    }
  `};

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
`

const Label = styled.div`
  ${typography};
`

export interface Props {
  children: React.ReactNode
  onChange?: (event: React.SyntheticEvent, value: string) => void
  value: string
  selected?: boolean
  expanded?: boolean
}

export default function ToggleButton(props: Props) {
  const {children, onChange, value, ...rest} = props
  const handleChange = (event: React.SyntheticEvent) => {
    if (onChange) {
      onChange(event, value)
      if (event.isDefaultPrevented()) {
        return
      }
    }
  }
  return (
    <View {...rest} onClick={handleChange}>
      <Label type="body1">{children}</Label>
    </View>
  )
}
