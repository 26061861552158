import * as React from 'react'
import styled from 'styled-components'
import {Props} from '../../../containers/NoteHistoryItem'
import Code from '../../atoms/Code'
import HistoryItemLayout from '../../../containers/NoteHistoryItemLayout'

const PrevCode = styled(Code)`
  color: #b30000;
  background: #fadad7;
`

export const getValue = (name: string, data: any, attr: KeySignature) => {
  const {[name]: ret = data} = data || {}
  const {suffix = ''} = attr
  // return typeof ret === 'string' ? ret || '(空白)' : JSON.stringify(ret)
  if (typeof ret !== 'string') {
    return '(空白)'
  }
  if (suffix) {
    return `${ret} ${suffix}`
  }
  return ret
}

export default function TextHistoryItem(props: Props) {
  const {previous, data, name, attr, ...rest} = props
  const prevData = previous && (
    <PrevCode>{getValue(name, previous, attr)}</PrevCode>
  )
  const currentData = <Code>{getValue(name, data, attr)}</Code>
  return (
    <HistoryItemLayout
      attr={attr}
      currentData={currentData}
      name={name}
      prevData={prevData}
      {...rest}
    />
  )
}
