import {createSelector} from 'reselect'
import Note from '../../domain/note/Note'
import {getThumbUrl} from '../../domain/resource/Resource'
import {getNotes} from '../../selectors/api/note'
import {getCurrentNoteId} from '../../selectors/note'

export const getNotesSelect = createSelector(
  getCurrentNoteId,
  getNotes,
  (currentNoteId: string, notes: Note[]) => {
    return notes
      ? notes.map(note => {
          const {id = '', personImageUrl = '', title = ''} = note || {}
          return {
            id,
            active: id === currentNoteId,
            src: getThumbUrl(personImageUrl),
            title,
          }
        })
      : []
  },
)
