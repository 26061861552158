import * as React from 'react'
import {useSelector} from 'react-redux'
import {getNoteEmergencyCodeById} from '../../selectors/note/emergency'
import {useRouter} from '../../lib/router'
import NoteEmergencyCode from '../../domain/note/NoteEmergencyCode'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import FetchNoteEmergencyCodeUseCase, {
  Params as FetchNoteEmergencyCodeUseCaseParams,
} from '../../useCases/note/FetchNoteEmergencyCodeUseCase'
import View from '../../components/pages/NoteEmergencyCodePrintPage';

export default function NoteEmergencyCodePrintPage() {
  const {
    match: {
      params: {noteId},
    },
  } = useRouter<{noteId: string}>()
  const noteEmergencyCode: NoteEmergencyCode | null = useSelector(
    getNoteEmergencyCodeById(noteId),
  )
  const {personName = '', emergencyInfo: {urlQrCodeData = ''} = {}} =
    noteEmergencyCode || {}

  React.useEffect(() => {
    if (noteEmergencyCode == null) {
      UseCaseCommander.create().command<FetchNoteEmergencyCodeUseCaseParams>(
        FetchNoteEmergencyCodeUseCase,
      )({noteId})
    }
  }, [noteId])

  return React.createElement(View, {
    personName,
    urlQrCodeData,
  })
}
