import {createSelector} from 'reselect'
import {ResourceSchema} from '../../infra/redux/api/schemata'
import {getById} from '../../infra/redux/api/selectors'

export const getResourceById = getById(ResourceSchema)

export const getResourceItems = (id: string) =>
  createSelector(
    getResourceById(id),
    (resource: any) => {
      const {items = []} = resource || {}
      return items
    },
  )

export const getResourceLatestItem = (id: string) =>
  createSelector(
    getResourceItems(id),
    (items: any[]) => {
      const [latest = ''] = items || []
      return latest
    },
  )
