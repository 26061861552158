import * as React from 'react'
import {useSelector} from 'react-redux'
import {getMe} from '../../selectors/api'
import View from '../../components/pages/LoginPage'
import {useEffect} from 'react'
import Navigation from '../../services/navigation/Navigation'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import SignInUseCase from '../../useCases/auth/SignInUseCase'

export default function LoginPage() {
  const me = useSelector(getMe) || {}

  const isSignInProcessing = useSelector(
    useCaseSelectors.isProcessing(SignInUseCase),
  )

  useEffect(() => {
    if (!isSignInProcessing && me.id) {
      Navigation.create().push('index')
    }
  }, [me.id])

  return React.createElement(View)
}
