import UseCase from '../lib/UseCase'

export interface OpenMailerParams {
  subject: string
  body: string
}

export default class OpenMailerUseCase extends UseCase {
  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor() {
    super()
  }

  /**
   * create
   */
  public static create() {
    return new OpenMailerUseCase()
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({subject, body}: OpenMailerParams): Promise<void> {
    window.location.href =
      'mailto:?subject=' +
      encodeURIComponent(subject) +
      '&body=' +
      encodeURIComponent(body)
  }
}
