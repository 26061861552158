import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import {replaceNewLine} from '../../helpers'

const Blank = styled.div`
  padding: ${space.xxl}px ${space.md}px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Figure = styled(Icon)`
  color: ${colors.gray300};
`

export interface Props {
  body: React.ReactNode
}

export default function Empty(props: Props) {
  return (
    <Blank>
      <Figure size={40} type="notification-circle" />
      <Body color={colors.gray300} mt="md">
        {replaceNewLine(props.body)}
      </Body>
    </Blank>
  )
}
