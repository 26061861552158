import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteMembers'
import {getNoteMembers} from './selectors'

export default function NoteMembers() {
  const members = useSelector(getNoteMembers)
  return React.createElement(View, {
    members,
  })
}
