import ISnapshotRepository from '../../domain/note/ISnapshotRepository'
import NoteId from '../../domain/note/NoteId'
import SnapshotRepository from '../../infra/repositories/note/SnapshotRepository'
import UseCase from '../lib/UseCase'

interface Props {
  snapshotRepository: ISnapshotRepository
}

/**
 * SignUpUseCase
 */
export default class RemoveNoteSnapshotUseCase extends UseCase {
  private _snapshotRepository: ISnapshotRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._snapshotRepository = props.snapshotRepository
  }

  /**
   * create
   */
  public static create() {
    return new RemoveNoteSnapshotUseCase({
      snapshotRepository: SnapshotRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({id}: {id: NoteId}): Promise<void> {
    await this._snapshotRepository.remove(id)
  }
}
