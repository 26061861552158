import * as React from 'react'
import styled from 'styled-components'

const View = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function NotFoundPage() {
  return <View>Page not found.</View>
}
