import {
  UseCaseCommand,
  UseCaseEvent,
  UseCaseEventTypes,
} from '../../../useCases/lib/types'
import {
  getUseCaseIdFromCommand,
  getUseCaseName,
} from '../../../useCases/lib/UseCase'
import {handleActions} from '../helpers'
import {
  Action,
  ActionType,
  IUseCaseActionCommandedType,
  IUseCaseActionUseCaseEventEmitted,
} from './types'

export interface UseCaseSummaryContent {
  processing: boolean
}

export interface UseCaseSummary {
  [useCaseName: string]: {
    [useCaseId: string]: UseCaseSummaryContent
  }
}

export interface UseCaseState {
  readonly history: UseCaseEvent[]
  summary: UseCaseSummary
}

const initialState: UseCaseState = {
  history: [],
  summary: {},
}

export const actions = {
  command: ({
    command: cmd,
  }: {
    command: UseCaseCommand
  }): IUseCaseActionCommandedType => {
    return actions.toDebugableActionForCommand({
      payload: {command: cmd},
      type: ActionType.CommandCommanded,
    })
  },
  report: (event: UseCaseEvent): IUseCaseActionUseCaseEventEmitted => {
    const action: Action =
      [
        UseCaseEventTypes.UsecaseFailed,
        UseCaseEventTypes.CommandCanceled,
      ].indexOf(event.type) >= 0
        ? {
            error: true,
            payload: {event},
            type: ActionType.UsecaseEventEmitted,
          }
        : {
            payload: {event},
            type: ActionType.UsecaseEventEmitted,
          }
    return actions.toDebugableActionForEvent(action)
  },
  toDebugableActionForCommand: (
    action: IUseCaseActionCommandedType,
  ): IUseCaseActionCommandedType => {
    // const {command} = action.payload;
    return action
  },
  toDebugableActionForEvent: (
    action: Action,
  ): IUseCaseActionUseCaseEventEmitted => {
    // const {event} = action.payload;
    return {
      ...action,
      // toJSON: () => ({
      //   ...action,
      //   type: `${event.command.UseCase.name}/${event.type}`,
      // }),
    } as IUseCaseActionUseCaseEventEmitted // FIXME: type
  },
}

const createMergedSummary = (
  summary: UseCaseSummary,
  command: UseCaseCommand,
  mergingContent: UseCaseSummaryContent,
): UseCaseSummary => {
  const useCaseName = getUseCaseName(command.UseCase)
  const useCaseId = getUseCaseIdFromCommand(command)
  return {
    ...summary,
    [useCaseName]: {
      ...summary[useCaseName],
      [useCaseId]: mergingContent,
    },
  }
}

export const handlers: KeySignature = {
  [ActionType.CommandCommanded]: (
    state: UseCaseState,
    action: IUseCaseActionCommandedType,
  ) => {
    const {
      payload: {command: cmd},
    } = action
    return {
      ...state,
      summary: createMergedSummary(state.summary, cmd, {processing: true}),
    }
  },
  [ActionType.UsecaseEventEmitted]: (
    state: UseCaseState,
    action: IUseCaseActionUseCaseEventEmitted,
  ) => {
    const {
      payload: {event},
    } = action
    const processing =
      [
        UseCaseEventTypes.CommandCanceled,
        UseCaseEventTypes.UsecaseFailed,
        UseCaseEventTypes.UsecaseSucceeded,
      ].indexOf(event.type) < 0
    return {
      ...state,
      history: state.history.concat([event]),
      summary: createMergedSummary(state.summary, event.command, {processing}),
    }
  },
}

const reducer = handleActions(handlers, initialState)
export default reducer
