import * as React from 'react'
import {useMemo} from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import Comment, {Props as CommentProps} from '../../molecules/Comment'

const CommentOuter = styled.div`
  ${spacing};
`

export interface Props {
  items: CommentProps[]
  onClickRemoveButton: (commentId: string) => void
}

export default function CommentList(props: Props) {
  const {items, onClickRemoveButton} = props
  const comments = useMemo(
    () =>
      items.map(item => {
        const {
          avatarSrc,
          avatarName,
          commentId,
          date,
          deletable,
          comment,
          editable,
          threadId,
        } = item
        return (
          <CommentOuter key={commentId} mb="xs">
            <Comment
              avatarName={avatarName}
              avatarSrc={avatarSrc}
              comment={comment}
              commentId={commentId}
              date={date}
              deletable={deletable}
              editable={editable}
              onClickRemoveButton={() => onClickRemoveButton(commentId)}
              threadId={threadId}
            />
          </CommentOuter>
        )
      }),
    [items],
  )
  return <>{comments}</>
}
