import {schema} from 'normalizr'
import {TreeItemResponse} from '../../api/types'

export const UserSchema = new schema.Entity('user')

export const HistorySchema = new schema.Entity(
  'history',
  {
    changeInfo: {userId: UserSchema},
  },
  {
    idAttribute: (value: TreeItemResponse): string => {
      const {changeInfo: {itemId = ''} = {}} = value || {}
      return itemId || Math.random().toString(32)
    },
  },
)

// FIXME: rename treeてなんやねん...
export const TreeSchema = new schema.Entity('tree')

const tree = new schema.Array(TreeSchema)
TreeSchema.define({tree})

// export const SnapshotSchema = new schema.Entity('snapshot', {
//   tree: [TreeSchema],
// })

export const NoteTimelineCommentSchema = new schema.Entity(
  'noteTimelineComment',
)
export const NoteTimelineItemSchema = new schema.Entity('noteTimelineItem', {
  comments: [NoteTimelineCommentSchema],
})

export const NoteTimelineSchema = new schema.Entity(
  'noteTimeline',
  {
    items: [NoteTimelineItemSchema],
    users: [UserSchema],
  },
  {
    idAttribute: (value: any): string => {
      return value.noteId || Math.random().toString(32)
    },
  },
)

export const NoteTrackCommentSchema = new schema.Entity('noteTrackComment')
export const NoteTrackItemSchema = new schema.Entity('noteTrackItem', {
  comments: [NoteTrackCommentSchema],
})

export const NoteTrackSchema = new schema.Entity(
  'noteTrack',
  {
    items: [NoteTrackItemSchema],
    users: [UserSchema],
  },
  {
    idAttribute: (value: any): string => {
      return value.noteId || Math.random().toString(32)
    },
  },
)

export const NoteTrackDailySumsSchema = new schema.Entity(
  'noteTrackDailySums',
  {},
  {
    idAttribute: (value: {date: string}): string => {
      const {date} = value
      return date
    },
  },
)

export const NoteSchema = new schema.Entity('note', {
  members: [UserSchema],
  users: [UserSchema],
  snapshot: {tree: [TreeSchema]},
  history: [HistorySchema],
  requestingUsers: [UserSchema],
  timeline: NoteTimelineSchema,
})

export const NoteMailNotificationTypeSchema = new schema.Entity(
  'noteMailNotificationType',
  {},
  {
    idAttribute: 'type',
  },
)

export const MeSchema = new schema.Entity('me', {
  ownedNotes: [NoteSchema],
  followingNotes: [NoteSchema],
})

export const UserTimelineSchema = new schema.Entity('userTimeline')

export const ResourceSchema = new schema.Entity('resource', {})

export const NoteInvitationSchema = new schema.Entity(
  'noteInvitation',
  {},
  {
    idAttribute: (value: any): string => {
      return value.token || Math.random().toString(32)
    },
  },
)

export const NoteInvitationCodeSchema = new schema.Entity('noteInvitationCode')

export const NoteEmergencyCodeSchema = new schema.Entity('noteEmergencyCode')
export const NoteEmergencySchema = new schema.Entity(
  'noteEmergency',
  {},
  {
    idAttribute: (value: {token: string}): string => {
      return value.token
    },
  },
)
