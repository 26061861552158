import * as React from 'react'
import {useRef} from 'react'
import styled from 'styled-components'
import LabeledIcon from '../../molecules/LabeledIcon'
import UserNotificationListMenu from '../UserNotificationListMenu'

const View = styled.div``

export interface Props {
  isShowModal: boolean
  hideModal: () => void
  onClickButton: () => void
  onClickMore: () => void
}

export default function UserNotificationsButton(props: Props) {
  const {isShowModal, hideModal, onClickButton, onClickMore} = props
  const iconElement = useRef<HTMLDivElement>(null)
  return (
    <View>
      <UserNotificationListMenu
        dismiss={hideModal}
        onClickMore={onClickMore}
        show={isShowModal}
        trigger={iconElement.current}
      />
      <LabeledIcon
        onClick={onClickButton}
        ref={iconElement}
        title="通知をを表示します"
        type="alarm"
      >
        通知
      </LabeledIcon>
    </View>
  )
}
