import * as React from 'react'
import {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/NoteCover'
import {
  getNoteCoverEditableToMe,
  getNoteEmergencyCodeShowable,
  getNoteInvitableToMe,
  getNoteMembersEditableToMe
} from '../../domain/note/Note'
import {getMemberUsers} from '../../domain/note/NoteRelatedUser'
import {getThumbUrl} from '../../domain/resource/Resource'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'

export default function NoteCover() {
  const noteId = useSelector(getCurrentNoteId)
  const note = useSelector(getNoteById(noteId))
  const {imageUrl, personImageUrl, personName, title} = note
  const emergencyCodeShowable = useMemo(
    () => getNoteEmergencyCodeShowable(note),
    [noteId],
  )
  const editable = useMemo(() => getNoteCoverEditableToMe(note), [noteId])
  const memberEditable = useMemo(() => getNoteMembersEditableToMe(note), [
    noteId,
  ])
  const invitable = useMemo(() => getNoteInvitableToMe(note), [noteId])
  const members = useMemo(() => getMemberUsers(note), [noteId])
  const onClickNoteButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'noteDetail', noteId})
  }, [noteId])
  const onClickEditButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'editNoteCover', noteId})
  }, [noteId])
  const onClickMembersButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'editNoteMembers', noteId})
  }, [noteId])
  const onClickTrackButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'track', noteId})
  }, [noteId])
  const onClickContactsButton = useCallback(() => {
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'noteContacts', noteId})
  }, [noteId])
  return React.createElement(View, {
    coverImageSrc: imageUrl,
    title: title,
    personName: personName,
    personImageUrl: getThumbUrl(personImageUrl),
    editable: editable,
    members,
    invitable,
    memberEditable,
    numMembersIconBadge: note.numRequests || undefined,
    emergencyCodeShowable,
    onClickNoteButton,
    onClickEditButton,
    onClickMembersButton,
    onClickTrackButton,
    onClickContactsButton,
  })
}
