import * as React from 'react'
import HeaderLogo from '../../../containers/HeaderLogo'
import HeaderRight from '../../../containers/HeaderRight'
import HeaderLayout from '../../layouts/HeaderLayout'

export default function IndexHeader() {
  const left = <HeaderLogo />
  const right = <HeaderRight />
  return <HeaderLayout left={left} right={right} />
}
