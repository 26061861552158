import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import {getThumbUrl} from '../../../domain/resource/Resource'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Avatar from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import EditAccountDialog from '../EditAccountDialog'

const View = styled.div``

const Member = styled.div`
  margin-top: ${space.xs}px;
  display: inline-flex;
  align-items: center;
  color: ${colors.gray400};
`

export interface Props {
  members: {
    iconUrl: string
    isMe: boolean
    fullname: string
  }[]
}

export default function NoteMembers(props: Props) {
  const [showEditAccountDialog, setShowEditAccountDialog] = useState(false)
  const members = props.members.map((member, index) => {
    return (
      <Col col="auto" key={index}>
        <Member>
          <Avatar src={getThumbUrl(member.iconUrl)} />
          {member.fullname ? (
            <Body ml="xs" type="body2">
              {member.fullname}
            </Body>
          ) : (
            <React.Fragment>
              {member.isMe ? (
                <Body
                  color={colors.brand}
                  ml="xxs"
                  onClick={() => setShowEditAccountDialog(true)}
                  type="body2"
                >
                  (名無しさん) <Icon size={16} type="pen" />
                </Body>
              ) : (
                <Body ml="xxs" type="body2">
                  (名無しさん)
                </Body>
              )}
            </React.Fragment>
          )}
        </Member>
        {!member.fullname && member.isMe && (
          <EditAccountDialog
            dismiss={() => setShowEditAccountDialog(false)}
            isShow={showEditAccountDialog}
            message="あなたのアカウントの「お名前」を入力しましょう!\n「お名前」は「ノートの招待」や「招待状の申請」に必要です。"
          />
        )}
      </Col>
    )
  })
  return (
    <View>
      {members.length > 0 && (
        <React.Fragment>
          <Row gutter={space.sm}>{members}</Row>


        </React.Fragment>
      )}
    </View>
  )
}
