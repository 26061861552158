import {maxLength, minLength} from '../../lib/validators'
import {ValidationResult, Validator} from '../../lib/validators/types'

const PASSWORD_MAX_LENGTH = 255
const PASSWORD_MIN_LENGTH = 8

export const passwordMaxLengthValidator: Validator = {
  ...maxLength(PASSWORD_MAX_LENGTH),
  name: 'passwordMaxLength',
}

export const passwordMinLengthValidator: Validator = {
  ...minLength(PASSWORD_MIN_LENGTH),
  name: 'passwordMinLength',
}

export type Password = string

const passwordValidators = [
  passwordMaxLengthValidator,
  passwordMinLengthValidator,
]

export function validate(val: any): ValidationResult {
  let errors: any = []
  const isValid = passwordValidators.every(validator => {
    const valid = validator.validate(val)
    !valid && (errors = {...errors, [validator.name]: validator, val})
    return valid
  })
  return isValid ? null : errors
}
