import AppError from '../../../services/error/AppError'
import {handleActions} from '../helpers'
import {Action, ActionType, IDismissAction, IShowAction} from './types'

export const actions = {
  dismiss: (errorId: string): IDismissAction => ({
    payload: errorId,
    type: ActionType.Dismiss,
  }),
  show: (error: AppError): IShowAction => ({
    payload: error,
    type: ActionType.Show,
  }),
}

export const handlers: KeySignature = {
  [ActionType.Show]: (state: ErrorState, action: Action) => {
    return {items: [...state.items, action.payload]}
  },
  [ActionType.Dismiss]: (state: ErrorState, action: Action) => ({
    ...state,
    items: state.items.filter(err => err.id !== action.payload),
  }),
}

export interface ErrorState {
  readonly items: AppError[]
}

const initialState: ErrorState = {
  items: [],
}

const reducer = handleActions(handlers, initialState)
export default reducer
