import INoteNotifications from '../../../domain/note/notifications/INoteNotifications'
import NoteNotifications from '../../../infra/repositories/note/notifications/NoteNotifications'
import UseCase from '../../lib/UseCase'

interface Props {
  noteNotification: INoteNotifications
}

export default class FetchNoteNotificationsUseCase extends UseCase {
  private noteNotification: INoteNotifications

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteNotification = props.noteNotification
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteNotificationsUseCase({
      noteNotification: NoteNotifications.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(): Promise<void> {
    await this.noteNotification.getTypes()
  }
}
