import {Schema} from 'normalizr'
import {Action as ReduxAction} from 'redux'

export enum ActionType {
  Store = 'app/api/entities/store',
  ClearAll = 'app/api/entities/clearAll',
  Spend = 'app/api/entities/spend',
  Clear = 'app/api/entities/clear',
}

export type ActionPayload = any // FIXME: type

export interface StorePagination {
  offset?: number
  limit?: number
  totalCount?: number
  nextOffset?: number
}

export interface StoreAction extends ReduxAction<ActionType> {
  type: ActionType
  meta: {
    schema: Schema
    result?: string[]
    pagination?: StorePagination
  }
  payload: ActionPayload
}

export interface ClearAllAction extends ReduxAction<ActionType> {
  type: ActionType
}

export type Action = StoreAction | ClearAllAction
