import NoteManager from '../../infra/app/NoteManager'
import {INoteManager} from '../../services/app/INoteManager'
import UseCase from '../lib/UseCase'

interface Props {
  noteManager: INoteManager
}

export interface Params {
  noteId: string
}

export default class UpdateCurrentNoteIdUseCase extends UseCase {
  private _noteManager: INoteManager

  public constructor(props: Props) {
    super()
    this._noteManager = props.noteManager
  }

  public static create() {
    return new UpdateCurrentNoteIdUseCase({
      noteManager: NoteManager.create(),
    })
  }

  public async execute({noteId}: Params): Promise<void> {
    this._noteManager.setCurrent(noteId)
  }
}
