import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import Icon from '../Icon'

const View = styled.div`
  ${spacing}
  display: inline-flex;
  cursor: pointer;
`

const MenuIcon = styled(Icon)`
  transform: rotate(90deg);
  transform-origin: 50% 50%;
`

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  size?: number
}

const MenuIconButton = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const {size = 18, ...rest} = props
    return (
      <View {...rest} ref={ref}>
        <MenuIcon size={size} type="ellipsis" />
      </View>
    )
  },
)

export default MenuIconButton
