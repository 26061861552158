import * as React from 'react'
import {useCallback} from 'react'
import styled from 'styled-components'
import {useModalState} from '../../../selectors/app/dom'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Modal from '../../layouts/Modal'
import Row from '../../layouts/Row'

const View = styled.div`
  ${spacing};
`

const Actions = styled.div`
  ${spacing};
  overflow: hidden;
`

export interface Props {
  editable: boolean
  deletable: boolean
  onClickEditButton: () => void
  onClickRemoveButton: () => void
}

export default function EditRemoveActions(props: Props) {
  const {editable, deletable, onClickEditButton, onClickRemoveButton} = props
  const [confirmDialog, setConfirmDialog] = useModalState(false)
  const handleDelete = useCallback(() => {
    onClickRemoveButton()
    setConfirmDialog(false)
  }, [confirmDialog])
  const handleShowConfirmDelete = useCallback(() => {
    setConfirmDialog(true)
  }, [])
  const handleDismiss = useCallback(() => {
    setConfirmDialog(false)
  }, [])
  return (
    <View>
      {deletable && (
        <Modal dismiss={handleDismiss} show={confirmDialog}>
          <Body type="body2">削除してもよろしいですか？</Body>
          <Actions mt="auto">
            <Row justify="flex-end" mt="md">
              <Col>
                <Button
                  backgroundColor={colors.gray400}
                  onClick={handleDismiss}
                >
                  キャンセル
                </Button>
              </Col>
              <Col>
                <Button backgroundColor={colors.red100} onClick={handleDelete}>
                  削除する
                </Button>
              </Col>
            </Row>
          </Actions>
        </Modal>
      )}

      <Row gutter={space.md}>
        {editable && (
          <Col>
            <Icon onClick={onClickEditButton} type="pencil3" />
          </Col>
        )}
        {deletable && (
          <Col>
            <Icon onClick={handleShowConfirmDelete} type="trash2" />
          </Col>
        )}
      </Row>
    </View>
  )
}
