import INoteTimelineRepository from '../../domain/note/timeline/INoteTimelineRepository'
import NoteTimelineRepository from '../../infra/repositories/note/NoteTimelineRepository'
import FormService from '../../infra/services/FormService'
import {NotificationPrinciple, NotificationType} from '../../services/app/Notification'
import {Forms} from '../../services/forms'
import NoticeUseCase from '../app/NoticeUseCase'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTimelineRepository: INoteTimelineRepository
  formService: IFormService
}

interface Params {
  noteId: string
  timelineId: string
  data: {
    comment: string
  }
  onComplete?: () => void
}

export default class PostTimelineCommentUseCase extends UseCase {
  private _noteTimelineRepository: INoteTimelineRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTimelineRepository = props.noteTimelineRepository
    this._formService = props.formService
  }

  public static create() {
    return new PostTimelineCommentUseCase({
      noteTimelineRepository: NoteTimelineRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({
    noteId,
    timelineId,
    data,
    onComplete,
  }: Params): Promise<void> {
    this.registerComplete(async () => {
      this._formService.reset(Forms.timelineComment(timelineId))
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '投稿しました',
      })
      onComplete && onComplete()
    })
    await this._noteTimelineRepository.postComment({noteId, timelineId, data})
  }
}
