import * as React from 'react'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'

const View = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  background-color: #fff;
  border: solid 1px ${colors.gray200};
  ${borderRadius};
`

const Header = styled.header`
  padding: ${space.sm}px ${space.md}px 0 ${space.md}px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Label = styled(Body)`
  margin-right: ${space.sm}px;
  font-weight: 600;
  text-align: left;
`

const Error = styled(Body)`
  margin-left: auto;
  color: ${colors.error};
  text-align: left;
`

const Content = styled.div`
  margin-top: ${space.xs}px;
`

export interface Props {
  error?: string
  label?: string
  children?: React.ReactNode
}

export default function LabeledField({error, label, children}: Props) {
  return (
    <View>
      <Header>
        {label && <Label>{label}</Label>}
        {error && <Error>{error}</Error>}
      </Header>

      <Content>{children}</Content>
    </View>
  )
}
