import View, {Props} from '../../components/pages/NoteInvitationPage'
import React, {useEffect} from 'react'
import {useRouter} from '../../lib/router'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import PushNavigationUseCase, {
  PushNavigationUseCaseParams,
} from '../../useCases/app/PushNavigationUseCase'
import FetchNoteInvitationUseCase from '../../useCases/note/FetchNoteInvitationUseCase'
import {useSelector} from 'react-redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import {getMe} from '../../selectors/api'
import RequestNoteInvitationJoinUseCase from '../../useCases/note/RequestNoteInvitationJoinUseCase'
import {getNoteInvitationByToken} from './selectors'
import NoteInvitation, {
  MemberStatusType,
} from '../../domain/note/NoteInvitation'
import UseCaseRedirectParams from '../../useCases/lib/UseCaseRedirectParams'

export default function NoteInvitationPage() {
  const {
    match: {
      params: {token: urlToken},
    },
  } = useRouter<{token: string}>()

  useEffect(() => {
    if (!urlToken) {
      return
    }
    UseCaseCommander.create().command(FetchNoteInvitationUseCase)({
      token: urlToken,
    })
  }, [urlToken]) // NOTE: depsを指定しないと無限API呼び出し

  const me = useSelector(getMe)

  const {
    noteTitle,
    noteImageUrl,
    inviterName,
    memberStatus,
    token,
  } = useSelector(getNoteInvitationByToken(urlToken)) as NoteInvitation

  // ISSUE: 以下ロジック一部をドメインモデルに移動, selectors.ts に移動?
  const visibleAccountForm = memberStatus === MemberStatusType.notLogin
  const visibleFullnameRequest = !visibleAccountForm && me && !me.fullname
  const visibleAlreadyMemberAlert = memberStatus === MemberStatusType.member
  const visibleAlreadyRequestedAlert =
    memberStatus === MemberStatusType.requesting
  const isProcessing = useSelector(
    useCaseSelectors.isProcessing(RequestNoteInvitationJoinUseCase),
  )
  const enableRequestButton =
    !!token && memberStatus === MemberStatusType.notMember && !isProcessing

  const redirect: UseCaseRedirectParams = {
    routeKey: 'noteInvitation',
    params: {token},
  }

  const props = {
    title: noteTitle,
    imageUrl: noteImageUrl,
    inviterName,
    visibleAccountForm,
    visibleFullnameRequest,
    visibleAlreadyMemberAlert,
    visibleAlreadyRequestedAlert,
    enableRequestButton,
    clickRequestButton: () => {
      token &&
        UseCaseCommander.create().command(RequestNoteInvitationJoinUseCase)({
          token,
        })
    },
    clickNoteLink: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'index',
      })
    },
    clickCreateAccountLink: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'signUp',
        redirect,
      })
    },
    clickLoginLink: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'login',
        redirect,
      })
    },
  }

  return React.createElement<Props>(View, props)
}
