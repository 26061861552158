import Br from '../../atoms/Br'

export const TemplateComponents = {
  ['\n']: Br,
}

export type TemplateComponent = keyof typeof TemplateComponents

export const getComponentFromTemplate = (key: TemplateComponent) => {
  return TemplateComponents[key]
}

