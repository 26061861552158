import INoteHistoryRepository from '../../domain/note/INoteHistoryRepository'
import NoteHistoryRepository from '../../infra/repositories/note/NoteHistoryRepository'
import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'

interface Props {
  noteHistoryRepository: INoteHistoryRepository
}

export interface Params {
  noteId: string
  limit?: number
  itemId?: string
}

export default class FetchNoteHistoryUseCase extends UseCase {
  private noteHistoryRepository: INoteHistoryRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteHistoryRepository = props.noteHistoryRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteHistoryUseCase({
      noteHistoryRepository: NoteHistoryRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: Params): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(() => {
      applicationService.toUnbusy()
    })
    // FIXME: currentNoteIdが切り替わるたびにクリアしたい(そもそもクリアしていいのか）
    this.noteHistoryRepository.clear()
    await this.noteHistoryRepository.get(params)
  }
}
