import {merge} from '../misc/merge'
import {
  getStyleFromPropValue,
  getThemeValue,
  StyleValueProp,
} from '../variables'
import {Breakpoint, defaultBreakpoints} from './breakpoints'
import style from './style'

const keys = [
  'color',
  'type',
  'size',
  'weight',
  'fontWeight',
  'ta',
  'textAlign',
  'lh',
  'lineHeight',
  'ls',
  'letterSpacing',
]

const properties = {
  type: ['fontSize', 'fontWeight', 'lineHeight'],
  color: 'color',
  size: 'fontSize',
  weight: 'fontWeight',
  ta: 'textAlign',
  lh: 'lineHeight',
  ls: 'letterSpacing',
}

export interface TypographyProps {
  type?: string
  color?: string
  size?: StyleValueProp
  weight?: StyleValueProp
  textAlign?: 'center' | 'left' | 'right'
  lineHeight?: StyleValueProp
  letterSpacing?: StyleValueProp
  lh?: StyleValueProp
  ls?: StyleValueProp
}

export type TypographyBreakPointsProps = TypographyProps & {
  tablet?: TypographyProps
  desktop?: TypographyProps
}

const getCssProperties = (prop: string) => {
  const property = properties[prop]
  return Array.isArray(property) ? property.map(p => p) : [property || prop]
}

export const _typography = (props: TypographyProps) => {
  return props
    ? Object.keys(props)
        .map(prop => {
          if (!keys.includes(prop)) {
            return
          }
          const cssProperties = getCssProperties(prop)
          const styleFromPropValue = getStyleFromPropValue(
            'typography',
            cssProperties,
            (propValue, cssProperty) => `${propValue}.${cssProperty}`,
          )
          const propValue = props[prop]
          return styleFromPropValue(propValue)
        })
        .reduce(merge, {})
    : {}
}

export const typography = (props: TypographyBreakPointsProps) => {
  // TODO: phone, tablet, desktop でsort
  return Object.keys(props).reduce((acc, item) => {
    if (!defaultBreakpoints.keys.includes(item)) {
      return acc
    }
    acc[defaultBreakpoints.up(item as Breakpoint)] = _typography(props[item])
    return acc
  }, _typography(props))
}

export const color = style({prop: 'color', themeKey: 'typography'})
export const lineHeight = style({prop: 'lineHeight', themeKey: 'typography'})
export const fontWeight = style({prop: 'fontWeight', themeKey: 'typography'})
export const fontSize = style({prop: 'fontSize', themeKey: 'typography'})
export const letterSpacing = style({
  prop: 'letterSpacing',
  themeKey: 'typography',
})
export const whiteSpace = style({prop: 'whiteSpace', themeKey: 'typography'})
