import * as React from 'react'
import {TransitionGroup} from 'react-transition-group'
import styled from 'styled-components'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import TransitionRiseRight from '../../layouts/TransitionRiseRight'
import {Props as SnackbarProps} from '../../molecules/Snackbar'
import Toast from '../../molecules/Toast'

const View = styled.div`
  z-index: ${zIndex.notification};
  position: fixed;
  top: 80px;
  right: 0;
`

const Bar = styled(Toast)`
  margin-top: ${space.xs}px;
  margin-right: ${space.sm}px;
  min-width: 300px;
`

interface ListedToastProps extends SnackbarProps {
  id: string
}

export interface Props {
  onClickClose: (id: string) => void
  items: ListedToastProps[]
}

export default function ToastNotifications(props: Props) {
  const items = props.items.map((item, index) => {
    return (
      <TransitionRiseRight key={index} timeout={300}>
        <Bar
          iconType={item.iconType}
          message={item.message}
          onClickClose={() => props.onClickClose(item.id)}
        />
      </TransitionRiseRight>
    )
  })
  return (
    <View>
      <TransitionGroup>{items}</TransitionGroup>
    </View>
  )
}
