import style from './style'

const transform = (value: number | string) => {
  if (typeof value === 'number') {
    if (value <= 1) {
      return `${value * 100}%`
    }
    return `${value}px`
  }
  return value
}
export const width = style({prop: 'width', transform})
export const height = style({prop: 'height', transform})
export const maxWidth = style({prop: 'maxWidth', transform})
export const maxHeight = style({prop: 'maxHeight', transform})
export const minWidth = style({prop: 'minWidth', transform})
export const minHeight = style({prop: 'minHeight', transform})
