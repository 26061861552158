import styled from 'styled-components'
import space from '../../../styles/variables/space'

const Br = styled.div`
  padding-bottom: ${space.sm}px;
  height: 0;
  min-width: 100%;
`

export default Br
