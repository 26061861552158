import * as React from 'react'
import styled from 'styled-components'
import Heading from '../../atoms/Heading'
import Body from '../../atoms/Body'

const View = styled.div`
  height: 90vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  text-align: center;
`

export default function ConfirmEmailVerificationPage() {
  return (
    <View>
      <Heading size="heading4">メールを確認してください</Heading>
      <Body mt="lg">
        じぶんノートにご登録ありがとうございます。
        <br />
        メールの内容を確認し、登録を完了させてください。
      </Body>
    </View>
  )
}
