import * as React from 'react'
import styled from 'styled-components'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import BaseIcon from '../../atoms/Icon'
import Body from '../../atoms/Body'

export interface LabeledIconListItem {
  onClick?: () => void
  content: React.ReactNode
  icon: string
}

export interface Props {
  list: LabeledIconListItem[]
}

const View = styled.div`
  display: block;
`

const Icon = styled(BaseIcon)`
  margin-right: ${space.sm}px;
`

const Item = styled.div`
  padding: ${space.md}px ${space.lg}px;

  display: flex;
  align-items: center;

  cursor: ${p => p.onClick && 'pointer'};

  border-bottom: solid 1px ${colors.border};
`

const ItemContent = styled(Body)``

const Arrow = styled(Icon)`
  margin-left: auto;
`

export default function LabeledIconList({list}: Props) {
  const items = list.map((item: LabeledIconListItem, i: number) => {
    return (
      <Item key={i} onClick={item.onClick}>
        {item.icon && <Icon size="icon1" type={item.icon} />}
        <ItemContent>{item.content}</ItemContent>
        {item.onClick && <Arrow size="body1" type="chevron-right" />}
      </Item>
    )
  })
  return <View>{items}</View>
}
