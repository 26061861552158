import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import EditRemoveActions from '../../molecules/EditRemoveActions'

export interface Props {
  iconType: string
  content: React.ReactNode
  time: string
  editable?: boolean
  deletable?: boolean
  onClickEditButton?: () => void
  onClickRemoveButton?: () => void
}

const View = styled.div`
  ${spacing};
`

const ICON_SIZE = 28
const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  border: solid 1px ${colors.gray200};
  border-radius: 50%;
`

const TrackTime = styled(Body)`
  color: ${colors.gray400};
`

export default function TrackHeader(props: Props) {
  const {
    iconType,
    time,
    content,
    editable = false,
    deletable = false,
    onClickEditButton = () => {},
    onClickRemoveButton = () => {},
    ...rest
  } = props
  return (
    <View {...rest}>
      <Row align="center" flexWrap="nowrap">
        <Col>
          <TrackTime type="caption">{time}</TrackTime>
        </Col>
        <Col>
          <IconCircle>
            <Icon
              color={colors.gray400}
              size={ICON_SIZE - 10}
              type={iconType}
            />
          </IconCircle>
        </Col>
        <Col col="fit">
          <Body type="body2">{content}</Body>
        </Col>
        {(editable || deletable) && (
          <Col ml="auto">
            <EditRemoveActions
              deletable={deletable}
              editable={editable}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
          </Col>
        )}
      </Row>
    </View>
  )
}
