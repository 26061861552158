import * as React from 'react'
import NoteInvitationCodeDialog from '../../../containers/NoteInvitationCodeDialog'
import {useModalState} from '../../../selectors/app/dom'
import LabeledIcon from '../../molecules/LabeledIcon'

export default function NoteInvitationCodeButton() {
  const [showInvitationCodeDialog, setShowInvitationCode] = useModalState(false)

  return (
    <>
      <NoteInvitationCodeDialog
        dismiss={() => setShowInvitationCode(false)}
        isShow={showInvitationCodeDialog}
      />
      <LabeledIcon onClick={() => setShowInvitationCode(true)} type="user-plus">
        招待
      </LabeledIcon>
    </>
  )
}
