import * as React from 'react'
import {useSelector} from 'react-redux'
import Indicator from '../../components/organisms/Indicator'
import * as appSelectors from '../../selectors/app'

export interface Props {
  children: React.ReactNode
}

export default function ApplicationInitializer(props: Props) {
  const initialized = useSelector(appSelectors.getInitialized)
  return initialized
    ? React.createElement(React.Fragment, props)
    : React.createElement(Indicator)
}
