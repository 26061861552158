import * as React from 'react'
import styled from 'styled-components'
import media from '../../../styles/mixins/media'
import {cursor} from '../../../styles/mixins/misc'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  typography,
  TypographyProps,
  whiteSpace,
} from '../../../styles/mixins/typography'

export type MediaProps = TypographyProps & SpacingProps

export type Props = {
  children?: React.ReactNode
  desktop?: MediaProps
} & MediaProps

// FIXME: 存在しないcsspropertyで出力してしまう
const StyledBody = styled.div<Props>`
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${typography};
  ${fontSize}
  ${media.desktop((p: Props) => p.desktop && typography(p.desktop))}
  ${spacing}
  word-break: break-all;
  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};
  ${cursor};
  ${whiteSpace};
`

export default function Body({type = 'body1', ...rest}: Props) {
  return <StyledBody type={type} {...rest} />
}
