import {format} from 'date-fns'
import * as React from 'react'
import styled from 'styled-components'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import Avatar, {AvatarSize} from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'
import EditRemoveActions from '../../molecules/EditRemoveActions'

export interface Props {
  avatarSrc: string
  avatarSize?: number
  title: React.ReactNode
  date: string
  editable?: boolean
  deletable?: boolean
  onClickEditButton?: () => void
  onClickRemoveButton?: () => void
}

const View = styled.div`
  ${spacing};
`

const DiaryDate = styled(Body)`
  color: ${colors.gray300};
`

export default function TimelineHeader(props: Props) {
  const {
    avatarSrc,
    avatarSize = AvatarSize.large,
    date,
    title,
    onClickEditButton,
    onClickRemoveButton,
    editable = false,
    deletable = false,
    ...rest
  } = props
  return (
    <View {...rest}>
      <Row align="center" flexWrap="nowrap">
        <Col>
          <Avatar size={avatarSize} src={avatarSrc}></Avatar>
        </Col>
        <Col col="fit">
          <Body type="caption">{title}</Body>
          <DiaryDate type="overline">
            {date}
          </DiaryDate>
        </Col>
        {onClickEditButton && onClickRemoveButton && (
          <Col ml="auto">
            <EditRemoveActions
              deletable={deletable}
              editable={editable}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
          </Col>
        )}
      </Row>
    </View>
  )
}
