import * as React from 'react'
import styled from 'styled-components'
import media from '../../../styles/mixins/media'
import {
  SpacingProps as ViewProps,
  spacing,
} from '../../../styles/mixins/spacing'

type MediaProps = {
  desktop?: ViewProps
} & ViewProps

export type SpaceProps = {
  children?: React.ReactNode
} & MediaProps

const View = styled.div`
  ${(p: MediaProps) => spacing(p)}
  ${media.desktop((p: MediaProps) => p.desktop && spacing(p.desktop))}
`

export default function Space(props: SpaceProps) {
  return <View {...props}>{props.children}</View>
}
