import BaseAPIClient from './BaseAPIClient'

interface LogAPIParams {
  category: string
  event?: string
  strVal?: string
  intVal?: number
  dateVal?: Date
  group?: string // ユーザのグループ（ABテストグルーブなど）
  userId?: string
  contentId?: string
  freeData?: any
}

export default class LogAPIClient extends BaseAPIClient {
  private isDebugMode: boolean

  public constructor({endpoint}: {endpoint: string}) {
    super({endpoint})
    this.isDebugMode = !endpoint
  }

  protected getDefaultOptions(): KeySignature {
    return {}
  }

  public async postLog(params: LogAPIParams): Promise<Response | undefined> {
    const data = {
      ...params,
      via: 'app',
    }
    if (this.isDebugMode) {
      console &&
        console.log(
          `LogAPIClient.postLog: [${data.category}:${data.event || ''}]`,
          data,
        )
      return
    }
    return await this.post('/log', {
      log: data,
    })
  }
}
