import {Email} from '../../domain/auth/Email'
import IAuthenticationService from '../../domain/auth/IAuthenticationService'
import {Password} from '../../domain/auth/Password'
import AuthenticationService from '../../infra/services/AuthenticationService'
import ApplicationService from '../../services/app/ApplicationService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import Navigation from '../../services/navigation/Navigation'
import ApplicationInitializeUseCase from '../app/ApplicationInitializeUseCase'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  authenticationService: IAuthenticationService
  applicationService: ApplicationService
  navigation: Navigation
}

export interface SignInUseCaseParams {
  email: Email
  password: Password
  isAutoLogout: boolean
  redirect?: string
}

/**
 * SignUpUseCase
 */
export default class SignInUseCase extends UseCase {
  private _authenticationService: IAuthenticationService
  private _applicationService: ApplicationService
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._authenticationService = props.authenticationService
    this._applicationService = props.applicationService
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new SignInUseCase({
      authenticationService: AuthenticationService.create(),
      applicationService: ApplicationService.create(),
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: SignInUseCaseParams): Promise<void> {
    this._applicationService.toBusy()
    this.registerComplete(() => {
      this._applicationService.toUnbusy()
    })
    await this._authenticationService.signIn(
      params.email,
      params.password,
      params.isAutoLogout,
    )
    await this.subUseCaseExecutor(ApplicationInitializeUseCase)()

    this.subUseCaseExecutor(NoticeUseCase)({
      principle: NotificationPrinciple.temporary,
      type: NotificationType.snackbar,
      message: 'ログインしました。',
    })

    if (params.redirect) {
      this._navigation.pushByPath(params.redirect)
      return
    }
    this._navigation.push('index')
  }
}
