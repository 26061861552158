import Note from '../note/Note'
import ContactableUser from './ContactableUser'

export default interface UsersMe extends ContactableUser {
  ownedNotes: Note[]
  followingNotes: Note[]
  resourceUploadToken: string
  capability: {
    totalOwnableNotes: number
    useDefaultNoteCoverImage: boolean
    useDefaultNotePersonImage: boolean
  }
}

export const getMeName = (me: UsersMe, blankName = 'あなた') => {
  const accountName = me.nickname || me.fullname
  return (accountName && `${accountName}さん`) || blankName
}
