import INoteTimelineRepository from '../../domain/note/timeline/INoteTimelineRepository'
import NoteId from '../../domain/note/NoteId'
import {
  NoteTimelineId,
  NoteTimelineItemType,
} from '../../domain/note/timeline/NoteTimeline'
import NoteTimelineRepository from '../../infra/repositories/note/NoteTimelineRepository'
import FormService from '../../infra/services/FormService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTimelineRepository: INoteTimelineRepository
  formService: IFormService
}

export interface Params {
  noteId: NoteId
  type: NoteTimelineItemType
  timelineId: NoteTimelineId
  data: any
  onComplete?: () => void
}

export default class UpdateNoteTimelineUseCase extends UseCase {
  private _noteTimelineRepository: INoteTimelineRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTimelineRepository = props.noteTimelineRepository
    this._formService = props.formService
  }

  public static create() {
    return new UpdateNoteTimelineUseCase({
      noteTimelineRepository: NoteTimelineRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({
    noteId,
    type,
    timelineId,
    onComplete,
    data,
  }: Params): Promise<void> {
    this.registerComplete(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '更新しました',
      })
      onComplete && onComplete()
    })
    await this._noteTimelineRepository.update({noteId, type, timelineId, data})
  }
}
