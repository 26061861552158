import NoteId from '../../../domain/note/NoteId'
import {NoteTimelineItemType} from '../../../domain/note/timeline/NoteTimeline'
import INoteTrackRepository from '../../../domain/note/track/INoteTrackRepository'
import NoteTrack, {
  dateToTimestampStr,
  NoteTrackItem,
  NoteTrackItemType
} from '../../../domain/note/track/NoteTrack'
import NoteTrackDailySum from '../../../domain/note/track/NoteTrackDailySum'
import AppAPI from '../../api/AppAPI'
import {actions} from '../../redux/api/entities'
import {
  NoteTrackCommentSchema,
  NoteTrackDailySumsSchema,
  NoteTrackItemSchema,
  UserSchema
} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteTrackRepository implements INoteTrackRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteTrackRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteTrackRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async get(params: {
    noteId: NoteId
    types: NoteTrackItemType[]
    begin: string
    end: string
    sort: string
  }): Promise<NoteTrack | null> {
    const {begin, end, noteId, types, sort} = params
    const client = await this.api.getClient()
    const {timeline: track} = await client.fetchNoteTimeline({
      id: noteId,
      types: types.join(','),
      ...(sort && {sort}),
      ...(begin && {begin}),
      ...(end && {end}),
    })
    const {items = {}, users = {}} = track || {}
    this.redux.dispatch(actions.store(items, [NoteTrackItemSchema]))
    this.redux.dispatch(actions.store(users, [UserSchema]))
    return track ? (track as NoteTrack) : null
  }

  public async post(params: {
    noteId: NoteId
    type: NoteTimelineItemType
    data: any
    timestamp?: Date
  }): Promise<NoteTrackItem | null> {
    const {data, noteId, timestamp, type} = params
    const client = await this.api.getClient()
    const track = await client.postNoteTimeline({
      id: noteId,
      type,
      data,
      ...((timestamp && {timestamp: dateToTimestampStr(timestamp)}) || {}),
    })
    this.redux.dispatch(actions.store(track, NoteTrackItemSchema))
    // this.redux.dispatch(actions.store(users, [UserSchema]))
    return track ? (track as NoteTrackItem) : null
  }

  public async update(params: {
    noteId: NoteId
    type: NoteTimelineItemType
    trackId: string
    data: any /*fixme: type*/
    timestamp?: Date
  }): Promise<void> {
    const {noteId, type, trackId, data, timestamp} = params
    const client = await this.api.getClient()
    const track = await client.updateNoteTimeline({
      id: noteId,
      type,
      timelineId: trackId,
      data,
      ...((timestamp && {timestamp: dateToTimestampStr(timestamp)}) || {}),
    })
    this.redux.dispatch(actions.store(track, NoteTrackItemSchema))
  }

  public async delete(params: {
    noteId: string
    trackId: string
  }): Promise<void> {
    const {noteId, trackId} = params
    const client = await this.api.getClient()
    await client.deleteNoteTimeline({
      id: noteId,
      timelineId: trackId,
    })
    this.redux.dispatch(actions.spend(trackId, NoteTrackItemSchema))
  }

  public async postComment(params: {
    noteId: NoteId
    trackId: string
    data: {comment: string}
  }): Promise<NoteTrackItem> {
    const {data, noteId, trackId} = params
    const client = await this.api.getClient()
    const trackItem = await client.postNoteTimelineComment({
      id: noteId,
      timelineId: trackId,
      data,
    })
    this.redux.dispatch(actions.store(trackItem, NoteTrackItemSchema))
    return trackItem as NoteTrackItem
  }

  public async deleteComment(params: {
    noteId: string
    trackId: string
    commentId: string
  }): Promise<void> {
    const {commentId, noteId, trackId} = params
    const client = await this.api.getClient()
    await client.deleteNoteTimelineComment({
      id: noteId,
      timelineId: trackId,
      commentId,
    })
    this.redux.dispatch(actions.spend(commentId, NoteTrackCommentSchema))
  }

  public async getDailySums(params: {
    noteId: NoteId
    types: NoteTrackItemType[]
    begin?: string
    end?: string
  }): Promise<NoteTrackDailySum[]> {
    const {begin, end, noteId, types} = params
    const client = await this.api.getClient()
    const {
      dailySums: {list = []} = {},
    } = await client.fetchNoteTimelineDailySums({
      id: noteId,
      types: types.join(','),
      ...(begin && {begin}),
      ...(end && {end}),
    })
    this.redux.dispatch(actions.store(list, [NoteTrackDailySumsSchema]))
    return list
  }
}
