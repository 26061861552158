import React, {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import View, {Props} from '../../components/pages/SettingsNotificationsPage'
import {NoteMailNotificationType} from '../../domain/note/notifications/NoteMailNotificationType'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getNoteMailNotificationsTypesForView} from '../../selectors/api/note/notifications'
import * as navigationSelectors from '../../selectors/app/navigation'
import FetchNoteNotificationsUseCase from '../../useCases/note/relation/FetchNoteNotificationsUseCase'
import UnNoticeNoteNotificationsUseCase from '../../useCases/note/relation/UnNoticeNoteNotificationsUseCase'
import NoticeNoteNotificationsUseCase from '../../useCases/note/relation/NoticeNoteNotificationsUseCase'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'

export default function SettingsNotificationsPage() {
  const noteId = navigationSelectors.getNoteId()
  const {title: noteTitle} = useSelector(getNoteById(noteId))

  useEffect(() => {
    UseCaseCommander.create().command(FetchNoteNotificationsUseCase)({noteId})
  }, [noteId])

  const types = useSelector(getNoteMailNotificationsTypesForView(noteId))

  const onChangeMailNotification = useCallback(
    (type: NoteMailNotificationType, notice: boolean) => {
      if (notice) {
        UseCaseCommander.create().command(NoticeNoteNotificationsUseCase)({
          noteId,
          type,
        })
      } else {
        UseCaseCommander.create().command(UnNoticeNoteNotificationsUseCase)({
          noteId,
          type,
        })
      }
    },
    [noteId],
  )

  const isProcessingNotice = useSelector(
    useCaseSelectors.isProcessing(NoticeNoteNotificationsUseCase),
  )
  const isProcessingUnNotice = useSelector(
    useCaseSelectors.isProcessing(UnNoticeNoteNotificationsUseCase),
  )

  const props = {
    noteTitle,
    title: `${noteTitle}のお知らせ設定`,
    types,
    onChangeMailNotification,
    disabled: isProcessingNotice || isProcessingUnNotice,
  }

  return React.createElement<Props>(View, props)
}
