import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import Swiper from 'react-id-swiper'
import styled from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {width} from '../../../styles/mixins/sizing'
import {SpacingProps} from '../../../styles/mixins/spacing'
import useMediaQuery from '../../../styles/useMediaQuery'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import {zIndex} from '../../../styles/variables/zIndex'
import Avatar, {AvatarSize} from '../../atoms/Avatar'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'

const View = styled.div`
  position: relative;
  ${width};
`

const Current = styled.div`
  cursor: pointer;
  padding: ${space.xs}px 0;
  display: flex;
  align-items: center;
`

const Text = styled(Body)<{rowCount?: number}>`
  overflow: hidden;
  max-height: ${p => (p.rowCount ? p.rowCount * 1.5 : 'none')}em;
`

const Arrow = styled.div`
  margin-left: ${space.sm}px;

  font-weight: bold;
`

const Item = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  //white-space: nowrap;
`

const List = styled.div<{isPhone: boolean}>`
  min-width: 260px;
  z-index: ${zIndex.header + 1};
  position: absolute;
  overflow: hidden;
  padding: 0 ${space.sm + 4}px;
  border: solid 1px ${colors.border};
  background-color: #fff;
  ${borderRadius};
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

  /* FIXME: swiper-container: {height: 100%;} */
  & > div {
    max-height: ${p => (p.isPhone ? '450px' : 'none')};
  }

  ${Item} {
    padding: ${space.sm}px 0;
  }
`

const Slide = styled.div`
  padding: ${space.xs}px 0;
  height: auto !important; /* MEMO: これがないとswiper.jsのfreemodeeでscroll有効にならない */
`

export interface ItemProps {
  id: string
  active?: boolean
  noAvatar?: boolean
  src?: string
  title: React.ReactNode
}

export type Props = SpacingProps & {
  rowCount?: number
  items: ItemProps[]
  onClickItem?: (id: string) => void
}

export default function Select(props: Props) {
  const {items, onClickItem, rowCount, ...rest} = props
  const [show, setShow] = useState(false)
  const isPhone = !useMediaQuery('tablet')
  const gen = (item: ItemProps) => {
    const handleClickItem = (id: string) => {
      onClickItem && onClickItem(id)
      setShow(false)
    }
    const avatarSize = item.active ? AvatarSize.large : AvatarSize.medium
    return (
      <Item
        key={item.id}
        onClick={() => !item.active && handleClickItem(item.id)}
      >
        {!item.noAvatar && <Avatar mr="xs" size={avatarSize} src={item.src} />}
        {(!isPhone || !item.active) && (
          <Text rowCount={rowCount}>{item.title}</Text>
        )}
      </Item>
    )
  }
  const element = useRef<HTMLDivElement>(null)
  const [current = null] = items
    .filter(item => {
      return item.active
    })
    .map(gen, AvatarSize.large)
  const list = items
    .filter(item => {
      return !item.active
    })
    .map(gen)

  const handleClick = (e: MouseEvent) => {
    const {current} = element
    const outside =
      current && !current.contains(e.target as Node /* FIXME: あっているのか */)
    outside && setShow(false)
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  const swiperProps = {
    freeMode: true,
    slidesPerView: 'auto',
    direction: 'vertical',
  } as any // FIXME: react-id-swiper のpropsが不足している

  return (
    <View {...rest} ref={element}>
      <Current onClick={() => setShow(!show)}>
        {current}
        {!isPhone && (
          <Arrow>
            <Icon size={10} type="chevron-down" />
          </Arrow>
        )}
      </Current>

      {show && (
        <List isPhone={isPhone}>
          <Swiper {...swiperProps}>
            <Slide>{list}</Slide>
          </Swiper>
        </List>
      )}
    </View>
  )
}
