import INoteTrackRepository from '../../domain/note/track/INoteTrackRepository'
import NoteTrackRepository from '../../infra/repositories/note/NoteTrackRepository'
import FormService from '../../infra/services/FormService'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import IFormService from '../lib/IFormService'
import UseCase from '../lib/UseCase'

interface Props {
  noteTrackRepository: INoteTrackRepository
  formService: IFormService
}

export interface Params {
  noteId: string
  trackId: string
  commentId: string
}

export default class DeleteNoteTrackCommentUseCase extends UseCase {
  private _noteTrackRepository: INoteTrackRepository
  private _formService: IFormService

  public constructor(props: Props) {
    super()
    this._noteTrackRepository = props.noteTrackRepository
    this._formService = props.formService
  }

  public static create() {
    return new DeleteNoteTrackCommentUseCase({
      noteTrackRepository: NoteTrackRepository.create(),
      formService: FormService.create(),
    })
  }

  public async execute({noteId, trackId, commentId}: Params): Promise<void> {
    this.registerComplete(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '削除しました',
      })
    })
    await this._noteTrackRepository.deleteComment({noteId, trackId, commentId})
  }
}
