import {handleActions} from '../helpers'
import {
  Action,
  ActionType,
  TreeItemId,
  UpdateCurrentNoteIdAction,
  UpdateCurrentNoteSlideIdAction,
  UpdateCurrentTrackDateAction,
  UpdateFilteredTreeIdsAction,
  UpdateFilterQueryAction,
  UpdateSearchNoteQueryAction
} from './types'

export const actions = {
  updateFilteredNoteFields: (
    items: TreeItemId[],
  ): UpdateFilteredTreeIdsAction => {
    return {
      payload: items,
      type: ActionType.filteredTreeIds,
    }
  },
  updateFilterNoteQuery: (query: string): UpdateFilterQueryAction => ({
    payload: query,
    type: ActionType.filterQuery,
  }),
  updateCurrentNoteId: (noteId: string): UpdateCurrentNoteIdAction => ({
    payload: noteId,
    type: ActionType.currentNoteId,
  }),
  updateCurrentNoteSlideId: (
    slideIndex: number,
  ): UpdateCurrentNoteSlideIdAction => ({
    payload: slideIndex,
    type: ActionType.currentNoteSlideId,
  }),
  updateCurrentTrackDate: (date: string): UpdateCurrentTrackDateAction => ({
    payload: date,
    type: ActionType.currentTrackDate,
  }),
  updateSearchNoteQuery: (query: string): UpdateSearchNoteQueryAction => ({
    payload: query,
    type: ActionType.searchNoteQuery,
  }),
}

export const handlers: KeySignature = {
  [ActionType.currentNoteId]: (state: NoteState, action: Action) => {
    return {...state, currentNoteId: action.payload}
  },
  [ActionType.currentNoteSlideId]: (state: NoteState, action: Action) => {
    return {...state, currentNoteSlideId: action.payload}
  },
  [ActionType.filteredTreeIds]: (state: NoteState, action: Action) => {
    return {
      ...state,
      filteredTreeIds: [...action.payload],
    }
  },
  [ActionType.filterQuery]: (state: NoteState, action: Action) => {
    return {...state, filterQuery: action.payload}
  },
  [ActionType.currentTrackDate]: (state: NoteState, action: Action) => {
    return {...state, currentTrackDate: action.payload}
  },
  [ActionType.searchNoteQuery]: (state: NoteState, action: Action) => {
    return {...state, searchNoteQuery: action.payload}
  },
}

export interface NoteState {
  readonly currentNoteId: string
  readonly currentNoteSlideId: number
  readonly filteredTreeIds: TreeItemId[]
  readonly filterQuery: string
  readonly currentTrackDate: string
  readonly searchNoteQuery: string
}

const initialState: NoteState = {
  currentNoteId: '',
  currentNoteSlideId: 0,
  filteredTreeIds: [],
  filterQuery: '',
  currentTrackDate: '',
  searchNoteQuery: '',
}

const reducer = handleActions(handlers, initialState)

export default reducer
