import {useCallback, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {ROOT_ELEMENT_ID} from '../../config'
import {getModalState} from '../../infra/redux/router/selectors'
import Navigation from '../../services/navigation/Navigation'

export const useRootElement = () => {
  return useMemo(() => {
    return document.getElementById(ROOT_ELEMENT_ID) || document.body
  }, [document])
}

export const useOffsetPosition = (element: HTMLElement) => {
  return useMemo(() => {
    const {
      top = 0,
      left = 0,
      right = 0,
      bottom = 0,
    } = element.getBoundingClientRect()
    return {
      top: top + window.scrollY,
      bottom: bottom + window.scrollY,
      left,
      right,
    }
  }, [element, window])
}

// urlを変更しないpushstateを行い、非表示でブラウザバックを行う
// ブラウザバックを行ったあとにブラウザで進むをしても何もしない（google ユーザー管理参考）
export const useModalState = (initialState: boolean) => {
  const [show, setShow] = useState(initialState)
  const navigation = useMemo(() => Navigation.create(), [])
  const pushedModalState = useSelector(getModalState)

  const handleShow = useCallback(
    (show: boolean): void => {
      setShow(show)
      if (!show && pushedModalState) {
        navigation.goBack()
      }
    },
    [show, pushedModalState],
  )

  const handlePopstate = useCallback(() => {
    setShow(false)
  }, [show])

  useEffect(() => {
    if (show && !pushedModalState) {
      navigation.pushState({modalState: true})
    }
    if (show) {
      window.addEventListener('popstate', handlePopstate)
    }
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [show])
  return useMemo(
    () =>
      [show, handleShow, pushedModalState] as [
        boolean,
        (show: boolean) => void,
        boolean,
      ], // なぜか型指定しないと怒られる
    [show, handleShow, pushedModalState],
  )
}

export const useIndicate = (msec: number, getVal: () => boolean) => {
  const [val, setVal] = useState(false)
  // FIXME: これ大丈夫か
  setTimeout(() => {
    setVal(getVal())
  }, msec)
  return val
}

export const useEffectIndicate = (
  effect: () => void,
  deps: any[] = [],
  msec: number,
) => {
  return useEffect(() => {
    setTimeout(() => {
      effect()
    }, msec)
  }, deps)
}
