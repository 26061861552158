import INoteInvitationRepository from '../../domain/note/INoteInvitationRepository'
import NoteInvitationRepository from '../../infra/repositories/note/NoteInvitationRepository'
import UseCase from '../lib/UseCase'
import NoticeUseCase from '../app/NoticeUseCase'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'

interface Props {
  noteInvitationRepository: INoteInvitationRepository
}

export default class RequestNoteInvitationJoinUseCase extends UseCase {
  private noteInvitationRepository: INoteInvitationRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteInvitationRepository = props.noteInvitationRepository
  }

  /**
   * create
   */
  public static create() {
    return new RequestNoteInvitationJoinUseCase({
      noteInvitationRepository: NoteInvitationRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({token}: {token: string}): Promise<void> {
    const invitation = await this.noteInvitationRepository.requestJoin({token})
    this.registerComplete(async () => {
      const message = `「${invitation.noteTitle}」への参加申請が行われました。${
        invitation.inviterName
      } さんに連絡して、ノートへの参加を許可してもらいましょう。`
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.informational,
        type: NotificationType.snackbar,
        message,
      })
    })
  }
}
