import {Action as ReduxAction} from 'redux'

// TODO: TreeなのかFieldなのかなんなのか名前どうにかしたい
export type TreeItemId = string

export enum ActionType {
  currentNoteId = 'app/note/currentNoteId',
  currentNoteSlideId = 'app/note/currentNoteSlideId',
  filteredTreeIds = 'app/note/filteredTreeIds',
  filterQuery = 'app/note/filterQuery',
  currentTrackDate = 'app/note/trackDate',
  searchNoteQuery = 'app/note/searchNoteQuery',
}

export interface UpdateCurrentNoteIdAction extends ReduxAction<ActionType> {
  type: typeof ActionType.currentNoteId
  payload: string
}

export interface UpdateFilterQueryAction extends ReduxAction<ActionType> {
  type: typeof ActionType.filterQuery
  payload: string
}

export interface UpdateCurrentNoteSlideIdAction
  extends ReduxAction<ActionType> {
  type: typeof ActionType.currentNoteSlideId
  payload: number
}

export interface UpdateCurrentTrackDateAction extends ReduxAction<ActionType> {
  type: typeof ActionType.currentTrackDate
  payload: string
}

export interface UpdateFilteredTreeIdsAction extends ReduxAction<ActionType> {
  type: typeof ActionType.filteredTreeIds
  payload: TreeItemId[]
}

export interface UpdateSearchNoteQueryAction extends ReduxAction<ActionType> {
  type: typeof ActionType.searchNoteQuery
  payload: string
}

export type Action =
  | UpdateCurrentNoteIdAction
  | UpdateCurrentNoteSlideIdAction
  | UpdateFilteredTreeIdsAction
  | UpdateFilterQueryAction
  | UpdateCurrentTrackDateAction
  | UpdateSearchNoteQueryAction
