import * as React from 'react'
import HamburgerButton from '../../../containers/HamburgerButton'
import UserNotificationsButton from '../../../containers/UserNotificationsButton'
import space from '../../../styles/variables/space'
import Col from '../../layouts/Col'
import Row from '../../layouts/Row'

export interface Props {
  isAuthorized: boolean
}

export default function HeaderRight(props: Props) {
  const {isAuthorized} = props
  return (
    <Row gutter={space.sm}>
      {isAuthorized && (
        <Col>
          <UserNotificationsButton />
        </Col>
      )}
      <Col>
        <HamburgerButton />
      </Col>
    </Row>
  )
}
