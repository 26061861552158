import {RouterState} from 'connected-react-router'
import {Location} from 'history'
import {createSelector} from 'reselect'
import {IReduxState} from '../'

/**
 * getState
 *
 * @param state - ReduxState
 */
export const getState = (state: IReduxState) => state.router

export const getLocation = createSelector(
  getState,
  (state: RouterState) => {
    return state.location
  },
)

export const getLocationState = createSelector(
  getLocation,
  (location: Location) => {
    return location.state || {}
  },
)

export const getModalState = createSelector(
  getLocationState,
  ({modalState = false}: {modalState: boolean}) => {
    return modalState
  },
)
