import NoteManager from '../../infra/app/NoteManager'
import {INoteManager} from '../../services/app/INoteManager'
import UseCase from '../lib/UseCase'

interface Props {
  noteManager: INoteManager
}

export interface Params {
  query: string
}

export default class UpdateSearchNoteQueryUseCase extends UseCase {
  private _noteManager: INoteManager

  public constructor(props: Props) {
    super()
    this._noteManager = props.noteManager
  }

  public static create() {
    return new UpdateSearchNoteQueryUseCase({
      noteManager: NoteManager.create(),
    })
  }

  public async execute({query}: Params): Promise<void> {
    this._noteManager.setSearchNoteQuery(query)
  }
}
