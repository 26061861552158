import * as React from 'react'
import styled from 'styled-components'
import {flexDirection, flexWrap} from '../../../styles/mixins/flex'
import {flexPosition} from '../../../styles/mixins/grid'
import media from '../../../styles/mixins/media'
import {cursor} from '../../../styles/mixins/misc'
import {width} from '../../../styles/mixins/sizing'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {GUTTER} from '../../../styles/variables/dom'
import {ColView} from '../Col'

type MediaProps = {
  align?: string
  gutter?: number
  justify?: string
} & SpacingProps

type ViewProps = {
  desktop?: MediaProps
} & MediaProps

type Props = {
  children: React.ReactNode
} & ViewProps

export const row = ({
  align = flexPosition.flexStart,
  gutter = GUTTER,
  justify = flexPosition.flexStart,
}: ViewProps) => {
  return `
    display: flex;
    flex-flow: row wrap;
    align-items: ${align};
    justify-content: ${justify};
    margin-left: ${Math.ceil(gutter / -2)}px;
    margin-right: ${Math.floor(gutter / -2)}px;
    & ${ColView} {
      padding-left: ${gutter / 2}px;
      padding-right: ${gutter / 2}px;
    }
  `
}

const View = styled.div`
  ${(p: ViewProps) => row(p)};
  ${media.desktop((p: ViewProps) => p.desktop && row(p.desktop))};
  ${cursor};
  ${width};
  ${spacing};

  ${flexDirection};
  ${flexWrap};
`

const Row = ({
  align,
  gutter = GUTTER,
  children,
  desktop,
  justify,
  ...rest
}: Props) => {
  return (
    <View
      align={align}
      desktop={desktop}
      gutter={gutter}
      justify={justify}
      {...rest}
    >
      {children}
    </View>
  )
}

export default Row
