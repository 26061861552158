import * as React from 'react'
import styled from 'styled-components'
import BackButton from '../../../containers/BackButton'
import {RouteKey} from '../../../routes'
import Body from '../../atoms/Body'

const Navigation = styled.div`
  display: flex;
  align-items: center;
`

const NavigationLabel = styled(Body)``

export interface Props {
  title?: string
  back?: boolean | RouteKey
}

export default function HeaderNavigation(props: Props) {
  const {back, title} = props
  const backProps = {
    ...(typeof back === 'string' && {routeKey: back}),
  }
  return (
    <Navigation>
      {back && <BackButton {...backProps} />}
      <NavigationLabel>{title}</NavigationLabel>
    </Navigation>
  )
}
