import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/organisms/CommentList'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import {getTimelineComments} from '../../selectors/note/timeline'
import DeleteNoteTimelineCommentUseCase, {
  Params as DeleteNoteTimelineCommentUseCaseParams,
} from '../../useCases/timeline/DeleteNoteTimelineCommentUseCase'

export interface Props {
  timelineId: string
}

export default function NoteTimelineCommentList(props: Props) {
  const {timelineId} = props
  const noteId = useSelector(getCurrentNoteId)
  const items = useSelector(getTimelineComments(timelineId))
  const onClickRemoveButton = useCallback(
    (commentId: string) => {
      UseCaseCommander.create().command<DeleteNoteTimelineCommentUseCaseParams>(
        DeleteNoteTimelineCommentUseCase,
      )({noteId, timelineId, commentId})
    },
    [timelineId],
  )
  return React.createElement(View, {
    items,
    onClickRemoveButton,
  })
}
