import {addDays, format} from 'date-fns'
import * as React from 'react'
import {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/NoteTrackPage'
import {dateToDateStr, getNoteTrackEditableToMe} from '../../domain/note/track/NoteTrack'
import {getNoteById} from '../../infra/redux/api/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import * as navigationSelectors from '../../selectors/app/navigation'
import {getCurrentNoteIdWithRoute, getCurrentTrackDateWithRoute} from '../../selectors/note'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'
import UpdateCurrentNoteIdUseCase from '../../useCases/app/UpdateCurrentNoteIdUseCase'

export default function NoteTrackPage() {
  const {
    noteId: routeNoteId,
    date: routeDate,
  } = navigationSelectors.getRouteParams()
  const noteId = useSelector(getCurrentNoteIdWithRoute(routeNoteId))
  const currentTrackDate = useSelector(getCurrentTrackDateWithRoute(routeDate))

  const note = useSelector(getNoteById(noteId)) || {}
  const {title} = note
  const pageTitle = `${title}の日々の記録`

  const editable = getNoteTrackEditableToMe(note)

  const prevDate = useMemo(() => {
    return format(addDays(currentTrackDate, -1), 'M月d日')
  }, [routeDate])

  const nextDate = useMemo(() => {
    return format(addDays(currentTrackDate, 1), 'M月d日')
  }, [routeDate])

  const onClickPrevDate = useCallback(() => {
    const routeDate = dateToDateStr(addDays(currentTrackDate, -1))
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'track', date: routeDate, noteId})
  }, [routeDate])

  const onClickToday = useCallback(() => {
    const routeDate = dateToDateStr(new Date())
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'track', date: routeDate, noteId})
  }, [routeDate])

  const onClickNextDate = useCallback(() => {
    const routeDate = dateToDateStr(addDays(currentTrackDate, 1))
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase,
    )({routeKey: 'track', date: routeDate, noteId})
  }, [routeDate])

  useMemo(() => {
    UseCaseCommander.create().command(UpdateCurrentNoteIdUseCase)({
      noteId,
    })
  }, [noteId])

  return React.createElement(View, {
    title: pageTitle,
    editable,
    prevDate,
    nextDate,
    onClickPrevDate,
    onClickToday,
    onClickNextDate,
  })
}
