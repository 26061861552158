import INoteEmergencyRepository from '../../domain/note/INoteEmergencyRepository'
import NoteEmergencyRepository from '../../infra/repositories/note/NoteEmergencyRepository'
import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'

interface Props {
  noteEmergencyRepository: INoteEmergencyRepository
}

export default class FetchNoteEmergencyUseCase extends UseCase {
  private noteEmergencyRepository: INoteEmergencyRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteEmergencyRepository = props.noteEmergencyRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteEmergencyUseCase({
      noteEmergencyRepository: NoteEmergencyRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({token}: {token: string}): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
    })
    await this.noteEmergencyRepository.getByToken({token})
  }
}
