import * as React from 'react'
import styled from 'styled-components'
import ResetPasswordForm from '../../../containers/ResetPasswordForm'
import Container from '../../layouts/Container'

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

export default function RequestResetPasswordPage() {
  return (
    <View>
      <Container width={1.0}>
        <ResetPasswordForm />
      </Container>
    </View>
  )
}
