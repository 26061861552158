import INoteInvitationRepository from '../../../domain/note/INoteInvitationRepository'
import AppAPI from '../../api/AppAPI'
import {actions} from '../../redux/api/entities'
import {NoteInvitationSchema} from '../../redux/api/schemata'
import ReduxProvider from '../../redux/ReduxProvider'
import NoteInvitation from '../../../domain/note/NoteInvitation'
import {noteInvitationResponseToEntity} from '../../api/translators/note'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteInvitationRepository
  implements INoteInvitationRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   * @param [opts] - options
   */
  public static create(): NoteInvitationRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteInvitationRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   * @param [opts] - options
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  public async getInvitation({
    token,
  }: {
    token: string
  }): Promise<NoteInvitation> {
    const client = await this.api.getClient()
    const res = await client.getNoteInvitation({token})
    const entity = noteInvitationResponseToEntity(res, token)
    this.redux.dispatch(actions.store(entity, NoteInvitationSchema))
    return entity
  }

  public async requestJoin({token}: {token: string}): Promise<NoteInvitation> {
    const client = await this.api.getClient()
    const res = await client.requestNoteInvitationJoin({token})
    const entity = noteInvitationResponseToEntity(res, token)
    this.redux.dispatch(actions.store(entity, NoteInvitationSchema))
    return entity
  }
}
