import {RouteKey} from '../../routes'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'
import UseCaseRedirectParams, {getQueryStringByRedirectParams} from '../lib/UseCaseRedirectParams'
import ToggleMenuUseCase from './ToggleMenuUseCase'

interface Props {
  navigation: Navigation
}

export interface PushNavigationUseCaseParams {
  routeKey: RouteKey
  query?: KeySignature
  hash?: string
  redirect?: UseCaseRedirectParams
  redirectPathname?: string

  [key: string]: any
}

/**
 * SignUpUseCase
 */
export default class PushNavigationUseCase extends UseCase {
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new PushNavigationUseCase({
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - PushNavigationUseCaseParams
   */
  public async execute({
    routeKey,
    query,
    hash,
    redirect,
    redirectPathname,
    ...params
  }: PushNavigationUseCaseParams): Promise<void> {
    this.subUseCaseExecutor(ToggleMenuUseCase)({hide: true})
    this._navigation.push(
      routeKey,
      params,
      {
        ...query,
        redirect: redirect
          ? getQueryStringByRedirectParams(redirect)
          : redirectPathname,
      },
      hash,
    )
  }
}
