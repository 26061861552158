import * as localForage from 'localforage'
import {useCallback, useEffect, useState} from 'react'

const APP_PERSIST_KEY = 'jnote::persist'

export const getPersist = async (key: string) => {
  return await localForage.getItem(`${APP_PERSIST_KEY}::${key}`)
}

export const setPersist = async <V>(key: string, val: V) => {
  return await localForage.setItem<V>(`${APP_PERSIST_KEY}::${key}`, val)
}

export const usePersist = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(defaultValue)
  useEffect(() => {
    const getData = async () => {
      const val = await getPersist(key)
      setValue(val === undefined || val === null ? defaultValue : val)
    }
    getData()
  }, [key, value])
  const handleSetValue = useCallback(
    (value: any) => {
      setValue(value)
      setPersist(key, value)
    },
    [key, value],
  )
  return [value, handleSetValue]
}
