import * as React from 'react'
import styled from 'styled-components'
import {TreeContainerType} from '../../../domain/note/snapshot/Tree'
import {usePersist} from '../../../selectors/app/persist'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'
import {
  spacing,
  SpacingProps as ViewProps,
} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Icon from '../../atoms/Icon'
import Collapse from '../Collapse'

type MediaProps = {
  desktop?: ViewProps
} & ViewProps

export type SpaceProps = {
  children?: React.ReactNode
} & MediaProps

const View = styled.div`
  ${spacing};
  ${boxShadow};
  ${borderRadius};
  margin: ${space.sm}px 3px 3px;
  background-color: #fff;
`

const Header = styled.div`
  padding: ${space.sm}px;
  display: flex;
  align-items: center;
`

const HeaderTitle = styled(Body)`
  color: ${colors.gray800};
`

const HeaderControls = styled.div`
  margin-left: auto;
`

const Content = styled.div`
  border-top: solid 1px ${colors.border};
  padding: 0 ${space.sm}px ${space.sm}px;
`

export interface Props {
  children: React.ReactNode
  type: TreeContainerType
  id: string
  title: string
}

export default function NoteSection(props: Props) {
  const {id, title} = props
  const [open, setOpen] = usePersist(`NoteSection.${id}`, true)
  return (
    <View key={id} {...props}>
      <Header onClick={() => setOpen(!open)}>
        <HeaderTitle type="heading5">{title}</HeaderTitle>
        <HeaderControls>
          {open ? (
            <Icon fontWeight={700} size={14} type="chevron-up" />
          ) : (
            <Icon fontWeight={700} size={14} type="chevron-down" />
          )}
        </HeaderControls>
      </Header>

      <Collapse in={open}>
        <Content>{props.children}</Content>
      </Collapse>
    </View>
  )
}
