import INoteRepository from '../../domain/note/INoteRepository'
import NoteRepository from '../../infra/repositories/note/NoteRepository'
import UseCase from '../lib/UseCase'

interface Props {
  noteRepository: INoteRepository
}

export default class FetchNoteUseCase extends UseCase {
  private noteRepository: INoteRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteRepository = props.noteRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteUseCase({
      noteRepository: NoteRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({id}: {id: string}): Promise<void> {
    if (!id) return
    await this.noteRepository.getNote(id)
  }
}
