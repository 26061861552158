import * as React from 'react'
import sanitize from 'sanitize-html'
import styled from 'styled-components'
import {Props} from '../../../containers/NoteHistoryItem'
import NoteHistoryItemLayout from '../../../containers/NoteHistoryItemLayout'
import Code from '../../atoms/Code'

const PrevCode = styled(Code)`
  color: #b30000;
  background: #fadad7;
`

const getArray = (name: string, data: any) => {
  const {[name]: ret = data} = data || {}
  return ret
}

const getItemValue = (item: {}, getLabel: (key: string) => string) => {
  return Object.keys(item).reduce((acc, key) => {
    acc = `${acc} ${getLabel(key)}: ${item[key]}<br />`
    return acc
  }, '')
}

const getValue = (
  name: string,
  data: any,
  getLabel: (key: string) => string,
) => {
  const items = getArray(name, data)
  return (
    items &&
    items.reduce((acc: string, val: string) => {
      const itemValue = getItemValue(val, getLabel)
      acc = `${acc} ${itemValue}<br />`
      return acc
    }, '')
  )
}

const getItemLabel = (attrItems: KeySignature) => (key: string) => {
  const {[key]: {label = ''} = ''} = attrItems
  return label
}

export default function ArrayHistoryItem(props: Props) {
  const {previous, data, name, ...rest} = props
  const {attr: {items = {}} = {}} = props
  const prevData = previous && (
    <PrevCode
      dangerouslySetInnerHTML={{
        __html: sanitize(getValue(name, previous, getItemLabel(items))),
      }}
    />
  )
  const currentData = (
    <Code
      dangerouslySetInnerHTML={{
        __html: sanitize(getValue(name, data, getItemLabel(items))),
      }}
    />
  )
  return (
    <NoteHistoryItemLayout
      currentData={currentData}
      name={name}
      prevData={prevData}
      {...rest}
    />
  )
}
