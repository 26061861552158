import * as React from 'react'
import {useCallback, useEffect, useRef} from 'react'
import ReactDom from 'react-dom'
import styled from 'styled-components'
import {containsElement} from '../../../lib/dom'
import {useOffsetPosition, useRootElement} from '../../../selectors/app/dom'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadowFloating} from '../../../styles/mixins/boxShadow'
import {bottom, left, right, top} from '../../../styles/mixins/position'
import {maxWidth, width} from '../../../styles/mixins/sizing'
import {zIndex} from '../../../styles/variables/zIndex'
import Icon from '../../atoms/Icon'
import ListSubheader from '../../atoms/ListSubheader'
import ListItem from '../../layouts/ListItem'
import ListItemText from '../ListItemText'

const View = styled.div<{fixed?: boolean}>`
  z-index: ${zIndex.menu};
  position: ${p => (p.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: none;
`

const Inner = styled.div`
  pointer-events: auto;
  z-index: 10;
  position: absolute;
  ${maxWidth};
  ${width};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${boxShadowFloating};
  ${borderRadius};
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  opacity: 0;
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.1);
`

export interface SubHeaderMenuItem {
  subheader: string
}

export interface ActionMenuItem {
  label: string
  iconType?: string
  onClick?: () => void
  subLabel?: string
}

export type MenuItem = SubHeaderMenuItem | ActionMenuItem

export interface Props {
  children?: React.ReactNode
  trigger: HTMLElement | null
  items?: MenuItem[]
  fixed?: boolean
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: number | string
  onClose: () => void
}

export default function Menu(props: Props) {
  const innerElement = useRef<HTMLDivElement>(null)
  const {fixed, children, items, onClose, trigger, ...rest} = props
  const hasPosition =
    props.hasOwnProperty('top') ||
    props.hasOwnProperty('bottom') ||
    props.hasOwnProperty('left') ||
    props.hasOwnProperty('right')
  const {left = 0, right = 0, bottom = 0} = hasPosition
    ? props
    : trigger
    ? useOffsetPosition(trigger)
    : {}
  const rootElement = useRootElement()
  const list =
    items &&
    items.map((props, index) => {
      const {subheader} = props as SubHeaderMenuItem
      const {label, iconType, onClick, subLabel = ''} = props as ActionMenuItem
      return subheader ? (
        <ListSubheader key={index}>{subheader}</ListSubheader>
      ) : (
        <ListItem key={index} onClick={onClick}>
          {iconType && <Icon size={18} type={iconType}></Icon>}
          <ListItemText primary={label} secondary={subLabel} />
        </ListItem>
      )
    })
  const handleClick = useCallback(e => {
    if (!containsElement(e.target, [innerElement.current, trigger])) {
      onClose()
    }
  }, [])
  useEffect(() => {
    const {current} = innerElement
    if (hasPosition) {
      if (current) {
        current.style.top = `${top}px`
        current.style.left = `${left}px`
      }
    } else {
      if (current) {
        const {width = 0} = current ? current.getBoundingClientRect() : {}
        current.style.top = `${bottom}px`
        current.style.left = `${Number(right) - width}px`
      }
    }
    current && (current.style.opacity = '1')
    document.addEventListener('touchstart', handleClick)
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('touchstart', handleClick)
      document.removeEventListener('mousedown', handleClick)
    }
  }, [innerElement])
  return ReactDom.createPortal(
    <View fixed={fixed}>
      <Inner ref={innerElement} {...rest}>
        {list && list.length > 0 ? list : children}
      </Inner>

      <Overlay />
    </View>,
    rootElement,
  )
}
