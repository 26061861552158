import IAccountRegistryService from '../../domain/account/IAccountRegistryService'
import AccountRegistryService from '../../infra/services/AccountRegistryService'
import {NotificationPrinciple, NotificationType} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  accountRegistryService: IAccountRegistryService
}

interface Params {
  fullname: string
  nickname: string
  iconUrl: string
  onSuccess?: () => void
}

export default class UpdateProfileUseCase extends UseCase {
  private _accountRegistryService: IAccountRegistryService

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._accountRegistryService = props.accountRegistryService
  }

  /**
   * create
   */
  public static create() {
    return new UpdateProfileUseCase({
      accountRegistryService: AccountRegistryService.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(params: Params): Promise<void> {
    const {onSuccess, ...rest} = params
    this.registerSuccess(() => {
      this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: '更新しました',
      })
      onSuccess && onSuccess()
    })
    await this._accountRegistryService.updateProfile(rest)
  }
}
