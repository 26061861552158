import * as React from 'react'
import DefaultPage from '../../templates/DefaultPage'
import styled from 'styled-components'
import space from '../../../styles/variables/space'

const Container = styled.div`
  background-color: #fff;
  padding-top: ${space.lg}px;
  padding-bottom: ${space.lg}px;
`

const QrCodeCard = styled.div`
  // 名刺サイズ
  width: 345px;
  text-align: center;
  font-size: 12px;
`

const QrCodeImg = styled.img`
  width: 150px;
  height: 150px;
`

const Description = styled.div`
  padding-top: ${space.md}px;
  padding-left: ${space.md}px;
  font-size: 12px;
  width: 345px;
`

export interface Props {
  personName: string
  urlQrCodeData: string
}

export default function View(props: Props) {
  const {personName, urlQrCodeData} = props
  return (
    <DefaultPage back={true}>
      <Container>
        <QrCodeCard>
          <QrCodeImg src={urlQrCodeData} />
          <br />
          {personName}さんのたすけてQRページのURLです
          <br />
          このQRコードを読み取りウェブで閲覧して下さい
        </QrCodeCard>
        <Description>
          【読み取り方法】
          <br />
          ・Android 「QRコードリーダー」の場合
          <br />
          　→ 「ウェブサイトを閲覧」を選択
          <br />
          ・Android の場合（ほか）
          <br />
          　→ 「Googleレンズ」または「LINE」から読み取る
          <br />
          ・iPhone の場合 → カメラをかざして読み取る
        </Description>
      </Container>
    </DefaultPage>
  )
}
