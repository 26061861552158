import {useSelector} from 'react-redux'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'
import Nav from '../Nav'

const list = (noteId: string) => [
  {
    subheader: 'ノート',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'noteCover',
        noteId,
      })
    },
    icon: 'document2',
    content: 'ノートの表紙を見る',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'track',
        noteId,
      })
    },
    icon: 'calendar-check',
    content: '日々の記録をつける',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'noteDetail',
        noteId,
      })
    },
    icon: 'book',
    content: 'じぶんのことを見る',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'history',
        noteId,
      })
    },
    icon: 'history',
    content: 'ノートの編集履歴を見る',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'index',
      })
    },
    icon: 'arrow-left',
    content: 'ノート一覧へ戻る',
  },
  {
    onClick: () => {
      UseCaseCommander.create().command<PushNavigationUseCaseParams>(
        PushNavigationUseCase,
      )({
        routeKey: 'settingsNoteNotifications',
        noteId,
      })
    },
    icon: 'alarm-ringing',
    content: 'ノートのお知らせ設定',
  },
]

export default function NoteNav() {
  const noteId = useSelector(getCurrentNoteId)

  return Nav({
    list: list(noteId),
  })
}
