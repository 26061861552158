import * as React from 'react'
import styled from 'styled-components'
import NoteNav from '../../../containers/NoteNav'
import {borderRadius} from '../../../styles/mixins/border'
import {boxShadow} from '../../../styles/mixins/boxShadow'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Container from '../../layouts/Container'
import HelpLink from '../../molecules/HelpLink'
import EditNoteMembers, {Props as EditNoteMembersProps} from '../../organisms/EditNoteMembers'
import DefaultPage from '../../templates/DefaultPage'

const View = styled(Container)`
  margin-top: ${space.lg}px;
  margin-bottom: ${space.lg}px;
  padding-top: ${space.lg}px;
  // padding-bottom: ${space.lg}px;
  background-color: #fff;
  ${boxShadow};
  ${borderRadius};
`

const DescriptionOuter = styled(Body)`
  text-align: left;
`

const Description = styled(Body)`
  margin: ${space.md}px;
`

export type Props = EditNoteMembersProps & {
  title: string
  noteImageUrl: string
  visibleEditableExplain: boolean // 「編集可」の説明文を表示するかどうか
}

export default function EditNoteMembersPage(props: Props) {
  const {title, visibleEditableExplain, ...rest} = props
  return (
    <DefaultPage back={true} nav={NoteNav} title={title}>
      <View>
        <EditNoteMembers {...rest} />
        <DescriptionOuter type="body2">
          {visibleEditableExplain && (
            <>
              <Description>
                この画面では、メンバーの権限の変更をすることができます。
                詳しくはを
                <HelpLink url="https://jnote.jp/faq/whats-member-editable/">
                  ヘルプ
                </HelpLink>
                ご覧ください。
              </Description>
            </>
          )}

          <Description>
            ご家族、ご親戚、ご友人、または介護・医療関係者を招待して、
            メンバーになってもらいましょう。 ノートへ招待する方法は
            <HelpLink url="https://jnote.jp/faq/how-to-join/">ヘルプ</HelpLink>
            をご覧ください。
          </Description>

          <Description color={colors.gray400} type="caption">
            ※このページは、ノートの管理者だけに表示しています（管理者以外のメンバーには見えません）。
          </Description>
        </DescriptionOuter>
      </View>
    </DefaultPage>
  )
}
