import * as React from 'react'
import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import View, {Props as ViewProps} from '../../components/organisms/NoteDiaryItem'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteId} from '../../selectors/note'
import DeleteNoteTrackUseCase, {Params as DeleteNoteTrackUseCaseParams} from '../../useCases/track/DeleteNoteTrackUseCase'

export type Props = ViewProps

export default function NoteTrackDiary(props: Props) {
  const noteId = useSelector(getCurrentNoteId)
  const {trackId} = props
  const handleClickRemoveButton = useCallback(() => {
    UseCaseCommander.create().command<DeleteNoteTrackUseCaseParams>(
      DeleteNoteTrackUseCase,
    )({noteId, trackId})
  }, [noteId, trackId])
  return React.createElement(View, {
    ...props,
    onClickRemoveButton: handleClickRemoveButton,
  })
}
