import * as React from 'react'
import CheckboxRadioItems from '../../layouts/CheckboxRadioItems'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import {Props as FormedFieldProps} from '../FormedField'

export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FormedFieldProps & {
    options: KeySignature
  }

export default function FormedCheckboxRadioField({
  anyTouched,
  anyActive,
  disabled,
  options,
  errors,
  formDirty,
  formErrorMessage,
  data,
  formMeta,
  label,
  isProcessing,
  onClickCancel,
  onClickHistory,
  help,
  type,
  name,
  ...rest
}: Props) {
  return (
    <FormedFieldLayout
      active={anyActive || anyTouched || formDirty}
      dirty={formDirty}
      disabled={disabled}
      error={formErrorMessage}
      help={help}
      isProcessing={isProcessing}
      label={label}
      onClickCancel={onClickCancel}
      onClickHistory={onClickHistory}
    >
      <CheckboxRadioItems name={name} options={options} type={type} />
    </FormedFieldLayout>
  )
}
