import * as React from 'react'
import {useMemo} from 'react'
import styled from 'styled-components'
import {iconCss} from '../../../styles/fonts'
import {getIcon} from '../../../styles/icon'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {color, typography, TypographyProps} from '../../../styles/mixins/typography'

export type Props = {
  type: string
  cursor?: string
  onClick?: any
} & TypographyProps

const View = styled.i<
  TypographyProps & SpacingProps & {cursor?: string; type: string}
>`
  ${color}
  ${iconCss}
  ${typography}
  ${spacing};

  &::before {
    content: '${p => p.type}';
  }

  cursor: ${p => p.cursor};
`

const Icon = React.forwardRef<HTMLDivElement, Props>(
  ({size = 16, type, cursor, ...rest}: Props, ref) => {
    const cursorValue = cursor || (rest.onClick && 'pointer')
    const iconType = useMemo(() => {
      return getIcon(type)
    }, [type])
    return (
      // TODO: sizeがtypeのあとにないとsizeが有効にならない。typographyのtypeを別の単語にする・・・
      /* eslint-disable react/jsx-sort-props */
      <View
        type={iconType}
        size={size}
        {...rest}
        cursor={cursorValue}
        ref={ref}
      />
      /* eslint-enable react/jsx-sort-props */
    )
  },
)

export default Icon
