import * as React from 'react'
import {useCallback} from 'react'
import styled from 'styled-components'
import NoteDiaryCommentInput from '../../../containers/NoteDiaryCommentInput'
import NoteTrackCommentList from '../../../containers/NoteTrackCommentList'
import {useModalState} from '../../../selectors/app/dom'
import {spacing} from '../../../styles/mixins/spacing'
import Divider from '../../atoms/Divider'
import Paper from '../../atoms/Paper'
import LightboxImages from '../../molecules/LightboxImages'
import ShrinkedBody from '../../molecules/ShrinkedBody'
import TimelineHeader from '../../molecules/TimelineHeader'
import PostDiaryModal from '../PostDiaryModal'

export interface Props {
  avatarSrc: string
  avatarName: string
  name: string
  date: string
  timestamp: Date
  trackId: string
  diary: string
  editable: boolean
  deletable: boolean
  onClickRemoveButton?: () => void
  title: string
  image: string
}

const View = styled.div`
  ${spacing};
`
const Footer = styled.div``

const Outer = styled.div`
  ${spacing};
`

const CommentInputOuter = styled.div`
  ${spacing};
`

export default function NoteDiaryItem(props: Props) {
  const {
    avatarName,
    avatarSrc,
    date,
    deletable,
    editable,
    diary,
    name,
    trackId,
    timestamp,
    onClickRemoveButton,
    title,
    image,
    ...rest
  } = props
  const [editing, setEditing, pushed] = useModalState(false)
  const handleDismiss = useCallback(() => {
    setEditing(false)
  }, [editing, pushed])
  const handleEditButton = useCallback(() => {
    setEditing(true)
  }, [editing, pushed])
  return (
    <View {...rest}>
      <Paper>
        <PostDiaryModal
          content={diary}
          dismiss={handleDismiss}
          image={image}
          show={editing}
          timestamp={timestamp}
          trackId={trackId}
        />
        <TimelineHeader
          avatarSrc={avatarSrc}
          date={date}
          deletable={deletable}
          editable={editable}
          onClickEditButton={handleEditButton}
          onClickRemoveButton={onClickRemoveButton}
          title={`${avatarName}が日記を書きました`}
        />
        <Outer mt="xs">
          <ShrinkedBody expandLabel="全部見る" maxLength={500}>
            {diary}
          </ShrinkedBody>
        </Outer>
        {image && (
          <Outer mt="xs">
            <LightboxImages hasLightbox={true} sources={[image]} />
          </Outer>
        )}
        <Footer>
          <Divider py="sm" />

          <NoteTrackCommentList trackId={trackId} />

          <CommentInputOuter>
            <NoteDiaryCommentInput trackId={trackId} />
          </CommentInputOuter>
        </Footer>
      </Paper>
    </View>
  )
}
