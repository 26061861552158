import {createSelector} from 'reselect'
import UsersMe from '../../domain/account/UsersMe'
import Note from '../../domain/note/Note'
import {getMemberUsers} from '../../domain/note/NoteRelatedUser'
import {getMe} from '../../selectors/api'
import {getCurrentNote} from '../../selectors/note'

export const getNoteMembers = createSelector(
  getMe,
  getCurrentNote,
  (usersMe: UsersMe, note: Note) => {
    // FIXME: すでにstateにはdomainとして入っているはずでは？
    const members = getMemberUsers(note)
    // TODO: 名前設定ダイアログ
    return members.map(member => {
      const isMe = member.id === usersMe.id
      return {
        ...member,
        isMe,
        fullname: member.fullname,
      }
    })
  },
)
