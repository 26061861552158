import * as React from 'react'
import styled from 'styled-components'
import {ReduxField} from '../../../hocs/withReduxField'
import {ReduxFieldProps} from '../../../lib/form/types'
import space from '../../../styles/variables/space'
import FormedFieldLayout from '../../layouts/FormedFieldLayout'
import {Props as FormedFieldProps} from '../FormedField'
import StretchyTextarea from '../StretchyTextarea'

const Field = styled.div`
  //padding: ${space.sm}px 0 0;
  width: 100%;
`

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FormedFieldProps

const ReduxFormedTextarea = ({
  disabled,
  formDirty,
  meta,
  formMeta,
  label,
  help,
  isProcessing,
  onClickCancel,
  onClickHistory,
  ...rest
}: Props & ReduxFieldProps) => {
  const {active = false, touched = false, visited = false, error = ''} =
    meta || {}
  const err = touched ? error : ''
  const {attr: {minHeight = '4.0em'} = {}} = rest

  return (
    <FormedFieldLayout
      active={active || visited}
      dirty={formDirty}
      disabled={disabled}
      error={err}
      help={help}
      isProcessing={isProcessing}
      label={label}
      onClickCancel={onClickCancel}
      onClickHistory={onClickHistory}
    >
      <Field>
        <StretchyTextarea
          disabled={disabled}
          minHeight={minHeight}
          {...rest}
          {...rest.input}
        />
      </Field>
    </FormedFieldLayout>
  )
}

/**
 * type: freetext
 */
export default function FormedTextareaField(props: Props) {
  const {attr: {validators = []} = {}} = props
  return (
    <ReduxField
      {...props}
      component={ReduxFormedTextarea}
      validators={validators}
    />
  )
}
