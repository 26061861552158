import * as React from 'react'
import styled from 'styled-components'
import UserNotificationList from '../../../containers/UserNotificationList'
import useMediaQuery from '../../../styles/useMediaQuery'
import colors from '../../../styles/variables/colors'
import Body from '../../atoms/Body'
import ListItem from '../../layouts/ListItem'
import Menu from '../../molecules/Menu'

const View = styled.div``

const More = styled(Body)`
  width: 100%;
  text-align: center;
`

export interface Props {
  trigger: HTMLElement | null
  show: boolean
  dismiss: () => void
  onClickMore: () => void
}

const LIMIT = 6

export default function UserNotificationListMenu(props: Props) {
  const {dismiss, show, trigger, onClickMore} = props
  const tablet = useMediaQuery('tablet')
  const maxWidth = tablet ? '500px' : '300px'
  const menuPosition = {
    ...(tablet
      ? {left: 'auto', right: 54}
      : {left: 'calc(100% / 2 - 300px / 2)'}),
    ...(tablet ? {top: 60} : {top: 60}),
  }
  return (
    <View>
      {show && (
        <Menu
          {...menuPosition}
          fixed={true}
          onClose={dismiss}
          trigger={trigger}
          width={maxWidth}
        >
          <UserNotificationList limit={LIMIT} onClickItem={dismiss} />
          <ListItem onClick={onClickMore}>
            <More color={colors.link}>もっと見る</More>
          </ListItem>
        </Menu>
      )}
    </View>
  )
}
