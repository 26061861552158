import * as React from 'react'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {unregister} from '../../registerServiceWorker'
import NoticeUseCase, {
  Params as NoticeUseCaseParams,
} from '../../useCases/app/NoticeUseCase'
import {
  NOTIFICATION_TEMPORARY_MSEC,
  NotificationPrinciple,
  NotificationType,
} from './Notification'
import Body from '../../components/atoms/Body'
import {reload} from './WindowService'

export function noticeNewApplication() {
  const handleUnregister = () => {
    unregister()
    reload()
  }
  UseCaseCommander.create().command<NoticeUseCaseParams>(NoticeUseCase)({
    principle: NotificationPrinciple.temporary,
    type: NotificationType.toast,
    id: 'Refresh_application',
    message: (
      <Body onClick={() => handleUnregister()}>
        新しいバージョンが使えます！アプリを再起動します。
      </Body>
    ),
    icon: 'lamp',
  })
  // FIXME: setTimeout
  setTimeout(() => {
    handleUnregister()
  }, NOTIFICATION_TEMPORARY_MSEC)
}
