import NoteId from '../../domain/note/NoteId'
import INoteTrackRepository from '../../domain/note/track/INoteTrackRepository'
import {NoteTrackId, NoteTrackItemType} from '../../domain/note/track/NoteTrack'
import NoteTrackRepository from '../../infra/repositories/note/NoteTrackRepository'
import {
  NotificationPrinciple,
  NotificationType,
} from '../../services/app/Notification'
import NoticeUseCase from '../app/NoticeUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  noteTrackRepository: INoteTrackRepository
}

export interface Params {
  noteId: NoteId
  type: NoteTrackItemType
  data: any
  timestamp?: Date
  trackId?: NoteTrackId
  onComplete?: () => void
}

export default class PostNoteTrackUseCase extends UseCase {
  private _noteTrackRepository: INoteTrackRepository

  public constructor(props: Props) {
    super()
    this._noteTrackRepository = props.noteTrackRepository
  }

  public static create() {
    return new PostNoteTrackUseCase({
      noteTrackRepository: NoteTrackRepository.create(),
    })
  }

  public async execute({
    noteId,
    type,
    data,
    trackId,
    timestamp,
    onComplete,
  }: Params): Promise<void> {
    this.registerComplete(async () => {
      await this.subUseCaseExecutor(NoticeUseCase)({
        principle: NotificationPrinciple.temporary,
        type: NotificationType.snackbar,
        message: trackId ? '更新しました' : '投稿しました',
      })
      onComplete && onComplete()
    })
    if (trackId) {
      await this._noteTrackRepository.update({
        noteId,
        trackId,
        type,
        data,
        timestamp,
      })
    } else {
      await this._noteTrackRepository.post({noteId, type, data, timestamp})
    }
  }
}
