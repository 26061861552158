import NoteManager from '../../infra/app/NoteManager'
import {INoteManager} from '../../services/app/INoteManager'
import UseCase from '../lib/UseCase'
import {logNoteDetailTabToggled} from '../../services/log'

interface Props {
  noteManager: INoteManager
}

export interface Params {
  slideIndex: number
}

export default class ChangeNoteSlideUseCase extends UseCase {
  private _noteManager: INoteManager

  public constructor(props: Props) {
    super()
    this._noteManager = props.noteManager
  }

  public static create() {
    return new ChangeNoteSlideUseCase({
      noteManager: NoteManager.create(),
    })
  }

  public async execute({slideIndex}: Params): Promise<void> {
    const oldSlideIndex = this._noteManager.getCurrentNoteSlide()
    this._noteManager.setCurrentNoteSlide(slideIndex)
    if (oldSlideIndex !== slideIndex) {
      logNoteDetailTabToggled(slideIndex)
    }
  }
}
