import ApplicationService from '../../services/app/ApplicationService'
import UseCase from '../lib/UseCase'
import INoteMemberRepository from '../../domain/note/INoteMemberRepository'
import NoteMemberRepository from '../../infra/repositories/note/NoteMemberRepository'

interface Props {
  noteMemberRepository: INoteMemberRepository
}

export default class FetchNoteMembersUseCase extends UseCase {
  private noteMemberRepository: INoteMemberRepository

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this.noteMemberRepository = props.noteMemberRepository
  }

  /**
   * create
   */
  public static create() {
    return new FetchNoteMembersUseCase({
      noteMemberRepository: NoteMemberRepository.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute({noteId}: {noteId: string}): Promise<void> {
    const applicationService = ApplicationService.create()
    applicationService.toBusy()
    this.registerComplete(async () => {
      applicationService.toUnbusy()
    })
    await this.noteMemberRepository.getMembers(noteId)
  }
}
