import INoteRepository from '../../domain/note/INoteRepository'
import NoteRepository from '../../infra/repositories/note/NoteRepository'
import Navigation from '../../services/navigation/Navigation'
import UseCase from '../lib/UseCase'
import {NoteAttributesProps} from '../../domain/note/Note'
import {logNoteCreated} from '../../services/log'

interface Props {
  navigation: Navigation
  noteRepository: INoteRepository
}

export default class CreateNoteUseCase extends UseCase {
  private noteRepository: INoteRepository
  private navigation: Navigation

  public constructor(props: Props) {
    super()
    this.navigation = props.navigation
    this.noteRepository = props.noteRepository
  }

  public static create() {
    return new CreateNoteUseCase({
      navigation: Navigation.create(),
      noteRepository: NoteRepository.create(),
    })
  }

  public async execute(params: NoteAttributesProps): Promise<void> {
    const note = await this.noteRepository.create(params)
    if (!note) {
      return
    }

    logNoteCreated(note.id, params)

    this.navigation.replaceByHistory('noteCover', {noteId: note.id})
    this.navigation.push('noteCover', {noteId: note.id})
  }
}
