import * as React from 'react'
import styled, {css} from 'styled-components'
import {borderRadius} from '../../../styles/mixins/border'
import {font} from '../../../styles/mixins/font'
import {spacing, SpacingBreakPointsProps} from '../../../styles/mixins/spacing'
import transition from '../../../styles/mixins/transition'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'

enum Size {
  sm = 'sm',
  basic = 'basic',
  large = 'large',
}

const getSize = {
  [Size.sm]: (props: StyledButtonProps) => css`
    padding: ${space.xs - 2}px ${space.sm - 2}px;
    ${font(10)};
  `,
  [Size.basic]: (props: StyledButtonProps) => css`
    padding: ${space.xs}px ${space.sm}px;
    ${font(13)};
  `,
  [Size.large]: (props: StyledButtonProps) => css`
    padding: ${space.sm + 4}px ${space.md}px;
    ${font(18)};
  `,
}

interface StyledButtonProps {
  backgroundColor?: string
  color?: string
  expanded?: boolean
  size?: Size | keyof typeof Size
}

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: string
  children: React.ReactNode
} & StyledButtonProps

const StyledButton = styled.button<StyledButtonProps & SpacingBreakPointsProps>`
  padding: ${space.xs}px ${space.md}px;
  ${p => getSize[p.size || Size.basic]};
  ${spacing};
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  appearance: none;
  border: solid 1px ${p => p.backgroundColor};
  background-color: ${p => p.backgroundColor};
  color: ${p => p.color};
  ${transition};
  ${borderRadius};


  width: ${p => (p.expanded ? '100%' : 'auto')};
  cursor: pointer;

  &:disabled {
    border: solid 1px ${colors.gray400};
    color: ${colors.gray400};
    background-color: transparent;
    opacity: 0.5;
    cursor: auto;
  }
`

/**
 * ボタン
 */
export default React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      color = colors.white,
      backgroundColor = colors.brand,
      children,
      type = 'submit',
      size = Size.basic,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <StyledButton
        backgroundColor={backgroundColor}
        color={color}
        ref={ref}
        size={size}
        type={type}
        {...props}
      >
        {children}
      </StyledButton>
    )
  },
)
