import * as React from 'react'
import getNoteComponentType from '../../../containers/NoteComponent'
import NoteSectionGroup from '../../../containers/NoteSectionGroup'
import NoteSwiper from '../../../containers/NoteSwiper'
import {
  NodePolicyType,
  NodePolicyTypes,
  Tree,
  TreeContainer,
  TreeContainerType,
  TreeContainerTypes,
  TreeLeaf,
  TreeLeafType,
} from '../../../domain/note/snapshot/Tree'
import {RelationType} from '../../../infra/api/types'
import NoteSection from '../../layouts/NoteSection'

export interface NoteContainerComponentProps {
  attr?: KeySignature
  type: TreeContainerType
  key: string
}

export type NoteLeafComponentProps = NoteContainerComponentProps & {
  attr: {
    validators?: string[]
    suffix?: string
    [key: string]: any
  }
  data?: KeySignature
  policyType?: NodePolicyType
  help?: string | string[]
  type: TreeLeafType
  name: string
  form: string
  noteId: string
  disabled: boolean
  initialValues: KeySignature
}

export type NoteComponentProps =
  | NoteContainerComponentProps
  | NoteLeafComponentProps

const getPagination = ({tree}: TreeContainer) => {
  return (
    tree &&
    tree.map(container => {
      const {attr: {title = ''} = {}} = container || {}
      return title
    })
  )
}

// TODO: ちゃんとやる
let slideId = -1
const getFormGroup = (
  noteId: string,
  tree: Tree,
  children: React.ReactNode[],
) => {
  const {attr = {}, type, id} = tree
  const isSwiper = type === TreeContainerTypes.root
  isSwiper && (slideId = -1)
  const elementType =
    {
      [TreeContainerTypes.root]: NoteSwiper,
      [TreeContainerTypes.h1]: NoteSectionGroup,
      [TreeContainerTypes.h2]: NoteSection,
      [TreeContainerTypes.h3]: NoteSection,
    }[tree.type] || 'div'
  tree.type === TreeContainerTypes.h1 && slideId++
  const props = isSwiper
    ? {
        pagination: getPagination(tree as TreeContainer),
        key: id,
      }
    : {key: id, id, type, slideId, ...attr}
  return React.createElement(
    elementType as any /*FIXME: types*/,
    props as any /*FIXME: types*/,
    children,
  )
}

const getProps = (
  noteId: string,
  relationType: RelationType,
  container: Tree,
): NoteComponentProps => {
  const {attr, id, type} = container
  if (TreeContainerTypes.hasOwnProperty(type)) {
    return {
      // attr: attr,
      ...attr,
      type: type as TreeContainerType,
      key: id,
    }
  }
  const {data, policyType = NodePolicyTypes.default} = container as TreeLeaf
  return {
    attr,
    data,
    name: id,
    key: id,
    form: `${id}-form`,
    noteId,
    type: type as any,
    initialValues: typeof data === 'string' ? {[id]: data} : data,
    policyType,
  }
}

export const generate = (
  noteId: string,
  relationType: RelationType,
  container: Tree | undefined,
) => {
  if (!container || !Object.keys(container).length) return null
  const {tree = []} = (container as TreeContainer) || {}
  const children: React.ReactNode[] = tree.map((child: Tree) => {
    return generate(noteId, relationType, child)
  })
  const ret = React.createElement<any /*FIXME: type*/>(
    getNoteComponentType(container.type),
    getProps(noteId, relationType, container),
    null,
  )
  return !children.length ? ret : getFormGroup(noteId, container, [...children])
}

export const generateFiltered = (
  noteId: string,
  relationType: RelationType,
  filteredMap: {[key: string]: TreeLeaf} | TreeLeaf[],
) => {
  if (!filteredMap || !Object.keys(filteredMap).length) return null
  return Object.keys(filteredMap).map(key => {
    const field = filteredMap[key]
    return React.createElement<any /*FIXME: type*/>(
      getNoteComponentType(field.type),
      getProps(noteId, relationType, field as Tree),
      null,
    )
  })
}
