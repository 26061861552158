import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getLocation} from '../../infra/redux/router/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {RouteKey} from '../../routes'
import {RouteScope} from '../../routes/routes'
import * as sessionSelectors from '../../selectors/app/session'
import {windowScrollTop} from '../../services/app/WindowService'
import Navigation from '../../services/navigation/Navigation'
import PushNavigationUseCase, {PushNavigationUseCaseParams} from '../../useCases/app/PushNavigationUseCase'

interface Props {
  children: React.ReactNode
  title?: string
  header?: React.ReactNode
}

export default function Private(props: Props) {
  const {pathname = ''} = useSelector(getLocation)
  const isAuthorized = useSelector(sessionSelectors.isAuthorized)
  // FIXME: 無限ループ回避したい
  const {scope, path} = Navigation.create().getRouteByPathname(pathname)
  const isPrivate = scope === RouteScope.private
  const renderable = !isPrivate || (isPrivate && isAuthorized)
  if (!isAuthorized && isPrivate) {
    const isIndex = path === '/'
    const redirectRouteKey: RouteKey = isIndex ? 'index' : 'login'
    UseCaseCommander.create().command<PushNavigationUseCaseParams>(
      PushNavigationUseCase
    )({
      routeKey: redirectRouteKey,
      redirectPathname: pathname
    })
  }
  useEffect(() => {
    windowScrollTop()
  }, [pathname])
  return renderable ? React.createElement(React.Fragment, {...props}) : null
}
