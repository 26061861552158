import IAuthenticationService from '../../domain/auth/IAuthenticationService'
import AuthenticationService from '../../infra/services/AuthenticationService'
import {NotificationPrinciple, NotificationType} from '../../services/app/Notification'
import Navigation from '../../services/navigation/Navigation'
import NoticeUseCase from '../app/NoticeUseCase'
import ToggleMenuUseCase from '../app/ToggleMenuUseCase'
import UseCase from '../lib/UseCase'

interface Props {
  authenticationService: IAuthenticationService
  navigation: Navigation
}

/**
 * SignUpUseCase
 */
export default class SignOutUseCase extends UseCase {
  private _authenticationService: IAuthenticationService
  private _navigation: Navigation

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    super()
    this._authenticationService = props.authenticationService
    this._navigation = props.navigation
  }

  /**
   * create
   */
  public static create() {
    return new SignOutUseCase({
      authenticationService: AuthenticationService.create(),
      navigation: Navigation.create(),
    })
  }

  /**
   * execute
   *
   * @param params - Params
   */
  public async execute(): Promise<void> {
    await this._authenticationService.signOut().catch(() => {})
    this.subUseCaseExecutor(NoticeUseCase)({
      principle: NotificationPrinciple.temporary,
      type: NotificationType.snackbar,
      message: 'ログアウトしました。',
    })
    this.subUseCaseExecutor(ToggleMenuUseCase)({
      hide: true,
    })
    this._navigation.push('login')
  }
}
