import INoteRepository from '../../../domain/note/INoteRepository'
import Note, {
  NoteAttributesProps,
  NotePropsBase,
} from '../../../domain/note/Note'
import AppAPI from '../../api/AppAPI'
import {actions} from '../../redux/api/entities'
import {NoteSchema} from '../../redux/api/schemata'
import {getNoteById} from '../../redux/api/selectors'
import ReduxProvider from '../../redux/ReduxProvider'

interface Props {
  api: AppAPI
  redux: ReduxProvider
}

export default class NoteRepository implements INoteRepository {
  private api: AppAPI
  private redux: ReduxProvider

  /**
   * create
   *
   */
  public static create(): NoteRepository {
    const api = AppAPI.create()
    const redux = ReduxProvider.create()
    return new NoteRepository({api, redux})
  }

  /**
   * constructor
   *
   * @param props - Props
   */
  public constructor(props: Props) {
    this.api = props.api
    this.redux = props.redux
  }

  private mergedNoteWithState<AnyNote extends Note>({
    id,
    newProps,
  }: {
    id: string
    newProps: AnyNote
  }): AnyNote {
    const note = getNoteById(id)(this.redux.getState())
    const newNote = {...note, ...newProps}
    return newNote
  }

  public async getNote(id: string): Promise<Note> {
    const client = await this.api.getClient()
    const res = await client.getNote({id})
    const note = this.mergedNoteWithState({id, newProps: res})
    this.redux.dispatch(actions.store(note, NoteSchema))
    return note
  }

  public async create(params: NoteAttributesProps): Promise<Note> {
    const client = await this.api.getClient()
    const note = await client.createNote(params)
    this.redux.dispatch(actions.store(note, NoteSchema))
    return note
  }

  public async updateAttrs(params: NotePropsBase): Promise<Note> {
    const client = await this.api.getClient()
    const res = await client.updateNoteAttrs(params)
    const note = this.mergedNoteWithState({id: res.id, newProps: res})
    this.redux.dispatch(actions.store(note, NoteSchema))
    return note
  }
}
