import AppError from './AppError'
import {DEFAULT_ERROR_BEHAVIOR, errorHandlers, IErrorBehavior} from './handlers'

let idIndex = 0

const createId = () => {
  const id = idIndex++
  return id.toString()
}

export const createAppError = (error?: Error): AppError => {
  let behavior: IErrorBehavior = DEFAULT_ERROR_BEHAVIOR
  for (const handler of errorHandlers) {
    const b = handler(error)
    if (b) {
      behavior = b
      break
    }
  }
  return {
    actions: behavior.actions,
    original: error,
    id: createId(),
    message: behavior.message,
    raisedAt: new Date(),
    // type,
  }
}
