import * as React from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/layouts/Tabs'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getCurrentNoteSlideId} from '../../selectors/note'
import ChangeNoteSlideUseCase, {
  Params as ChangeNoteSlideUseCaseParams,
} from '../../useCases/app/ChangeNoteSlideUseCase'

export interface Props {
  items: string[]
}

export default function NoteTabs(props: Props) {
  const slideIndex = useSelector(getCurrentNoteSlideId)
  return React.createElement(View, {
    ...props,
    tabIndex: slideIndex,
    onChangeTab: (tabIndex: number) => {
      UseCaseCommander.create().command<ChangeNoteSlideUseCaseParams>(
        ChangeNoteSlideUseCase,
      )({slideIndex: tabIndex})
    },
  })
}
