import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import View from '../../components/pages/UserNotificationsPage'
import {getMeName} from '../../domain/account/UsersMe'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import {getMe} from '../../selectors/api'
import {getUserNotifications} from '../../selectors/api/usersMe'
import FetchUserTimelineUseCase from '../../useCases/account/FetchUserTimelineUseCase'

export default function UserNotificationsPage() {
  const usersMe = useSelector(getMe)
  const meName = getMeName(usersMe)
  const {length} = useSelector(getUserNotifications)

  useEffect(() => {
    length === 0 &&
      UseCaseCommander.create().command(FetchUserTimelineUseCase)()
  }, [])

  return React.createElement(View, {
    userName: meName,
  })
}
