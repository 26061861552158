import * as React from 'react'
import styled from 'styled-components'
import {spacing, SpacingProps} from '../../../styles/mixins/spacing'
import {
  fontWeight,
  letterSpacing,
  lineHeight,
  typography,
  TypographyProps,
} from '../../../styles/mixins/typography'

type MediaProps = TypographyProps & SpacingProps

export type Props = {
  desktop?: MediaProps
} & MediaProps

// FIXME: 存在しないcsspropertyで出力してしまう
const StyledHeading = styled.div<Props>`
  ${lineHeight};
  ${fontWeight};
  ${letterSpacing};
  ${typography};
  ${spacing};
  word-break: break-all;
`

export default function Heading({type = 'heading1', ...rest}: Props) {
  return <StyledHeading type={type} {...rest} />
}
