import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import container from '../../../styles/mixins/container'
import {spacing} from '../../../styles/mixins/spacing'
import colors from '../../../styles/variables/colors'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import TextButton from '../../atoms/TextButton'
import EditAccountDialog from '../../organisms/EditAccountDialog'
import NoteInvitationCard from '../../organisms/NoteInvitationCard'

const View = styled.div`
  ${container};
  padding-top: ${space.xl}px;
  padding-bottom: ${space.xl}px;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
`

const ButtonContainer = styled.div`
  ${spacing};
  text-align: center;
`

const Description = styled.div`
  margin: ${space.lg}px;
  text-align: left;
`

const RequestButton = styled.div`
  display: flex;
  align-items: center;
`
const AlreadyMember = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.success};
`

export interface Props {
  title: string
  imageUrl: string
  inviterName: string
  visibleAccountForm: boolean
  visibleFullnameRequest: boolean
  visibleAlreadyMemberAlert: boolean
  visibleAlreadyRequestedAlert: boolean
  enableRequestButton: boolean
  clickRequestButton: () => void
  clickCreateAccountLink: () => void
  clickLoginLink: () => void
  clickNoteLink: () => void
}

export default function NoteInvitationPage(props: Props) {
  const [showEditAccountDialog, setShowEditAccountDialog] = useState(false)
  const {
    imageUrl,
    title,
    inviterName,
    visibleAccountForm,
    visibleFullnameRequest,
    visibleAlreadyMemberAlert,
    visibleAlreadyRequestedAlert,
    enableRequestButton,
    clickRequestButton,
    clickCreateAccountLink,
    clickLoginLink,
    clickNoteLink,
  } = props

  const requestButton = enableRequestButton && (
    <RequestButton>
      <Icon mr="xxs" type="arrow-up-circle" />
      <TextButton
        onClick={() =>
          visibleFullnameRequest
            ? setShowEditAccountDialog(true)
            : clickRequestButton()
        }
      >
        申請する
      </TextButton>
    </RequestButton>
  )
  const requestedMember = visibleAlreadyRequestedAlert && (
    <AlreadyMember>
      <Icon mr="xxs" type="checkmark-circle" />
      <TextButton color={colors.success}>申請中</TextButton>
    </AlreadyMember>
  )
  return (
    <View>
      <EditAccountDialog
        dismiss={() => setShowEditAccountDialog(false)}
        isShow={showEditAccountDialog && visibleFullnameRequest}
        message="招待状へ申請するにはあなたの「お名前」が必要です。\n「お名前」を入力しましょう！"
        onSuccess={clickRequestButton}
      />
      <Content>
        <NoteInvitationCard
          action={requestButton || requestedMember}
          body={`招待してくれた方: ${inviterName}`}
          headerTitle={`${title}への招待状`}
          thumbnailImageUrl={imageUrl}
        />
        {enableRequestButton && (
          <ButtonContainer mt="lg">
            <Button
              onClick={() =>
                visibleFullnameRequest
                  ? setShowEditAccountDialog(true)
                  : clickRequestButton()
              }
            >
              このノートへの参加を申請する
            </Button>
          </ButtonContainer>
        )}
        <Description>
          {visibleAccountForm && (
            <Body>
              ノートへ参加するために「じぶんノート」のアカウントを作成しましょう。
              <TextButton onClick={clickCreateAccountLink}>
                アカウント作成ページへ
              </TextButton>
              <Body mt="lg" type="body2">
                アカウント作成済みの方はログインしてください。
                <TextButton onClick={clickLoginLink}>
                  ログインページへ
                </TextButton>
              </Body>
            </Body>
            /* TODO: AccountForm or link to login */
          )}
          {visibleAlreadyMemberAlert && (
            <Body>
              あなたはすでに「{title}」のメンバーです。
              <TextButton onClick={clickNoteLink}>ノート一覧</TextButton>
              から「{title}」を閲覧してください。
            </Body>
          )}
          {visibleAlreadyRequestedAlert && (
            <div>
              <Body>
                おめでとうございます！ 「{title}」への参加を申し込みました。
                {inviterName}
                さんに連絡して、参加への申請を確認してもらいましょう。
              </Body>
              <ButtonContainer mt="lg">
                <Button onClick={clickNoteLink}>ノート一覧へ</Button>
              </ButtonContainer>
            </div>
          )}
        </Description>
      </Content>
    </View>
  )
}
