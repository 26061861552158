import * as React from 'react'
import styled from 'styled-components'
import NoteHistoryItem, {Props as NoteHistoryItemProps} from '../../../containers/NoteHistoryItem'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Divider from '../../atoms/Divider'
import Loading from '../../atoms/Loading'
import List from '../../layouts/List'
import ListItem from '../../layouts/ListItem'
import ViewportObserver from '../../layouts/ViewportObserver'
import Empty from '../../molecules/Empty'

export interface Props {
  list: NoteHistoryItemProps[]
  hasNext: boolean
  onViewed: () => void
}

const Pager = styled.div`
  padding-top: ${space.xxl}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export default function History(props: Props) {
  const {hasNext, list, onViewed} = props
  const items = list.map((item: NoteHistoryItemProps, index: number) => {
    return (
      <React.Fragment key={index}>
        <ListItem>
          <NoteHistoryItem {...item} />
        </ListItem>
        <Divider />
      </React.Fragment>
    )
  })
  return (
    <React.Fragment>
      {items.length > 0 && (
        <List>
          {items}
          {hasNext && (
            <ViewportObserver onBelow={onViewed}>
              <Pager>
                <Loading />
                <Body mt="sm">読み込み中...</Body>
              </Pager>
            </ViewportObserver>
          )}
        </List>
      )}
      {items.length === 0 && (
        <Empty
          body={
            <span>
              ノートを編集すると、このページに表示されます。
              <br />
              ノートを編集してみましょう!
            </span>
          }
        />
      )}
    </React.Fragment>
  )
}
