import * as React from 'react'
import styled from 'styled-components'
import {omit} from '../../../lib'
import {IFieldCustomProps} from '../../../lib/form/types'
import {NoteLeafComponentProps} from '../../helpers/snapshot'
import {spacing} from '../../../styles/mixins/spacing'

const Form = styled.form`
  width: 100%;
  height: 100%;

  ${spacing};
`

// from InjectedFormProps
export interface FormedProps {
  anyActive: boolean
  anyTouched: boolean
  formDirty: boolean
  error: string
  errors: KeySignature
  formErrorMessage: string
  formMeta: {
    [key: string]: {active?: boolean; touched?: boolean; visited: boolean}
  }
  form: string
  initialized: boolean
  initialValues: KeySignature
  invalid: boolean
  pristine: boolean
  valid: boolean
  warning: any
  handleSubmit: (any: any) => any
  submitForm: (values: any) => void
}

export type Props = FormedProps &
  NoteLeafComponentProps &
  IFieldCustomProps & {
    label: string
    isProcessing: boolean
    onClickCancel: () => void
    onClickHistory: () => void
    component: React.ComponentType<any> /* fixme: types */
  }

export default function FormedField(props: Props) {
  const {attr, component, handleSubmit, submitForm} = props
  // _reduxFormを除外しないとエラー
  const omittedProps = omit(props as Props & {_reduxForm: any}, ['_reduxForm'])

  const FormedComponent = component
  return (
    <Form autoComplete="off" onSubmit={handleSubmit(submitForm)}>
      <FormedComponent {...attr} /* FIXME: attr のあり方*/ {...omittedProps} />
    </Form>
  )
}
