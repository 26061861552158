import * as React from 'react'
import styled from 'styled-components'
import PushNavigationLink from '../../../containers/PushNavigationLink'
import SignUpForm from '../../../containers/SignUpForm'
import container from '../../../styles/mixins/container'
import space from '../../../styles/variables/space'
import Body from '../../atoms/Body'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Icon from '../../atoms/Icon'
import Paper from '../../atoms/Paper'
import TextButton from '../../atoms/TextButton'
import UnauthorizedHeader from '../../organisms/UnauthorizedHeader'
import DefaultPage from '../../templates/DefaultPage'
import ListItem from '../../layouts/ListItem'
import HelpLink from '../../molecules/HelpLink'

const View = styled.div`
  padding-top: ${space.xl}px;
  padding-bottom: ${space.xxl}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

const Panel = styled(Paper)`
  ${container};
  padding-top: ${space.xl}px;
  padding-bottom: ${space.xl}px;
`

const Outer = styled.div`
  padding-top: ${space.lg}px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  text-align: center;
`

const Header = styled.header`
  text-align: center;
`

const Description = styled(Body)`
  text-align: left;
`

const Login = styled.div`
  ${container};
  text-align: center;
`

export default function SignUpPage() {
  return (
    <DefaultPage
      allowRobots={true}
      header={UnauthorizedHeader}
      titleTag="新規アカウント作成"
    >
      <View>
        <Panel>
          <Header>
            <Heading mb="md" size={'heading3'}>
              じぶんノートへようこそ
            </Heading>
            <Description>
              「じぶんノート」は、 “じぶん”
              のことを伝えるための取扱説明書のようなものです。
              <br />
              <br />
              「“じぶん” ってこんなひとです」
              <br />
              「最近こんな変化がありました」
              <br />
              <br />
              じぶん用・お子さん用・ご両親用などなど、使い方はさまざまで、一人一人のニーズに合わせた使い方が可能です。ノートの中身は、性格やコミュニケーション方法、身体的状況、必要な介助、生活のリズムなど多岐にわたる項目が用意されています。
              <HelpLink url="https://jnote.jp/faq/whats-jnote/">
                →さらに詳しく
              </HelpLink>
              <br />
              <br />
              情報のプライバシーは設定で管理できるので安心です。
              <br />
              まずは無料でアカウント登録をしましょう。
            </Description>
          </Header>
          <Outer>
            <Heading mb="sm" size={'heading1'}>
              無料でアカウント登録
            </Heading>
            <SignUpForm />
          </Outer>
        </Panel>
        <Login>
          <Body mt="xl">
            すでにアカウントをお持ちの方は
            <br />
            ログインしましょう。
          </Body>
          <PushNavigationLink
            routeKey="login"
            view={props => (
              <Button mt="md" type="button" {...props}>
                ログイン
                <Icon ml="xs" type="enter" />
              </Button>
            )}
          />
        </Login>
      </View>
    </DefaultPage>
  )
}
