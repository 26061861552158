import {connect} from 'react-redux'
import {compose} from 'recompose'
import {getFormValues, isDirty, isInvalid, reduxForm} from 'redux-form'
import View, {Props} from '../../components/molecules/EditableText'
import {NoteTimelineItemType} from '../../domain/note/timeline/NoteTimeline'
import {IReduxState} from '../../infra/redux'
import * as useCaseSelectors from '../../infra/redux/useCase/selectors'
import UseCaseCommander from '../../infra/redux/useCase/UseCaseCommander'
import FormService from '../../infra/services/FormService'
import {HandleSubmitProps} from '../../lib/form/types'
import {getCurrentNoteId} from '../../selectors/note'
import {Forms} from '../../services/forms'
import UploadResourceUseCase from '../../useCases/resource/UploadResourceUseCase'
import PostTimelineUseCase from '../../useCases/timeline/PostTimelineUseCase'
import UpdateNoteTimelineUseCase from '../../useCases/timeline/UpdateNoteTimelineUseCase'

export interface OwnProps {
  type: NoteTimelineItemType
  timelineId?: string
  content: string
  dismiss: () => void
  editing: boolean
  name: string
  onComplete: () => void
  placeholder: string
  shrinkable?: boolean
  image?: string
}

export type FormData = unknown

export default compose<Props & HandleSubmitProps, OwnProps>(
  connect(
    (state: IReduxState, ownProps: OwnProps): Props => {
      const noteId = getCurrentNoteId(state)
      const form = `${Forms.noteTimeline}-${ownProps.timelineId}`
      const {image = ''} =
        (getFormValues(form)(state) as any) /*fixme: type*/ || {}
      return {
        ...ownProps,
        form,
        text: ownProps.content,
        initialValues: {
          [ownProps.name]: ownProps.content,
          image: ownProps.image,
        },
        image,
        timelineId: ownProps.timelineId,
        onClickCancelButton: () => {
          FormService.create().reset(form)
          ownProps.dismiss && ownProps.dismiss()
        },
        submitForm: data => {
          if (ownProps.timelineId) {
            UseCaseCommander.create().command(UpdateNoteTimelineUseCase)({
              noteId,
              type: ownProps.type,
              timelineId: ownProps.timelineId,
              onComplete: () => ownProps.onComplete(),
              data,
            })
          } else {
            UseCaseCommander.create().command(PostTimelineUseCase)({
              noteId,
              type: ownProps.type,
              onComplete: () => ownProps.onComplete(),
              data,
            })
          }
        },
        submitDisabled:
          !isDirty(form)(state) ||
          isInvalid(form)(state) ||
          useCaseSelectors.isProcessing(UploadResourceUseCase)(state),
        isProcessing: ownProps.timelineId
          ? useCaseSelectors.isProcessing(UpdateNoteTimelineUseCase)(state)
          : useCaseSelectors.isProcessing(PostTimelineUseCase)(state),
      }
    },
  ),
  reduxForm<FormData>({
    enableReinitialize: true,
  }),
)(View)
