import {getFormValues, isInvalid} from 'redux-form'
import {createSelector} from 'reselect'
import {FormData} from '../../components/organisms/LoginForm'
import {loginForm} from '../../services/forms'

export const getValues = createSelector(
  getFormValues(loginForm.id) as any /*FIXME*/,
  (values: FormData) => {
    const {email = '', password = '', isAutoLogout = false} = values || {}
    return {email, password, isAutoLogout}
  },
)

export const isSubmitDisabled = createSelector(
  isInvalid(loginForm.id),
  (formIsInvalid: boolean) => {
    return formIsInvalid
  },
)
