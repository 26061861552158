import {getThemeValue} from '../variables'

interface Props {
  prop: string
  themeKey?: string
  transform?: (value: any) => any
}

/**
 * themeからデフォルトのcss値をstyleする
 *
 * @param prop
 * @param themeKey
 */
export default function style<V>({prop, themeKey, transform}: Props) {
  return (props: any) => {
    const propValue = transform ? transform(props[prop]) : props[prop]
    const cssValue = getThemeValue({
      themeKey,
      path: prop,
    })
    return {
      [prop]: propValue || cssValue,
    }
  }
}
